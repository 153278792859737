const { ThemeType } = require("../constants");

const truthy = (val) => ['1', 'y', 'true'].includes(typeof val == 'number' ? val.toString() : (val || '').toLowerCase());

module.exports = {
    config: {
        //sys
        publicUrl: process.env.PUBLIC_URL,
        isDebug: process.env.NODE_ENV === 'development' || truthy(process.env.REACT_APP_IS_DEBUG),
        enableMultiLanguage: truthy(process.env.REACT_APP_ENABLE_MULTI_LANGUAGE),
        enableAuth0MultiLanguage: truthy(process.env.REACT_APP_ENABLE_AUTH0_MULTI_LANGUAGE),

        version: process.env.REACT_APP_VERSION,

        obtUrl: process.env.REACT_APP_OBT_URL,
        obtApiUrl: process.env.REACT_APP_OBT_API_URL,
        obtApiToken: process.env.REACT_APP_OBT_API_TOKEN,
        obtApiClientId: process.env.REACT_APP_OBT_API_CLIENT_ID,

        localhostUrl: process.env.REACT_APP_LOCALHOST_URL,
        logoutUrl: process.env.REACT_APP_LOGOUT_URL,
        serviceTimeout: process.env.REACT_APP_SERVICE_TIMEOUT,

        ssoAppId: process.env.REACT_APP_SSO_APP_ID,
        ssoSecret: process.env.REACT_APP_SSO_SECRET,

        //auth0
        auth0Domain: process.env.REACT_APP_AUTH0_DOMAIN,
        auth0ClientID: process.env.REACT_APP_AUTH0_CLIENT_ID,
        auth0RedirectUri: process.env.REACT_APP_AUTH0_REDIRECT_URI,
        auth0ExternalLinkKey: process.env.REACT_APP_AUTH0_EXTERNAL_LINK_KEY,

        mapboxToken: process.env.REACT_APP_MAPBOX_TOKEN,
        mapboxDarkStyle: process.env.REACT_APP_MAPBOX_DARK_STYLE,
        mapboxLightStyle: process.env.REACT_APP_MAPBOX_LIGHT_STYLE,

        signalRHubUrl: process.env.REACT_APP_SIGNALR_HUB_URL,
        resetPwdRoute: process.env.REACT_APP_RESETPWD_ROUTE,
        externalSsoIdentifier: process.env.REACT_APP_EXTERNAL_SSO_IDENTIFIER,
        enableLightDarkMode: truthy(process.env.REACT_APP_ENABLE_LIGHT_DARK_MODE)
    }
}