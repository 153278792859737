//#region action type
export const OTP_NOTIFY_STATUS = 'OTP_NOTIFY_STATUS';
export const OTP_GET_METADATA = 'OTP_GET_METADATA';
export const OTP_GET_METADATA_SUCCESS = 'OTP_GET_METADATA_SUCCESS';
export const OTP_GET_METADATA_FAILURE = 'OTP_GET_METADATA_FAILURE';
//#endregion

//#region action creator
export const notifyStatus = (payload) => ({ type: OTP_NOTIFY_STATUS, payload });
export const getMetadata = () => ({ type: OTP_GET_METADATA });
export const getMetadataSuccess = (payload) => ({ type: OTP_GET_METADATA_SUCCESS, payload });
export const getMetadataFailure = (payload) => ({ type: OTP_GET_METADATA_FAILURE, payload });
//#endregion

//#region reducer
const initState = {
    notify: {
        ReferenceNumber: null,
        TipMsg: null,
        Status: null,
        CurrentStep: null,
        MakeBookingDone: false,
    },
    metadata: null,
}

const tripProposal = (state = initState, action) => {
    switch (action.type) {
        case OTP_NOTIFY_STATUS:
            return {
                ...state,
                notify: {
                    ...action.payload,
                },
            };
        case OTP_GET_METADATA:
            return {
                ...state,
                metadata: null,
                errMsg: '',
                loading: true,
            };
        case OTP_GET_METADATA_SUCCESS:
            return {
                ...state,
                metadata: action.payload,
                loading: false,
            };
        case OTP_GET_METADATA_FAILURE:
            return {
                ...state,
                errMsg: action.payload,
                loading: false,
            };
        default:
            return state
    }
}
//#endregion

export default tripProposal;
