import { getMetadataSuccess, getMetadataFailure, OTP_GET_METADATA } from '../tripProposal';
import { put, takeLatest, call, select } from 'redux-saga/effects';
import { getError } from '~/common';
import { getMetadata as getMetadataAPI } from '~/client';

function* getMetadataSaga() {
    try {
        const metaPayload = yield call(getMetadataAPI, {});
        const errMsg = getError(metaPayload);
        if (!errMsg) {
            yield put(getMetadataSuccess(metaPayload));
        } else {
            yield put(getMetadataFailure(errMsg));
        }
    } catch (ex) {
        yield put(getMetadataFailure(ex.toString()));
    }
}

export default function* () {
    yield takeLatest(OTP_GET_METADATA, getMetadataSaga);
}