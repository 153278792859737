import React, { Fragment } from 'react';
import { Select } from 'antd';
import { searchAirline } from '~/client';
import { debounce, includesSpecialCharacter } from '~/common';
import PropTypes from 'prop-types';

const Option = Select.Option;

class AirlineSelect extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            dataSource: [],
            value: [],
        };
        this.handleSearch = debounce(this.handleSearch, 300);
    }

    componentDidMount() {
        this.mounted = true;
    }

    componentWillUnmount() {
        this.mounted = false;
    }

    componentDidUpdate(prevProps, prevState) {

    }

    handleSearch = val => {
        val = val || '';
        searchAirline(val, (err, data) => {
            let dataSource = [];
            if (data && data.datas) {
                Object.keys(data.datas).forEach(key => {
                    dataSource.push({ value: key, text: data.datas[key] });
                });
            }
            this.mounted && this.setState({ dataSource });
        });
    }

    render() {
        const { placeholder, onChange } = this.props;
        const { dataSource, value } = this.state;
        return <Select
            mode="multiple"
            labelInValue
            value={value}
            placeholder={placeholder}
            notFoundContent={null}
            filterOption={false}
            optionLabelProp="text"
            onSearch={this.handleSearch}
            onChange={value => {
                this.setState({ value, dataSource: [] });
                onChange(value ? value.map(v => ({ code: v.key, name: v.label })) : []);
            }}
        >
            {dataSource && dataSource.map(d => (
                <Option key={d.value} text={d.value}>{d.text}</Option>
            ))}
        </Select>;
    }
}
AirlineSelect.propTypes = {
    //value: PropTypes.object,
    onChange: PropTypes.func,
    placeholder: PropTypes.string,
}

export default AirlineSelect;