import { request, combineUrl, config } from '~/common';

const profileRequest = (params, callback, action, options) => {
    return request(combineUrl(config.obtApiUrl, `Profile/V1/json/${action}`), params, callback, options);
}

export function genQRCode4MobileLogin(params, callback) {
    return profileRequest(params, callback, 'GenQRCode4MobileLogin');
}

export function genAppDownloadUrlQRCode(params, callback) {
    return profileRequest(params, callback, 'GenAppDownloadUrlQRCode');
}

export function deactivateQRCodeMobileLogin(params, callback) {
    return profileRequest(params, callback, 'DeactivateQRCodeMobileLogin');
}

export function getLoginPlatformInfo(params, callback) {
    return profileRequest(params, callback, 'GetLoginPlatformInfo');
}

export function getContactUs(params, callback) {
    return profileRequest(params, callback, 'GetContactUs');
}

export function loginLightningBySSO(params, callback) {
    return profileRequest(params, callback, 'LoginByAuth0SSO');
}

export function loginLightningByAccessToken(params, callback) {
    return profileRequest(params, callback, 'LoginByAccessToken', { needValidAccessToken: false });
}

export function getCTMProfileSSO(params, callback) {
    return profileRequest(params, callback, 'GetCTMProfileSSO');
}

export function validateCTMProfile(params, callback) {
    return profileRequest(params, callback, 'ValidateCTMProfile');
}

export function getUserData(params, callback) {
    return profileRequest(params, callback, 'GetUserData');
}

export function sso2App(params, callback) {
    return profileRequest(params, callback, 'getappssourl');
}

export function getCovidRiskMapUrl(params, callback) {
    return profileRequest(params, callback, 'getcovidriskmapurl');
}

export function getLoginProfile(params, callback) {
    return profileRequest(params, callback, 'GetLoginProfile');
}

export function getSSOConnectionKey(params, callback) {
    return profileRequest(params, callback, 'GetSSOConnectionKey', { needValidAccessToken: false });
}

export function logout(params, callback) {
    return profileRequest(params, callback, 'Logout');
}