//#region action type
export const PTA_GET_LOGIN_RIGHTS = 'PTA_GET_LOGIN_RIGHTS';
export const PTA_GET_LOGIN_RIGHTS_SUCCESS = 'PTA_GET_LOGIN_RIGHTS_SUCCESS';
export const PTA_GET_LOGIN_RIGHTS_FAILURE = 'PTA_GET_LOGIN_RIGHTS_FAILURE';
//#endregion

//#region action creator
export const getLoginRights = () => ({ type: PTA_GET_LOGIN_RIGHTS });
export const getLoginRightsSuccess = payload => ({ type: PTA_GET_LOGIN_RIGHTS_SUCCESS, payload });
export const getLoginRightsFailure = payload => ({ type: PTA_GET_LOGIN_RIGHTS_FAILURE, payload });
//#endregion

//#region reducer
const initState = {
    viewCompanies: [],//[{Code,Id,DisplayName}]
    isArranger: false,
    loginEnums: null,
    meta: null,
    errMsg: '',
    loading: false,
    customBookingRight: null,
}

const preTrip = (state = initState, action) => {
    switch (action.type) {
        case PTA_GET_LOGIN_RIGHTS:
            return {
                ...state,
                meta: null,
                errMsg: '',
                loading: true,
            };
        case PTA_GET_LOGIN_RIGHTS_SUCCESS:
            return {
                ...state,
                viewCompanies: action.payload.accessCompanies ? action.payload.accessCompanies.map(c => ({ Id: c.companyId, Code: `${c.companyId}`, DisplayName: c.companyName })) : [],
                isArranger: action.payload.isArranger,
                loginEnums: action.payload.loginEnums,
                meta: action.payload.meta,
                loading: false,
                customBookingRight: action.payload.customBookingRight,
            };
        case PTA_GET_LOGIN_RIGHTS_FAILURE:
            return {
                ...state,
                errMsg: action.payload,
                loading: false,
            };
        default:
            return state
    }
}
//#endregion

export default preTrip;
