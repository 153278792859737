import { request, combineUrl, config } from '~/common';

const adminRequest = (params, callback, action, options) => {
    return request(combineUrl(config.obtApiUrl, `Admin/V1/json/${action}`), params, callback, options);
}

export function sendForgotPasswordEmail(params, callback) {
    return adminRequest(params, callback, 'SendForgotPasswordEmail', { needValidAccessToken: false });
}

export function validResetPasswordEmailToken(params, callback) {
    return adminRequest(params, callback, 'ValidResetPasswordEmailToken', { needValidAccessToken: false });
}

export function validPassword(params, callback) {
    return adminRequest(params, callback, 'ValidPassword', { needValidAccessToken: false });
}

export function resetPassword(params, callback) {
    return adminRequest(params, callback, 'ResetPassword', { needValidAccessToken: false });
}