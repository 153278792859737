import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { injectIntl, FormattedMessage } from 'react-intl';
import { WorldMap, MetricGauge, FlightCardCollection, RiskCardCollection } from '~/components';
import { updateUserSettingSaga } from '~/redux/userSetting';
import { toggleAsideMenu } from '~/redux/application';
import { searchTravelerTracker, getRiskCountryAlert } from '~/client';
import { getCurrentLang, Languages, getProfileToken, TravelStatus, config, getTravelStatus, mapTimeFrames, WidgetType, ThemeType, getCacheUserSetting } from '~/common';
import { withRouter } from 'react-router-dom'
import { Companies } from '~/components';
import BaseWidget from '../../components/BaseWidget';
import { sso2Lightning } from '~/redux/sso';
import { Drawer } from 'antd'
import GeoRiskToggle from '../../components/GeoRiskToggle'

const DEPART_DATE_FORMAT_API = 'YYYYMMDD';
const DEPART_DATE_FORMAT_SHOW = getCurrentLang() === Languages.en_us ? 'DD MMM YY' : 'MM/DD/YY';
const SUPPORT_TRAVEL_STATUS = [TravelStatus.On_Time, TravelStatus.Delayed, TravelStatus.Landed, TravelStatus.En_Route, TravelStatus.Warning, TravelStatus.All];
const TRIP_COLLECTION_WIDTH = 450;

class TravelerTracker extends React.Component {
    constructor(props) {
        super(props);
        let statuses = [];
        for (let code of SUPPORT_TRAVEL_STATUS) {
            const ts = getTravelStatus(code);
            statuses.push({ code: ts.statusCode, name: props.intl.formatMessage({ id: ts.statusCode.replace('-', '_') }), field: ts.statusField, type: ts.statusType });
        }
        this.state = {
            statuses,
            selectedStatusCode: TravelStatus.All,
            loading: true,
            updateLoading: false,
            markersPadding: { right: TRIP_COLLECTION_WIDTH },
            companyCode: null,
            geoRiskOn: false,
            geoRiskLevels: {
                0: true,
                1: true,
                2: true,
                3: true,
                4: true,
                5: true,
            }
        }
        this.worldRef = React.createRef();
        this.cardRef = React.createRef();
        this.processData2GeoJSON = this.processData2GeoJSON.bind(this);
        this.handleMetricClick = this.handleMetricClick.bind(this);
        this.handleSearchTravelerTracker = this.handleSearchTravelerTracker.bind(this);
        this.updateTimeRange = this.updateTimeRange.bind(this);
    }

    componentDidMount() {
        this.mounted = true;
        this.setState({ loading: true });
        this.handleSearchTravelerTracker({});
        !this.props.isWidgets && this.props.toggleAsideMenu(true);
        this.setDefaultGeoRiskLevels();
    }

    setDefaultGeoRiskLevels() {
        const riskMapRiskLevels = getCacheUserSetting().RiskMapRiskLevels;
        let newGeoRiskLevels = this.state.geoRiskLevels;
        if (riskMapRiskLevels) {
            for (let k in newGeoRiskLevels) {
                if (k !== "0") {
                    newGeoRiskLevels[k] = riskMapRiskLevels.includes(Number(k));
                }
            }
        }
    }

    updateTimeRange(timeFrames) {
        const widgets = JSON.parse(JSON.stringify(this.props.widgets));
        widgets.find(item => item.WidgetType === WidgetType.TravellerTracker).Attributes.timeFrames = {
            previous: timeFrames[0],
            upcoming: timeFrames[1]
        };
        this.props.updateWidgetSaga(widgets);
        this.handleSearchTravelerTracker({ date: mapTimeFrames(timeFrames, DEPART_DATE_FORMAT_API), isUpdate: true });
    }

    handleSearchTravelerTracker({ date = mapTimeFrames(this.props.timeFrames, DEPART_DATE_FORMAT_API), isUpdate = false, companyCode = this.state.companyCode }) {
        this.setState({ updateLoading: true, companyCode });
        const params = {
            profileToken: getProfileToken(),
            ...!(date && date.startDate === 'noLimit') && { StartDateFromRemark: date.startDate },
            ...!(date && date.endDate === 'noLimit') && { StartDateToRemark: date.endDate },
            ...this.props.isWidgets && { OnlyStatistic: true },
            ...(companyCode && { customerCodes: [companyCode] }) || {}
        }
        searchTravelerTracker(params, (err, data) => {
            if (this.mounted) {
                this.setState({ loading: false, updateLoading: false });
                data && this.setState({ CountryRiskSummaries: data.CountryRiskSummaries || [] });
                if (data && data.Itineraries && data.Itineraries.length) {
                    const geojson = this.processData2GeoJSON(this.filterItineraries(this.state.selectedStatusCode, data.Itineraries));
                    data.TravelSummaries && data.TravelSummaries.forEach((summary, index) => {
                        summary.index = index;
                    });
                    this.setState({ geojson, itineraries: data.Itineraries, TotalItinerary: data.TotalItinerary || 0, TravelSummaries: data.TravelSummaries, TravelStateTypeStatistics: data.TravelStateTypeStatistics, CountryRisks: data.CountryRisks });
                } else {
                    this.setState({ itineraries: [], TravelSummaries: [], geojson: this.processData2GeoJSON([]) });
                }
                !isUpdate && this.props.location && this.props.location.state && !this.state.showRiskCollection && this.handleMetricClick(this.props.location.state);
            }
        });
    }

    componentWillUnmount() {
        this.mounted = false;
        clearInterval(this.interval);
    }

    componentDidUpdate(prevProps, prevState) {
        if (!this.props.isWidgets) {
            // if (prevState.geoRiskOn !== this.state.geoRiskOn) return;
            if (prevProps.isLightMode !== this.props.isLightMode) {
                this.worldRef.current.initMapboxGL();
            }
            if (
                (!prevState.CountryRiskSummaries && this.state.CountryRiskSummaries) ||
                (this.state.CountryRiskSummaries && (prevState.geoRiskLevels !== this.state.geoRiskLevels)) ||
                (this.state.CountryRiskSummaries && (prevState.geoRiskOn !== this.state.geoRiskOn))
            ) {
                this.worldRef.current.updateLayer('mapAreafillInstance');
                return;
            }
        }
    }

    processData2GeoJSON(itineraries) {
        //https://www.oschina.net/translate/geojson-spec
        let features = [];
        let featureTemplate = {
            "type": "Feature",
            "properties": {
                "message": "Foo",
                "iconSize": [60, 60]
            },
            "geometry": {
                "type": "Point",
                "coordinates": [
                    -66.324462890625,
                    -16.024695711685304
                ]
            }
        };
        itineraries.forEach(itinerary => {
            let feature = JSON.parse(JSON.stringify(featureTemplate));
            feature.properties = JSON.parse(JSON.stringify(itinerary));
            feature.properties.En_Route = itinerary.Statistics.En_Route.Count;
            feature.properties.Landed = itinerary.Statistics.Landed.Count;
            feature.properties.Warning = itinerary.Statistics.Warning.Count;
            feature.properties.On_Time = itinerary.Statistics.On_Time.Count;
            delete feature.properties.Statistics;
            feature.geometry.coordinates = [itinerary.Longitude, itinerary.Latitude];
            if (itinerary.Longitude || itinerary.Latitude) {
                features.push(feature);
            }
        });
        let geojson = {
            "type": "FeatureCollection",
            "features": features
        };
        return geojson;
    }

    handleMetricClick(statusCode) {
        clearInterval(this.interval);
        if (this.cardRef.current) {
            this.cardRef.current.reset();
            this.highlightMarker(null);
        }
        if (this.props.isWidgets) {
            this.props.history.push('/traveller-tracker', statusCode);
            return;
        }
        if (this.state.loading) return;
        const geojson = this.processData2GeoJSON(this.filterItineraries(statusCode));
        this.setState({ selectedStatusCode: statusCode, geojson, closeTripCollection: false, showArrow: true, showRiskCollection: false });
        this.interval = statusCode === TravelStatus.Warning ? setInterval(() => {
            this.setState({ showArrow: false });
        }, 4000) : undefined;
    }

    filterItineraries = (statusCode, itineraries = this.state.itineraries) => {
        let filterItineraries = itineraries ? JSON.parse(JSON.stringify(itineraries)) : [];
        if (statusCode !== 'all') {
            filterItineraries = filterItineraries.filter(item => ((item.Statistics[getTravelStatus(statusCode).statusField] && item.Statistics[getTravelStatus(statusCode).statusField].Count) || 0) > 0);
        }
        return filterItineraries;
    }

    highlightMarker = (markerId) => {
        if (!this.worldRef.current.isMapLoaded) return;
        this.worldRef.current.selectMarker(markerId);
    }

    toggleTripCollection = close => {
        let markersPadding = null;
        if (!close) {
            markersPadding = { right: TRIP_COLLECTION_WIDTH };
            this.setState({ showRiskCollection: close });
        }
        this.setState({ closeTripCollection: close, markersPadding });
    }

    toggleRiskCollection = show => {
        this.setState({ showRiskCollection: show });
    }

    handleCompanyChange = ({ companyCode }) => {
        this.handleSearchTravelerTracker({ companyCode, isUpdate: true })
    }

    handleViewInLightning = bkgRef => {
        if (bkgRef && bkgRef.trim() !== '') {
            let params = {
                DirectUrl: `trip/BookingDetail.aspx?BkgRef=${bkgRef}`
            }
            this.props.sso2Lightning(params);
        }
    }

    getRiskAlerts = (country, clickCountryName) => {
        this.setState({ loading: true, showRiskCollection: true, clickCountryName, closeTripCollection: true });
        getRiskCountryAlert({ country, profileToken: getProfileToken() }, (err, data) => {
            let riskAlerts = [], riskAlertsTotal = 0;
            if (data) {
                riskAlerts = data.RiskAlerts;
                riskAlertsTotal = data.Total;
            }
            this.mounted && this.setState({
                loading: false,
                riskAlerts: riskAlerts,
                riskAlertsTotal: riskAlertsTotal
            });
        });
    }

    showDrawer = () => {
        this.setState({
            visible: true,
        });
    };

    onClose = () => {
        this.setState({
            visible: false,
        });
    };

    renderMetricGauge = () => {
        const { itineraries, statuses, selectedStatusCode, TravelStateTypeStatistics, TotalItinerary } = this.state;
        let all = TotalItinerary;
        // if (itineraries && itineraries.length) all = itineraries.reduce((a, b) => ({ Total: a.Total + b.Total })).Total;
        return statuses.map(item => {
            const selected = item.code === selectedStatusCode;
            let count = 0;
            let proportion = 0;
            if (itineraries && itineraries.length) {
                if (item.code === TravelStatus.All) {
                    count = all;
                    proportion = 1;
                } else {
                    const field = item.field;
                    count = (TravelStateTypeStatistics[field] && TravelStateTypeStatistics[field].Count) || 0;
                    proportion = (TravelStateTypeStatistics[field] && TravelStateTypeStatistics[field].Proportion) || 0;
                }
            }

            return (
                <MetricGauge
                    key={item.code}
                    count={count}
                    proportion={proportion}
                    total={all}
                    isCalculated={true}
                    onSelected={() => this.handleMetricClick(item.code)}
                    text={item.name}
                    code={item.code}
                    isSelected={selected}
                    hasBg={true}
                ></MetricGauge>
            );
        })
    };

    setGeoRiskLevels = (level) => {
        const newRiskLevels = { ...this.state.geoRiskLevels };
        newRiskLevels[level] = !newRiskLevels[level];
        this.setState({ geoRiskLevels: newRiskLevels });
        let newRiskLevelsArr = [];
        for (let k in newRiskLevels) {
            if (newRiskLevels[k]) {
                newRiskLevelsArr.push(Number(k));
            }
        }
        this.props.updateUserSettingSaga(newRiskLevelsArr);
    };

    setRiskOn = (geoRiskOn) => {
        this.setState({ geoRiskOn });
        !geoRiskOn && this.setState({ showRiskCollection: false, closeTripCollection: false });
    };

    render() {
        const { geojson, statuses, selectedStatusCode, updateLoading, markersPadding, geoRiskOn, geoRiskLevels,
            CountryRiskSummaries, showRiskCollection, riskAlerts, loading, riskAlertsTotal, clickCountryName } = this.state;
        let { startDate, endDate } = mapTimeFrames(this.props.timeFrames, DEPART_DATE_FORMAT_SHOW);
        return (
            <div className='traveler-tracker' id="traveler-tracker">
                {this.props.isWidgets && <label className="widget-caption" onClick={() => this.handleMetricClick('all')}>
                    <i className="plane-icon"></i>
                    <FormattedMessage
                        id="trip_between"
                        tagName="label"
                        values={{ previous: <span className="bold">{startDate}</span>, upcoming: <span className="bold">{endDate}</span>, startDate: startDate, endDate: endDate }}
                    />
                </label>}
                <WorldMap
                    centerPosition={[113.27, 23.13]}
                    canZoom={true}
                    ref={this.worldRef}
                    geojson={geojson}
                    zoom={3}
                    selectedStatusCode={selectedStatusCode}
                    statuses={statuses}
                    intl={this.props.intl}
                    style={this.props.isLightMode ? config.mapboxLightStyle : config.mapboxDarkStyle}
                    useMapbox={!this.props.isWidgets}
                    isLightMode={this.props.isLightMode}
                    markersPadding={markersPadding}
                    handleMarkerPopupClose={(target) => this.cardRef.current && this.cardRef.current.resetItinerary()}
                    geoRiskLevels={geoRiskLevels}
                    CountryRiskSummaries={CountryRiskSummaries}
                    geoRiskOn={geoRiskOn}
                    getRiskAlerts={this.getRiskAlerts}
                />
                {!this.props.isWidgets && <Fragment>
                    <FlightCardCollection
                        ref={this.cardRef}
                        {...this.state}
                        {...this.props}
                        updateTimeRange={this.updateTimeRange}
                        toggleTripCollection={this.toggleTripCollection}
                        highlightMarker={this.highlightMarker}
                        handleViewInLightning={this.handleViewInLightning}
                    >
                        {this.props.viewCompanies.length > 1 && <Companies
                            id="trip-collection-decorated"
                            viewCompanies={this.props.viewCompanies}
                            disabled={updateLoading}
                            companyChange={this.handleCompanyChange}
                            showLoadingStatus={this.state.selectedStatusCode !== TravelStatus.All}
                        />}
                        <span className="countIcon" onClick={this.showDrawer}></span>
                    </FlightCardCollection>
                    {this.state.closeTripCollection && <span className="countIcon map" onClick={this.showDrawer}></span>}
                    <div id='tracker-itinerary-detail' className='tracker-itinerary-detail' style={{ display: 'none' }}></div>
                </Fragment>
                }
                {!this.props.isWidgets && showRiskCollection &&
                    <RiskCardCollection
                        riskAlerts={riskAlerts}
                        loading={loading}
                        clickCountryName={clickCountryName}
                        {...this.props}
                        riskAlertsTotal={riskAlertsTotal}
                        toggleRiskCollection={this.toggleRiskCollection}
                    />
                }
                {!this.props.isWidgets && CountryRiskSummaries && <GeoRiskToggle
                    riskOn={geoRiskOn}
                    riskLevels={geoRiskLevels}
                    setRiskLevels={this.setGeoRiskLevels}
                    setRiskOn={this.setRiskOn}
                    intl={this.props.intl}
                />}
                <div className="metric-group" id="metric-group">
                    <Drawer
                        placement="right"
                        closable={false}
                        onClose={this.onClose}
                        visible={this.state.visible}
                        getContainer={() => document.getElementById('metric-group')}
                        width="30%"
                    >
                        {this.renderMetricGauge()}
                    </Drawer>
                </div>
                <div className={`metric-group ${this.props.isWidgets ? '' : 'metric-group-pc'}`}>
                    {this.renderMetricGauge()}
                </div>
            </div>
        )
    };
};

export default connect(state => {
    let timeFrames = state.userSetting.Widgets.find(item => item.WidgetType === WidgetType.TravellerTracker).Attributes.timeFrames;
    let prev = 1, upc = 6;
    if (timeFrames) {
        if (timeFrames.previous !== null && (config.isDebug || timeFrames.previous !== 'noLimit')) {
            prev = timeFrames.previous;
        }
        if (timeFrames.upcoming !== null) {
            upc = timeFrames.upcoming;
        }
    }
    return ({
        widgets: state.userSetting.Widgets,
        timeFrames: [prev, upc],
        collapsed: state.application.asideMenu.collapsed,
        isLightMode: state.userSetting.ThemeType === ThemeType.Breezy,
        viewCompanies: state.user.viewCompanies || [],
        enableAsiaLightning: state.user.moduleRights.AllowAccessAsiaLightning,
    })
}, dispatch => ({
    updateWidgetSaga: payload => dispatch(updateUserSettingSaga({ Widgets: payload })),
    updateUserSettingSaga: payload => dispatch(updateUserSettingSaga({ RiskMapRiskLevels: payload })),
    toggleAsideMenu: payload => dispatch(toggleAsideMenu(payload)),
    sso2Lightning: payload => dispatch(sso2Lightning(payload)),
})
)(injectIntl(BaseWidget(withRouter(TravelerTracker))));