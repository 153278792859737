import React from 'react';
import { AutoComplete } from 'antd';
import { searchHotelCity, searchHotel } from '~/client';
import { debounce, includesSpecialCharacter, getValue4Lang } from '~/common';
import * as PropTypes from 'prop-types';

const Option = AutoComplete.Option;

class HotelComplete extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            dataSource: [],
            displayName: props.defaultValue && props.defaultValue.name || '',
            code: props.defaultValue && props.defaultValue.code || '',
            type: props.type || 'City',
        };

        this.handleSearch = debounce(this.handleSearch, 300);
    }

    componentDidMount() {
        this.mounted = true;
    }

    componentWillUnmount() {
        this.mounted = false;
    }

    componentWillUpdate() {
        this.previousDefaultValue = this.props.defaultValue;
    }

    componentDidUpdate(prevProps, prevState) {
        const prevCityInfo = this.previousDefaultValue;
        const cityInfo = this.props.defaultValue;
        if (prevProps.city != this.props.city) {
            this.setState({ dataSource: [] });
        }
        if ((prevCityInfo && cityInfo && prevCityInfo.code === cityInfo.code) || (cityInfo && cityInfo.code === this.state.code)) return;

        if (cityInfo && cityInfo.code) {
            setTimeout(() => { this.setState({ displayName: cityInfo.name, code: cityInfo.code }) });
        } else {
            setTimeout(() => { this.setState({ displayName: '', code: '' }) });
        }
    }

    handleSearch = val => {
        this.searchIndex = (this.searchIndex || 0) + 1;
        const currentSearchIndex = this.searchIndex;
        if (this.state.type == 'Hotel') {
            searchHotel(val, this.props.city, (err, rs) => {
                if (currentSearchIndex != this.searchIndex) return;
                this.dataSourceMatchIndex = currentSearchIndex;
                let dataSource = [];
                if (rs && rs.datas && rs.datas.length > 0) {
                    rs.datas.forEach(item => {
                        dataSource.push({ ...item, query: val });
                    });
                }
                this.mounted && this.setState({ dataSource });
            });
        } else {
            searchHotelCity(val, this.props.country, (err, rs) => {
                if (currentSearchIndex != this.searchIndex) return;
                this.dataSourceMatchIndex = currentSearchIndex;
                let dataSource = [];
                if (rs && rs.datas && rs.datas.length > 0) {
                    rs.datas.forEach(item => {
                        dataSource.push({ ...item, query: val });
                    });
                }
                this.mounted && this.setState({ dataSource });
            });
        }
    }

    renderOption = item => {
        let query = item.query;
        let displayName = item.name;
        if (query && query.trim() && !includesSpecialCharacter(query)) {
            displayName = displayName.replace(new RegExp(query, 'ig'), m => {
                return `<span class='matched'>${m}</span>`;
            });
        }
        return (
            <Option
                key={item.code}
                text={item.name}
                className={`sg-option ${/^hotel$/i.test(item.type) ? 'Hotel' : /^[a-z]{3}$/i.test(item.code) ? 'City' : 'Region'}`}
            >
                <span dangerouslySetInnerHTML={{ __html: displayName }}></span>
            </Option>
        );
    }

    render() {
        const { onSelect, inputId, placeholder, allowFreeInput } = this.props;
        const { displayName, dataSource, code, type } = this.state;
        return (
            <AutoComplete
                value={displayName}
                className={`ctm-dropdown-txt__wrap ${type}`}
                dataSource={dataSource.map(this.renderOption)}
                onSelect={(val, opt) => {
                    this.setState({ displayName: opt.props.text, code: val });
                    onSelect(val, opt.props.text);
                }}
                onFocus={() => {
                    document.getElementById(inputId).select();
                    this.handleSearch(code || displayName);
                }}
                onBlur={() => {
                    const searching = this.searchIndex != this.dataSourceMatchIndex;
                    if (!code && displayName && dataSource && dataSource.length > 0 && !searching) {
                        this.setState({ displayName: dataSource[0].name, code: dataSource[0].code });
                        onSelect(dataSource[0].code, dataSource[0].name);
                    } else if (!code) {
                        this.setState({ displayName: allowFreeInput ? displayName : '', code: '' });
                        onSelect('', allowFreeInput ? displayName : '');
                    }
                    this.setState({ dataSource: [] });
                }}
                onSearch={val => {
                    this.setState({ displayName: val, code: '' });
                    this.handleSearch(val);
                }}
                placeholder={placeholder}
                optionLabelProp="text"
                dropdownMatchSelectWidth={false}
                dropdownClassName="ctm-dropdown"
            >
                <input type='text' id={inputId} autoComplete='off' />
            </AutoComplete>
        );
    }
}
HotelComplete.propTypes = {
    onSelect: PropTypes.func.isRequired,
    inputId: PropTypes.string.isRequired,
    placeholder: PropTypes.string,
    type: PropTypes.oneOf(['City', 'Hotel']),
    city: PropTypes.string,
    country: PropTypes.string,
    allowFreeInput: PropTypes.bool,
}

export default HotelComplete;