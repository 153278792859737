import auth0 from 'auth0-js';
import {
    config,
    getValue4Lang,
    isLightMode,
    isEmpty,
    clearCache,
    setAuthenticatedLocationHash,
    getUrlHashValue,
    combineUrl,
    getErrorParams,
    getAuth0CurrentLang,
    parseQueryString,
    parseUrlHash,
    getQueryStringValue,
} from '~/common';
import { exceptionLog } from '~/client';

export const PREFIX = 'ctmsmartportal';
export const ACCESS_TOKEN = `${PREFIX}_access_token`;
export const ID_TOKEN = `${PREFIX}_id_token`;
export const EXPIRES_AT = `${PREFIX}_expires_at`;
export const SCOPES = `${PREFIX}_scopes`;
export const LOGOUT_URL = `${PREFIX}_logout_url`;

export default class Auth {
    tokenRenewalTimeout;

    auth0 = new auth0.WebAuth({
        domain: config.auth0Domain,
        clientID: config.auth0ClientID,
        redirectUri: config.auth0RedirectUri,
        responseType: 'token id_token',
        scope: 'openid profile email',
        leeway: 300
    });

    login(options) {
        //https://github.com/auth0/lock/tree/master/src/i18n
        const authLang = getValue4Lang('en', 'zh', 'zh-TW', getAuth0CurrentLang());
        //https://github.com/auth0/lock
        this.auth0.authorize({
            ...options,
            language: authLang,
            device: isLightMode() ? 'portal-light' : 'portal-dark',
            application_url: config.localhostUrl,
            reset_pwd_url: config.resetPwdRoute
        });
    }

    extSSOLogin(options) {
        this.auth0.authorize({
            connection: options.code,
            redirectUri: (config.auth0RedirectUri + '?esp=1'),
            nonce: config.auth0ExternalLinkKey,
            state: config.auth0ExternalLinkKey,
        });
    }

    autoLogin(email, pwd, auth0Connection) {
        var options = {
            domain: config.auth0Domain,
            clientID: config.auth0ClientID,
            redirectUri: config.auth0RedirectUri,
            responseType: 'token id_token',
            scope: 'openid profile email',
            realm: auth0Connection,
        };

        var webAuth = new auth0.WebAuth(options);

        webAuth.login({
            username: email,
            password: pwd,
        }, function (err, resp) {
            const params = {
                ...getErrorParams(new Error('Auth0_LoginFail'), JSON.stringify({ errObj: err, errRes: resp, opts: options }, null, 4)),
                userEmail: email,
                userName: email,
            };
            exceptionLog(params);
        });
    }

    handleAuthentication(callback) {
        var hashOptions = { __enableIdPInitiatedLogin: true };
        var extContextId = getUrlHashValue(window.location.href, "ext_context_id") || '';
        var useExtSsoPage = getQueryStringValue(window.location.search, "esp") == 1;
        var route = getUrlHashValue(window.location.href, "route") || '';

        if (!isEmpty(config.auth0ExternalLinkKey)) {
            if (!isEmpty(extContextId) || useExtSsoPage) {
                hashOptions = { "state": config.auth0ExternalLinkKey, "nonce": "" };
            }
            else {
                if (!isEmpty(document.referrer) && document.referrer.toLowerCase().indexOf(config.localhostUrl.toLowerCase()) < 0) {
                    var dcLinkKey = window.atob(config.auth0ExternalLinkKey).toLowerCase();
                    if (document.referrer.toLowerCase().indexOf(dcLinkKey) > 0) {
                        hashOptions = { "state": config.auth0ExternalLinkKey, "nonce": config.auth0ExternalLinkKey };
                        ////logout 4 localhost only 20200527 TLL
                        // if(!isEmpty(config.logoutUrl)) {
                        //   logoutUrl = config.logoutUrl;
                        // } else {
                        //   logoutUrl = document.referrer;
                        //   if (logoutUrl.lastIndexOf('?') >= 0) {
                        //     logoutUrl = logoutUrl + '&logout=self';
                        //   } else {
                        //     logoutUrl = logoutUrl + '?logout=self';
                        //   }
                        // }
                    }
                }
            }
        }


        this.auth0.parseHash(hashOptions, (err, authResult) => {
            if (authResult && authResult.accessToken && authResult.idToken) {
                setAuthenticatedLocationHash(window.location.hash);
                const userInfo = this.setSession(authResult, {});
                typeof callback === 'function' && callback({ ...userInfo, extContextId, route });
            } else if (err) {
                const params = {
                    ...getErrorParams(new Error('Auth0_ParseHashFail'), JSON.stringify({
                        errObj: err,
                        authRes: authResult,
                        opts: hashOptions,
                        href: window.location.href,
                        query: parseQueryString(),
                        hash: parseUrlHash(window.location.hash),
                    }, null, 4)),
                    userEmail: '',
                    userName: '',
                };
                if ('sendBeacon' in navigator) {
                    navigator.sendBeacon(combineUrl(config.obtApiUrl, 'Common/V1/json/ExceptionLog'), JSON.stringify(params))
                } else {
                    exceptionLog(params);
                }
                config.isDebug && console.log(err);
                this.clearData(false);
                if (err.error === 'access_denied') {
                    this.logout();
                    return;
                }
                if (config.isDebug) {
                    alert(`${err.error}. Check the console for further details.(show in debug mode)`);
                }
                this.logout();
            }
        });
    }

    setSession(authResult, { isRenewal = false }) {
        // Set the time that the access token will expire at
        let expiresAt = JSON.stringify((authResult.expiresIn * 1000) + new Date().getTime());
        let logoutUrl = "";
        // If there is a value on the `scope` param from the authResult,
        // use it to set scopes in the session for the user. Otherwise
        // use the scopes as requested. If no scopes were requested,
        // set it to nothing
        if (config.externalSsoIdentifier) {
            let identifier = config.externalSsoIdentifier.split(',');
            logoutUrl = (identifier.some(i => authResult.idTokenPayload.sub.includes(i)) && combineUrl(config.localhostUrl, 'sign-out')) || "";
        }
        const scopes = authResult.scope || '';
        const userInfo = this.formatUser(authResult.idTokenPayload);
        localStorage.setItem(ACCESS_TOKEN, authResult.accessToken);
        localStorage.setItem(ID_TOKEN, authResult.idToken);
        localStorage.setItem(EXPIRES_AT, expiresAt);
        localStorage.setItem(SCOPES, JSON.stringify(scopes));
        localStorage.setItem(LOGOUT_URL, logoutUrl);
        // if (!isRenewal) {
        //   localStorage.setItem(LOGOUT_URL, logoutUrl);
        // } else {
        //   localStorage.setItem(LOGOUT_URL, "");
        // }
        //localStorage.setItem(DEBTOR_ID, authResult.idTokenPayload['https://www.ctmsmart.com/debtor']);
        // schedule a token renewal
        this.scheduleRenewal();
        return userInfo;
    }

    logout() {
        var logoutUrl = localStorage.getItem(LOGOUT_URL);
        // Clear access token and ID token from local storage

        //强制跳转到Lightning Login Page时再开放
        // if (isEmpty(logoutUrl)) {
        //   logoutUrl = config.logoutUrl;
        // }

        if (isEmpty(logoutUrl)) {
            const returnTo = combineUrl(config.localhostUrl, 'login');
            this.auth0.logout({ "returnTo": returnTo });
            // this.auth0.logout({ returnTo });
            // this.auth0.logout({ "federated": 1 });
        } else {
            this.auth0.logout({ "returnTo": logoutUrl });
            // this.auth0.logout({ "returnTo": logoutUrl, "federated": 1 });
        }
        this.clearData(false);
    }

    clearData(clearLogoutUrl = true) {
        localStorage.removeItem(ACCESS_TOKEN);
        localStorage.removeItem(ID_TOKEN);
        localStorage.removeItem(EXPIRES_AT);
        localStorage.removeItem(SCOPES);
        if (clearLogoutUrl) {
            localStorage.removeItem(LOGOUT_URL);
        }
        clearCache();
        clearTimeout(this.tokenRenewalTimeout);
    }

    renewToken() {
        this.auth0.checkSession({},
            (err, result) => {
                if (err) {
                    localStorage.removeItem(ACCESS_TOKEN);
                    console.warn(
                        `Could not get a new token (${err.error}: ${err.error_description}).`
                    );
                } else {
                    if (result && result.accessToken && result.idToken) {
                        this.setSession(result, { isRenewal: true });
                        console.log(`Successfully renewed auth!`);
                    }
                    else {
                        localStorage.removeItem(ACCESS_TOKEN);
                    }
                }
            }
        );
    }

    scheduleRenewal() {
        const expiresAt = JSON.parse(localStorage.getItem(EXPIRES_AT));
        const delay = expiresAt - Date.now();
        if (delay > 0) {
            this.tokenRenewalTimeout = setTimeout(() => {
                this.renewToken();
            }, delay);
        }
    }

    isAuthenticated() {
        // Check whether the current time is past the
        // access token's expiry time
        let expiresAt = JSON.parse(localStorage.getItem(EXPIRES_AT));
        return new Date().getTime() < expiresAt;
    }

    userHasScopes(scopes) {
        const grantedScopes = (JSON.parse(localStorage.getItem(SCOPES)) || '').split(' ');
        return scopes.every(scope => grantedScopes.includes(scope));
    }

    formatUser(orgUser) {
        const { nickname, name, email, picture, sub } = orgUser;
        let app_metadata, user_metadata, identities;
        for (let key in orgUser) {
            if (/.*app_metadata$/i.test(key)) {
                app_metadata = orgUser[key];
            } else if (/.*user_metadata$/i.test(key)) {
                user_metadata = orgUser[key];
            } else if (/.*identities$/i.test(key)) {
                identities = orgUser[key];
            }
        }
        return { nickname, name, email, picture, sub, app_metadata, user_metadata, identities };
    }
}
