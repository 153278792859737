import React from 'react';
import { getRiskNews, getRiskCountries } from '~/client';
import {
    getProfileToken,
    animate,
    RiskLevel,
    getCurrentLang,
    Languages,
    getCacheUserSetting,
    isSameArr,
    includesSpecialCharacter,
} from '~/common';
import { Loading, RecordNotFound, BaseWidget } from '~/components';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Button, Select, Input, Tooltip, Row, Col, AutoComplete, Pagination } from 'antd';
import { updateUserSettingSaga } from '~/redux/userSetting';
import { connect } from 'react-redux';

const Option = Select.Option;
const PAGESIZE = 5;

class RenderNews extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            toggledStories: {},
            displayName: "",
            searchParams: {
                riskLevels: (this.props.searchParams && this.props.searchParams.riskLevels) || getCacheUserSetting().RiskNewsRiskLevels || [1, 2, 3, 4, 5],
            },
        };
        this.myRefs = {};
        this.setRefs = (element, id) => {
            this.myRefs[id] = element;
        };
    }

    componentDidMount() {
        this.mounted = true;
        this.props.searchParams && this.setState({ searchParams: this.props.searchParams, displayName: this.props.displayName });
        this.onSearchCounties();
    }

    componentWillUnmount() {
        this.mounted = false;
    }

    handleClick = (id) => {
        const toggled = this.toggleNews(id);
        if (toggled) {
            this.expandNews(id);
            const topPos = this.myRefs[id].offsetTop;
            animate.scrollTo(this.myRefs['news-container'], topPos, 400)
        }
        else this.myRefs[id].style.height = '160px';

    }

    toggleNews = (id) => {
        const toggledStories = { ...this.state.toggledStories };
        if (toggledStories[id]) {
            delete toggledStories[id];
            this.setState({ toggledStories });
            return false;
        } else {
            toggledStories[id] = true;
            this.setState({ toggledStories });
            return true;
        }
    }

    handleSearchParamChange = (searchParams) => {
        this.setState({ searchParams });
    }

    renderNews() {
        let { total, loading, error, news = [], isFirstFetch } = this.props;
        if (loading || error) {
            return <Loading error={error} style={{ color: 'white' }} />;
        } else if (!news.length || total === 0) {
            return <RecordNotFound formatId={isFirstFetch ? 'no_risk_news' : 'record_not_found'} />;
        } else {
            return news
                .map((item, index) => {
                    return (
                        <div
                            key={index}
                            className={`news-item`}
                            ref={(el) => this.setRefs(el, index)}
                        >
                            <h4>{item.AlertTitle}</h4>
                            <div className='news-byline-row'>
                                {item.source && <span>{item.source}</span>}
                                <span>{item.StartDateRemark}</span>
                                <div className={`news-label status status-${item.RiskLevel}`}>{item.RiskLevelRemark}</div>
                            </div>
                            <div style={{ fontSize: '15px', paddingBottom: '30px' }} >{item.Title}:{item.Body}</div>
                            <div className='news-item-footer'>
                                <span onClick={() => this.handleClick(index)}><FormattedMessage id={this.state.toggledStories[index] ? 'read_less' : 'read_more'} /></span>
                            </div>
                        </div>
                    );
                });
        }
    }

    expandNews(id) {
        setImmediate(() => {
            if (this.myRefs[id].style.height !== 'auto') {
                this.myRefs[id].style.height = 'auto';
            }
        });
    }

    onSearchCounties = (value) => {
        let countries = (this.props.countries && this.props.countries.filter(c => !value || [c.Code, c.Name, c.NameZhCn, c.NameZhTw].some(val => (val || '').toLowerCase().includes(value.toLowerCase())))) || [];
        countries.forEach(item => item.query = value);
        this.mounted && this.setState({ dataSource: countries });
    }
    renderOption = (item) => {
        let query = item.query;
        let lang = getCurrentLang();
        let displayName = lang === Languages.en_us ? item.Name : lang === Languages.zh_cn ? item.NameZhCn : item.NameZhTw;
        let displayNameHtml = `${displayName} (${item.Code})`;
        if (query && query.trim() && !includesSpecialCharacter(query)) {
            displayNameHtml = displayNameHtml.replace(new RegExp(query, 'ig'), m => {
                return `<span class='matched'>${m}</span>`;
            });
        }
        return (
            <Option
                key={`${item.Code}-${item.Name}`}
                text={displayName}
                code={item.Code}
                className="county-option "
            >
                <span dangerouslySetInnerHTML={{ __html: displayNameHtml }}></span>
            </Option>
        );
    }

    renderSearchForm() {
        let { dataSource, searchParams, displayName } = this.state;
        return <div style={{ paddingRight: "10px" }}>
            <Row gutter={8} type="flex" align="middle" justify="space-between">
                <Col span={12}>
                    <div>
                        <span className="search-title"><FormattedMessage id="risk_level" /></span>
                        <Select
                            mode="multiple"
                            className="m-select"
                            maxTagCount={1}
                            value={searchParams.riskLevels}
                            onChange={(val) => this.handleSearchParamChange({ ...searchParams, riskLevels: val })}
                        >
                            {
                                Object.keys(RiskLevel).map((level, idx) => RiskLevel[level] === 0 ? null : <Option value={RiskLevel[level]} key={idx}>
                                    {level}
                                </Option>)
                            }
                        </Select>
                    </div>
                </Col>
                <Col span={12}>
                    <div>
                        <span className="search-title"><FormattedMessage id="country" /></span>
                        <AutoComplete
                            value={displayName}
                            dataSource={dataSource && dataSource.map(this.renderOption)}
                            style={{ width: '100%', marginBottom: 0 }}
                            onSelect={(val, opt) => { this.setState({ displayName: opt.props.text }); this.handleSearchParamChange({ ...this.state.searchParams, countries: opt.props.code }) }}
                            onSearch={val => {
                                this.setState({ displayName: val });
                                this.handleSearchParamChange({ ...searchParams, countries: val });
                                this.onSearchCounties(val)
                            }}
                            placeholder={this.props.intl.formatMessage({ id: 'country_code_or_name' })}
                            onFocus={() => this.onSearchCounties(displayName)}
                            optionLabelProp="text"
                        />
                    </div>
                </Col>
            </Row>
            <Row gutter={8} type="flex" align="middle" justify="space-between">
                <Col span={21}><Input className="key-input" value={searchParams.keyword} placeholder={this.props.intl.formatMessage({ id: 'keyword' })} onChange={(e) => this.handleSearchParamChange({ ...searchParams, keyword: e.target.value })} /></Col>
                <Col span={2}><Tooltip placement="right"
                    disabled={this.props.loading ? true : false}
                    title={this.props.intl.formatMessage({ id: 'search' })}>
                    <Button shape="circle" icon="search"
                        onClick={() => {
                            !isSameArr(getCacheUserSetting().RiskNewsRiskLevels, searchParams.riskLevels) && this.props.updateUserSettingSaga(searchParams.riskLevels);
                            this.props.changeState({ searchParams: searchParams, displayName: displayName });
                            this.props.getRiskNews({ ...searchParams })
                        }}
                    />
                </Tooltip></Col>
            </Row>
        </div>
    }

    render() {
        let { loading, isFirstFetch, total } = this.props;
        return <div className='news-component'>
            {(!isFirstFetch || (!loading && total !== 0)) && this.renderSearchForm()}
            <div className='news-items-wrapper'>
                <div
                    className='news-items'
                    style={{ height: !isFirstFetch || (!loading && total !== 0) ? "432px" : "540px" }}
                    ref={(el) => this.setRefs(el, 'news-container')}
                >
                    {this.renderNews()}
                </div>
            </div>
        </div >
    }
}

class News extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
        };
    }

    componentDidMount() {
        this.mounted = true;
        this.getRiskCountries();
        this.getRiskNews({ isFirstFetch: true });
    }

    componentWillUnmount() {
        this.mounted = false;
    }

    shouldComponentUpdate(nextProps, nextState) {
        return nextState !== this.state;
    }

    getRiskNews({ riskLevels = getCacheUserSetting().RiskNewsRiskLevels, keyword = "", countries = "", pageIndex = 1, pageSize = PAGESIZE, isFirstFetch = false } = {}) {
        this.setState({
            loading: true,
            pageIndex: pageIndex,
            isFirstFetch: isFirstFetch
        });
        let params = {
            profileToken: getProfileToken(),
            riskLevels: riskLevels,
            keyword: keyword,
            countries: [countries],
            paging: {
                pageIndex,
                pageSize
            },
        };
        getRiskNews(params, (err, data) => {
            let news = [], total = 0;
            if (data) {
                news = data.RiskNewsList;
                total = data.Total;
            }
            this.mounted && this.setState({
                loading: false,
                error: err,
                news: news,
                total: total
            });
        });
    }

    getRiskCountries() {
        getRiskCountries({ profileToken: getProfileToken() }, (err, data) => {
            if (data)
                this.mounted && this.setState({
                    countries: data.Countries || []
                });
        })
    }

    onSearchCounties = (value) => {
        let countries = (this.state.countries && this.state.countries.filter(c => !value || [c.Code, c.Name, c.NameZhCn, c.NameZhTw].some(val => (val || '').toLowerCase().includes(value.toLowerCase())))) || [];
        countries.forEach(item => item.query = value);
        this.mounted && this.setState({ dataSource: countries });
    }

    onChange = (pageIndex, pageSize) => {
        this.getRiskNews({ pageIndex, pageSize, ...this.state.searchParams });
    }

    render() {
        const RenderNew = BaseWidget(RenderNews);
        let { pageIndex, total, loading } = this.state;
        return <RenderNew
            {...this.props}
            {...this.state}
            getRiskNews={(val) => this.getRiskNews(val)}
            changeState={(val) => this.setState(val)}
        >
            <div className="paging" >
                <Pagination
                    defaultCurrent={1}
                    current={pageIndex}
                    total={total}
                    onChange={this.onChange}
                    pageSize={PAGESIZE}
                    hideOnSinglePage={true}
                    size="small" />
                {loading && <div className="mask"></div>}
            </div>
        </RenderNew>
    }
}

export default connect(state => ({
}), dispatch => ({
    updateUserSettingSaga: payload => dispatch(updateUserSettingSaga({ RiskNewsRiskLevels: payload }))
}))(injectIntl(News));