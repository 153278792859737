import { getSessionTimeoutMinutes, getProfileManageScriptUrl } from '~/common';

export const AUTH0_TIMEOUT = 'AUTH0_TIMEOUT';
export const TOGGLE_ASIDE_MENU = 'TOGGLE_ASIDE_MENU';
export const SET_SESSION_TIMEOUT_MINUTES = 'SET_SESSION_TIMEOUT_MINUTES';
export const SET_PROFILE_MANAGE_SCRIPT_URL = 'SET_PROFILE_MANAGE_SCRIPT_URL';

export const auth0Timeout = () => ({ type: AUTH0_TIMEOUT });
export const toggleAsideMenu = payload => ({ type: TOGGLE_ASIDE_MENU, payload });
export const setSessionTimeoutMinutes = payload => ({ type: SET_SESSION_TIMEOUT_MINUTES, payload });
export const setProfileManageScriptUrl = payload => ({ type: SET_PROFILE_MANAGE_SCRIPT_URL, payload });

const getInitialState = () => {
    let initialState = {
        data: {},
        header: {},
        asideMenu: {},
        sessionTimeoutMinutes: getSessionTimeoutMinutes(),
        profileManageScriptUrl: getProfileManageScriptUrl(),
    };
    return initialState;
}

const application = (state = getInitialState(), action) => {
    switch (action.type) {
        case AUTH0_TIMEOUT:
            return {
                ...state,
                auth0Timeout: true
            };
        case TOGGLE_ASIDE_MENU:
            return {
                ...state,
                asideMenu: {
                    ...state.asideMenu,
                    collapsed: action.payload,
                }
            };
        case SET_SESSION_TIMEOUT_MINUTES:
            return {
                ...state,
                sessionTimeoutMinutes: action.payload,
            };
        case SET_PROFILE_MANAGE_SCRIPT_URL:
            return {
                ...state,
                profileManageScriptUrl: action.payload,
            };
        default: return state;
    }

}

export default application;

