import { getLoginRightsSuccess, getLoginRightsFailure, PTA_GET_LOGIN_RIGHTS } from '../preTrip';
import { put, takeLatest, call, select } from 'redux-saga/effects';
import { getError } from '~/common';
import { getLoginRights as getLoginRightsAPI, getMeta } from '~/client';

function* getLoginRightsSaga() {
    try {
        const payload = yield call(getLoginRightsAPI, {});
        const language = yield select(state => state.userSetting.Language);
        const metaPayload = yield call(getMeta, { language });
        const errMsg = getError(payload) || getError(metaPayload);
        if (!errMsg) {
            yield put(getLoginRightsSuccess({ ...payload, meta: metaPayload }));
        } else {
            yield put(getLoginRightsFailure(errMsg));
        }
    } catch (ex) {
        yield put(getLoginRightsFailure(ex.toString()));
    }
}

export default function* () {
    yield takeLatest(PTA_GET_LOGIN_RIGHTS, getLoginRightsSaga);
}