import { request, combineUrl, config, getCurrentLang, getProfileToken } from '~/common';

const commonRequest = (params, callback, action, options) => {
    return request(combineUrl(config.obtApiUrl, `Common/V1/json/${action}`), params, callback, options);
}

export function searchCity(query, options, callback) {
    const { countryCode } = options || {};
    let url = combineUrl(config.obtApiUrl, `Common/City.ashx?q=${encodeURIComponent(query || '')}&lang=all&includeCountry=1&countryCode=${countryCode || ''}&profileToken=${encodeURIComponent(getProfileToken())}`);
    return request(url, null, callback, { needValidAccessToken: false });
}

export function exceptionLog(params, callback) {
    return commonRequest(params, callback, 'ExceptionLog', { needValidAccessToken: false });
}

export function getRiskNews(params, callback) {
    return commonRequest(params, callback, 'GetRiskNews');
}

export function getRiskCountries(params, callback) {
    return commonRequest(params, callback, 'GetRiskCountries');
}

export function getRiskCountryAlert(params, callback) {
    return commonRequest(params, callback, 'GetRiskCountryAlert');
}

export function getContent4CTMPortal(params, callback) {
    return commonRequest(params, callback, 'GetContent4CTMPortal', { needValidAccessToken: false });
}

export function searchHotelCity(query, country, callback) {
    let url = combineUrl(config.obtApiUrl, `Hotel/Location.ashx?type=City&q=${encodeURIComponent(query || '')}&lang=${getCurrentLang()}&country=${country || ''}`);
    return request(url, null, callback, { needValidAccessToken: false });
}

export function searchHotel(query, city, callback) {
    let url = combineUrl(config.obtApiUrl, `Hotel/Location.ashx?type=Hotel&q=${encodeURIComponent(query || '')}&lang=${getCurrentLang()}&city=${city || ''}`);
    return request(url, null, callback, { needValidAccessToken: false });
}

export function searchAirline(query, callback) {
    let url = combineUrl(config.obtApiUrl, `Common/Airline.ashx?q=${encodeURIComponent(query || '')}&lang=${getCurrentLang()}&qty=5`);
    return request(url, null, callback, { needValidAccessToken: false });
}

export function searchCountry(query, callback) {
    let url = combineUrl(config.obtApiUrl, `Common/Country.ashx?q=${encodeURIComponent(query || '')}&lang=${getCurrentLang()}&qty=5&profileToken=${encodeURIComponent(getProfileToken())}`);
    return request(url, null, callback, { needValidAccessToken: false });
}

export function getFooterScript4CTMPortal(params, callback) {
    return commonRequest(params, callback, 'GetFooterScript4CTMPortal');
}