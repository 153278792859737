import { updateUserSetting, UPDATE_USER_SETTING_SAGA } from '../userSetting';
import { put, takeLatest, select } from 'redux-saga/effects';
import { getProfileToken, setCacheUserSetting } from '~/common';
import { saveUserSetting } from '~/client';
import { ThemeType } from '../../common/constants';

const getUserSetting = state => {
    return JSON.parse(JSON.stringify(state.userSetting));
}

function* updateUserSettingSaga(action) {
    const isChangeLang = Boolean(action.payload && action.payload.Language);
    // const isApiNotSupportTheme = Boolean(action.payload && action.payload.ThemeType != null && ![ThemeType.Galaxy, ThemeType.Breezy].includes(action.payload.ThemeType));
    yield put(updateUserSetting(action.payload));
    const userSetting = yield select(getUserSetting);
    setCacheUserSetting(userSetting);
    // if (isApiNotSupportTheme) return;//remove this when api ready
    try {
        userSetting.Widgets = userSetting.Widgets.map(({ Attributes, IsVisible, WidgetType }) => ({
            Attributes: JSON.stringify(Attributes), IsVisible, WidgetType,
        }));
        saveUserSetting({ ProfileToken: getProfileToken(), UserSetting: userSetting }, () => {
            isChangeLang && window.location.reload();
        });
    }
    catch (ex) {
        console.error(ex);
    }
}

export default function* () {
    yield takeLatest(UPDATE_USER_SETTING_SAGA, updateUserSettingSaga);
}