import { request, combineUrl, config } from '~/common';

const flightRequest = (params, callback, action) => {
    return request(combineUrl(config.obtApiUrl, `Flights/V1/json/${action}`), params, callback);
}

export function searchFlightCalendar(params, callback) {
    return flightRequest(params, callback, 'SearchFlightCalendar');
}

export function searchFlightCalendarDetail(params = { CalendarId: '', ProfileToken: '' }, callback) {
    return flightRequest(params, callback, 'SearchFlightCalendarDetail');
}