import React, { Fragment } from 'react';
import { AutoComplete } from 'antd';
import { searchCountry } from '~/client';
import { debounce, includesSpecialCharacter } from '~/common';
import PropTypes from 'prop-types';

const Option = AutoComplete.Option;

class CountryComplete extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            dataSource: [],
            query: this.getQueryFromValue(props.value),
            code: props.value && props.value.code || '',
        };
        this.handleSearch = debounce(this.handleSearch, 300);
    }

    componentDidMount() {
        this.mounted = true;
    }

    componentWillUnmount() {
        this.mounted = false;
    }

    getQueryFromValue = value => value && value.name ? `${value.name}${value.code ? ` (${value.code})` : ''}` : '';

    componentDidUpdate(prevProps, prevState) {
        const prevCode = prevProps.value && prevProps.value.code || '';
        const code = this.props.value && this.props.value.code || '';
        const currentCode = this.state.code;
        if (prevCode == code || currentCode == code) return;
        setTimeout(() => {
            this.setState({ query: this.getQueryFromValue(this.props.value), code });
        });
    }

    handleSearch = val => {
        val = val || '';
        this.searchIndex = (this.searchIndex || 0) + 1;
        const currentSearchIndex = this.searchIndex;
        searchCountry(val, (err, data) => {
            if (currentSearchIndex != this.searchIndex) return;
            this.dataSourceMatchIndex = currentSearchIndex;
            let dataSource = [];
            if (data && data.datas) {
                Object.keys(data.datas).forEach(key => {
                    dataSource.push({ code: key, name: data.datas[key] });
                });
            }
            this.mounted && this.setState({ dataSource });
        });
    }

    renderOption = item => {
        let query = this.state.query;
        let displayName = `${item.name} (${item.code})`;
        if (query && query.trim() && !includesSpecialCharacter(query)) {
            displayName = displayName.replace(new RegExp(query, 'ig'), m => {
                return `<span class='matched'>${m}</span>`;
            });
        }
        return (
            <Option
                key={item.code}
                text={this.getQueryFromValue(item)}
                className='sg-option'
            >
                <span dangerouslySetInnerHTML={{ __html: displayName }}></span>
            </Option>
        );
    }

    render() {
        const { className, onSelect, placeholder, getPopupContainer, allowFreeInput } = this.props;
        const { dataSource, query, code } = this.state;
        return (
            <AutoComplete
                id={this.props.id}
                value={query}
                className={className}
                dataSource={dataSource.map(this.renderOption)}
                onSelect={(val, opt) => {
                    this.setState({
                        code: val,
                        query: opt.props.text,
                    });
                    this.inputChanged = false;
                    onSelect({ code: val, name: opt.props.text });
                }}
                onFocus={() => {
                    this.props.id && document.getElementById(this.props.id).querySelector('.ant-select-search__field').select();
                    this.handleSearch(code || (query || '').split('(')[0].trim());
                }}
                onBlur={() => {
                    if (this.inputChanged) {
                        this.inputChanged = false;
                        const searching = this.searchIndex != this.dataSourceMatchIndex;
                        if (!code && query && dataSource && dataSource.length > 0 && !searching) {
                            const firstItem = dataSource[0];
                            this.setState({ code: firstItem.code, query: this.getQueryFromValue(firstItem) });
                            onSelect({ code: firstItem.code, name: this.getQueryFromValue(firstItem) });
                        } else if (!code) {
                            this.setState({ code: '', query: allowFreeInput ? query : '' });
                            onSelect({ code: '', name: allowFreeInput ? query : '' });
                        }
                    }
                    this.setState({ dataSource: [] });
                }}
                onSearch={val => {
                    this.inputChanged = true;
                    this.setState({ query: val, code: '' });
                    this.handleSearch(val);
                }}
                onChange={val => {
                    if (val == null) {
                        this.inputChanged = false;
                        this.setState({ query: '', code: '' });
                        onSelect({ code: '', name: '' });
                    }
                }}
                placeholder={placeholder}
                optionLabelProp="text"
                dropdownMatchSelectWidth={false}
                getPopupContainer={getPopupContainer}
            >
                <input type='text' autoComplete='off' />
            </AutoComplete>
        );
    }
}
CountryComplete.propTypes = {
    value: PropTypes.object,
    onSelect: PropTypes.func,
    className: PropTypes.string,
    placeholder: PropTypes.string,
    getPopupContainer: PropTypes.func,
    allowFreeInput: PropTypes.bool,
}

export default CountryComplete;