import appLocaleData from 'react-intl/locale-data/zh';
import messages from './messages.js';
import antd from 'antd/lib/locale-provider/zh_TW';
import 'moment/locale/zh-tw';

export default {
    // 合并所有 messages，加入 antd 组件的 messages
    messages: {
        ...messages,
    },

    antd: {
        ...antd,
        Modal: {
            ...antd.Modal,
            justOkText: "確 定",
        }
    },

    // locale
    locale: 'zh-TW',

    // react-intl locale-data
    data: appLocaleData,
};

