import appLocaleData from 'react-intl/locale-data/zh';
import messages from './messages.js';
import antd from 'antd/lib/locale-provider/zh_CN';
import 'moment/locale/zh-cn'

export default {
    // 合并所有 messages，加入 antd 组件的 messages
    messages: {
        ...messages,
    },

    antd: antd,

    // locale
    locale: 'zh-CN',

    // react-intl locale-data
    data: appLocaleData,
};