import { all } from 'redux-saga/effects';
import sso from './sso';
import userSetting from './userSetting';
import watchOnUpdates from './signalr';
import userData from './userData';
import loginProfile from './loginProfile';
import preTrip from './preTrip';
import tripProposal from './tripProposal';

export default function* () {
    yield all([
        sso(),
        userSetting(),
        watchOnUpdates(),
        userData(),
        loginProfile(),
        preTrip(),
        tripProposal(),
    ])
}
