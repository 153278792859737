import React, { Fragment } from 'react'
import { Popover } from 'antd'
import { FormattedMessage, injectIntl } from 'react-intl'
import * as PropTypes from 'prop-types';
import { getImageUrl } from '~/common'


function RenderSelect(props) {
    let selectedCode = props.selectCode;
    let viewCompanies = JSON.parse(JSON.stringify(props.viewCompanies));
    viewCompanies.unshift({ Code: "any", Id: "any", DisplayName: "any" });
    return <div className="company-box">
        <ul className="clearfix">
            {viewCompanies.map((company) =>
                <li key={company.Code}
                    title={company.Code === "any" ? props.intl.formatMessage({ id: 'any' }) : company.DisplayName}
                    onClick={() => props.companyChange(company)}
                    className={selectedCode === company.Code ? 'li-select' : ""}>
                    {company.Code === "any" ? <Fragment>
                        <FormattedMessage id="any" />
                    </Fragment> : company.DisplayName}
                </li>
            )}
            <span className={`li-arrow ${props.placement || "bottom"}`}></span>
        </ul>
        {props.disabled && <div className="mask"></div>}
    </div>
}
class Companies extends React.Component {
    constructor(props) {
        super(props);
        this.state = { selectCode: "any" }
    }
    componentDidMount() {
        this.props.companyCode && this.setState({ selectCode: this.props.companyCode });
    }
    companyChange = ({ Code, Id, DisplayName }) => {
        this.state.selectCode !== Code && this.props.companyChange({
            companyCode: Code === "any" ? "" : Code,
            widgetType: this.props.widgetType,
            companyId: Id === "any" ? "" : Id,
            displayName: DisplayName === "any" ? "" : DisplayName
        });
        this.setState({ selectCode: Code });
    };
    showVisible = () => {
        this.setState({ visible: true });
    }
    onVisibleChange = (visible) => {
        visible !== this.state.visible && this.setState({ visible: visible });
    }
    render() {
        return <Popover
            getPopupContainer={() => (this.props.id && document.getElementById(this.props.id)) || document.body}
            overlayClassName="company-popover"
            id={`popover-${this.props.id}`}
            placement={this.props.placement || "bottom"}
            content={<RenderSelect {...this.props}
                companyChange={this.companyChange}
                selectCode={this.props.companyCode ? this.props.companyCode : this.state.selectCode}
                placement={this.props.placement || "bottom"}
            />}
            trigger="hover"
            visible={this.state.visible}
            mouseEnterDelay={2}
            mouseLeaveDelay={0.3}
            autoAdjustOverflow
            onVisibleChange={this.onVisibleChange}
        >
            <span className="filter-cmp" onClick={this.showVisible}>
                {this.props.disabled && this.props.showLoadingStatus ? <img src={getImageUrl('loading.svg')} alt="Loading" style={{ verticalAlign: 'middle' }}></img> :
                    <i className={this.state.selectCode === "any" ? "filter" : "filter-data"}></i>}
                <span className="filter-txt"><FormattedMessage id="filter" /></span>
            </span>
        </Popover>
    }
}
Companies.propTypes = {
    companyChange: PropTypes.func.isRequired,
    viewCompanies: PropTypes.array,
    disabled: PropTypes.bool,
    id: PropTypes.string,
    companyCode: PropTypes.string,
}
export default injectIntl(Companies)