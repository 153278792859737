//#region action type
export const GET_LOGIN_PROFILE = 'GET_LOGIN_PROFILE';
export const GET_LOGIN_PROFILE_INVOKED = 'GET_LOGIN_PROFILE_INVOKED';
export const GET_LOGIN_PROFILE_SUCCESS = 'GET_LOGIN_PROFILE_SUCCESS';
export const GET_LOGIN_PROFILE_FAILED = 'GET_LOGIN_PROFILE_FAILED';
//#endregion

//#region action creator
export const getLoginProfile = payload => ({ type: GET_LOGIN_PROFILE, payload });
export const getLoginProfileInvoked = () => ({ type: GET_LOGIN_PROFILE_INVOKED });
export const getLoginProfileSuccess = payload => ({ type: GET_LOGIN_PROFILE_SUCCESS, payload });
export const getLoginProfileFailed = payload => ({ type: GET_LOGIN_PROFILE_FAILED, payload });
//#endregion

//#region reducer
const initState = {
    loading: false,
    error: null,
    data: null,
}

const loginProfile = (state = initState, action) => {
    switch (action.type) {
        case GET_LOGIN_PROFILE_INVOKED:
            return {
                loading: true,
                error: null,
                data: null,
            };
        case GET_LOGIN_PROFILE_SUCCESS:
            return {
                loading: false,
                error: null,
                data: action.payload,
            };
        case GET_LOGIN_PROFILE_FAILED:
            return {
                loading: false,
                error: action.payload,
                data: null,
            };
        default:
            return state
    }
}
//#endregion

export default loginProfile;
