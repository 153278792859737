import React from 'react';
import { Loading, CTMModal } from '~/components';
import {
    config,
    parseQueryString,
    getError,
    getProfileToken,
    getToken,
    getCurrentLang,
    getAuthenticatedLocationHash,
    ErrorCode,
    getAuth0CurrentLang,
    getLoginRedirect,
    setLoginRedirect,
    combineUrl,
    getCacheUserInfo,
    CTMPortalSystemType,
} from '~/common';
import { loginLightningBySSO, validResetPasswordEmailToken, loginLightningByAccessToken } from '~/client';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { Modal } from 'antd';
import { getLoginProfileSuccess } from '~/redux/loginProfile';
import { formatMessage as customFormatMessage } from '~/locales';

const UniqueRefType = {
    PretripApproval: 0,
    TripProposal: 1,
}

class AuthCallback extends React.Component {
    constructor(props) {
        super(props);
        this.currentLang = getCurrentLang();
    }

    componentDidMount() {
        this.mounted = true;
        this.queryObj = parseQueryString();

        const localUrl = document.location.href;
        if (localUrl.indexOf('/?') >= 0 && localUrl.indexOf('?') != localUrl.lastIndexOf('?')) {
            document.location.href = localUrl.replace('/?', '&');
        }

        if (this.queryObj['sso'] === '1') {
            this.handleSSOCallback();
        } else if (this.queryObj['reset-pwd'] === '1') {
            this.handleResetPassword();
        } else if (this.queryObj['token']) {
            this.handleLoginLightningByAccessToken(this.queryObj['token']);
        } else {
            this.handleAuthentication();
        }
    }

    componentWillUnmount() {
        this.mounted = false;
    }

    handleSSOCallback = () => {
        if (this.queryObj['status'] === '-1') {
            let msg = this.queryObj['msg'] + (this.queryObj['errorCode'] ? ` (${this.queryObj['errorCode']})` : '');
            const auth0Timeout = this.queryObj['errorCode'] === ErrorCode.CheckIdTokenFail;
            if (auth0Timeout) {
                msg = this.props.intl.formatMessage({ id: 'timeout_tip' });
            }
            Modal.error({
                centered: true,
                title: this.props.intl.formatMessage({ id: 'system_information' }),
                content: msg,
                onOk: () => this.props.history.replace(auth0Timeout ? '/logout' : '/'),
            });
        } else {
            this.props.history.replace('/');
        }
    }

    handleAuthentication = () => {
        const { auth, history, location } = this.props;
        //redirect to home page case already authenticated
        const authenticatedHash = getAuthenticatedLocationHash();
        if (authenticatedHash === location.hash && auth.isAuthenticated() && getProfileToken()) {
            history.replace('/');
            return;
        }
        if (/access_token|id_token|error/.test(location.hash)) {
            auth.handleAuthentication(this.handleLoginLightningBySSO);
        }
    }

    handleLoginLightningBySSO = ({ email, extContextId, route }) => {
        const { history, auth, getLoginProfileSuccess } = this.props;
        let params = {
            Token: config.obtApiToken,
            AccessToken: getToken(),
            Email: email,
            ContextId: extContextId,
            SystemType: CTMPortalSystemType,
        };
        loginLightningBySSO(params, (err, data) => {
            if (data && data.ProfileToken) {
                getLoginProfileSuccess(data);
                const redirect = getLoginRedirect();
                if (redirect) {
                    setLoginRedirect('');
                    window.location.href = redirect;
                    return;
                }
                if (this.currentLang !== data.UserSetting.Language) {
                    window.location.href = combineUrl(config.localhostUrl, route);
                } else {
                    history.replace(route || '/');
                }
            } else {
                auth.clearData(false);
                config.isDebug && console.log("debug mode message:", err, data);
                Modal.error({
                    centered: true,
                    title: this.props.intl.formatMessage({ id: 'system_information' }),
                    content: getError(data) || this.props.intl.formatMessage({ id: 'login_lightning_failed_prompt' }),
                    onOk: () => history.replace('/logout'),
                });
            }
        });
    }

    handleLoginLightningByAccessToken = (accessToken) => {
        const { history, auth, getLoginProfileSuccess } = this.props;
        loginLightningByAccessToken({ accessToken, systemType: CTMPortalSystemType }, (err, data) => {
            if (data && data.ProfileToken) {
                const currentLogin = getCacheUserInfo();
                let reload = this.currentLang !== data.UserSetting.Language;
                if (auth.isAuthenticated() && currentLogin && currentLogin.loginId != data.LoginId) {
                    auth.clearData(false);
                    reload = true;
                }
                data.EmailToken = accessToken;
                getLoginProfileSuccess(data);
                let route = '/';
                if (data.RedictData) {
                    route = `/pre-trip/detail?requestRef=${data.RedictData.UniqueRef}${data.RedictData.Action ? `&action=${data.RedictData.Action}` : ''}`;
                    if (data.RedictData.UniqueRefType == UniqueRefType.TripProposal) {
                        route = `/trip-proposal/detail?referenceNumber=${data.RedictData.UniqueRef}${data.RedictData.Action ? `&action=${data.RedictData.Action}` : ''}${this.queryObj['isPreview'] ? `&isPreview=${this.queryObj['isPreview']}` : ''}`;
                    }
                }
                if (reload) {
                    window.location.href = combineUrl(config.localhostUrl, route);
                } else {
                    history.replace(route);
                }
            } else {
                auth.clearData(false);
                config.isDebug && console.log("debug mode message:", err, data);
                CTMModal.error({
                    centered: true,
                    title: this.props.intl.formatMessage({ id: 'system_information' }),
                    content: getError(data) || this.props.intl.formatMessage({ id: 'login_lightning_failed_prompt' }),
                    onOk: () => history.replace('/logout'),
                });
            }
        });
    }

    handleResetPassword = () => {
        const token = this.queryObj['token'];
        const params = {
            Token: config.obtApiToken,
            EmailToken: token,
            MsgLanguage: getAuth0CurrentLang(),
        };
        const fm = id => customFormatMessage({ id, lang: getAuth0CurrentLang() });
        validResetPasswordEmailToken(params, (err, data) => {
            const errMsg = getError(err || data);
            if (errMsg) {
                Modal.error({
                    centered: true,
                    title: fm('system_information'),
                    okText: fm('ok'),
                    content: errMsg,
                    onOk: () => this.props.history.replace('/'),
                });
            } else {
                this.props.history.replace(`${config.resetPwdRoute}?token=${encodeURIComponent(token)}`, data.Email);
            }
        });
    }

    render() {
        return <Loading style={{ backgroundColor: 'rgba(0,0,0,.85)' }} />;
    }
}

export default connect(null, dispatch => ({
    getLoginProfileSuccess: payload => dispatch(getLoginProfileSuccess(payload)),
}))(injectIntl(AuthCallback));
