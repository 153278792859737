import React, { Fragment, Component } from 'react';
import * as PropTypes from 'prop-types';
import { getProfileToken, Channel } from '~/common';
import { FlightSummary, HotelSummary, FlightDetail, HotelDetail } from '~/components';
import { Modal, message } from 'antd';
import { searchItineraryDetail } from '~/client';
import { FormattedMessage } from 'react-intl';

class TravelItineraries extends Component {
    constructor(props) {
        super(props);
        this.state = {
            detail: null,
            modalVisible: false,
            loadingIndex: null,
            activeIndex: null,
        }
        this.detailCache = {};
    }
    componentDidMount() {
        this.mounted = true;
    }
    componentWillUnmount() {
        this.mounted = false;
    }
    componentDidUpdate(prevProps, prevState) {
        if (prevState.modalVisible !== this.state.modalVisible) {
            const detailNode = document.getElementById(this.props.detailContainerId);
            if (detailNode) {
                detailNode.style.display = this.state.modalVisible ? 'block' : 'none';
            }
            if (this.state.modalVisible) {
                const detailContentNode = document.getElementById('itinerary-detail-content');
                if (detailContentNode) {
                    detailContentNode.scrollTop = 0;
                }
            }
        }
    }

    handleDetailClick = async ({ e, orderId, index }) => {
        if (this.props.stopDetailClickPropagation) {
            e.stopPropagation();
        }
        this.setState({ loadingIndex: index, activeIndex: index, modalVisible: false });
        const detailCache = this.detailCache || {};
        const cacheKey = `cache_${orderId}`;
        let data = detailCache[cacheKey];
        let timeout = 500;
        if (!data) {
            timeout = 0;
            const params = {
                ProfileToken: getProfileToken(),
                OrderId: orderId,
            };
            data = await searchItineraryDetail(params);
            this.detailCache = { ...detailCache, [cacheKey]: data };
        }
        setTimeout(() => {
            if (this.mounted) {
                let detail = null;
                if (data && data.ItineraryDetails && data.ItineraryDetails.length > 0) {
                    detail = data;
                }
                this.setState({ detail, modalVisible: !!detail, loadingIndex: null });
                if (!detail) {
                    message.warn(this.props.intl.formatMessage({ id: 'record_not_found' }));
                }
            }
        }, timeout);
    }

    handleDetailClose = () => {
        this.setState({ modalVisible: false, activeIndex: this.props.showActiveIndicator ? this.state.activeIndex : null, loadingIndex: null });
    }

    handleTripClick = (summary, index) => {
        let activeIndex = null;
        if (this.props.showActiveIndicator) {
            activeIndex = index;
        }
        this.setState({ activeIndex, modalVisible: false });
        this.props.handleTripClick(summary);
    }

    renderDetail = data => {
        const { enableAsiaLightning, handleViewInLightning } = this.props;
        const isLightningBooking = data.ItineraryDetails.some(d => (!!d.A && d.A.Channel === Channel.AsiaLightning) || (!!d.H && d.H.Channel === Channel.AsiaLightning));
        const showViewInLightning = isLightningBooking && enableAsiaLightning && !!data.MainBkgRef;
        return <div className='trip-detail-info'>
            <div className='detail-header'>
                <div className='traveler-font'>{data.Travelers}</div>
                <div className='rloc'>
                    <FormattedMessage id='agency_record_locator' />
                    <span>{data.Rloc}</span>
                </div>
                <div className='itinerary-icons'>
                    {data.ItineraryDetails.some(d => !!d.A) && <span className='flight-icon'></span>}
                    {data.ItineraryDetails.some(d => !!d.H) && <span className='bed-icon'></span>}
                </div>
                {isLightningBooking && <div className='lightning-icon-wrap' onClick={!showViewInLightning ? null : () => handleViewInLightning(data.MainBkgRef)} style={{ cursor: showViewInLightning ? 'pointer' : 'default' }}>
                    {showViewInLightning && <FormattedMessage id='view_in' />}
                    <span className="lightning-icon"></span>
                </div>}
            </div>
            <div className='detail-content' id='itinerary-detail-content'>
                {data.ItineraryDetails.map((d, index) => {
                    return <Fragment key={index}>
                        {index > 0 && <div className='detail-item-divider'></div>}
                        {d.A ? <FlightDetail
                            detail={d.A}
                        /> : d.H ? <HotelDetail
                            detail={d.H}
                        /> : null}
                    </Fragment>;
                })}</div>
        </div>;
    }

    reset = () => {
        this.setState({ loadingIndex: null, activeIndex: null, detail: null, modalVisible: false });
    }

    render() {
        const { loadingIndex, activeIndex, detail, modalVisible } = this.state;
        const { travelSummaries, showActiveIndicator, detailContainerId, detailWrapClassName } = this.props;
        if (!travelSummaries || travelSummaries.length === 0) return null;
        return <div className={`content travel-itineraries${showActiveIndicator ? ' show-indicator' : ''}`}>
            {travelSummaries.map((summary, index) => {
                const summaryAttr = {
                    isLoading: loadingIndex === summary.index,
                    isActive: activeIndex === summary.index,
                    handleDetailClick: (e, orderId) => this.handleDetailClick({ e, orderId, index: summary.index }),
                    handleTripClick: (s) => this.handleTripClick(s, summary.index),
                    showActiveIndicator: showActiveIndicator && !!detail && modalVisible,
                };
                return <Fragment key={index}>
                    {summary.A && <FlightSummary
                        summary={summary.A}
                        {...summaryAttr}
                    />}
                    {summary.H && <HotelSummary
                        summary={summary.H}
                        {...summaryAttr}
                    />}
                </Fragment>;
            })}
            {detail && <Modal
                wrapClassName={`modal-trip-detail ${detailWrapClassName || ''}`}
                visible={modalVisible}
                footer={null}
                onCancel={this.handleDetailClose}
                getContainer={() => document.getElementById(detailContainerId) || document.body}
            >
                {this.renderDetail(detail)}
            </Modal>}
        </div>;
    }
}

TravelItineraries.propTypes = {
    handleTripClick: PropTypes.func.isRequired,
    travelSummaries: PropTypes.array.isRequired,
    showActiveIndicator: PropTypes.bool,
    intl: PropTypes.object,
    detailContainerId: PropTypes.string,
    detailWrapClassName: PropTypes.string,
    handleViewInLightning: PropTypes.func,
    stopDetailClickPropagation: PropTypes.bool,
    enableAsiaLightning: PropTypes.bool,
}

export default TravelItineraries;
