import React, { Component, Fragment } from 'react';
import ReactDOM from 'react-dom';
import { Layout, Modal, Carousel } from 'antd';
import Routes from '../Routes';
import { connect } from 'react-redux';
import { toggleAsideMenu } from '~/redux/application';
import { updateUserSettingSaga } from '~/redux/userSetting';
import { sso2AppReset, sso2App } from '~/redux/sso';
import { injectIntl } from 'react-intl';
import { config, getError, getIEVersion, media, ThemeType, WidgetType, getProfileToken, CTMPortalSystemType } from '~/common';
import { Loading, AsideMenu, Header, Footer } from '~/components';
import { getUserData } from '~/redux/userData.js';
import { searchNewsContent, getFooterScript4CTMPortal } from '~/client';
import { readNewsContent } from '~/client'

class SmartApp extends Component {
    constructor() {
        super();
        this.state = {};
    }

    componentDidMount() {
        const intl = this.props.intl;
        this.SYSTEM_INFORMATION = intl.formatMessage({ id: 'system_information' });
        this.ERROR_OCCURRED = intl.formatMessage({ id: 'error_occurred' });
        if (this.props.auth.isAuthenticated()) {
            this.props.getUserData();
            this.handleGetPopupNews();
            this.handleInitScout();
        }
        let selectedKeys = ['dashboard'];
        const pathArr = window.location.pathname.toLowerCase().replace(config.publicUrl.toLowerCase(), '').split('/').filter(Boolean);
        if (pathArr.length) {
            selectedKeys = [pathArr[0]];
        }
        this.setState({ selectedKeys });
        this.props.toggleAsideMenu(selectedKeys[0] !== 'dashboard' || media.isMobile());
        document.body.className = this.props.themeType === ThemeType.Breezy ? (config.enableLightDarkMode ? 'light-mode' : 'breezy-mode') : (config.enableLightDarkMode ? 'dark-mode' : '');
        this.historyUnListen = this.props.history.listen((e) => {
            e.pathname && this.setState({ selectedKeys: e.pathname === '/' ? ['dashboard'] : [e.pathname.substring(1).split('/')[0]] });
            this.props.toggleAsideMenu(e.pathname !== '/');
            if (!e.hash) {
                window.scrollTo(0, 0);
                Modal.destroyAll();
            }
        });
    }

    componentWillUnmount() {
        document.title = document.title.split('-')[0].trim();
        document.body.className = '';
        this.historyUnListen();
    }

    componentDidUpdate(prevProps, prevState) {
        const { redirectUrl, error, sso2AppReset, isAuth0Timeout, loginProfileError, auth } = this.props;
        if (isAuth0Timeout) {
            !prevProps.isAuth0Timeout && Modal.error({
                centered: true,
                title: this.SYSTEM_INFORMATION,
                content: this.props.intl.formatMessage({ id: 'timeout_tip' }),
                onOk: () => this.props.history.replace('/logout'),
            });
            return;
        }
        if (loginProfileError) {
            auth.clearData();
            !prevProps.loginProfileError && Modal.error({
                centered: true,
                title: this.SYSTEM_INFORMATION,
                content: loginProfileError,
                onOk: () => this.props.history.replace('/logout'),
            });
            return;
        }
        if (redirectUrl) {
            sso2AppReset();
            window.open(redirectUrl);
        }
        else if (error) {
            sso2AppReset();
            Modal.error({
                centered: true,
                title: this.SYSTEM_INFORMATION,
                content: JSON.stringify(error) || this.ERROR_OCCURRED,
            });
        }
        if (prevState.selectedKeys !== this.state.selectedKeys) {
            const key = this.state.selectedKeys[0];
            let title = document.title.split('-')[0].trim();
            if (key === 'traveller-tracker') {
                title += ' - ' + this.props.intl.formatMessage({ id: 'traveler_tracker' });
            } else if (key === 'fare-forecaster') {
                title += ' - ' + this.props.intl.formatMessage({ id: 'fare_forecaster' });
            } else if (key === 'contact-us') {
                title += ' - ' + this.props.intl.formatMessage({ id: 'contact_us' });
            } else if (key === 'pre-trip') {
                title += ' - ' + this.props.intl.formatMessage({ id: 'trip_request' });
            } else if (key === 'trip-proposal') {
                title += ' - ' + this.props.intl.formatMessage({ id: 'online_trip_proposal' });
            }
            document.title = title;
        }
    }

    handleMenuItemClick = e => {
        this.setState({ selectedKeys: [e.key] });
    }

    handleWidgets = widgets => {
        this.props.updateWidgetSaga(widgets);
    }

    newsContentChange = (newItem) => {
        (!this.hadReadNewsId || (this.hadReadNewsId && !this.hadReadNewsId.includes(newItem.NewsId))) && this.readNews(newItem.NewsId);
    }

    readNews = (newsId) => {
        let params = {
            ProfileToken: getProfileToken(),
            newsId: newsId
        };
        readNewsContent(params, (err, data) => {
            if (data.Status === 0) {
                this.hadReadNewsId = this.hadReadNewsId ? [...new Set([...this.hadReadNewsId, newsId])] : [newsId];
            }
        });
    }

    handleGetPopupNews = () => {
        const newsReleaseType = 1;
        let params = {
            ProfileToken: getProfileToken(),
            paging: {
                pageIndex: 1,
                pageSize: 50,
            },
            systemType: CTMPortalSystemType,
            releaseType: newsReleaseType,
            keyword: null,
            isPopup: true,
        };

        searchNewsContent(params, (err, data) => {
            if (data && data.NewsContentSummaries && data.NewsContentSummaries.length > 0) {
                this.readNews(data.NewsContentSummaries[0].NewsId);
                const ele = document.createElement('div');
                ele.id = 'popup-news-container';
                document.body.appendChild(ele);
                ReactDOM.render(<Modal
                    wrapClassName={'modal-trip-detail modal-news-detail modal-alert-news-detail'}
                    //centered={true}
                    visible={true}
                    footer={null}
                    onCancel={() => ReactDOM.unmountComponentAtNode(ele)}
                    destroyOnClose={true}
                >
                    <Carousel arrows={true} dots={true} draggable={true} afterChange={(index) => this.newsContentChange(data.NewsContentSummaries[index])}>
                        {data.NewsContentSummaries.map((summary, index) => {
                            return <div className="trip-detail-info" key={index}>
                                <div className="news-detail-header clearfix">
                                    <div className="news-title">{summary.Title}</div>
                                    <div className="news-date"><span>{summary.CreateTimeRemark}</span></div>
                                </div>
                                <div className="detail-content" dangerouslySetInnerHTML={{ __html: summary.Content }} ></div>
                            </div>;
                        })}
                    </Carousel>
                </Modal>, ele);
            }
        });
    }

    handleInitScout = () => {
        if (!this.props.ctmScoutUserInfo) return;
        const rq = {
            token: config.obtApiToken,
        };
        getFooterScript4CTMPortal(rq, (_, rs) => {
            if (rs.Content) {
                try {
                    window.CtmScoutMetaSetting = this.props.ctmScoutUserInfo;
                    const el = document.createElement('div');
                    el.innerHTML = rs.Content;
                    const script = document.createElement('script');
                    script.innerHTML = el.querySelector('script').innerHTML;
                    document.body.appendChild(script);
                } catch (err) {
                    console.error(err);
                }
            }
        });
    }

    render() {
        const {
            isShowLoading,
            collapsed,
            widgets,
            isLightMode,
            showContactUs,
            enableCTMProfile,
            userApps,
            sso2App,
            toggleAsideMenu,
            auth,
            companyLogoUrl,
            companyLogoSize,
            themeType
        } = this.props;
        const currentPage = this.state.selectedKeys && this.state.selectedKeys[0];
        const showTravelerTracker = widgets.some((w) => w.WidgetType === WidgetType.TravellerTracker);
        const showFareForecaster = widgets.some((w) => w.WidgetType === WidgetType.FareForecaster);
        const showPreTrip = widgets.some((w) => w.WidgetType === WidgetType.CustomBooking);
        const showTripProposal = widgets.some((w) => w.WidgetType === WidgetType.TripProposal);
        const asideMenuProps = {
            ...this.state,
            isLightMode,
            collapsed,
            widgets,
            enableCTMProfile,
            handleCollapsed: toggleAsideMenu,
            handleMenuItemClick: this.handleMenuItemClick,
            handleWidgets: this.handleWidgets,
            showDashboard: currentPage !== 'dashboard',
            userApps: userApps,
            handleAppClick: sso2App,
            companyLogoUrl,
            companyLogoSize,
            themeType
        }
        return (
            <Fragment>
                {isShowLoading && <Loading style={{ position: 'fixed' }} />}
                <Layout className={`smart-layout${getIEVersion() ? ' ie' : ''}`}>
                    {auth.isAuthenticated() && <AsideMenu
                        {...asideMenuProps}
                        className='aside-menu-mobile'
                        collapsedWidth={0}
                        trigger=''
                        isMobile={true}
                    />}
                    <Layout style={{ minHeight: '100vh' }}>
                        <Header auth={auth} />

                        <Layout>
                            {auth.isAuthenticated() && <AsideMenu
                                {...asideMenuProps}
                                className='aside-menu-desktop'
                                collapsedWidth={60}
                                trigger={null}
                            />}
                            <Layout.Content className={`smart-content${currentPage === 'traveller-tracker' ? ' full' : ''}`}>
                                <Routes {...{ showTravelerTracker, showFareForecaster, showContactUs, showPreTrip, showTripProposal, auth }} />
                            </Layout.Content>
                        </Layout>
                        {currentPage !== 'traveller-tracker' && <Footer showContactUs={showContactUs} />}
                    </Layout>
                </Layout>
            </Fragment>
        );
    }
}

export default connect(state => ({
    userApps: state.user.moduleRights.UserApps,
    isAuth0Timeout: state.application.auth0Timeout,
    isShowLoading: state.sso.loading,
    redirectUrl: state.sso.data && state.sso.data.Url,
    error: state.sso.error || getError(state.sso.data),
    collapsed: state.application.asideMenu.collapsed,
    isLightMode: state.userSetting.ThemeType === ThemeType.Breezy,
    themeType: state.userSetting.ThemeType,
    widgets: state.userSetting.Widgets,
    showContactUs: state.userData.ShowContactUs,
    loginProfileError: state.loginProfile.error,
    companyLogoUrl: state.userData && state.userData.CompanyLogoUrl,
    companyLogoSize: state.userData.CompanyLogoSize,
    ctmScoutUserInfo: state.user.ctmScoutUserInfo,
}), dispatch => ({
    sso2App: payload => dispatch(sso2App(payload)),
    sso2AppReset: () => dispatch(sso2AppReset()),
    toggleAsideMenu: payload => dispatch(toggleAsideMenu(payload)),
    updateWidgetSaga: payload => dispatch(updateUserSettingSaga({ Widgets: payload })),
    getUserData: () => dispatch(getUserData()),
}))(injectIntl(SmartApp));
