import { request, config, combineUrl, getOBTApiAccessToken, setOBTApiAccessToken, getProfileToken } from '~/common';

let _ExpireRenewAttempt = 0;
let _RequestingToken = false;
const _WaitingRequestQueue = [];

const preTripRequest = (params, callback, controller, action) => {
    return new Promise((resolve) => {
        let accessToken = getOBTApiAccessToken();
        if (!accessToken) {
            if (_RequestingToken) {
                _WaitingRequestQueue.push(resolve);
            } else {
                const requestTokenParams = {
                    ClientId: config.obtApiClientId,
                    ClientSecret: getProfileToken(),
                };
                _RequestingToken = true;
                request(combineUrl(config.obtApiUrl, 'Auth/V1/json/RequestToken'), requestTokenParams, (_, data) => {
                    _RequestingToken = false;
                    if (data && data.accessToken) {
                        accessToken = data.accessToken;
                        setOBTApiAccessToken(accessToken);
                    }
                    resolve(accessToken);
                    while (_WaitingRequestQueue.length > 0) {
                        _WaitingRequestQueue.shift()(accessToken);
                    }
                }, { needValidAccessToken: false });
            }
        } else {
            resolve(accessToken);
        }
    }).then((accessToken) => {
        if (!accessToken) {
            const data = { status: -1, msg: 'access token not found' };
            typeof callback == 'function' && callback(null, data);
            return data;
        } else {
            const options = {
                needValidAccessToken: false,
                interceptors: {
                    request: (reqInit) => {
                        reqInit.headers.set('Authorization', `Basic ${accessToken}`);
                    },
                    response: (res) => {
                        const statusMapping = {
                            Allow: 0,//200
                            '200': 0,
                            InvalidToken: 1,//403
                            '403': 1,
                            TokenExpired: 2,//401
                            '401': 2,
                            NoAccessRight: 3,//406
                            '406': 3,
                        };
                        //handle server return html situation
                        if (res.status != 200 && res.headers.get('Content-Type').split(';')[0] == 'text/html') {
                            return new Response(JSON.stringify({ status: -1, authorizationStatus: statusMapping[res.status] }), { status: res.status, headers: { "Content-Type": "application/json" } });
                        }
                        if (res.status == 500) {
                            return new Response(JSON.stringify({ status: -1, msg: 'internal server error' }), { status: res.status, headers: { "Content-Type": "application/json" } });
                        }
                        return res;
                    },
                },
            };
            return request(combineUrl(config.obtApiUrl, `${controller}/V1/json/${action}`), params, null, options).then(data => {
                const tokenExpiredStatus = 2;
                if (data && data.authorizationStatus == tokenExpiredStatus) {
                    setOBTApiAccessToken('');
                    _ExpireRenewAttempt++;
                    if (_ExpireRenewAttempt >= 5) {
                        typeof callback == 'function' && callback(null, data);
                        return data;
                    } else {
                        return preTripRequest(params, callback, controller, action);
                    }
                } else {
                    _ExpireRenewAttempt = 0;
                    typeof callback == 'function' && callback(null, data);
                    return data;
                }
            });
        }
    });

}

export function getLoginRights(params, callback) {
    return preTripRequest(params, callback, 'Traveler', 'GetLoginRights');
}

export function searchTraveler(params, callback) {
    return preTripRequest(params, callback, 'Traveler', 'SearchTraveler');
}

export function getTravelerDetail(params, callback) {
    return preTripRequest(params, callback, 'Traveler', 'GetTravelerDetail');
}

export function reqBooking(params, callback) {
    return preTripRequest(params, callback, 'PreApproval', 'ReqBooking');
}

export function getBookUrl(params, callback) {
    return preTripRequest(params, callback, 'PreApproval', 'GetBookUrl');
}

export function searchRequest(params, callback) {
    return preTripRequest(params, callback, 'PreTrip', 'SearchRequest');
}

export function retrieveRequest(params, callback) {
    return preTripRequest(params, callback, 'PreTrip', 'RetrieveRequest');
}

export function cancelRequest(params, callback) {
    return preTripRequest(params, callback, 'PreTrip', 'CancelRequest');
}

export function updateRequest(params, callback) {
    return preTripRequest(params, callback, 'PreTrip', 'UpdateRequest');
}

/**
 * submit to approver
 */
export function finalizeRequest(params, callback) {
    return preTripRequest(params, callback, 'PreTrip', 'FinalizeRequest');
}

export function loadRequestHistory(params, callback) {
    return preTripRequest(params, callback, 'PreTrip', 'LoadRequestHistory');
}

export function selectApprover(params, callback) {
    return preTripRequest(params, callback, 'PreTrip', 'SelectApprover');
}

export function changeApprover(params, callback) {
    return preTripRequest(params, callback, 'PreTrip', 'ChangeApprover');
}

export function auditApprove(params, callback) {
    return preTripRequest(params, callback, 'PreTrip', 'AuditApprove');
}

/**
 * submit to TC
 */
export function submitRequest(params, callback) {
    return preTripRequest(params, callback, 'PreTrip', 'SubmitRequest');
}

export function resendApproveEmail(params, callback) {
    return preTripRequest(params, callback, 'PreTrip', 'ResendApproveEmail');
}

export function getMeta(params, callback) {
    return preTripRequest(params, callback, 'ExtOrder', 'Meta');
}

export function saveAttachment(params, callback) {
    return preTripRequest(params, callback, 'PreTrip', 'SaveAttachment');
}

export function getAttachmentData(params, callback) {
    return preTripRequest(params, callback, 'PreTrip', 'GetAttachmentData');
}

export function postMessage(params, callback) {
    return preTripRequest(params, callback, 'PreTrip', 'PostMessage');
}

export function getMessageRelatedLogin(params, callback) {
    return preTripRequest(params, callback, 'PreTrip', 'GetMessageRelatedLogin');
}

export const tripProposalRequest = (params, callback, action) => preTripRequest(params, callback, 'TripProposal', action);

export const extOrderRequest = (params, callback, action) => preTripRequest(params, callback, 'ExtOrder', action);