import { combineReducers } from 'redux';
import application from './application';
import sso from './sso';
import user from './user';
import userSetting from './userSetting';
import qrCode from './qrCode';
import userData from './userData';
import loginProfile from './loginProfile';
import preTrip from './preTrip';
import tripProposal from './tripProposal';

export { default as rootSaga } from './sagas';

export const rootReducer = combineReducers({
    application,
    sso,
    user,
    userSetting,
    qrCode,
    userData,
    loginProfile,
    preTrip,
    tripProposal,
});
