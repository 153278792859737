import moment from 'moment';
import { Form } from 'antd';
import { store } from '~/index.js';
import { getLoginRights } from '~/redux/preTrip';
import { CTMModal } from '~/components';
import { getValue4Lang } from '~/common';

const UDID_ControlType = {
    TextBox: 0,
    SingleSel: 1,
    Textarea: 2,
    Checkbox: 3,
}
Object.keys(UDID_ControlType).forEach(k => UDID_ControlType[UDID_ControlType[k]] = k);

const RenderSuggestType = {
    Normal: 0,
    Airline: 1,
    Airport: 2,
    HotelCity: 3,
    Country: 4,
}
Object.keys(RenderSuggestType).forEach(k => RenderSuggestType[RenderSuggestType[k]] = k);

const RenderInputFormatType = {
    Text: 0,
    Datetime: 1,
    Time: 2,
    Number: 3,
}
Object.keys(RenderInputFormatType).forEach(k => RenderInputFormatType[RenderInputFormatType[k]] = k);

const RenderControlType = {
    TextBox: 0,
    SingleSel: 1,
    Textarea: 2,
    Checkbox: 3,
}
Object.keys(RenderControlType).forEach(k => RenderControlType[RenderControlType[k]] = k);

export { UDID_ControlType, RenderSuggestType, RenderInputFormatType, RenderControlType };

export const API_DATE_FORMAT = 'YYYY-MM-DD';

export const convertDate = (dateTxt, format) => {
    if (dateTxt && dateTxt.length != (format || API_DATE_FORMAT).length) throw new Error('invalid date format', dateTxt);
    return dateTxt ? moment(dateTxt, format || API_DATE_FORMAT) : null;
}

export const formatDate = (date, format) => date ? date.format(format || API_DATE_FORMAT) : '';


export function initFormFields(submitParam) {
    let field = {};
    const keys = [
        'travelerId',
        'companyId',
        'tripName',
    ];
    keys.forEach(k => field[k] = { value: submitParam[k] });
    field.tripUdids = [];
    submitParam.tripUdids && submitParam.tripUdids.forEach((tripUdid, index) => {
        const key = Object.keys(tripUdid)[0];
        field.tripUdids.push({ [key]: { value: tripUdid[key] } });
    });
    field.flight = {
        estimate: {
            totalBudget: { value: submitParam.flight.estimate ? submitParam.flight.estimate.totalBudget : null },
            currency: { value: submitParam.flight.estimate ? submitParam.flight.estimate.currency : null },
        },
        segments: [],
    };
    submitParam.flight.segments && submitParam.flight.segments.forEach((seg, index) => {
        field.flight.segments.push(initFlightSegmentFields(seg));
    });
    field.hotels = [];
    submitParam.hotels && submitParam.hotels.forEach((htl, index) => {
        field.hotels.push(initHotelFields(htl));
    });
    field.otherProducts = [];
    submitParam.otherProducts && submitParam.otherProducts.forEach((op, index) => {
        field.otherProducts.push(initOtherProductFields(op, submitParam));
    });
    return field;
}

export function initFlightSegmentFields(segment) {
    let field = {};
    const keys = [
        "depCity",
        "dstCity",
        "depDateText",
        "depTime",
        "fareClass",
        "airlines",
        "specialRequest",
        "meal",
        "seat",
    ];
    keys.forEach(k => field[k] = { value: segment[k] });
    return field;
}

export function initHotelFields(segment) {
    let field = {};
    const keys = [
        "cityCode",
        "itemSpecailRequest",
        "checkinText",
        "checkoutText",
        "hotelCode",
        "hotelName",
        "roomName",
        "hotelLocation",
        "loyaltyProgram",
    ];
    keys.forEach(k => field[k] = { value: segment[k] });
    field['estimate'] = {
        totalBudget: { value: segment.estimate ? segment.estimate.totalBudget : null },
        currency: { value: segment.estimate ? segment.estimate.currency : null },
    };
    return field;
}

export function initOtherProductFields(segment, submitParam) {
    let field = {};
    const keys = [
        "productCode",
        "requestSummary",
        "itemSpecailRequest",
        "startDateText",
        "endDateText",
    ];
    keys.forEach(k => field[k] = { value: segment[k] });
    field['estimate'] = {
        totalBudget: { value: segment.estimate ? segment.estimate.totalBudget : null },
        currency: { value: segment.estimate ? segment.estimate.currency : null },
    };
    if (!segment.otherTravelerSels || segment.otherTravelerSels[0].travelerId != submitParam.travelerId) {
        segment.otherTravelerSels = [{
            travelerId: submitParam.travelerId,
            fieldSels: [],
        }];
    }
    const segmentTravelerSel = segment.otherTravelerSels[0];
    const orgFieldSels = [];
    if (submitParam.products && submitParam.products.some(p => p.productDetail && p.productDetail.fields && p.productDetail.fields.length > 0)) {
        submitParam.products.forEach(product => {
            product && product.productDetail && product.productDetail.fields && product.productDetail.fields.forEach(f => {
                const fieldKey = product.productCode + '__' + f.fieldKey;
                let value = f.settingValue || '';
                if (!value && f.predefineSetting && f.predefineSetting.dftSelected) {
                    value = f.predefineSetting.dftSelected;
                }
                if (!value && f.ctrlType == RenderControlType[RenderControlType.Checkbox]) {
                    value = 'N';//default value for checkbox
                }
                orgFieldSels.push({ [fieldKey]: value });
            });
        });
    }
    segmentTravelerSel.fieldSels = segmentTravelerSel.fieldSels.filter(f => orgFieldSels.some(pf => Object.keys(pf)[0] == Object.keys(f)[0]));
    orgFieldSels.forEach(pf => {
        const matchField = segmentTravelerSel.fieldSels.find(f => Object.keys(pf)[0] == Object.keys(f)[0]);
        if (matchField) {
            if (!matchField[Object.keys(matchField)[0]] && pf[Object.keys(pf)[0]]) {
                matchField[Object.keys(matchField)[0]] = pf[Object.keys(pf)[0]];
            }
        } else {
            segmentTravelerSel.fieldSels.push(pf);
        }
    });
    field.otherTravelerSels = [{
        travelerId: { value: submitParam.travelerId },
        fieldSels: [],
    }];
    const otherSels = field.otherTravelerSels[0].fieldSels;
    segmentTravelerSel.fieldSels.forEach(f => {
        const key = Object.keys(f)[0];
        otherSels.push({ [key]: { value: f[key] } });
    });
    return field;
}

export function createFormFields(fields) {
    let formFields = {};
    [
        'travelerId',
        'companyId',
        'tripName',
    ].forEach(k => {
        let formField = Form.createFormField(fields[k]);
        eval(`formFields['${k}']=formField`);
    });
    fields.tripUdids && fields.tripUdids.forEach((field, index) => {
        const key = Object.keys(field)[0];
        let formField = Form.createFormField(field[key]);
        eval(`formFields['tripUdids.${index}.${key}']=formField`);
    });
    if (fields.flight.estimate) {
        formFields['flight.estimate.currency'] = Form.createFormField(fields.flight.estimate.currency);
        formFields['flight.estimate.totalBudget'] = Form.createFormField(fields.flight.estimate.totalBudget);
    }
    fields.flight.segments && fields.flight.segments.forEach((field, index) => {
        [
            "depCity",
            "dstCity",
            "depDateText",
            "depTime",
            "fareClass",
            "airlines",
            "specialRequest",
            "meal",
            "seat",
        ].forEach(key => {
            let formField = Form.createFormField(field[key]);
            eval(`formFields['flight.segments.${index}.${key}']=formField`);
        });
    });
    fields.hotels && fields.hotels.forEach((field, index) => {
        [
            "cityCode",
            "itemSpecailRequest",
            "checkinText",
            "checkoutText",
            "hotelCode",
            "hotelName",
            "roomName",
            "hotelLocation",
            "loyaltyProgram",
        ].forEach(key => {
            let formField = Form.createFormField(field[key]);
            eval(`formFields['hotels.${index}.${key}']=formField`);
        });
        if (field.estimate) {
            formFields[`hotels.${index}.estimate.currency`] = Form.createFormField(field.estimate.currency);
            formFields[`hotels.${index}.estimate.totalBudget`] = Form.createFormField(field.estimate.totalBudget);
        }
    });
    fields.otherProducts && fields.otherProducts.forEach((field, index) => {
        [
            "productCode",
            "requestSummary",
            "itemSpecailRequest",
            "startDateText",
            "endDateText",
        ].forEach(key => {
            let formField = Form.createFormField(field[key]);
            eval(`formFields['otherProducts.${index}.${key}']=formField`);
        });
        if (field.estimate) {
            formFields[`otherProducts.${index}.estimate.currency`] = Form.createFormField(field.estimate.currency);
            formFields[`otherProducts.${index}.estimate.totalBudget`] = Form.createFormField(field.estimate.totalBudget);
        }
        if (field.otherTravelerSels) {
            field.otherTravelerSels && field.otherTravelerSels.forEach((travelerSel, fIndex) => {
                let travelerSelField = Form.createFormField(travelerSel.travelerId);
                eval(`formFields['otherProducts.${index}.otherTravelerSels.${fIndex}.travelerId']=travelerSelField`);
                travelerSel.fieldSels && travelerSel.fieldSels.forEach((fs, fsIndex) => {
                    const key = Object.keys(fs)[0];
                    let formField = Form.createFormField(fs[key]);
                    eval(`formFields['otherProducts.${index}.otherTravelerSels.${fIndex}.fieldSels.${fsIndex}.${key}']=formField`);
                });
            });
        }
    });
    return formFields;
}

export const convertFormFields2Obj = (obj, fields, { clearNamePrefix = false } = {}) => {
    if (!obj || !fields) return;
    function exec(val) {
        if (typeof val !== 'object') return;
        const isFieldVal = ['value', 'name'].every(k => Object.keys(val).some(vk => k === vk));
        if (isFieldVal) {
            const chain = val.name.split('.').slice(clearNamePrefix ? 1 : 0).map(k => `['${k}']`).join('');
            eval(`obj${chain}=val.value`);
        } else {
            Object.keys(val).forEach(k => {
                exec(val[k]);
            });
        }
    }
    exec(fields);
};

export const mergeFormFields = (target, fields) => {
    function exec(val) {
        if (typeof val !== 'object') return;
        const isFieldVal = ['value', 'name'].every(k => Object.keys(val).some(vk => k === vk));
        if (isFieldVal) {
            const chain = val.name.split('.').map(k => `['${k}']`).join('');
            eval(`target${chain}=val`);
        }
        else {
            Object.keys(val).forEach(k => {
                exec(val[k]);
            });
        }
    }
    exec(fields);
}

export const prepareMeta = () => {
    return new Promise(resolve => {
        const state = store.getState();
        if (state.preTrip.meta) {
            resolve();
        } else {
            !state.preTrip.loading && store.dispatch(getLoginRights());
            const unsubscribe = store.subscribe(() => {
                const preTrip = store.getState().preTrip;
                if (preTrip.meta || preTrip.errMsg) {
                    unsubscribe();
                    preTrip.errMsg && CTMModal.error({ title: getValue4Lang('Get trip request data failed: ', '获取行程请求数据失败：', '獲取行程請求數據失敗：') + preTrip.errMsg });
                    resolve(preTrip.errMsg);
                }
            });
        }
    });
}

export const getDefaultDepartureCity = (travelerDetail) => {
    let city = '';
    if (travelerDetail && travelerDetail.svcs && travelerDetail.svcs.some(s => s.serviceType == 'Flight')) {
        const flightSvc = travelerDetail.svcs.find(s => s.serviceType == 'Flight');
        if (flightSvc.channels && flightSvc.channels.length > 0 && flightSvc.channels[0].defaultDepartureCity) {
            city = flightSvc.channels[0].defaultDepartureCity;
        }
    }
    return city;
}

export const isUdidShow = (tripUdids, codKey) => {
    if (!codKey || !tripUdids) return false;
    const matchUdid = tripUdids.find(u => u.codKey == codKey);
    if (matchUdid && matchUdid.isDefaultShowInDoc) return true;
    const relatedUdid = tripUdids.find(u => u.predefineSetting && u.predefineSetting.preVals && u.predefineSetting.preVals.some(v => v.val == u.selVal && v.codKey == codKey));
    if (relatedUdid) {
        return isUdidShow(tripUdids, relatedUdid.codKey);
    }
    return false;
}

export const isUdidRequired = (tripUdids, codKey) => {
    if (!codKey || !tripUdids || !isUdidShow(tripUdids, codKey)) return false;
    const matchUdid = tripUdids.find(u => u.codKey == codKey);
    if (matchUdid && matchUdid.isMandatory) return true;
    const relatedUdid = tripUdids.find(u => u.predefineSetting && u.predefineSetting.preVals && u.predefineSetting.preVals.some(v => v.val == u.selVal && v.codKey == codKey));
    if (relatedUdid) return true;
    return false;
}

export const isOtherProductFieldShow = (fields, fieldKey) => {
    if (!fieldKey || !fields) return false;
    const matchField = fields.find(u => u.fieldKey == fieldKey);
    if (matchField && matchField.isDefaultShowInDoc) return true;
    const relatedUdid = fields.find(u => u.predefineSetting && u.predefineSetting.preVals && u.predefineSetting.preVals.some(v => v.val == u.selVal && v.relatedFieldKeys && v.relatedFieldKeys.includes(fieldKey)));
    if (relatedUdid) {
        return isOtherProductFieldShow(fields, relatedUdid.fieldKey);
    }
    return false;
}

export const isOtherProductFieldRequired = (fields, fieldKey) => {
    if (!fieldKey || !fields || !isOtherProductFieldShow(fields, fieldKey)) return false;
    const matchField = fields.find(u => u.fieldKey == fieldKey);
    if (matchField && matchField.isMandatory) return true;
    const relatedUdid = fields.find(u => u.predefineSetting && u.predefineSetting.preVals && u.predefineSetting.preVals.some(v => v.val == u.selVal && v.relatedFieldKeys && v.relatedFieldKeys.includes(fieldKey)));
    if (relatedUdid) return true;
    return false;
}