import './polyfill';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { SmartApp, AuthCallback, Login, Logout, ResetPwd, SignOut, SSOCallback, ContentView, NewsAttachment, PreTripAttachment, TripProposalAttachment } from './pages';
import { ProtectedRoute, ErrorBoundary } from '~/components';
import { createStore, applyMiddleware } from 'redux'
import { rootReducer, rootSaga } from './redux';
import createSagaMiddleware from 'redux-saga';
import Auth from '~/common/Auth';
import './index.less';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { config, getIEVersion, getCacheAppVersion, setCacheAppVersion, isEmpty } from '~/common';
import { addLocaleData, IntlProvider } from 'react-intl';
import appLocale from '~/locales';
import ConfigProviderPolyfill from '~/components/ConfigProviderPolyfill';

const sagaMiddleWare = createSagaMiddleware();

export const store = createStore(rootReducer, applyMiddleware(sagaMiddleWare));
store.subscribe(() => {
    //console.log(store.getState());
});
sagaMiddleWare.run(rootSaga);

const auth = new Auth();

const cacheVersion = getCacheAppVersion();

addLocaleData(appLocale.data);

const App = () => {
    const ieVersion = getIEVersion();
    if (ieVersion > 0 && ieVersion < 11) return null;
    if (cacheVersion !== config.version) {
        localStorage.clear();
        setCacheAppVersion(config.version);
    }

    return (
        <IntlProvider locale={appLocale.locale} key={appLocale.locale} messages={appLocale.messages}>
            <Provider store={store}>
                <ErrorBoundary>
                    <BrowserRouter basename={config.publicUrl}>
                        <Switch>
                            <Route path='/login' exact render={props => <Login auth={auth} {...props} />} />
                            <Route path='/logout' exact render={props => <Logout auth={auth} {...props} />} />
                            <Route path='/callback' exact render={props => <AuthCallback auth={auth} {...props} />} />
                            <Route path='/sign-out' exact render={props => <SignOut auth={auth} {...props} />} />
                            {!isEmpty(config.resetPwdRoute) && <Route path={config.resetPwdRoute} exact render={props => <ResetPwd auth={auth}  {...props} />} />}
                            <Route path='/sso/:code' exact render={props => <SSOCallback auth={auth} {...props} />} />
                            <Route path='/privacy-agreement' exact render={props => <ContentView category='Terms' keyword='privacy-agreement' {...props} />} />
                            <ProtectedRoute path='/attachment/news' exact auth={auth} component={NewsAttachment} />
                            <ProtectedRoute path='/pre-trip/attachment' exact auth={auth} component={PreTripAttachment} />
                            <ProtectedRoute path='/booking/attachment' exact auth={auth} component={TripProposalAttachment} />
                            <ProtectedRoute path='/' auth={auth} component={SmartApp} />
                        </Switch>
                    </BrowserRouter>
                </ErrorBoundary>
            </Provider>
        </IntlProvider>
    );
};

ReactDOM.render(
    <ConfigProviderPolyfill locale={appLocale.antd} component={App} />,
    document.getElementById('ctm-smart-app')
);