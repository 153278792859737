module.exports = {
    language: "語言",
    system_information: "系統提示",
    login_lightning_failed_prompt: "單點登錄lightning失敗, 無效用戶",
    loading: "加載中...",
    page_not_found: "404 - 找不到頁面!",
    page_not_found_prompt: "非常抱歉，你查詢的頁面不存在。",
    record_not_found: "找不到記錄",
    error_occurred: "發生錯誤",
    booking_not_found: '未找到預訂',
    dashboard: '首頁',
    tools: '我的工具',
    fare_forecaster: '票價預覽',
    deadline_alert: '行程預訂提醒',
    pending_for_approval: '待批核行程',
    upcoming_trips: '待出發行程',
    deadline: '截止日期',
    logout: '退出',
    change_password: '更改密碼',
    fare_forecaster_form_prompt: '請在下方輸入您的搜尋條件，以查看最優惠的航班價格。',
    close_filters: '關閉搜尋',
    flight_index_fmt: '航班 {index}',
    enter_city_airport_name: '輸入城市 / 機場',
    departure_date: '出發日期',
    any_time: '不限時間',
    add_another_flight: '添加航班',
    cabin_class: '艙位等級',
    first_class: '頭等艙',
    business: '商務艙',
    premium_economy: '特選經濟艙',
    economy: '經濟艙',
    direct: '直航',
    search: '搜尋',
    no_data: '-',
    one_way: '單程',
    round_trip: '往返',
    multi_city: '多程',
    enter_an_airport: '輸入機場',
    just_loading_your_search_results: '搜尋中',
    departing: '去程',
    returning: '回程',
    departure: '去程',
    return: '回程',
    lowest_fare: '最低票價',
    booking_now: '立即預訂',
    same_city_prompt: '出發城市不能和到達城市相同',
    multi_city_not_support_prompt: '多程暫未支持，請試單程或者往返',
    search_timeout_prompt: '搜尋超時，請刷新你的搜尋',
    refresh: '刷新',
    all: '所有行程',
    stop: '轉機',
    duration_fmt: '{hours}小時{minutes}分鐘',
    traveler_tracker: '旅客追蹤',
    highest_fare: '最高票價',
    browser_not_support_webgl: 'Mapbox GL需要WebGL支持。 請檢查您是否使用支持的浏覽器並啓用了WebGL。',
    en_route: '旅途中',
    landed: '已抵達',
    at_risk: '有風險',
    type: '類型',
    code: '代碼',
    total: '總數',
    airport: '機場',
    hotel: '酒店',
    back_to_home_page: '返回首頁',
    oops_something_went_wrong: '糟糕，頁面出錯了！',
    trip_name: '行程名稱',
    update_search: '重新搜尋',
    on_time: '準時出發',
    delayed: '延誤出發',
    not_departed: '未出發',
    my_apps: '我的應用程式',
    hello: '你好',
    status: '狀態',
    arrival: '抵達',
    check_in: '入住',
    check_out: '退房',
    order_no: '訂單编号',
    select_screen_theme: '選擇主題',
    select_language: '選擇語言',
    breezy: '微風',
    galaxy: '銀河',
    widgets: '工具捷徑',
    start_date: '開始日期',
    end_date: '結束日期',
    to: '到',
    world_clock: '環球時間',
    add_new_clock: '添加時區',
    choose_timezone: '選擇時區',
    delete: '刪除',
    cancel: '取消',
    day: '{type,select,0{前}other{後}}{count}天',
    week: '{type,select,0{前}other{後}}{count}週',
    month_to_date: '本月初',
    date_to_month_end: '本月月底',
    search_trip: '輸入姓名、航班、酒店名稱或地點搜索',
    trip_between: '{endDate,select,noLimit{{startDate,select,noLimit{顯示所有行程}other{顯示 {previous} 之後的行程}}}other{{startDate,select,noLimit{顯示 {upcoming} 之前的行程}other{顯示 {previous} 和 {upcoming} 之間的行程}}}}',
    previous: '從',
    upcoming: '至',
    found: '找到',
    no_limit: '沒有限制',
    please_select: "請選擇",
    filter: "篩選",
    no_booking_for_approval: "沒有待批核行程",
    no_booking_for_upcoming: "沒有待出發行程 ",
    no_booking_be_alert: "沒有行程預訂提醒",
    any: "所有",
    click_for_detail: '點擊查看詳情',
    agency_record_locator: '行程編號',
    travel_time_fmt: '飛行時間: {hours}小時{minutes}分鐘',
    amenities: '設施',
    amenities_not_found: '沒有找到設施',
    nights_count: '晚數',
    description: '說明',
    location: '所在位置',
    stopover: '經停',
    view_in: '前往',
    trip_count_fmt: '{count,plural,=0{沒有行程}other{# 段行程}}',
    fare_not_found: '找不到機票報價',
    online_check_in: '在線值機',
    login_for_mobile: '登錄Lightning Mobile',
    mobile_phone_login: '手機登錄',
    download_app: '下載應用程序',
    scan_qr_code_to_download_app: '掃描二維碼下載客戶端',
    download_app_already_login_now: '已下載客戶端，立即登錄',
    deactivate: '停用',
    login_ctm_lightning_mobile_app: "請掃描二維碼登錄CTM Lightning手機客戶端",
    risk_news: '新聞資訊',
    risk_level: '風險等級',
    country: "國家 / 地區",
    keyword: "關鍵字",
    country_code_or_name: "國家/地區 代碼或名稱",
    read_less: "收起...",
    read_more: "查看更多...",
    scroll: "鼠標滾動",
    no_risk_news: "沒有新聞資訊",
    on: "開",
    off: "關",
    georisk_map: "風險地區",
    risk_level_up: '風險等級',
    risk_alert_fmt: '{count,plural,=0{沒有風險概覽}other{# 則風險概覽}}',
    new: "未讀",
    load_more: "加載更多",
    search_for_a_news: "搜索更新",
    search_for_a_document: "搜索文件",
    search_for_a_link: "搜索網址",
    news: "快訊",
    documents: "檔案",
    links: "網址",
    news_feed: "資訊",
    no_news_found: "找不到快訊",
    no_links_found: "找不到網址",
    no_documents_found: "找不到檔案",
    cancelled: "航班取消",
    unknown: "未知",
    warning: "警告",
    contact_us: "聯繫我們",
    contact_person: "聯繫人",
    phone: "電話",
    email: "電郵",
    reset_your_password: "重置密碼",
    please_enter_your_email_address: "請輸入您的電子郵件地址，我們將發送電子郵件給您重置密碼",
    your_new_password: "設置新密碼",
    confirm_your_new_password: "再次確認新密碼",
    invalid_email_format: "無效的郵件地址",
    send_email: "發送郵件",
    your_password_has_been_reset_successfully: "您已成功重置密碼。",
    enter_a_new_password: "請為{email}重置密码",
    retry: "重試",
    or: "或者",
    timeout_tip: "會話超時，請重新登錄",
    you_have_signed_out: "您已成功退出 CTM Portal",
    profile: '資料',
    continue: '繼續',
    password_policy: '密碼政策',
    at_least_6_characters_in_length: '請輸入至少6位字符',
    ok: '確定',
    share: '分享',
    copy_link_success: '複製鏈接成功',
    the_document_was_not_downloaded: '文檔沒有下載？',
    trip_request: '行程請求',
    page_no_right: '403 - 你沒有權限查看此頁面！',
    not_authorized_login_tip: '您無權訪問此網址。請在訪問此網址前登錄CTM Portal。',
    not_authorized_tip: '您無權訪問此網址。',
    login_now: '立即登錄',
    invalid_parameter: '無效的參數',
    later: '稍後',
    confirm: '確認',
    close: '關閉',
    save: '保存',
    cancel_request_tip: '此行程請求將被取消。請確認是否要繼續。',
    submit_to_approver_tip: '您確認將此行程請求提交至審批人嗎？',
    submit_to_tc_tip: '您確認將此行程請求提交至旅遊顧問跟進嗎？',
    cancel_flight_request_tip: '您確定要取消這個航班請求嗎？',
    cancel_hotel_request_tip: '您確定要取消這個酒店請求嗎？',
    cancel_other_service_request_tip: '您確定要取消這個服務請求嗎？',
    update_success: '更新成功',
    make_booking_tip: '您確定爲此請求創建訂單嗎？',
    approve_tip: '您確定要批准此行程請求嗎？',
    optional_approve_reason: '選填批准原因',
    please_enter: '請輸入',
    approve_request: '審批請求',
    approve: '批准',
    please_enter_reject_reason: '請輸入拒絕原因',
    approval_declined: '審批不通過',
    decline: '拒絕',
    resend_email: '重發電子郵件',
    resend_email_success: '重新發送郵件成功',
    resend_email_tip: '您確定重新發送審批郵件嗎？',
    select_approver: '選擇審批人',
    select_approver_tip: '請先選擇審批人並創建審批流程。',
    add_flight_request: '添加航班請求',
    add_hotel_request: '添加酒店請求',
    add_other_service_request: '添加其他服務請求',
    edit_flight_request: '編輯航班請求',
    edit_hotel_request: '編輯酒店請求',
    edit_other_service_request: '編輯其他服務請求',
    trip_request_detail: '行程請求詳情',
    operation_history: '操作曆史',
    operated_by: '操作人',
    operated_on: '操作時間',
    back: '返回',
    new_trip_request: '新建行程請求',
    approver_not_found: '找不到審批人',
    please_select_approver: '請選擇審批人',
    change_level_approver_fmt: '更改級別{step}審批人',
    edit_note_to_approver: '編輯審批人須知',
    approve_form_not_found_tip: '找不到審批表單，請聯系您的旅遊顧問',
    approval_workflow: '審批流程',
    no_need_approval: '不需要審批',
    please_select_level_approver_fmt: '請選擇級別{step}審批人',
    note_to_approver: '審批人須知',
    attachment: '附件',
    level_fmt: '級別{step}',
    change_approver: '更改審批人',
    remark: '備注',
    remark_colon: '備注：',
    please_enter_trip_name: '請輸入行程名稱',
    special_request: '特別要求',
    trip_overview: '行程概覽',
    traveller_udid_not_found: '找不到旅客UDID信息',
    enable: '啓用',
    please_enter_this_field: '請輸入此項',
    update_trip_information: '更改行程信息',
    created: '創建',
    total_budget: '總計',
    request_reference: '請求編號',
    booking_num: '訂單號',
    traveller: '旅客',
    contact_phone_num: '聯系電話號碼',
    trip_information: '行程信息',
    details: '詳情',
    flight: '航班',
    price_currency_tip_fmt: '如無額外聲明，所有票價均以{currency}顯示',
    segment_departure: '出發',
    cabin: '艙位等級',
    time_prefer: '時間偏好',
    airlines: '航空公司',
    seat_preference: '座位偏好',
    meal_preference: '餐食偏好',
    flight_budget: '航班預算',
    taxes_tip_with_parenthesis: '（包括稅費和服務費）',
    edit: '編輯',
    clear: '清除',
    room_name: '房間名稱',
    loyalty_program: '會員計劃',
    hotel_budget: '酒店預算',
    add_flight: '添加航班',
    add_hotel: '添加酒店',
    add_other_service: '添加其他服務',
    cancel_request: '取消請求',
    submit_to_approver: '提交至審批人',
    confirm_request: '確認請求',
    submit_to_consultant: '提交至旅遊顧問',
    make_booking: '創建訂單',
    create_new: '新建',
    search_result_colon: '搜尋結果：',
    trip_request_search: '搜尋行程請求',
    advanced_trip_request_search: '高級搜尋行程請求',
    company: '公司',
    traveller_names: '旅客姓名',
    traveller_email: '旅客電郵',
    destination: '目的地',
    hotel_name: '酒店名稱',
    request_date_from: '請求日期從',
    request_date_to: '請求日期至',
    departure_date_from: '出發日期從',
    departure_date_to: '出發日期至',
    return_date_from: '返回日期從',
    return_date_to: '返回日期至',
    general_search: '一般搜尋',
    advanced_search: '高級搜尋',
    start: '開始',
    end: '結束',
    requested: '請求',
    please_select_fmt: '請選擇{0}',
    please_enter_fmt: '請輸入{0}',
    please_enter_correct_fmt: '請輸入正確的{0}',
    departure_city: '出發城市',
    arrival_city: '抵達城市',
    fare_class: '票價類別',
    departure_time: '出發時間',
    city: '城市',
    check_in_date: '入住日期',
    check_out_date: '退房日期',
    please_fill_in_your_other_requirements: '請填寫你的其他需求',
    add_another_hotel: '添加酒店',
    all_status: '所有狀態',
    comment: '評論',
    write_a_message_here: '在此處填寫留言...',
    invalid_message: '無效的留言',
    message_size_max_limit_colon: '留言大小最大限制：',
    pending: '待審批',
    approved: '已批准',
    rejected: '已拒絕',
    already_exist_fmt: '{0}已經存在',
    invalid_attachment_type: '無效的附件類型',
    support_attachment_type_colon: '支持的附件類型：',
    invalid_attachment: '無效的附件',
    attachment_size_max_limit_colon: '附件大小最大限制：',
    click_drag_upload: '點擊或拖動文件到這裡',
    single_bulk_upload: '支持單個或批量上傳',
    download: '下載',
    invalid_traveller: '無效旅客',
    confirm_request_tip: '您確定此行程請求嗎？',
    no_trip_request: '沒有行程請求',
    message_dialog: '留言',
    related_login_not_found: '找不到關聯用戶',
    special_request_remark: '特別要求（備註）',
    itinerary: '行程',
    please_enter_different_city: '請輸入不同城市',
    flow_type: '流程類型',
    approval_history: '審批歷史',
    original_total_colon: '原總計：',
    original_budget_colon: '原預算: ',
    show_all: '顯示全部',
    show_less: '顯示更少',
    other_service: '其他服務',
    budget: '預算',
    category: '類別',
    add_another_service: '添加其他服務',
    online_trip_proposal: '在線行程計劃',
    no_online_trip_proposal: '沒有在線行程計劃',
    reference_number: '請求編號',
    online_trip_proposal_search: '搜尋在線行程計劃',
    advanced_online_trip_proposal_search: '高級搜尋在線行程計劃',
    online_trip_proposal_detail: '在線行程計劃詳情',
    proposal_ref: '請求編號',
    overviews: '概述',
    estimate_total: '估計總額',
    total_amount: '總額',
    per_adult: '每成人',
    detail: '詳情',
    client_information: '客人信息',
    name: '姓名',
    itinerary_detail_of_fmt: '{0}的行程詳情',
    ticket_fare_per_person: '每人的票價',
    show_detail: '顯示詳情',
    depart: '出發',
    travel_time: '飛行時間',
    fare: '票價',
    tax: '税',
    surcharge: '附加費',
    service_fee: '服務費',
    pnr_fee: 'PNR費',
    credit_card_payment: '信用卡支付',
    discount: '折扣',
    selected_fmt: '已選{0}',
    option: '選項',
    carbon_emission: '碳排放',
    not_included: '不包含',
    higher: '更高',
    emissions: '排放',
    this_flight: '本航班',
    typical_for_this_route: '這條路線的典型情況',
    lower: '更低',
    emissions_tip: '排放量是以您選擇的座位等級中的一名乘客計算的。',
    carry_on_bag: '手提行李',
    checked_bag: '托運行李',
    carry_on_bag_fmt: '第{0}段手提行李',
    checked_bag_fmt: '第{0}段托運行李',
    confirm_trip_detail: '確認行程詳情',
    traveller_information: '旅客信息',
    itinerary_information: '行程信息',
    not_lowest_price_tip: '該航班不是最低價格。',
    current_price: '當前價格',
    lowest_price: '最低價格',
    lowest_fare_tax: '最低價税',
    lowest_fare_carrier: '最低價航空公司',
    optional: '可選',
    proposal_created_tip: '你的預訂已成功創建。',
    proposal_submit_tip: '請提交本預訂以繼續您的預訂程序。',
    trip_plan_fmt: '行程計劃{0}',
    option_fmt: '選項{0}',
    agency_contact_person: '代理聯繫人',
    client_company_name: '客人公司名稱',
    operated_by_fmt: '承運：{0}',
    no_check_in_baggage: '沒有托運行李',
    please_confirm_trip_plan_option_first: '請先確認行程計劃選項',
    fare_rules: '票價規則',
    itineraries: '行程',
    corporate_deal: '公司協議價',
    sme_deal: 'SME協議價',
    rules: '規則',
    selected: '已選',
    select: '選擇',
    processing_your_booking_now: '正在處理你的預訂...',
    traveller_not_found: '找不到旅客',
    finalize: '確認',
    select_approver_success_tip: '選擇審批人成功，現在確認你的訂單嗎？',
    related_booking_info_not_found: '找不到關聯訂單信息',
    submit: '提交',
    ancillary: '附加費',
    flight_ordinal_fmt: '第{ordinal}躺航班',
    transfer: '中轉',
    transfer_in: '中轉',
    otp_stop: '經停',
    otp_stopover: '經停',
    transaction_fee: '附加交易手續費（CTM收取）',
    admin_service_fee: '附加服務手續費（CTM收取）',
    preview_email_dialog_history: '預覽郵件對話歷史',
    email_dialog_history: '郵件對話歷史',
    date: '日期',
    subject: '主題',
    email_dialog_history_not_found: '找不到郵件對話歷史',
    email__from: '發件人',
    email__to: '收件人',
    email__cc: '抄送',
    email__bcc: '密送',
    all_rules: '所有規則',
    all_detail: '所有詳情',
    transit_time: '中轉時間',
    risk_hub: '風險等級查詢',
    dark: '深色',
    light: '淺色',
}