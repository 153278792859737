import { Languages, config, TripType, TravelStateType, TravelStatus, WidgetSettings, getCacheUserInfo } from '~/common';
import moment from 'moment';
import { getCurrentLang } from '../cache';

// Detects whether or not a browser is IE (which we don't support)
export function getIEVersion() {
    var sAgent = window.navigator.userAgent;
    var Idx = sAgent.indexOf("MSIE");

    // If IE, return version number.
    if (Idx > 0)
        return parseInt(sAgent.substring(Idx + 5, sAgent.indexOf(".", Idx)));

    // If IE 11 then look for Updated user agent string.
    else if (!!navigator.userAgent.match(/Trident\/7\./))
        return 11;

    else
        return 0; //It is not IE
}

export function parseQueryString(url) {
    const obj = {}
    url = url || window.location.search;
    if (url.indexOf('?') !== -1) {
        const str = url.split('?')[1];
        const strs = str.split('&');
        strs.map((item, i) => {
            const arr = strs[i].split('=');
            /* eslint-disable */
            obj[arr[0]] = decodeURIComponent(arr[1] || '');
            return item;
        })
    }
    return obj;
}

export const getQueryStringValue = (url, key) => {
    let ret = '';
    const obj = parseQueryString(url);
    if (key && Object.keys(obj).some(k => k.toLowerCase() == key.toLowerCase())) {
        ret = obj[Object.keys(obj).find(k => k.toLowerCase() == key.toLowerCase())];
    }
    return ret;
}

export function toQueryString(data) {
    function parseDataType(data) {
        if (moment.isMoment(data)) return data;
        if (typeof data === 'object' && data !== null) {
            return JSON.stringify(data);
        }
        return data;
    }
    return Object.keys(data)
        .map(k => {
            return encodeURIComponent(k) + '=' + encodeURIComponent(parseDataType(data[k]));
        })
        .join('&');
}

export function combineUrl(baseUrl, path) {
    baseUrl = baseUrl || '';
    path = path || '';
    baseUrl = `${baseUrl}${baseUrl.endsWith('/') ? '' : '/'}`;
    path = path.startsWith('/') ? path.substring(1) : path;
    return baseUrl + path;
}

export const getTripType = (multiFlights = []) => {
    let tripType = TripType.Roundtrip;
    if (!multiFlights || !multiFlights.length) return tripType;
    if (multiFlights.length === 1) {
        tripType = TripType.Oneway;
    } else if (multiFlights.length === 2 && multiFlights[0].depCity === multiFlights[1].dstCity && multiFlights[0].dstCity === multiFlights[1].depCity) {
        tripType = TripType.Roundtrip;
    } else {
        tripType = TripType.MultiCity;
    }
    return tripType;
}

export const getCurrencySymbol = currency => {
    let symbol = '';
    switch (currency) {
        case "HKD":
            symbol = "\$";
            break;
        case "CNY":
            symbol = "¥";
            break;
        case "SGD":
            symbol = "\$";
            break;
        case "TWD":
            symbol = "\$";
            break;
        case "ECB":
            symbol = "€";
            break;
        case "JPY":
            symbol = "¥";
            break;
        case "EUR":
            symbol = "€";
            break;
    }
    return symbol;
}

export const animate = {
    slideDown(dom, duration, callback) {
        if (!dom || !dom.nodeType) return;
        duration = duration || 800;
        const computedStyle = getComputedStyle(dom);
        const currentDisplay = computedStyle.display;
        dom.style.display = currentDisplay === 'none' ? 'block' : currentDisplay;
        dom.style.overflow = 'hidden';
        dom.style.height = 'auto';
        dom.style.transition = '';
        const h = dom.clientHeight;
        dom.style.height = 0;
        window.setTimeout(function () {
            dom.style.transition = `height ${duration}ms linear`;
            dom.style.height = h + 'px';
            window.setTimeout(function () {
                dom.style.height = '';
                dom.style.overflow = '';
                typeof callback === 'function' && callback();
            }, duration + 100);
        }, 100);
    },
    slideUp(dom, duration, callback) {
        if (!dom || !dom.nodeType) return;
        duration = duration || 800;
        const computedStyle = getComputedStyle(dom);
        const currentDisplay = computedStyle.display;
        if (currentDisplay === 'none') return;
        dom.style.display = currentDisplay === 'none' ? 'block' : currentDisplay;
        dom.style.overflow = 'hidden';
        dom.style.height = 'auto';
        dom.style.transition = '';
        const h = dom.clientHeight;
        dom.style.height = h + 'px';
        window.setTimeout(function () {
            dom.style.transition = `height ${duration}ms linear`;
            dom.style.height = 0;
            window.setTimeout(function () {
                dom.style.display = 'none';
                dom.style.height = '';
                typeof callback === 'function' && callback();
            }, duration + 100);
        }, 100);
    },
    fadeIn(dom, duration, callback) {
        if (!dom || !dom.nodeType) return;
        duration = duration || 800;
        dom.style.display = '';
        dom.style.opacity = 0;
        dom.style.transition = '';
        window.setTimeout(function () {
            dom.style.transition = `opacity ${duration}ms linear`;
            dom.style.opacity = 1;
            window.setTimeout(function () {
                typeof callback === 'function' && callback();
            }, duration + 100);
        }, 100);
    },
    fadeOut(dom, duration, callback) {
        if (!dom || !dom.nodeType) return;
        duration = duration || 800;
        dom.style.display = '';
        dom.style.opacity = 1;
        dom.style.transition = '';
        window.setTimeout(function () {
            dom.style.transition = `opacity ${duration}ms linear`;
            dom.style.opacity = 0;
            window.setTimeout(function () {
                dom.style.display = 'none';
                typeof callback === 'function' && callback();
            }, duration + 100);
        }, 100);
    },
    scrollToTop(ele, yPosition) {
        let y = (yPosition || ele.scrollTop) / 3;
        if (ele.scrollTop > 0) {
            ele.scrollTop -= Math.max(y, 10);
            setTimeout(() => {
                animate.scrollToTop(ele, y);
            }, 10);
        }
    },
    scrollTo(ele, yPosition, time) {
        if (!time) {
            ele.scrollTop = yPosition;
            return;
        }
        const spacingTime = 20;
        let spacingInex = time / spacingTime;
        let nowTop = ele.scrollTop;
        let everTop = (yPosition - nowTop) / spacingInex;
        let scrollTimer = setInterval(() => {
            if (spacingInex > 0) {
                spacingInex--;
                animate.scrollTo(ele, nowTop += everTop);
            } else {
                clearInterval(scrollTimer);
            }
        }, spacingTime);
    }
};

export const getImageUrl = img => `${config.publicUrl}/images/${img}`;

export const media = {
    isMobile: () => {
        return window.innerWidth < 768;
    },
    isMobileDevice: () => {
        return window.innerWidth < 768 || ((navigator.userAgent.toLowerCase().match(/(phone|pad|pod|iphone|ipod|ios|ipad|android|mobile|blackberry|iemobile|mqqbrowser|juc|fennec|wosbrowser|browserng|webos|symbian|windows phone)/i)));
    }
};

export const getTravelStatus = status => {
    let travelStatus = {
        statusCode: '',
        statusField: '',
        statusType: null,
    };
    switch (status) {
        case TravelStateType.Landed:
        case TravelStatus.Landed:
            travelStatus.statusCode = TravelStatus.Landed;
            travelStatus.statusField = 'Landed';
            travelStatus.statusType = TravelStateType.Landed;
            break;
        case TravelStateType.En_Route:
        case TravelStatus.En_Route:
            travelStatus.statusCode = TravelStatus.En_Route;
            travelStatus.statusField = 'En_Route';
            travelStatus.statusType = TravelStateType.En_Route;
            break;
        case TravelStateType.At_Risk:
        case TravelStatus.At_Risk:
            travelStatus.statusCode = TravelStatus.At_Risk;
            travelStatus.statusField = 'At_Risk';
            travelStatus.statusType = TravelStateType.At_Risk;
            break;
        case TravelStateType.On_Time:
        case TravelStatus.On_Time:
            travelStatus.statusCode = TravelStatus.On_Time;
            travelStatus.statusField = 'On_Time';
            travelStatus.statusType = TravelStateType.On_Time;
            break;
        case TravelStatus.Delayed:
        case TravelStateType.Delayed:
            travelStatus.statusCode = TravelStatus.Delayed;
            travelStatus.statusField = 'Delayed';
            travelStatus.statusType = TravelStateType.Delayed;
            break;
        case TravelStatus.Not_Departed:
            travelStatus.statusCode = TravelStatus.Not_Departed;
            travelStatus.statusField = 'Not_Departed';
            break;
        case TravelStatus.All:
            travelStatus.statusCode = TravelStatus.All;
            travelStatus.statusField = 'Total';
            break;
        case TravelStatus.Cancelled:
        case TravelStateType.Cancelled:
            travelStatus.statusCode = TravelStatus.Cancelled;
            travelStatus.statusField = 'Cancelled';
            break;
        case TravelStatus.Unknown:
        case TravelStateType.Unknown:
            travelStatus.statusCode = TravelStatus.Unknown;
            travelStatus.statusField = 'Total';
            break;
        case TravelStatus.Warning:
        case TravelStateType.Warning:
            travelStatus.statusCode = TravelStatus.Warning;
            travelStatus.statusField = 'Warning';
            break;
        default:
            break;
    }
    return travelStatus;
};

export function isEmpty(str) {
    return str == null || (typeof str === 'string' && str.trim() == '');
}

export function mapTimeFrames(timeFrames, format) {
    let startDate, endDate, timeFrames0 = timeFrames[0], timeFrames1 = timeFrames[1];
    const timeRange = {
        1: 1,
        2: 2,
        3: 7,
        4: 14,
        5: 30,
        "noLimit": "noLimit"
    };

    startDate = timeFrames0 === "noLimit" ? timeFrames0 : (timeFrames0 === 6 ? moment().startOf("month").format(format) : moment().endOf('day').subtract(timeRange[timeFrames[0]], 'days').format(format));
    endDate = timeFrames1 === "noLimit" ? timeFrames1 : (timeFrames1 === 6 ? moment().endOf("month").format(format) : moment().startOf('day').add(timeRange[timeFrames[1]], 'days').format(format));
    return { startDate, endDate };
}

export const setWidgetDefaults = (widgets) => {
    const newWidgets = widgets && widgets.reduce((prev, cur) => {
        const newWidget = { ...cur };
        const defaultWidget = WidgetSettings.find((w => w.WidgetType == newWidget.WidgetType));
        if (defaultWidget) {
            newWidget.EleId = defaultWidget.EleId;
            newWidget.FormatMessageId = defaultWidget.FormatMessageId;
            let attrs = newWidget.Attributes;
            if (typeof (newWidget.Attributes) == 'string') {
                try {
                    attrs = JSON.parse(newWidget.Attributes);
                } catch (ex) {
                    console.log(ex);
                    attrs = null;
                }
            }
            newWidget.Attributes = { ...defaultWidget.Attributes, ...attrs, ...{ spanSetting: defaultWidget.Attributes.spanSetting } };
            newWidget.Component = defaultWidget.Component;
            prev.push(newWidget);
        }
        return prev;
    }, []) || [];
    return newWidgets;
}

export const getValue4Lang = (valueEnUs, valueZhCn, valueZhTw = valueZhCn, lang = getCurrentLang()) => {
    const value = lang === Languages.en_us ? valueEnUs : lang === Languages.zh_cn ? valueZhCn : valueZhTw;
    return !isEmpty(value) ? value : !isEmpty(valueEnUs) ? valueEnUs : !isEmpty(valueZhCn) ? valueZhCn : valueZhTw;
}

export const debounce = (fn, wait = 300) => {
    let timeout;
    return function () {
        clearTimeout(timeout);
        timeout = setTimeout(() => {
            fn.apply(this, arguments);
        }, wait);
    }
}

export const isSameArr = (arrA, arrB) => {
    if (!Array.isArray(arrA) || !Array.isArray(arrB)) return false;
    return arrA.length === arrB.length && arrA.every(a => arrB.some(b => a === b)) && arrB.every(_b => arrA.some(_a => _a === _b));
}

export const includesSpecialCharacter = (query) => {
    return /[°"§%()[\]{}=\\?´`'#<>|,;.:+_-]+/g.test(query);
}

export const parseUrlHash = (url) => {
    const obj = {};
    if (url.indexOf('#') !== -1) {
        const str = url.split('#')[1];
        const strs = str.split('&');
        strs.map((item, i) => {
            const arr = strs[i].split('=');
            /* eslint-disable */
            obj[arr[0]] = decodeURIComponent(arr[1]);
            return item;
        });
    }
    return obj;
}

export const getUrlHashValue = (url, key) => {
    const obj = parseUrlHash(url);
    return obj[key];
}

export const isValidEmail = email => new RegExp(/^(\.|\+|\w|\-)+\@[A-Za-z0-9]+((\.|-)[A-Za-z0-9]+)*\.[A-Za-z0-9]+$/).test(email);

export const getErrorParams = (error, errorInfo) => {
    let userEmail = '';
    let userName = '';
    const userInfo = getCacheUserInfo();
    if (userInfo) {
        userEmail = userInfo.email;
        userName = userInfo.name;
    }

    let params = {
        appName: 'CTM Portal',
        appVersion: config.version,
        createDate: moment().format('YYYY-MM-DD HH:mm:ss.SSS'),
        deviceModle: window.navigator.platform,
        errorMessage: error instanceof Error ? error.toString() : JSON.stringify(error),
        deviceName: window.navigator.userAgent,
        deviceVersion: '',
        errorDetail: errorInfo.componentStack ? JSON.stringify(errorInfo.componentStack) : errorInfo,
        errorType: '',
        uuid: '',
        otherInfo: '',
        userEmail: userEmail,
        userName: userName
    }
    return params;
}

export const copyText = function (text) {
    if (navigator.clipboard) {//localhost, https
        navigator.clipboard.writeText(text);
    } else {
        var textarea = document.createElement('textarea');
        document.body.appendChild(textarea);
        textarea.style.position = 'fixed';
        textarea.style.clip = 'rect(0 0 0 0)';
        textarea.style.top = '10px';
        textarea.value = text;
        textarea.select();
        document.execCommand('copy', true);
        document.body.removeChild(textarea);
    }
}


export const isSupportFontFamily = function (fontFamily) {
    if (typeof fontFamily != 'string') {
        return false;
    }

    var defaultFontFamily = 'Arial';
    if (fontFamily.toLowerCase() == defaultFontFamily.toLowerCase()) {
        return true;
    }

    var defaultLetter = 'a';
    var defaultFontSize = 100;

    // 使用该字体绘制的canvas
    var width = 100, height = 100;
    var canvas = document.createElement('canvas');
    var context = canvas.getContext('2d');
    canvas.width = width;
    canvas.height = height;
    // 全局一致的绘制设定
    context.textAlign = 'center';
    context.fillStyle = 'black';
    context.textBaseline = 'middle';
    var getFontData = function (fontFamily) {
        // 清除
        context.clearRect(0, 0, width, height);
        // 设置字体
        context.font = defaultFontSize + 'px ' + fontFamily + ', ' + defaultFontFamily;
        context.fillText(defaultLetter, width / 2, height / 2);

        var data = context.getImageData(0, 0, width, height).data;

        return [].slice.call(data).filter(function (value) {
            return value != 0;
        });
    };

    return getFontData(defaultFontFamily).join('') !== getFontData(fontFamily).join('');
}

export const maskString = str => (str || '').length > 4 ? str.substr(-4).padStart(str.length, '*') : (str || '');

export const ordinalSuffix = (n) => (['st', 'nd', 'rd'][n < 20 ? n - 1 : n % 10 - 1] || 'th');

export const formatDuration = (duration) => `${parseInt(duration / 60)}h${duration % 60}m`;

export const getAppLogo = (app,themeType) => {
    if(!app.AppThemeLogos || !app.AppThemeLogos.length) return app.AppLogo
    let appThemeLogo = app.AppThemeLogos.find(theme=>theme.ThemeType === themeType)
    return appThemeLogo && appThemeLogo.AppLogo || app.AppLogo
}

export const toMoneyString = (num, currency) => {
    return getCurrencySymbol(currency) + toThousands(num);
}

export const toThousands = (num) => {
    return (num || 0).toString().replace(/(\d)(?=(?:\d{3})+($|\.))/g, '$1,');
}