import { default as enUSLocale } from './en-us';
import { default as zhCNLocale } from './zh-cn';
import { default as zhTWLocale } from './zh-tw';
import { getValue4Lang, getCurrentLang } from '~/common';

const appLocale = getValue4Lang(enUSLocale, zhCNLocale, zhTWLocale);

export default appLocale;

export const formatMessage = ({ id, values, lang = getCurrentLang() } = {}) => {
    const messages = getValue4Lang(enUSLocale.messages, zhCNLocale.messages, zhTWLocale.messages, lang)
    function getMsg(_id) {
        if (!_id) {
            throw new Error('[FormattedMessage] Missing prop "id"');
        }
        if (!Object.keys(messages).includes(_id)) {
            console.warn(`[FormattedMessage] Missing messages for "${_id}"`)
        }
        return messages[_id] || _id;
    }
    let msg = getMsg(id);
    if (typeof values === 'object') {
        Object.keys(values).forEach(key => {
            let value = values[key];
            if (typeof value === 'object' && Object.keys(value).includes('id')) {
                value = getMsg(value.id);
            }
            msg = msg.replace(new RegExp(`\\{${key}\\}`, 'g'), value);
        });
    }
    return msg;
}