
import React from 'react';
import { getImageUrl, getAuth0CurrentLang } from '~/common';
import { formatMessage } from '~/locales';

class SignOut extends React.Component {
    render() {
        return <div className="auth0-lock-container">
            <div className="auth0-lock auth0-lock-opened">
                <div className="auth0-lock-center">
                    <div className="auth0-lock-widget auth0-lock-widget-sign-out">
                        <div className="auth0-lock-widget-container">
                            <div className="auth0-lock-cred-pane auth0-lock-quiet auth0-lock-sign-out">
                                <div className="auth0-lock-cred-pane-internal-wrapper">
                                    <div className="auth0-lock-header">
                                        <div className="auth0-lock-header-bg auth0-lock-blur-support">
                                            <div className="auth0-lock-header-bg-blur"></div>
                                        </div>
                                        <div className="auth0-lock-header-welcome">
                                            <img alt="" className="auth0-lock-header-logo" src={getImageUrl("ctm-logo.png")} />
                                            <div className="auth0-lock-name"><span>CTM Portal</span></div>
                                        </div>
                                    </div>
                                    <div className="auth0-lock-content-wrapper">
                                        <div className="auth0-lock-body-content">
                                            <div className="auth0-lock-content">
                                                <div className="auth0-lock-form">
                                                    {formatMessage({ id: 'you_have_signed_out', lang: getAuth0CurrentLang() })}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    }
}
export default SignOut