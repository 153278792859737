import React, { Fragment } from 'react';
import mapboxgl from 'mapbox-gl';
import PropTypes from 'prop-types';
import { config, getCurrentLang, Languages, BookingType, isEmpty, getImageUrl, TravelStatus, getTravelStatus } from '~/common';
import { Modal } from 'antd';
import areafillLayer from './maplayer-fillarea';


let layerInstances = {};

class WorldMap extends React.Component {
    constructor() {
        super();
        this.state = {

        }
        this.initMapboxGL = this.initMapboxGL.bind(this);
        this.createMarkers = this.createMarkers.bind(this);
        this.getPopupHtml = this.getPopupHtml.bind(this);
        this.clearMarkers = this.clearMarkers.bind(this);
        this.updateMarkers = this.updateMarkers.bind(this);
        this.focusOnMarkers = this.focusOnMarkers.bind(this);
        this.focusOnTarget = this.focusOnTarget.bind(this);
        this.selectMarker = this.selectMarker.bind(this);
    }
    countriesJsonUrl = 'topo.countries.json';

    componentDidMount() {
        this.mounted = true;
        if (!this.props.useMapbox) {
            Promise.all([import('d3'), import('topojson')])
                .then(values => {
                    this.mounted && this.setState({ d3: values[0], topojson: values[1] });
                    this.getCountriesData(values[0], values[1]);
                });
        }
        else {
            if (mapboxgl.supported()) {
                this.initMapboxGL();
            } else {
                Modal.warning({
                    centered: true,
                    title: this.formatMessage('system_information'),
                    content: this.formatMessage('browser_not_support_webgl'),
                });
            }
        }
    }

    componentWillUnmount() {
        this.mounted = false;
    }

    componentDidUpdate(prevProps, prevState) {
        const { geojson, selectedStatusCode } = this.props;
        if (this.props.useMapbox) {
            if (this.isMapLoaded && geojson && selectedStatusCode && (selectedStatusCode !== prevProps.selectedStatusCode || JSON.stringify(geojson) !== JSON.stringify(prevProps.geojson))) {
                this.clearMarkers();
                this.createMarkers();
            }
            this.initLayer();
        }
    }

    initMapboxGL() {
        //https://docs.mapbox.com/mapbox-gl-js/examples/
        //https://docs.mapbox.com/mapbox-gl-js/api/
        const { canZoom, zoom, centerPosition, maxZoom, style, accessToken, markersPadding } = this.props;
        mapboxgl.accessToken = accessToken;
        this.isMapLoaded = false;
        this.map = new mapboxgl.Map({
            style: style,
            center: centerPosition,
            zoom: zoom,
            maxZoom: maxZoom,
            interactive: canZoom,
            container: 'world_map',
            attributionControl: false,
        });

        // window.mapbox = this.map;//this is for develop debug
        //support multi language, zh-tw not support
        //https://github.com/mapbox/mapbox-gl-language/
        // this.map.addControl(new MapboxLanguage({
        //     defaultLanguage: getCurrentLang() === Languages.en_us ? 'en' : 'zh'
        // }));

        //add navigation control
        //this.map.addControl(new mapboxgl.NavigationControl());
        //this.map.addControl(new mapboxgl.FullscreenControl({ container: 'world_map' }));
        this.map.on('load', () => {
            if (!this.mounted) return;
            this.isMapLoaded = true;
            this.markers = {};
            this.markersOnScreen = {};
            layerInstances = {};
            if (!this.checkIfFullSize()) {
                this.map.resize();
                this.interval = setInterval(this.handleMapLoad, 20);
            }
            this.changelan();
            this.initLayer();
            if (this.props.geojson) {
                this.createMarkers();
                this.updateMarkers();
            }

            this.map.on('data', e => {
                // if (e.sourceId !== 'itineraries' || !e.isSourceLoaded) return;
                this.map.on('move', () => this.updateMarkers());
                this.map.on('moveend', () => this.updateMarkers());
                this.updateMarkers();
                this.focusOnMarkers(markersPadding);
            });

            this.map.on('mouseenter', 'country-label', () => {
                if (this.props.geoRiskOn) {
                    this.map.getCanvas().style.cursor = 'pointer';
                }
            });
            this.map.on('mouseleave', 'country-label', () => {
                this.map.getCanvas().style.cursor = '';
            });

            this.map.on('click', 'country-label', e => {
                if (!this.props.geoRiskOn) return;
                const features = this.map.getLayer('cluster_circle') && this.map.queryRenderedFeatures(e.point, { layers: ['cluster_circle'] });
                if (features && features.length) return;
                let properties = e.features[0].properties;
                this.props.getRiskAlerts(properties.iso_3166_1,
                    {
                        name_zh_cn: properties["name_zh-Hans"],
                        name_zh_tw: properties["name_zh-Hant"],
                        name_en: properties["name_en"]
                    })
            })
        });
    }

    changelan() {
        const LayerLanConfig = [
            'country-label',
            'state-label',
            'water-point-label',
            'waterway-label',
            'airport-label',
            'road-label',
            'water-line-label',
            'natural-point-label',
            'natural-line-label',
        ];
        const langType = ['name_zh-Hans', 'name_en'];
        LayerLanConfig.forEach((config) => {
            const origin = this.map.getLayoutProperty(config, 'text-field');
            const originStr = JSON.stringify(origin);
            let lan = getCurrentLang();
            let reg;
            let replaceBy;
            if (lan === Languages.en_us) {
                reg = new RegExp(langType[0], 'g');
                replaceBy = langType[1];
            }
            else {
                reg = new RegExp(langType[1], 'g');
                replaceBy = langType[0];
            }
            const newStr = originStr.replace(reg, replaceBy);
            this.map.setLayoutProperty(config, 'text-field', JSON.parse(newStr))
        });
    }

    handleMapLoad = () => {
        if (this.checkIfFullSize()) {
            clearInterval(this.interval);
            return;
        }
        this.map.resize();
    }

    checkIfFullSize = () => {
        const mapCanvas = document.getElementsByClassName('mapboxgl-canvas')[0];
        if (!mapCanvas) return true;
        return mapCanvas.offsetWidth + 60 === document.getElementById('ctm-smart-app').offsetWidth;
    }

    getCountriesData = (d3Data, topojsonData) => {
        d3Data.json(this.countriesJsonUrl)
            .then(topo => {
                const countries = topojsonData.feature(topo, topo.objects.countries).features;
                this.mounted && this.setState({ countries });
            });
    }

    getProjection = () => {
        return this.state.d3.geoEquirectangular()
            .scale(this.props.height / Math.PI)
            .translate([(this.props.width / 2), (this.props.height / 2)])
            .rotate([0, 0, 0]);
    }

    renderD3Map = () => {
        const { countries, d3 } = this.state;
        if (!countries || !d3)
            return null;
        return <Fragment><svg className="world-map component" id='world_map'
            viewBox={`0 0 ${this.props.width} ${this.props.height}`}>
            {
                countries.map((item, index) => {
                    return (
                        <path
                            key={`path-${index}`}
                            className="country"
                            d={d3.geoPath().projection(this.getProjection())(item)} />
                    );
                })
            }
        </svg>
            <img alt='' src={getImageUrl('email-icon.png')} style={{ display: 'none' }} />
            <img alt='' src={getImageUrl('link.png')} style={{ display: 'none' }} />
        </Fragment>;
    }

    createMarkers() {
        const { geojson } = this.props;
        const statusField = getTravelStatus(this.props.selectedStatusCode).statusField;
        if (this.map.getSource('itineraries')) {
            return;
        }
        this.map.addSource('itineraries', {
            type: 'geojson',
            data: geojson,
            cluster: false,
            clusterRadius: 80,
            clusterProperties: {
                En_Route: ["+", ["get", "En_Route"]],
                Landed: ["+", ["get", "Landed"]],
                Warning: ["+", ["get", "Warning"]],
                Total: ["+", ["get", "Total"]],
                On_Time: ["+", ["get", "On_Time"]],
            }
        });
        this.map.addLayer({
            "id": "cluster_circle",
            "type": "circle",
            "source": "itineraries",
            "paint": {
                "circle-color": 'white',
                "circle-opacity": 0.1,
                'circle-radius': [
                    'step',
                    ['get', statusField],
                    18,
                    2,
                    26,
                    10,
                    26
                ]
            }
        });
        // this.map.addLayer({
        //     "id": "cluster_label",
        //     "type": "symbol",
        //     "source": "itineraries",
        //     "filter": ["==", "cluster", true],
        //     "layout": {
        //         "text-field": ["get", statusField]
        //     },
        //     "paint": {
        //         "text-color": 'white'
        //     }
        // });
    }

    toggleLayer = (on, name, className, data) => {
        const instance = layerInstances[name];
        if (!on && instance) {
            instance.destory();
            layerInstances[name] = null;
        }
        if (on && !instance && this.map && (data || []).length > 0) {
            let useData = data;
            layerInstances[name] = new className(this.map, useData, this.props);
        }
    }

    initLayer = () => {
        if (this.isMapLoaded) {
            this.toggleLayer(this.props.geoRiskOn, 'mapAreafillInstance', areafillLayer, this.props.CountryRiskSummaries);
        }
    }

    updateLayer = (name) => {
        if (this.isMapLoaded) {
            const instance = layerInstances[name];
            if (instance) {
                instance.updateLayer(this.props.CountryRiskSummaries, this.props);
            }
        }
    }

    updateMarkers() {
        const { selectedStatusCode, handleMarkerPopupClose } = this.props;
        let newMarkers = {};

        // let features = this.map.querySourceFeatures('itineraries');
        let features = (this.map.getSource('itineraries') && this.map.getSource('itineraries')._data && this.map.getSource('itineraries')._data.features) || [];
        // for every un-cluster on the screen, create an HTML marker for it (if we didn't yet),
        // and add it to the map if it's not there already
        for (let i = 0; i < features.length; i++) {
            var coords = features[i].geometry.coordinates;
            var props = features[i].properties;
            if (props.cluster) continue;
            var id = props.Code;
            let marker = this.markers[id];
            if (!marker) {
                const count = selectedStatusCode === 'all' ? props.Total : selectedStatusCode === 'on-time' ? props.On_Time : selectedStatusCode === 'en-route' ? props.En_Route : selectedStatusCode === 'landed' ? props.Landed : props.Warning;
                var el = document.createElement('div');
                el.id = `marker_${id}`;
                el.className = `marker ${selectedStatusCode}${count === 1 ? ' sm' : count > 10 ? ' m' : ''}`;
                el.innerHTML = `<div class='inner'><i class='${props.Type === BookingType.Airfare ? 'airport' : 'hotel'}'></i><span>${count === 1 ? '' : count}</span></div>`;
                marker = this.markers[id] = new mapboxgl.Marker({ element: el }).setLngLat(coords);

                // eslint-disable-next-line
                const feature = this.props.geojson.features.filter(f => f.properties.Code === id)[0];
                const popupHtml = this.getPopupHtml(feature);
                if (!!popupHtml) {
                    const popup = new mapboxgl.Popup({ offset: 25 })
                        .setLngLat(feature.geometry.coordinates)
                        .setHTML(popupHtml).on('close', (e) => {
                            handleMarkerPopupClose(e.target);
                        });
                    marker.setPopup(popup);
                };
            };
            newMarkers[id] = marker;
            if (!this.markersOnScreen[id]) {
                marker.addTo(this.map);
            }
        }
        // for every marker we've added previously, remove those that are no longer visible
        for (id in this.markersOnScreen) {
            if (!newMarkers[id])
                this.markersOnScreen[id].remove();
        }
        this.markersOnScreen = newMarkers;
    }

    clearMarkers() {
        for (let id in this.markers) {
            this.markers[id].remove();
        }
        this.markers = {};
        for (let id in this.markersOnScreen) {
            this.markersOnScreen[id].remove();
        }
        this.markersOnScreen = {};
        if (this.map.getLayer('cluster_circle')) {
            this.map.removeLayer('cluster_circle');
        }
        if (this.map.getLayer('cluster_label')) {
            this.map.removeLayer('cluster_label');
        }
        if (this.map.getSource('itineraries')) {
            this.map.removeSource('itineraries');
        }
        this.isFocused = false;
    }

    selectMarker(markerId) {
        if (!this.markers) return;
        for (let key in this.markers) {
            this.markers[key].getElement().classList.remove('selected')
        }
        if (!markerId || !this.markers[markerId]) return;
        const marker = this.markers[markerId];

        marker.getElement().classList.toggle('selected');

        for (let key in this.markersOnScreen) {
            const _m = this.markersOnScreen[key];
            const _p = this.markersOnScreen[key].getPopup();
            key !== markerId && _p.isOpen() && _m.togglePopup();
        }

        let boundingBox = new mapboxgl.LngLatBounds();
        boundingBox.extend(marker.getLngLat());
        this.focusOnTarget(boundingBox, {
            top: 300,
            bottom: 300,
            left: 300,
            right: 300
        });
    }

    focusOnMarkers(padding) {
        if (!this.markers || this.isFocused) return;
        let boundingBox = new mapboxgl.LngLatBounds();
        let isEmpty = true;
        for (let key in this.markers) {
            isEmpty = false;
            boundingBox.extend(this.markers[key].getLngLat());
        }
        if (isEmpty) return;
        this.focusOnTarget(boundingBox, padding);
        this.isFocused = true;
    }

    focusOnTarget(boundingBox, padding = {}) {
        this.map.fitBounds(boundingBox, {
            maxZoom: 6,
            padding: {
                top: 100,
                bottom: 135,
                left: 100,
                right: 100,
                ...padding
            }
        });
    }

    getPopupHtml(feature) {
        let detail = feature.properties;
        let groups = feature.properties.TravelGroups || [];
        const selectedStatus = this.props.statuses.find(s => s.code === this.props.selectedStatusCode);
        let content = groups.map(group => {
            let list = group.Items.map(item => selectedStatus.code !== TravelStatus.All && selectedStatus.type !== item.TravelStateType && !(selectedStatus.code === TravelStatus.Warning && item.IsWarning) ? '' : `<li><span>${item.Traveler}</span><div class="action"><i data-click="itinerary" data-traveler="${item.Traveler}" data-email="${item.Email}" data-travelerId="${item.TravelerId}" data-locationkey ="${detail.LocationKey}" data-orderid="${item.OrderId}" class="booking-icon">&nbsp;</i>
            ${item.Email ? `<a href="mailto:${item.Email}"><i class="email-icon">&nbsp;</i></a>` : ''}</div></li>`);
            return list.join('') === '' ? '' : `<div class="itinerary-segment">${isEmpty(group.Title) ? '' : `<h5>${group.Title}</h5>`}<ul>${list.join('')}</ul></div>`;
        });
        return content.join('') === '' ? '' : `<div class="popup-marker"><h4>${detail.Title}</h4><div>${content.join('')}</div></div>`;
    }

    formatMessage = id => this.props.intl.formatMessage({ id });

    resize = () => setTimeout(() => { this.map.resize(); }, 300);

    render() {
        if (!this.props.useMapbox) {
            return this.renderD3Map();
        }
        else return (
            <div className='world-map lg' id='world_map'></div>
        )
    }
}

WorldMap.defaultProps = {
    zoom: 1.5,
    canZoom: false,
    maxZoom: 13, // street level
    useMapbox: true,
    width: 900, // width and height is only set for viewbox to maintain aspect ratio
    height: 550,
    style: config.mapboxDarkStyle,
    accessToken: config.mapboxToken,
    centerPosition: [0, 0], // default position in long/lat format
    CountryRiskSummaries: [],
    geoRiskLevels: { "0": true, "1": true, "2": true, "3": true, "4": true, "5": true },
    geoRiskOn: true
}

WorldMap.propTypes = {
    canZoom: PropTypes.bool,
    zoom: PropTypes.number,
    maxZoom: PropTypes.number,
    useMapbox: PropTypes.bool,
    centerPosition: PropTypes.array,
    width: PropTypes.number,
    style: PropTypes.string,
    height: PropTypes.number,
    markersPadding: PropTypes.object,
    handleMarkerPopupClose: PropTypes.func,
    CountryRiskSummaries: PropTypes.array,
    geoRiskLevels: PropTypes.object
};

export default WorldMap;