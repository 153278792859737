import React, { Component } from 'react';
import { Loading } from '~/components';
import { config, isEmpty } from '~/common';

class Login extends Component {
    componentDidMount() {
        ////logout 4 localhost only 20200527 TLL
        // var logoutUrl = config.logoutUrl;
        // if(isEmpty(logoutUrl)) {
        this.props.auth.login(this.props.location.state);
        // } else {
        //   this.props.auth.logout({ "returnTo": logoutUrl });
        // }
    }

    render() {
        return <Loading style={{ backgroundColor: 'rgba(0,0,0,.85)' }} />;
    }
}

export default Login;
