class mapLayer {
    map;
    layers = [];
    constructor(map, data, { geoRiskLevels }) {
        this.map = map;
        this.data = data;
        this.geoRiskLevels = geoRiskLevels;
        const riskLayers = ['#9f9fa0', '#DEDDDB', '#DACCA1', '#FBB929', '#FF8400', '#FF0035'];
        for (let i = 0; i < riskLayers.length; i++) {
            let layer = {
                id: `riskLevel${i}`,
                'source': 'composite',// source: { type: 'vector', url: 'mapbox://arielll.11xmcz8c' }
                'source-layer': 'ne_10m_admin_0_countries-621k8v',
                type: 'fill',
                paint: { 'fill-color': riskLayers[i], }
            };
            this.layers.push(layer)
            this.map.addLayer(layer, 'countries-line');
            this.map.setFilter(`riskLevel${i}`, ['in', 'ISO_A2'].concat([]));
        }
        if (data) this.renderGeoRisk();
    };

    renderGeoRisk() {
        this.layers.forEach((layer, i) => {
            let riskCountries = [[], []];
            if (this.geoRiskLevels[i]) {
                this.data.filter(ele => ele.RiskLevel === i).map(c => {
                    riskCountries[0].push(c.CountryCode)
                    riskCountries[1].push(c.Country.toLowerCase().replace(/( |^)[a-z]/g, (L) => L.toUpperCase()));
                    return true;
                });
            }
            this.map.setFilter(layer.id, ['any', ['in', 'GEOUNIT'].concat(riskCountries[1]), ['in', 'ISO_A2'].concat(riskCountries[0])]);
        })
    };

    updateLayer(data, { geoRiskLevels }) {
        this.geoRiskLevels = geoRiskLevels;
        if (data) this.renderGeoRisk();
    };

    destory() {
        const map = this.map;
        this.layers.forEach((layer) => {
            map.removeLayer(layer.id);
        });
    };

}
export default mapLayer