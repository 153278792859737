import React from 'react';
import { Spin, Icon } from 'antd';
import { config, setLoginRedirect } from '~/common';
import { injectIntl } from 'react-intl';
import { CTMModal } from '~/components';
import { withRouter } from 'react-router-dom';

class Loading extends React.Component {

    componentDidMount() {
        if (this.props.showNotAuthorizedPrompt) {
            CTMModal.warning({
                centered: true,
                title: this.props.intl.formatMessage({ id: 'system_information' }),
                content: this.props.intl.formatMessage({ id: 'not_authorized_login_tip' }),
                onOk: () => {
                    setLoginRedirect(window.location.href);
                    this.props.history.replace('/login');
                },
                okText: this.props.intl.formatMessage({ id: 'login_now' }),
            });
        }
    }

    render() {
        const { tip, error, style, intl, spinStyle } = this.props;
        const antIcon = <Icon type="loading" style={{ fontSize: 24 }} spin />;
        if (error) {
            console.log(error);
            return <div style={style}>{intl.formatMessage({ id: 'error_occurred' })}{config.isDebug ? <div style={{ color: 'red' }}>[DEBUG MESSAGES:] {error instanceof Error ? error.toString() : JSON.stringify(error)}</div> : ''}</div>;
        }
        return (
            <div style={{ position: 'absolute', top: 0, left: 0, right: 0, bottom: 0, backgroundColor: 'rgba(0,0,0,.2)', textAlign: 'center', zIndex: '999', ...style }}>
                <Spin tip={tip || intl.formatMessage({ id: 'loading' })} indicator={antIcon} style={{ position: 'absolute', top: '50%', transform: 'translateY(-50%)', left: 'calc(50% - 30px)', color: 'hsla(0,0%,100%,.9)', fontSize: '1rem', ...spinStyle }} />
            </div>
        );
    }
}

export default withRouter(injectIntl(Loading));