import React from 'react';
import { getImageUrl } from '~/common'
import * as PropTypes from 'prop-types';

class DefaultImage extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            src: this.props.src || ''
        }
    }

    handleImageLoaded = () => {
    }

    handleImageErrored = () => {
        const { externalSource, defaultImg } = this.props;
        this.setState({
            src: externalSource ? defaultImg : getImageUrl(defaultImg)
        });
    }
    componentWillReceiveProps(nextProps) {
        if(nextProps.src !== this.props.src) {
            this.setState({
                src: nextProps.src
            })
        }
    }
    render() {
        let { className } = this.props;
        let { src } = this.state;
        return <img
            className={className}
            src={src}
            alt=""
            onLoad={this.handleImageLoaded}
            onError={this.handleImageErrored}
        />
    }
}

DefaultImage.propTypes = {
    defaultImg: PropTypes.string,
}

export default DefaultImage;