import React, { Component } from 'react';
import { config,getErrorParams } from '~/common';
import { FormattedMessage } from 'react-intl';
import { exceptionLog } from '~/client';

export default class ErrorBoundary extends Component {
    constructor(props) {
        super(props);
        this.state = { error: null, errorInfo: null };
    }

    componentDidCatch(error, errorInfo) {
        // Catch errors in any components below and re-render with error message
        this.setState({
            error: error,
            errorInfo: errorInfo
        })
        // You can also log error messages to an error reporting service here

        const params= getErrorParams(error,errorInfo);
        exceptionLog(params);
    }

    render() {
        if (this.state.errorInfo) {
            return (
                <div style={{ padding: 100, margin: '0 auto', textAlign: 'center' }}>
                    <div style={{ fontSize: '1.5rem', lineHeight: '3rem', marginBottom: 10 }}><FormattedMessage id='oops_something_went_wrong' /></div>
                    <div style={{ marginBottom: 10 }}>
                        <a href={config.localhostUrl} style={{ color: '#636363' }}><FormattedMessage id='back_to_home_page' /></a>
                    </div>
                    <details style={{ whiteSpace: 'pre-wrap', display: config.isDebug ? 'block' : 'none' }} open>
                        <summary>error details(show in debug mode)</summary>
                        {this.state.error && this.state.error.toString()}
                        <br />
                        {this.state.errorInfo.componentStack}
                    </details>
                </div>
            );
        }
        return this.props.children;
    }
}
