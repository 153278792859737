import { tripProposalRequest, extOrderRequest } from '~/client';

export function searchTripProposal(params, callback) {
    return tripProposalRequest(params, callback, 'Search');
}

export function retrieveTripProposal(params, callback) {
    return tripProposalRequest(params, callback, 'Retrieve');
}

export function submitProposalForBooking(params, callback) {
    return tripProposalRequest(params, callback, 'SubmitForBooking');
}

export function confirmPlan(params, callback) {
    return tripProposalRequest(params, callback, 'ConfirmPlan');
}

export function confirmOption(params, callback) {
    return tripProposalRequest(params, callback, 'ConfirmOption');
}

export function getMetadata(params, callback) {
    return tripProposalRequest(params, callback, 'GetMetadata');
}

export function searchFlightFacility(params, callback) {
    return extOrderRequest(params, callback, 'SearchFlightFacility');
}

export function searchFlightEmission(params, callback) {
    return extOrderRequest(params, callback, 'SearchFlightEmission');
}

export function saveAttachment4OTP(params, callback) {
    return extOrderRequest(params, callback, 'SaveAttachment');
}

export function getAttachmentData4OTP(params, callback) {
    return extOrderRequest(params, callback, 'GetAttachmentData');
}

export function selectApprover4OTP(params, callback) {
    return extOrderRequest(params, callback, 'SelectApprover');
}

export function changeApprover4OTP(params, callback) {
    return extOrderRequest(params, callback, 'ChangeApprover');
}

export function resendApproveEmail4OTP(params, callback) {
    return extOrderRequest(params, callback, 'ResendApproveEmail');
}

export function finalizeBooking4OTP(params, callback) {
    return extOrderRequest(params, callback, 'FinalizeBooking');
}

export function searchArchive(params, callback) {
    return tripProposalRequest(params, callback, 'SearchArchive');
}