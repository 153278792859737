import React, { Component, Fragment } from 'react';
import { Menu, Dropdown, Layout } from 'antd';
import { Link } from 'react-router-dom';
import { updateUserSettingSaga } from '~/redux/userSetting'
import { FormattedMessage } from 'react-intl';
import { config, Languages, getImageUrl, ThemeType, getValue4Lang } from '~/common';
import { connect } from 'react-redux';
import { QRCode } from '~/components';
import { stopHubConnection, startHubConnection } from '~/redux/sso';

class Header extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    componentDidMount() {
        this.mounted = true;
    }

    componentWillUnmount() {
        this.mounted = false;
    }


    handleLangChange = lang => {
        this.props.changeLocale(lang);
    }

    handleThemeChange = themeType => {
        this.props.toggleThemeMode(themeType);
        document.body.className = themeType === ThemeType.Breezy ? (config.enableLightDarkMode ? 'light-mode' : 'breezy-mode') : (config.enableLightDarkMode ? 'dark-mode' : '');
    }
    showQRCodeModal = () => {
        this.props.startHubConnection();
        this.setState({
            QRCodeVisible: true,
        });
    };
    handleCancel = () => {
        this.props.stopHubConnection();
        this.setState({
            QRCodeVisible: false,
        });
    };

    render() {
        const { locale, userName, enableLoginByQRCode4Mobile, auth, shortName, themeType } = this.props;
        const userMenu = (
            <Menu className='clearfix'>
                <Menu.Item className='theme'>
                    <div className='title'><FormattedMessage id="select_screen_theme" /></div>
                    <div>
                        {[ThemeType.Breezy,ThemeType.Galaxy].map(enableTheme => {
                            return <span key={enableTheme} className={`radio-item${themeType == enableTheme ? ' active' : ''}`} onClick={() => this.handleThemeChange(enableTheme)}><i></i><FormattedMessage id={{ 
                                [ThemeType.Galaxy]: config.enableLightDarkMode ? 'dark' : 'galaxy', 
                                [ThemeType.Breezy]: config.enableLightDarkMode ? 'light' : 'breezy'
                        }[enableTheme]} /></span>;
                        })}
                    </div>
                </Menu.Item>
                <Menu.Divider />
                {config.enableMultiLanguage && <Menu.Item className='language'>
                    <div className='title'><FormattedMessage id="select_language" /></div>
                    <div>
                        <span className={`radio-item${locale === Languages.en_us ? ' active' : ''}`} onClick={() => this.handleLangChange(Languages.en_us)}><i></i>English</span>
                        <span className={`radio-item${locale === Languages.zh_cn ? ' active' : ''}`} onClick={() => this.handleLangChange(Languages.zh_cn)}><i></i>中文简体</span>
                        <span className={`radio-item${locale === Languages.zh_tw ? ' active' : ''}`} onClick={() => this.handleLangChange(Languages.zh_tw)}><i></i>中文繁體</span>
                    </div>
                </Menu.Item>
                }
                {config.enableMultiLanguage && <Menu.Divider />}
                <Menu.Item style={enableLoginByQRCode4Mobile && { "float": "right" }}>
                    <Link to='/logout'><FormattedMessage id='logout' /></Link>
                </Menu.Item>
                {enableLoginByQRCode4Mobile && <Menu.Item style={{ "cursor": "pointer" }} onClick={this.showQRCodeModal}>
                    <FormattedMessage id="login_for_mobile" />
                </Menu.Item>}
            </Menu>
        );

        return (
            <Layout.Header className='header ctm-header'>
                <div className="ctm-header-logo"><Link to='/'><img src={getImageUrl('ctm_portal_logo.svg')} alt="" /></Link></div>

                {auth.isAuthenticated() && <div className='profile'>
                    {shortName && <div className='avatar'>{shortName}</div>}
                    <Dropdown overlay={userMenu} trigger={['click']} placement='bottomRight' overlayClassName='profile-popup'>
                        <a className="ant-dropdown-link">
                            <span className='greeting-desc'><FormattedMessage id='hello' />, {userName}</span>
                            <span className='triangle'></span>
                        </a>
                    </Dropdown>
                    {this.state.QRCodeVisible && <QRCode onCancel={this.handleCancel} />}
                </div>}
            </Layout.Header>
        )
    }
}

export default connect(state => ({
    locale: state.userSetting.Language,
    userName: getValue4Lang(state.user.name, state.user.nameZhCn, state.user.nameZhTw),
    themeType: state.userSetting.ThemeType,
    enableLoginByQRCode4Mobile: state.user.enableLoginByQRCode4Mobile,
    shortName: state.user.shortName,
}), dispatch => ({
    changeLocale: lang => dispatch(updateUserSettingSaga({ Language: lang })),
    toggleThemeMode: themeType => dispatch(updateUserSettingSaga({ ThemeType: themeType })),
    startHubConnection: () => dispatch(startHubConnection()),
    stopHubConnection: () => dispatch(stopHubConnection())
}))(Header);
