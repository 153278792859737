import React, { Component } from 'react';
import { Layout, Select } from 'antd';
import { getImageUrl, config } from '~/common';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import * as PropTypes from 'prop-types';

export default class Footer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            companyOpened: false,
            productsOpened: false,
            legalOpened: false,
            region: 'Asia',
            regionSource: [
                ['America', '2120 S. 72nd Street, Omaha, NE 68124'],
                ['Asia', 'Unit 01, 18/F, CDW Building, 388 Castle Peak Road, Tsuen Wan, Hong Kong'],
                ['AUS/NZ', 'Level 24, 307 Queen Street, Brisbane, QLD 4000, Australia'],
                ['EMEA', 'Senator House, 85 Queen Victoria Street, London'],
            ],
        };
    }

    render() {
        return (
            <Layout.Footer className='ctm-footer'>
                <div className='logo-col'>
                    <img src={getImageUrl('ctm-logo.png')} alt="CTM Portal" />
                    <div className='social-links'>
                        <a href='https://www.linkedin.com/company/corporate-travel-management-asia/mycompany/' target='_blank' rel='noopener noreferrer' className='sl-linkedin'></a>
                        <a href='https://www.facebook.com/CTMAsia/' target='_blank' rel='noopener noreferrer' className='sl-facebook'></a>
                        <a href='https://www.youtube.com/channel/UCRx9TVc2GYO8kuYCcpi4gow' target='_blank' rel='noopener noreferrer' className='sl-youtube'></a>
                    </div>
                </div>
                <ul className={`company-col${this.state.companyOpened ? ' opened' : ''}`}>
                    <li className='heading' onClick={() => this.setState({ companyOpened: !this.state.companyOpened })}>Company</li>
                    <li><a href='https://www.travelctm.com/global/?utm_campaign=Tech-Products-GLOBAL&utm_medium=WEB&utm_source=CTMT&utm_content=link-CTM-website-hp' target='_blank' rel='noopener noreferrer'>About us</a></li>
                    <li><a href='https://www.travelctm.com/global/privacy/?utm_campaign=Tech-Products-GLOBAL&utm_medium=WEB&utm_source=CTMT&utm_content=link-privacy-policy' target='_blank' rel='noopener noreferrer'>News</a></li>
                    <li><a href='https://www.travelctm.com/global/terms-and-conditions/?utm_campaign=Tech-Products-GLOBAL&utm_medium=WEB&utm_source=CTMT&utm_content=link-terms-conditions' target='_blank' rel='noopener noreferrer'>Investors</a></li>
                    <li><a href='https://www.travelctm.com/global/corporate-travel/sustainable-travel-climate/?utm_campaign=Tech-Products-GLOBAL&utm_medium=WEB&utm_source=CTMT&utm_content=link-sustainable-travel' target='_blank' rel='noopener noreferrer'>Green initiatives</a></li>
                    <li><a href='https://workforcenow.adp.com/mascsr/default/mdf/recruitment/recruitment.html?cid=7fd48a1d-db88-4603-b435-145b19ea144a&ccId=19000101_000001&lang=en_US' target='_blank' rel='noopener noreferrer'>Careers</a></li>
                </ul>
                <ul className={`products-col${this.state.productsOpened ? ' opened' : ''}`}>
                    <li className='heading' onClick={() => this.setState({ productsOpened: !this.state.productsOpened })}>Products</li>
                    <li><a href='https://portal.travelctm.asia' target='_blank' rel='noopener noreferrer'>Portal</a></li>
                    <li><a href='https://www.lightningbook.asia' target='_blank' rel='noopener noreferrer'>Lightning</a></li>
                </ul>
                <ul className={`legal-col${this.state.legalOpened ? ' opened' : ''}`}>
                    <li className='heading' onClick={() => this.setState({ legalOpened: !this.state.legalOpened })}>Legal</li>
                    <li><a href='https://asia.travelctm.com/privacy/' target='_blank' rel='noopener noreferrer'>Privacy Policy</a></li>
                </ul>
                <div className='region-col'>
                    <div>Region</div>
                    <div>
                        <Select value={this.state.region} onChange={val => this.setState({ region: val })} style={{ width: '100%' }}>
                            {this.state.regionSource.map(([code, address]) => {
                                return <Select.Option key={code} value={code}>{code}</Select.Option>;
                            })}
                        </Select>
                    </div>
                    <div>{`CTM ${this.state.region}`}</div>
                    <div>{this.state.regionSource.find(s => s[0] == this.state.region)[1]}</div>
                </div>
                <div className='copyright-col'>
                    Copyright 2023. All rights reserved by Corporate Travel Management
                </div>

            </Layout.Footer>
        )
    }
}

Footer.propTypes = {
    showContactUs: PropTypes.bool,
}
