import React from 'react';
import * as PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import moment from 'moment';
import { getTravelStatus, isEmpty, media, Languages, getCurrentLang } from '~/common';
import { Tooltip, Popover, Spin, Icon } from 'antd';

const API_TIME_FORMAT = 'YYYYMMDDHHmmss';
const UI_DATE_FORMAT = getCurrentLang() === Languages.en_us ? 'DD MMM YYYY' : 'YYYY年MM月DD日';
const UI_TIME_FORMAT = 'HH:mm';

class FlightSummary extends React.Component {


    renderPopup(depAirportName, arrAirportName, passengers) {
        return (
            <div className='todays-flights-popup' onClick={(e) => e.stopPropagation()}>
                {depAirportName && arrAirportName && <h4>{depAirportName} To {arrAirportName}</h4>}
                <ul>
                    {passengers.map(p => <li key={p.Id}>{p.Name} {p.Email ? <a href={`mailto:${p.Email}`}><i className='email-icon'>&nbsp;</i></a> : null}</li>)}
                </ul>
            </div>
        );
    }

    onlineCheckIn(airlineWebCheckInUrl, airlineMobileCheckinUrl) {
        window.open(media.isMobileDevice() ? airlineMobileCheckinUrl : airlineWebCheckInUrl)
    }

    render() {
        const { summary, isLoading, isActive, showActiveIndicator } = this.props;
        const loadingIcon = <Icon type='loading' />;
        return <div className={`flight-summary-info${isActive ? ' active' : ''}${isActive && showActiveIndicator ? ' with-indicator' : ''}`}>
            <div className='flight-segment-list'>
                {summary.Segments.map((segment, segIndex) => {
                    const travelStatus = getTravelStatus(segment.TravelStateType);
                    return (
                        <div
                            className={`flight-segment-item`} key={segIndex}
                            onClick={() => {
                                if (document.getElementById('popover')) return;
                                return this.props.handleTripClick(summary);
                            }}>
                            <div className="item-row-top">
                                <div className="title-name">
                                    <Tooltip title={summary.Travelers} className='title-name-inner'>{summary.Travelers}</Tooltip>
                                </div>
                                <div className="title-email">
                                    {summary.TravelerDetails && summary.TravelerDetails.filter(item => !isEmpty(item.Email)) && <div className="email" onClick={(e) => e.stopPropagation()}>
                                        <Popover placement="left" id='popover' destroyTooltipOnHide={true} content={this.renderPopup(segment.DepAirportName, segment.ArrAirportName, summary.TravelerDetails)} trigger="hover">
                                            <i className="email-icon">&nbsp;</i>{summary.TravelerDetails.length}
                                        </Popover>
                                    </div>}
                                </div>
                            </div>
                            <div className='item-row-content'>
                                <div className='dep-info'>
                                    <div className='airport-font'>
                                        <span className='addr-icon'></span>
                                        <span>{`${segment.DepAirportName || segment.DepAirport} (${segment.DepAirport})`}</span>
                                    </div>
                                    <div className='time-font'>
                                        {moment(segment.DepTimeRemark, API_TIME_FORMAT).format(UI_TIME_FORMAT)}
                                    </div>
                                    <div className='date-font'>
                                        {moment(segment.DepTimeRemark, API_TIME_FORMAT).format(UI_DATE_FORMAT).toUpperCase()}
                                    </div>
                                </div>
                                <div className='flight-icon'></div>
                                <div className='arr-info'>
                                    <div className='airport-font'>
                                        <span className='addr-icon'></span>
                                        <span>{`${segment.ArrAirportName || segment.ArrAirport} (${segment.ArrAirport})`}</span>
                                    </div>
                                    <div className='time-font'>
                                        {moment(segment.ArrTimeRemark, API_TIME_FORMAT).format(UI_TIME_FORMAT)}
                                    </div>
                                    <div className='date-font'>
                                        {moment(segment.ArrTimeRemark, API_TIME_FORMAT).format(UI_DATE_FORMAT).toUpperCase()}
                                    </div>
                                </div>

                            </div>
                            <div className="item-row-bottom">
                                <div>
                                    <FormattedMessage id='status' />
                                    <span className='travel-status'><FormattedMessage id={travelStatus.statusCode.replace('-', '_')} /></span>
                                </div>
                                {summary.AllowCheckIn && <div onClick={() => this.onlineCheckIn(summary.AirlineWebCheckInUrl, summary.AirlineMobileCheckinUrl)}><span className="online-check"><FormattedMessage id="online_check_in" /></span></div>}
                                <div className='detail-link' onClick={(e) => this.props.handleDetailClick(e, summary.OrderId)}>
                                    <FormattedMessage id='click_for_detail' />
                                    {!isLoading && <span className='indicator'></span>}
                                    {isLoading && <Spin size='small' className='loading-icon' indicator={loadingIcon} />}
                                </div>
                            </div>
                        </div>
                    )
                })}
            </div>
        </div>;
    }
}

FlightSummary.propTypes = {
    summary: PropTypes.object.isRequired,
    handleTripClick: PropTypes.func.isRequired,
    handleDetailClick: PropTypes.func.isRequired,
    isLoading: PropTypes.bool,
    isActive: PropTypes.bool,
    showActiveIndicator: PropTypes.bool,
}

export default FlightSummary;