import React, { Component, Fragment } from 'react';
import BaseWidget from '../BaseWidget';
import { Tabs, Input, Icon, Spin, Modal, Button, message } from 'antd';
import { searchNewsContent, readNewsContent, getNewsAttachment } from '~/client'
import { getProfileToken, config, debounce, isEmpty, getError, copyText, combineUrl } from '~/common'
import { Loading, RecordNotFound, DefaultImage } from '~/components'
import { FormattedMessage, injectIntl } from 'react-intl';
import { Link } from 'react-router-dom';

const { TabPane } = Tabs, NEWS = "news", DOCUMENT = "documents", LINK = "links", PAGESIZA = 5,
    NewsReleaseType = [
        {
            code: 1,
            label: NEWS,
            formattedMessageIdSearch: "search_for_a_news",
            formattedMessageIdTitle: "news",
            formattedMessageIdNofound: "no_news_found"
        },
        {
            code: 2,
            label: DOCUMENT,
            formattedMessageIdSearch: "search_for_a_document",
            formattedMessageIdTitle: "documents",
            formattedMessageIdNofound: "no_documents_found"
        },
        {
            code: 3,
            label: LINK,
            formattedMessageIdSearch: "search_for_a_link",
            formattedMessageIdTitle: "links",
            formattedMessageIdNofound: "no_links_found"
        }
    ],
    NewsSystemType = {
        any: 0,
        ASL: 1,
        CTMPortal: 2
    },
    getNewsReleaseTypelabel = (val) => {
        return NewsReleaseType.find(el => el.code === val).label;
    };


const RenderNewsLabel = ({ isReaded }) => isReaded ? null : <div className="newLabel ">
    <div className="tag">
        <div className="inner"> <span className="text"><FormattedMessage id="new" /></span>
        </div>
    </div>
</div>

const RenderDocumentItem = ({ DocumentName, Title, IsReaded, DocumentToken, CreateTimeRemark, intl }) => {
    const url = `/attachment/news?token=${encodeURIComponent(DocumentToken)}&file=${encodeURIComponent(DocumentName)}`;
    return <Link to={url} className='docs-item' target='_blank'>
        <Icon type="file" theme="filled" />
        <div className="docs-item-details">
            <span className="docs-item-fileName" title={DocumentName}>{DocumentName}</span>
            <span className="docs-item-nameDate">{CreateTimeRemark}</span>
        </div>
        <RenderNewsLabel isReaded={IsReaded} />
        <Button onClick={event => {
            event.nativeEvent.preventDefault();
            copyText(combineUrl(config.localhostUrl, url));
            message.success(intl.formatMessage({ id: 'copy_link_success' }));
        }} style={{ marginLeft: 10 }}><FormattedMessage id='share'></FormattedMessage></Button>
    </Link>;
}

const RenderLinkItem = ({ Link, LinkSummary, IsReaded, CreateTimeRemark }) => <a className="docs-item " href={Link} target="_blank" title={Link} rel="noopener noreferrer">
    <DefaultImage className="favicon" src={LinkSummary.ImageUrl} defaultImg="default.svg" />
    <div className="docs-item-details ">
        <span className="docs-item-fileName" title={LinkSummary.Title}>{isEmpty(LinkSummary.Title) ? Link : LinkSummary.Title}</span>
        <span className="docs-item-nameDate ">{CreateTimeRemark}</span>
    </div>
    <RenderNewsLabel isReaded={IsReaded} />
</a>



const RenderNewsItem = ({ Title, Content, IsReaded, readNewsContent, type, NewsId, showNewsDetail, CreateTimeRemark }) => <div className="news-item">
    <div className="news-item-details" onClick={() => showNewsDetail({ Title, Content, CreateTimeRemark, type, NewsId })}>
        <div className="news-item-title">
            <span className="docs-item_fileName" title={Title}>{Title}<span className="docs-item-nameDate ">{CreateTimeRemark}</span></span>
            <span className="news-item-new"><RenderNewsLabel isReaded={IsReaded} /></span>
        </div>
        <div dangerouslySetInnerHTML={{ __html: Content }} className="news-item-details-content">
        </div>
    </div>
</div>

class NewsFeed extends Component {
    constructor(props) {
        super(props);
        this.state = {
            news: {},
            documents: {},
            links: {},
        };
        this.searchContentsDebounce = debounce(this.searchContents, 300);
    }

    componentDidMount() {
        this.mounted = true;
        this.searchContents(NewsReleaseType[0].code, { PageIndex: 1, PageSize: PAGESIZA }, '', NewsSystemType.CTMPortal);
    }

    componentWillUnmount() {
        this.mounted = false;
    }

    searchContents = (releaseType, paging, keyword, systemType) => {
        const type = getNewsReleaseTypelabel(releaseType);
        this.setState({
            [type]: {
                ...this.state[type],
                loading: true,
                paging: paging,
            }
        });
        let params = {
            ProfileToken: getProfileToken(),
            paging,
            systemType,
            releaseType,
            keyword
        };
        searchNewsContent(params, (err, data) => {
            let total = 0, summaries = [];
            if (data && data.NewsContentSummaries) {
                total = data.Total;
                summaries = data.NewsContentSummaries;
            }
            this.mounted && this.setState({
                [type]: {
                    ...this.state[type],
                    data: [...paging.PageIndex !== 1 ? (this.state[type].data || []) : [], ...summaries],
                    total: total,
                    loading: false,
                    error: err
                }
            });
        });
    }

    readNewsContent = (type, newsId) => {
        let params = {
            ProfileToken: getProfileToken(),
            newsId
        };
        let listItem = this.state[type].data.find((item) => item.NewsId === newsId);
        listItem.IsReaded || readNewsContent(params, (err, data) => {
            if (data.Status === 0) {
                listItem.IsReaded = true;
                this.setState({
                    [type]: {
                        ...this.state[type]
                    }
                });
            }
        })
    }

    updateQuery = (releaseCode, val) => {
        let releaseType = getNewsReleaseTypelabel(releaseCode);
        this.setState({
            [releaseType]: {
                ...this.state[releaseType],
                query: val
            }
        });
        this.searchContentsDebounce(releaseCode, { PageIndex: 1, PageSize: PAGESIZA }, val.trim(), NewsSystemType.CTMPortal)
    }

    loadMore = (releaseCode) => {
        let { paging, query } = this.state[getNewsReleaseTypelabel(releaseCode)];
        let { PageIndex, PageSize } = paging;
        PageIndex += 1;
        this.searchContents(releaseCode, { PageIndex, PageSize }, query, NewsSystemType.CTMPortal);
    }

    showNewsDetail = ({ ...news }) => {
        this.readNewsContent(news.type, news.NewsId);
        this.setState({ newsDetail: news });
    }

    handleDetailClose = () => {
        this.setState({ newsDetail: null });
    }

    renderDetail = (newsDetail) => {
        return <div className="trip-detail-info">
            <div className="news-detail-header clearfix">
                <div className="news-title">{newsDetail.Title}</div>
                <div className="news-date"><span>{newsDetail.CreateTimeRemark}</span></div>
            </div>
            <div className="detail-content" dangerouslySetInnerHTML={{ __html: newsDetail.Content }} ></div>
        </div>
    }
    renderTabPane = (item) => {
        const RenderComponent = item.label === NEWS ? RenderNewsItem : item.label === LINK ? RenderLinkItem : RenderDocumentItem;
        const summaries = this.state[item.label].data;
        const content = (this.state[item.label].loading && !this.state[item.label].total && this.state[item.label].query === undefined ? <Loading error={this.state[item.label].error} style={{ color: 'white', height: '520px', position: 'relative' }} /> :
            <Fragment>
                <div className="links-search">
                    <Icon type="search" />
                    <Input placeholder={this.props.intl.formatMessage({ id: item.formattedMessageIdSearch })} value={this.state[item.label].query || ""} onChange={(ev) => this.updateQuery(item.code, ev.target.value)} />
                </div>
                {this.state[item.label].total === 0 && !this.state[item.label].loading ? <RecordNotFound formatId={item.formattedMessageIdNofound} style={{ height: "434px" }} /> :
                    <div className="tabs-content">
                        <Spin indicator={<Icon type="loading" />} spinning={this.state[item.label].loading && this.state[item.label].paging.PageIndex === 1} >
                            <div className="content">
                                {
                                    summaries.map(news => item.label === NEWS ? <RenderComponent showNewsDetail={this.showNewsDetail} key={news.NewsId} {...news} readNewsContent={this.readNewsContent} type={item.label} /> :
                                        <div onClick={() => this.readNewsContent(item.label, news.NewsId)} key={news.NewsId} ><RenderComponent {...news} intl={this.props.intl} /></div>)
                                }
                            </div>
                            {this.state[item.label].total > this.state[item.label].data.length && <div className="loadMore">
                                {
                                    this.state[item.label].loading && this.state[item.label].paging.PageIndex !== 1 ? <Icon type="loading" /> :
                                        <span className="loadMoreCta" onClick={() => this.loadMore(item.code)} >+ <FormattedMessage id="load_more" /></span>
                                }
                            </div>
                            }
                        </Spin>
                    </div>
                }
            </Fragment>
        )
        return content;
    }

    tabsChange(releaseCode) {
        const releaseType = getNewsReleaseTypelabel(releaseCode);
        this.state[releaseType].data || this.searchContents(releaseCode, { PageIndex: 1, PageSize: PAGESIZA }, '', NewsSystemType.CTMPortal);
    }

    render() {
        const { newsDetail } = this.state;
        return <div id="newsFeedContainer" className="newsFeed noScrollBar">
            <div className="container ">
                <div className="tabs ">
                    <Tabs onChange={(code) => this.tabsChange(parseInt(code))}>
                        {
                            NewsReleaseType.map(item => {
                                return <TabPane tab={this.props.intl.formatMessage({ id: item.formattedMessageIdTitle })} key={item.code}>
                                    {!this.state[item.label].loading && !this.state[item.label].total && this.state[item.label].query === undefined ? <div className="txt-center"><RecordNotFound formatId={item.formattedMessageIdNofound} style={{ height: "490px", lineHeight: "initial" }} /></div>
                                        : this.renderTabPane(item)
                                    }
                                </TabPane>
                            })
                        }
                    </Tabs>
                    {newsDetail && <Modal
                        wrapClassName={'modal-trip-detail modal-news-detail'}
                        visible={true}
                        footer={null}
                        onCancel={this.handleDetailClose}
                    >
                        {this.renderDetail(newsDetail)}
                    </Modal>}
                </div>
            </div>
        </div>
    }
}

export default BaseWidget(injectIntl(NewsFeed));
