import React from 'react';
import { Loading } from '~/components';
import { config, getError } from '~/common';
import { getSSOConnectionKey } from '~/client';
import { injectIntl } from 'react-intl';
import { Modal } from 'antd';

class SSOCallback extends React.Component {
    constructor(props) {
        super(props);
        this.code = this.props.match && this.props.match.params ? this.props.match.params.code : null;
    }

    componentDidMount() {
        this.mounted = true;
        if (this.code) {
            this.handleGetSSOConnection();
        } else {
            this.handleError('invalid sso parameter');
        }
    }

    componentWillUnmount() {
        this.mounted = false;
    }

    handleGetSSOConnection = () => {
        const rq = {
            Token: config.obtApiToken,
            Code: this.code,
        };
        getSSOConnectionKey(rq, (err, data) => {
            if (data && data.ConnectionKey) {
                this.props.auth.extSSOLogin({ code: data.ConnectionKey });
            } else {
                this.handleError(getError(data));
            }
        });
    }

    handleError = (errMsg) => {
        Modal.error({
            centered: true,
            title: this.props.intl.formatMessage({ id: 'system_information' }),
            okText: this.props.intl.formatMessage({ id: 'ok' }),
            content: errMsg,
            onOk: () => this.props.history.replace('/'),
        });
    }

    render() {
        return <Loading style={{ backgroundColor: 'rgba(0,0,0,.85)' }} />;
    }
}

export default injectIntl(SSOCallback);
