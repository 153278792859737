export const APPLICATION_NAME = "Smart portal";

export const MainRequestStatus = {
    Pending: 0, //(0),
    Cancelled: 1, //(1),
    Request: 2, //(2),
    Confirmed: 3, //(3),
    InProgress: 7, //(7),
    Rejected: 4, //(4),
    Finish: 6, //(6),
    OnHold: 8, //(8);
}

export const Languages = {
    en_us: 'en-us',
    zh_cn: 'zh-cn',
    zh_tw: 'zh-tw',
}

export const ThemeType = {
    Galaxy: 0,
    Breezy: 1,
}

export const TripType = {
    Oneway: 1,
    Roundtrip: 2,
    MultiCity: 4,
}

export const BookingType = {
    Airfare: 0, //Airfare/A
    Hotel: 1, //Hotel/H
    Insurance: 2, //Insurance/I
    AirportExpress: 3, //AirportExpress/Q
    MasterBooking: 4, //Master Booking/M
}

export const TravelStatus = {
    On_Time: 'on-time',
    Landed: 'landed',
    Delayed: 'delayed',
    Not_Departed: 'not-departed',
    En_Route: 'en-route',
    At_Risk: 'at-risk',
    All: 'all',
    Cancelled: "cancelled",
    Unknown: "unknown",
    Warning: "warning"
}

export const TravelStateType = {
    Landed: 0,
    En_Route: 1,
    At_Risk: 2,
    On_Time: 3,
    Delayed: 4,
    Cancelled: 5,
    Unknown: 6,
    Warning: 7
}

export const previousTimeFrame = [
    { key: 1, text: 'day', value: 1 },
    { key: 2, text: 'day', value: 2 },
    { key: 3, text: 'week', value: 1 },
    { key: 4, text: 'week', value: 2 },
    { key: 5, text: 'day', value: 30 },
    { key: 6, text: 'month_to_date', value: 6 }
]
export const upcomingTimeFrame = [
    { key: 1, text: 'day', value: 1 },
    { key: 2, text: 'day', value: 2 },
    { key: 3, text: 'week', value: 1 },
    { key: 4, text: 'week', value: 2 },
    { key: 5, text: 'day', value: 30 },
    { key: 6, text: 'date_to_month_end', value: 6 },
    { key: "noLimit", text: 'no_limit', value: 'noLimit' }
]

export const WidgetType = {
    TravellerTracker: 3,
    FareForecaster: 4,
    UpcomingTrips: 0,
    DeadlineAlert: 1,
    PendingForApproval: 2,
    WorldClock: 5,
    News: 6,//RiskNews = 6,
    NewsFeed: 7,//News = 7,
    ProfileManage: 8,
    CustomBooking: 9,//PreTrip
    TripProposal: 10,
}

export const WidgetSettings = [
    { WidgetType: WidgetType.TravellerTracker, EleId: "widget_traveler_tracker", FormatMessageId: "traveler_tracker", IsVisible: true, Attributes: { spanSetting: 1 }, Component: "TravelerTracker" },
    { WidgetType: WidgetType.UpcomingTrips, EleId: "widget_upcoming_trips", FormatMessageId: "upcoming_trips", IsVisible: true, Attributes: { spanSetting: 2 }, Component: "UpcomingTrips" },
    { WidgetType: WidgetType.DeadlineAlert, EleId: "widget_deadline_alert", FormatMessageId: "deadline_alert", IsVisible: true, Attributes: { spanSetting: 2 }, Component: "MyTrips" },
    { WidgetType: WidgetType.PendingForApproval, EleId: "widget_pending_for_approval", FormatMessageId: "pending_for_approval", IsVisible: true, Attributes: { spanSetting: 2 }, Component: "MyTrips" },
    { WidgetType: WidgetType.FareForecaster, EleId: "widget_fare_forecaster", FormatMessageId: "fare_forecaster", IsVisible: true, Attributes: { spanSetting: 1 }, Component: "FareForecaster" },
    { WidgetType: WidgetType.WorldClock, EleId: "widget_world_clock", FormatMessageId: "world_clock", IsVisible: true, Attributes: { spanSetting: 2, clocks: [] }, Component: "WorldClocks" },
    { WidgetType: WidgetType.News, EleId: "widget_news", FormatMessageId: "risk_news", IsVisible: true, Attributes: { spanSetting: 2 }, Component: "News" },
    { WidgetType: WidgetType.NewsFeed, EleId: "widget_news_links", FormatMessageId: "news_feed", IsVisible: true, Attributes: { spanSetting: 2 }, Component: "NewsFeed" },
    { WidgetType: WidgetType.ProfileManage, EleId: "widget_profile_manage", FormatMessageId: "profile", IsVisible: true, Attributes: { spanSetting: 2 }, Component: "ProfileManage" },
    { WidgetType: WidgetType.CustomBooking, EleId: "widget_pre_trip", FormatMessageId: "trip_request", IsVisible: true, Attributes: { spanSetting: 2 }, Component: "PreTripWidget" },
    { WidgetType: WidgetType.TripProposal, EleId: "widget_trip_proposal", FormatMessageId: "online_trip_proposal", IsVisible: true, Attributes: { spanSetting: 2 }, Component: "TripProposalWidget" },
]


export const SendAsyncOperationDoneMessageType = {
    qrCodeLogin: "2",
    tripProposal: "4",
}

export const RiskLevel = {
    Extreme: 5,
    High: 4,
    Medium: 3,
    Moderate: 2,
    Low: 1,
    Any: 0,
}

export const ErrorCode = {
    CheckIdTokenFail: "AU0005",// "Check id/access token failure."
    Timeout: "C0054",// "Session Timeout"
    TokenExpired: "C0401",
}

export const LoginSystemType = {
    OBT: 0,
    CTM_Portal: 1,
}

export const UserApps = [
    { AppId: "ASL", DefaultAppLogo: "lightning.svg" },
    { AppId: "Concur", DefaultAppLogo: "sap-concur.png" },
    { AppId: "Serko", DefaultAppLogo: "serko.svg" },
    { AppId: "Swift", DefaultAppLogo: "swift.png" },
]

export const Channel = {
    AsiaLightning: "ASL",
}

export const TripReqStatus = {
    /// <summary>
    /// Pending Finalize
    /// </summary>
    Pending: 0,
    /// <summary>
    /// user cancel booking
    /// </summary>
    Cancelled: 1,
    /// <summary>
    /// Pending Approve
    /// </summary>
    Request: 2,
    /// <summary>
    /// Confirm Approval,Can be submit to ticket or hotel processing
    /// Approved
    /// </summary>
    Confirmed: 3,
    /// <summary>
    /// Wait for ticket or hotel processing
    /// </summary>
    InProgress: 7,
    /// <summary>
    /// reject order,Reject by Approver
    /// </summary>
    Rejected: 4,
    /// <summary>
    /// success to ticket and confirm hotel
    /// </summary>
    Finish: 6,
    /// <summary>
    /// Hold for booking
    /// </summary>
    OnHold: 8,
}

export const ApproveStatusCode = {
    Pending: 0,
    Approved: 1,
    Rejected: 2,
}

export const EmailReceiverRoles = {
    None: 0,
    Traveler: 1,
    Creator: 2,
    TC: 4,
    Approver: 8,
    HasTrsRightUser: 16,
    HaveReceiveConfirmEmailRights: 32,
    AddtionReceivers: 64,
}

export const TripProposalStatus = {
    /// <summary>
    /// TP is Created
    /// </summary>
    Draft: 1,
    /// <summary>
    /// TP can be retrieved in Online TP
    /// </summary>
    Published: 2,
    /// <summary>
    /// TP has been sent to client email
    /// </summary>
    Submitted: 3,
    /// <summary>
    /// TP has been confirmed by user and custom booking is created
    /// </summary>
    Confirmed: 4,
    /// <summary>
    /// TP has been confirmed and ASL booking is created
    /// </summary>
    PendingFinalize: 5,
    /// <summary>
    /// ASL booking is created and pending for approval
    /// </summary>
    PendingApproval: 6,
    /// <summary>
    /// Booking is approved
    /// </summary>
    Approved: 7,
    Declined: 8,
    /// <summary>
    /// Success to ticket, Booking is completed
    /// </summary>
    Completed: 9,
    Cancelled: 10,
}

export const TripProposalCategory = {
    Airfare: 1,
    Hotel: 2,
    Car: 3,
}

export const ViewRight = {
    ViewByMyself: 1,
    ViewByTeam: 3,
    ViewByCompany: 7,
    ViewAll: 15,
}

export const TripProposalCutomTitleType = {
    ServiceFee: 1,
    Surcharge: 2,
    Tax: 3,
    TransactionFee: 4,
    AdminServiceFee: 5,
}

export const CorporateDealSourceClient = {
    Corporate: 1,
    CTM: 2,
}

export const CTMPortalSystemType = 2;

export const TripProposalArchiveType = {
    Custom: 0,
    Email: 1,
}