export const SSO_LOGIN_2_LIGHTNING = 'SSO_LOGIN_2_LIGHTNING';
export const SSO_LOGIN_2_APP_INVOKED = 'SSO_LOGIN_2_APP_INVOKED';
export const SSO_LOGIN_2_APP_SUCCESS = 'SSO_LOGIN_2_APP_SUCCESS';
export const SSO_LOGIN_2_APP_FAILED = 'SSO_LOGIN_2_APP_FAILED';
export const SSO_2_APP_RESET = 'SSO_2_APP_RESET';
export const SSO_LOGIN_2_APP='SSO_LOGIN_2_APP';
export const START_HUB_CONNECTION = 'START_HUB_CONNECTION'
export const STOP_HUB_CONNECTION = 'STOP_HUB_CONNECTION'

export const stopHubConnection =() => ({ type: STOP_HUB_CONNECTION });
export const startHubConnection =() => ({ type: START_HUB_CONNECTION });
export const sso2Lightning = payload => ({ type: SSO_LOGIN_2_LIGHTNING, payload });
export const sso2AppInvoked = () => ({ type: SSO_LOGIN_2_APP_INVOKED });
export const sso2AppSuccess = payload => ({ type: SSO_LOGIN_2_APP_SUCCESS, payload });
export const sso2AppFailed = error => ({ type: SSO_LOGIN_2_APP_FAILED, error });
export const sso2AppReset = () => ({ type: SSO_2_APP_RESET });
export const sso2App = payload => ({ type: SSO_LOGIN_2_APP, payload });



const initialState = { data: null };

const sso = (state = initialState, action) => {
    switch (action.type) {
        case SSO_LOGIN_2_APP_INVOKED:
            return {
                ...state,
                loading: true,
                error: null,
            }
        case SSO_LOGIN_2_APP_SUCCESS:
            return {
                ...state,
                loading: false,
                data: action.payload,
                error: null,
            }
        case SSO_LOGIN_2_APP_FAILED:
            return {
                ...state,
                loading: false,
                error: action.error
            }
        case SSO_2_APP_RESET:
            return {
                ...state,
                loading: false,
                error: null,
                data: null,
            }
        default: return state;
    }
}

export default sso;