export const UPDATE_QRCODE_MSG_DATA = 'UPDATE_QRCODE_MSG_DATA';
export const UPDATE_QUEUE_NOTIFY_KEYS = 'PUSH_NOTIFY_KEY';
export const UPDATE_LOGIN_PLATFORM_INFOS = 'UPDATE_LOGIN_PLATFORM_INFOS';

export const updateQRCodeMsg = payload => ({ type: UPDATE_QRCODE_MSG_DATA, payload });
export const updateQueueNotifyKeys = payload => ({ type: UPDATE_QUEUE_NOTIFY_KEYS, payload });
export const updateLoginPlatformInfos = payload => ({ type: UPDATE_LOGIN_PLATFORM_INFOS, payload });

const initState = {
    asyncOperationQueueKeys: []
}

const signalr = (state = initState, action) => {
    switch (action.type) {
        case UPDATE_LOGIN_PLATFORM_INFOS:
        case UPDATE_QRCODE_MSG_DATA:
            return {
                ...state,
                ...action.payload
            };
        case UPDATE_QUEUE_NOTIFY_KEYS:
            return {
                ...state,
                asyncOperationQueueKeys: action.payload
            };
        default:
            return state;
    }
}

export default signalr;
