import React from 'react';
import { ConfigProvider } from 'antd';

const ConfigProviderPolyfill = ({ component: Component, locale }) => {
    //在入口文件引入ConfigProvider在IE下会报错，未知原因
    return <ConfigProvider locale={locale}>
        <Component />
    </ConfigProvider>;
}

export default ConfigProviderPolyfill;