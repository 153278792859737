import React from 'react';
import { Route, Redirect } from 'react-router';
import { getProfileToken, getCacheUserInfo, getQueryStringValue, getEmailToken } from '~/common';
import { Loading } from '~/components';

const ProtectedRoute = ({ component: Component, auth, scopes, ...rest }) => {

    return (<Route {...rest} render={props => {
        const userInfo = getCacheUserInfo();
        const isAuth0Auth = Boolean(auth.isAuthenticated() && getProfileToken() && userInfo && userInfo.loginId);
        const isEmailTokenAuth = Boolean(getProfileToken() && getEmailToken());
        if (!isAuth0Auth && props.match && props.match.path === '/attachment/news') {
            return <Loading style={{ backgroundColor: 'rgba(0,0,0,.85)' }} showNotAuthorizedPrompt />;
        }
        if (props.match && (props.match.path === '/pre-trip/attachment' || props.match.path === '/booking/attachment')) {
            if (isAuth0Auth || isEmailTokenAuth) {
                return <Component auth={auth} {...props} {...rest} />;
            }
            return <Loading style={{ backgroundColor: 'rgba(0,0,0,.85)' }} showNotAuthorizedPrompt />;
        }
        if (props.match.path == '/' && props.location && ['/pre-trip/detail', '/trip-proposal/detail'].includes(props.location.pathname)) {
            const emailToken = getQueryStringValue(null, 'token');
            if (emailToken) {
                setTimeout(() => {
                    props.history.replace('/callback' + window.location.search);
                }, 100);
                return <Loading style={{ backgroundColor: 'rgba(0,0,0,.85)' }} />;
            }
            if (!isAuth0Auth && isEmailTokenAuth) {//pending login by access token validate time
                return <Component auth={auth} {...props} {...rest} />;
            }
        }
        return isAuth0Auth ? <Component auth={auth} {...props} {...rest} /> : <Redirect to={{ pathname: '/login', state: { from: props.location } }} />;
    }} />);
}

export default ProtectedRoute;
