import React, { Fragment } from 'react';
import { Loading, CTMModal } from '~/components';
import { config, getError, parseQueryString, getProfileToken } from '~/common';
import { getNewsAttachment } from '~/client';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Link } from 'react-router-dom';

class NewsAttachment extends React.Component {
    constructor(props) {
        super(props);
        const queryObj = parseQueryString();
        this.token = queryObj['token'];
        this.file = queryObj['file'];
        this.state = {
            showTips: false,
        };
    }

    componentDidMount() {
        this.mounted = true;
        if (this.token && this.file) {
            this.handleGetAttachment();
        } else {
            this.handleError('invalid parameter');
        }
    }

    componentWillUnmount() {
        this.mounted = false;
    }

    handleGetAttachment = () => {
        const rq = {
            DocumentToken: this.token,
            ProfileToken: getProfileToken(),
        };
        getNewsAttachment(rq, (err, data) => {
            if (data instanceof Blob) {
                const extension = this.file.split('.').slice(-1)[0].toLowerCase();
                const isText = extension == 'txt';
                const isImg = ['png', 'jpg', 'jpeg', 'gif'].includes(extension);
                this.setState({ showTips: true });
                const a = document.createElement('a');
                document.body.appendChild(a);
                a.download = this.file;
                a.style.display = 'none';
                a.href = window.URL.createObjectURL(data);
                const download = () => {
                    a.click();
                    window.URL.revokeObjectURL(a.href);
                    document.body.removeChild(a);
                }
                if (isText) {
                    data.text().then(txt => {
                        CTMModal.confirm({
                            title: this.file,
                            content: txt,
                            okText: 'Download',
                            cancelText: 'Close',
                            onOk: () => {
                                download();
                            }
                        });
                    })
                } else if (isImg) {
                    CTMModal.confirm({
                        title: this.file,
                        content: <img src={a.href} />,
                        okText: 'Download',
                        cancelText: 'Close',
                        onOk: () => {
                            download();
                        }
                    });
                } else {
                    download();
                }
            } else {
                this.handleError(getError(data));
            }
        });
    }

    handleError = (errMsg) => {
        CTMModal.error({
            centered: true,
            title: this.props.intl.formatMessage({ id: 'system_information' }),
            okText: this.props.intl.formatMessage({ id: 'ok' }),
            content: this.props.intl.formatMessage({ id: 'not_authorized_tip' }),
            onOk: () => this.props.history.replace('/'),
        });
    }

    render() {
        return this.state.showTips ? <div style={{ display: 'flex', height: '100vh', alignItems: 'center', justifyContent: 'center', color: '#fff', backgroundColor: 'rgba(0, 0, 0, 0.85)' }}>
            <FormattedMessage id='the_document_was_not_downloaded'></FormattedMessage>
            <a style={{ marginLeft: 10 }} onClick={() => window.location.reload()}><FormattedMessage id='retry'></FormattedMessage></a>
            <span style={{ margin: '0 5px' }}><FormattedMessage id='or'></FormattedMessage></span>
            <Link to='/'><FormattedMessage id='back_to_home_page'></FormattedMessage></Link>
        </div>
            : <Loading style={{ backgroundColor: 'rgba(0,0,0,.85)' }} />;
    }
}

export default injectIntl(NewsAttachment);
