module.exports = {
    language: 'Language',
    system_information: 'System Information',
    login_lightning_failed_prompt: 'Login lightning by SSO failed, invalid user',
    loading: 'Loading...',
    page_not_found: '404 - Page Not Found!',
    page_not_found_prompt: 'We are sorry but the page you are looking for does not exist.',
    record_not_found: 'Record Not Found',
    booking_not_found: 'Booking not found',
    error_occurred: 'error occurred',
    dashboard: 'Dashboard',
    tools: 'My Tools',
    fare_forecaster: 'Fare Forecaster',
    deadline_alert: 'Booking Reminder',
    pending_for_approval: 'Pending For Approval',
    upcoming_trips: 'Upcoming Trips',
    deadline: 'Deadline',
    logout: 'Logout',
    change_password: 'Change Password',
    fare_forecaster_form_prompt: 'Please enter your search criteria below to view the best available prices on flights.',
    close_filters: 'Close Filters',
    flight_index_fmt: 'Flight {index}',
    enter_city_airport_name: 'Enter City / Airport Name',
    departure_date: 'Departure Date',
    any_time: 'Any Time',
    add_another_flight: 'Add another flight',
    cabin_class: 'Cabin Class',
    first_class: 'First Class',
    business: 'Business',
    premium_economy: 'Premium Economy',
    economy: 'Economy',
    direct: 'Direct',
    search: 'Search',
    no_data: '-',
    one_way: 'One Way',
    round_trip: 'Round Trip',
    multi_city: 'Multi-City',
    enter_an_airport: 'Enter an airport',
    just_loading_your_search_results: 'Just loading your search results',
    departing: 'Departing',
    returning: 'Returning',
    departure: 'Departure',
    return: 'Return',
    lowest_fare: 'Lowest fare',
    booking_now: 'Booking Now',
    same_city_prompt: 'Departure city can\'t be the same as arrival city',
    multi_city_not_support_prompt: 'Multi-City not support yet, try one way or round trip',
    search_timeout_prompt: 'Search timeout, please refresh your search',
    refresh: 'Refresh',
    all: 'All',
    stop: 'Stop',
    duration_fmt: '{hours}h{minutes}m',
    traveler_tracker: 'Traveller Tracker',
    highest_fare: 'Highest fare',
    browser_not_support_webgl: 'Mapbox GL requires WebGL support. Please check that you are using a supported browser and that WebGL is enabled.',
    en_route: 'En-Route',
    landed: 'Arrived',
    at_risk: 'At Risk',
    type: 'Type',
    code: 'Code',
    total: 'Total',
    airport: 'Airport',
    hotel: 'Hotel',
    back_to_home_page: 'Back to home page',
    oops_something_went_wrong: 'Oops, something went wrong!',
    trip_name: 'Trip Name',
    update_search: 'Update Search',
    on_time: 'On Time',
    delayed: 'Delayed',
    not_departed: 'No Departed',
    my_apps: 'My Apps',
    hello: 'Hello',
    status: 'Status',
    arrival: 'Arrival',
    check_in: 'Check in',
    check_out: 'Check out',
    order_no: 'Order No.',
    select_screen_theme: 'Select Screen Theme',
    select_language: 'Select Language',
    breezy: 'Breezy',
    galaxy: 'Galaxy',
    widgets: 'Widgets',
    start_date: 'Start Date',
    end_date: 'End Date',
    to: 'to',
    world_clock: 'World Clocks',
    add_new_clock: 'Add New Clock',
    choose_timezone: 'Choose a timezone',
    delete: 'Delete',
    cancel: 'Cancel',
    day: '{count, plural,=0 {0 Day}one {1 Day}other {{count} Days}}',
    week: '{count, plural,=0 {0 Week}one {1 Week}other {{count} Weeks}}',
    month_to_date: 'Month to Date',
    date_to_month_end: 'Date to Month\'s End',
    search_trip: 'Search by name, flight, hotel name, or location',
    trip_between: '{endDate,select,noLimit{{startDate,select,noLimit{Showing all segments}other{Showing segments after {previous}}}}other{{startDate,select,noLimit{Showing segments before {upcoming}}other{Showing segments between {previous} and {upcoming}}}}}',
    previous: 'Previous',
    upcoming: 'Upcoming',
    found: 'found',
    no_limit: 'No Limit',
    please_select: "please select",
    filter: "filter",
    no_booking_for_approval: "No booking for approval",
    no_booking_for_upcoming: "No booking for upcoming trip",
    no_booking_be_alert: "No booking reminder",
    any: "Any",
    click_for_detail: 'click for detail',
    agency_record_locator: 'Agency Record Locator',
    travel_time_fmt: 'Travel time: {hours}h{minutes}m',
    amenities: 'Amenities',
    amenities_not_found: 'Amenities not found',
    nights_count: 'Nights',
    description: 'Description',
    location: 'Location',
    stopover: 'stopover',
    view_in: 'View in',
    trip_count_fmt: '{count,plural,=0{No Trips}one{1 Trip}other{# Trips}}',
    fare_not_found: 'Fare not found',
    online_check_in: 'online check in',
    login_for_mobile: 'Lightning mobile login ',
    mobile_phone_login: 'Mobile phone login',
    download_app: 'Download App',
    scan_qr_code_to_download_app: 'Scan QR Code to download app.',
    download_app_already_login_now: 'Download app already, login now.',
    deactivate: 'Deactivate',
    login_ctm_lightning_mobile_app: "Please scan this QR code to login CTM Lightning mobile app",
    risk_news: 'Risk News',
    risk_level: 'Risk Level',
    country: "Country / Region",
    keyword: "Keyword",
    country_code_or_name: "Country/Region Code or Name",
    read_less: "READ LESS...",
    read_more: "READ MORE...",
    scroll: "Scroll",
    no_risk_news: "No risk news",
    on: "ON",
    off: "OFF",
    georisk_map: "GEORISK MAP",
    risk_level_up: 'RISK LEVEL',
    risk_alert_fmt: '{count,plural,=0{No Risk Summaries}one{1 Risk Summary}other{# Risk Summaries}}',
    new: "NEW",
    load_more: "Load more",
    search_for_a_news: "Search for a update",
    search_for_a_document: "Search for a document",
    search_for_a_link: "Search for a link",
    news: "Updates",
    documents: "Documents",
    links: "Links",
    news_feed: "News",
    no_news_found: "No updates found",
    no_links_found: "No links found",
    no_documents_found: "No documents found",
    cancelled: "Cancelled",
    unknown: "Unknown",
    warning: "Warning",
    contact_us: "Contact Us",
    contact_person: "Contact Person",
    phone: "Phone",
    email: "Email",
    reset_your_password: "Reset your password",
    please_enter_your_email_address: "Please enter your email address. We will send you an email to reset your password.",
    your_new_password: "Your new password",
    confirm_your_new_password: "Confirm your new password",
    invalid_email_format: "Invalid email address",
    send_email: "Send Email",
    your_password_has_been_reset_successfully: "Your password has been reset successfully.",
    enter_a_new_password: "Enter a new password for {email}",
    retry: "Retry",
    or: "or",
    timeout_tip: "Session timeout, please login again",
    you_have_signed_out: "You have signed out of CTM Portal successfully.",
    profile: 'Profile',
    continue: 'Continue',
    password_policy: 'Password Policy',
    at_least_6_characters_in_length: 'At least 6 characters in length',
    ok: 'OK',
    share: 'Share',
    copy_link_success: 'Copy link success',
    the_document_was_not_downloaded: 'The document was not downloaded?',
    trip_request: 'Trip Request',
    page_no_right: '403 - You have no right to view this page!',
    not_authorized_login_tip: 'You are not authorized to access this Url. Please login CTM Portal before access this Url.',
    not_authorized_tip: 'You are not authorized to access this Url. ',
    login_now: 'Login Now',
    invalid_parameter: 'Invalid parameter',
    later: 'Later',
    confirm: 'Confirm',
    close: 'Close',
    save: 'Save',
    cancel_request_tip: 'This trip request will be cancelled. Please confirm if you want to proceed.',
    submit_to_approver_tip: 'Are you sure to submit this trip request to approver?',
    submit_to_tc_tip: 'Are you sure to submit this trip request to consultant?',
    cancel_flight_request_tip: 'Are you sure to cancel this flight request?',
    cancel_hotel_request_tip: 'Are you sure to cancel this hotel request?',
    cancel_other_service_request_tip: 'Are you sure to cancel this other service request?',
    update_success: 'Update success',
    make_booking_tip: 'Are you sure to make booking for this trip request?',
    approve_tip: 'Are you sure to approve this trip request?',
    optional_approve_reason: 'Optional approve reason',
    please_enter: 'Please enter',
    approve_request: 'Approve Request',
    approve: 'Approve',
    please_enter_reject_reason: 'Please enter reject reason',
    approval_declined: 'Approval Declined',
    decline: 'Decline',
    resend_email: 'Resend Email',
    resend_email_success: 'Resend email success',
    resend_email_tip: 'Are you sure to resend approve request email?',
    select_approver: 'Select Approver',
    select_approver_tip: 'Please select approver and create approve flow first.',
    add_flight_request: 'Add Flight Request',
    add_hotel_request: 'Add Hotel Request',
    add_other_service_request: 'Add Other Service Request',
    edit_flight_request: 'Edit Flight Request',
    edit_hotel_request: 'Edit Hotel Request',
    edit_other_service_request: 'Edit Other Service Request',
    trip_request_detail: 'Trip Request Detail',
    operation_history: 'Operation History',
    operated_by: 'Operated By',
    operated_on: 'Operated On',
    back: 'Back',
    new_trip_request: 'New Trip Request',
    approver_not_found: 'Approver not found',
    please_select_approver: 'Please select approver',
    change_level_approver_fmt: 'Change Level {step} Approver',
    edit_note_to_approver: 'Edit Note to Approver',
    approve_form_not_found_tip: 'Approve form not found, please contact your travel consultant',
    approval_workflow: 'Approval Workflow',
    no_need_approval: 'No need approval',
    please_select_level_approver_fmt: 'Please select level {step} approver',
    note_to_approver: 'Note to Approver',
    attachment: 'Attachment',
    level_fmt: 'Level {step}',
    change_approver: 'Change Approver',
    remark: 'Remark',
    remark_colon: 'Remark: ',
    please_enter_trip_name: 'Please enter trip name',
    special_request: 'Special Request',
    trip_overview: 'Trip Overview',
    traveller_udid_not_found: 'Traveller UDID not found',
    enable: 'Enable',
    please_enter_this_field: 'Please enter this field',
    update_trip_information: 'Update Trip Information',
    created: 'Created',
    total_budget: 'Total',
    request_reference: 'Request Reference',
    booking_num: 'Booking No.',
    traveller: 'Traveller',
    contact_phone_num: 'Contact Phone No.',
    trip_information: 'Trip Information',
    details: 'Details',
    flight: 'Flight',
    price_currency_tip_fmt: 'All prices shown in {currency} unless otherwise stated',
    segment_departure: 'Departure',
    cabin: 'Cabin',
    time_prefer: 'Time Prefer',
    airlines: 'Airlines',
    seat_preference: 'Seat Preference',
    meal_preference: 'Meal Preference',
    flight_budget: 'Flight Budget',
    taxes_tip_with_parenthesis: '(Taxes and services fees inclusive)',
    edit: 'Edit',
    clear: 'Clear',
    room_name: 'Room Name',
    loyalty_program: 'Loyalty Program',
    hotel_budget: 'Hotel Budget',
    add_flight: 'Add Flight',
    add_hotel: 'Add Hotel',
    add_other_service: 'Add Other Service',
    cancel_request: 'Cancel Request',
    submit_to_approver: 'Submit to Approver',
    confirm_request: 'Confirm Request',
    submit_to_consultant: 'Submit to Consultant',
    make_booking: 'Make Booking',
    create_new: 'New',
    search_result_colon: 'Search Result:',
    trip_request_search: 'Trip Request Search',
    advanced_trip_request_search: 'Advanced Trip Request Search',
    company: 'Company',
    traveller_names: 'Traveller Names',
    traveller_email: 'Traveller Email',
    destination: 'Destination',
    hotel_name: 'Hotel Name',
    request_date_from: 'Request date from',
    request_date_to: 'Request date to',
    departure_date_from: 'Departure date from',
    departure_date_to: 'Departure date to',
    return_date_from: 'Return date from',
    return_date_to: 'Return date to',
    general_search: 'General Search',
    advanced_search: 'Advanced Search',
    start: 'Start',
    end: 'End',
    requested: 'Requested',
    please_select_fmt: 'Please select {0}',
    please_enter_fmt: 'Please enter {0}',
    please_enter_correct_fmt: 'Please enter correct {0}',
    departure_city: 'Departure City',
    arrival_city: 'Arrival City',
    fare_class: 'Fare Class',
    departure_time: 'Departure Time',
    city: 'City',
    check_in_date: 'Check In Date',
    check_out_date: 'Check Out Date',
    please_fill_in_your_other_requirements: 'Please fill in your other requirements',
    add_another_hotel: 'Add another hotel',
    all_status: 'All Status',
    comment: 'Comment',
    write_a_message_here: 'Write a message here...',
    invalid_message: 'Invalid Message',
    message_size_max_limit_colon: 'Message size max limit: ',
    pending: 'Pending',
    approved: 'Approved',
    rejected: 'Rejected',
    already_exist_fmt: '{0} already exist',
    invalid_attachment_type: 'Invalid Attachment Type',
    support_attachment_type_colon: 'Support attachment type: ',
    invalid_attachment: 'Invalid Attachment',
    attachment_size_max_limit_colon: 'Attachment size max limit: ',
    click_drag_upload: 'Click or Drag file here',
    single_bulk_upload: 'Support for a single or bulk upload',
    download: 'Download',
    invalid_traveller: 'Invalid Traveller',
    confirm_request_tip: 'Are you sure to finalize this trip request?',
    no_trip_request: 'No trip request found',
    message_dialog: 'Message Dialog',
    related_login_not_found: 'Related login not found',
    special_request_remark: 'Special Request (Remark)',
    itinerary: 'Itinerary',
    please_enter_different_city: 'Please enter different city',
    flow_type: 'Flow Type',
    approval_history: 'Approval History',
    original_total_colon: 'Original Total: ',
    original_budget_colon: 'Original Budget: ',
    show_all: 'Show All',
    show_less: 'Show Less',
    other_service: 'Other Service',
    budget: 'Budget',
    category: 'Category',
    add_another_service: 'Add other service',
    online_trip_proposal: 'Online Trip Proposal',
    no_online_trip_proposal: 'No online trip proposal found',
    reference_number: 'Reference Number',
    online_trip_proposal_search: 'Online Trip Proposal Search',
    advanced_online_trip_proposal_search: 'Advanced Online Trip Proposal Search',
    online_trip_proposal_detail: 'Online Trip Proposal Detail',
    proposal_ref: 'Proposal Ref',
    overviews: 'Overviews',
    estimate_total: 'Estimate total',
    total_amount: 'Total amount',
    per_adult: 'per adult',
    detail: 'Detail',
    client_information: 'Client Information',
    name: 'Name',
    itinerary_detail_of_fmt: 'Itinerary Detail of {0}',
    ticket_fare_per_person: 'ticket fare per person',
    show_detail: 'Show Detail',
    depart: 'Depart',
    travel_time: 'Travel time',
    fare: 'Fare',
    tax: 'Tax',
    surcharge: 'Surcharge',
    service_fee: 'Service Fee',
    pnr_fee: 'PNR Fee',
    credit_card_payment: 'Credit Card Payment',
    discount: 'Discount',
    selected_fmt: 'Selected {0}',
    option: 'Option',
    carbon_emission: 'Carbon emission',
    not_included: 'Not included',
    higher: 'Higher',
    emissions: 'emissions',
    this_flight: 'This flight',
    typical_for_this_route: 'Typical for this route',
    lower: 'Lower',
    emissions_tip: 'Emissions are calculated for 1 passenger in your selected seating class.',
    carry_on_bag: 'Carry-on Bag',
    checked_bag: 'Checked Bag',
    carry_on_bag_fmt: '{0}{1} carry-on bag',
    checked_bag_fmt: '{0}{1} checked bag',
    confirm_trip_detail: 'Confirm Trip Detail',
    traveller_information: 'Traveller Information',
    itinerary_information: 'Itinerary Information',
    not_lowest_price_tip: 'This segment is not the lowest price.',
    current_price: 'Current price',
    lowest_price: 'Lowest price',
    lowest_fare_tax: 'Lowest fare tax',
    lowest_fare_carrier: 'Lowest fare carrier',
    optional: 'Optional',
    proposal_created_tip: 'Your reservation was successfully created.',
    proposal_submit_tip: 'Please submit this booking to proceed your booking procedure.',
    trip_plan_fmt: 'Trip Plan {0}',
    option_fmt: 'Option {0}',
    agency_contact_person: 'Agency Contact Person',
    client_company_name: 'Client Company Name',
    operated_by_fmt: 'Operated by {0}',
    no_check_in_baggage: 'No check-in baggage',
    please_confirm_trip_plan_option_first: 'Please confirm trip plan option first',
    fare_rules: 'Fare Rules',
    itineraries: 'Itineraries',
    corporate_deal: 'Corporate Deal',
    sme_deal: 'SME Deal',
    rules: 'Rules',
    selected: 'Selected',
    select: 'Select',
    processing_your_booking_now: 'Processing your booking now...',
    traveller_not_found: 'Traveller not found',
    finalize: 'Finalize',
    select_approver_success_tip: 'Select approver success, finalize your booking now?',
    related_booking_info_not_found: 'Related booking info not found',
    submit: 'Submit',
    ancillary: 'Ancillary',
    flight_ordinal_fmt: '{ordinal}{suffix} Flight',
    transfer: 'Transfer',
    transfer_in: 'Transfer in',
    otp_stop: 'Stop',
    otp_stopover: 'Stopover',
    transaction_fee: 'Transaction Fee (CTM Charge)',
    admin_service_fee: 'Admin Service Fee (CTM Charge)',
    preview_email_dialog_history: 'Preview email dialog history',
    email_dialog_history: 'Email Dialog History',
    date: 'Date',
    subject: 'Subject',
    email_dialog_history_not_found: 'Email dialog history not found',
    email__from: 'From',
    email__to: 'To',
    email__cc: 'Cc',
    email__bcc: 'Bcc',
    all_rules: 'All rules',
    all_detail: 'All detail',
    transit_time: 'Transit Time',
    risk_hub: 'Risk Hub',
    dark: 'Dark',
    light: 'Light',
}