import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { FormattedMessage } from 'react-intl';
import { getImageUrl, Languages, getCurrentLang } from '~/common';

const API_TIME_FORMAT = 'YYYYMMDDHHmmss';
const UI_DATE_TIME_FORMAT = getCurrentLang() === Languages.en_us ? 'DD MMM YYYY (ddd) h:mm a' : 'YYYY年MM月DD日 (ddd) h:mm a';

class FlightDetail extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    componentDidMount() {

    }

    render() {
        const { detail } = this.props;
        if (!detail) return null;
        const airports = detail.Segments.reduce((a, b) => [...new Set([...a, b.DepAirport, b.ArrAirport])], []);
        const flightNumbers = detail.Segments.reduce((a, b) => [...a, `${b.MarketingCarrier + b.FlightNumber}`], []);
        const marketingCarriers = detail.Segments.reduce((a, b) => [...new Set([...a, b.MarketingCarrierName])], []);
        const cabinClasses = detail.Segments.reduce((a, b) => [...new Set([...a, b.CabinClassName])], []);
        const bookingClasses = detail.Segments.reduce((a, b) => [...a, b.BookingClass], []);
        const aircrafts = detail.Segments.reduce((a, b) => [...new Set([...a, b.AircraftName])], []);
        return (
            <div className='detail-flight-item'>
                <div className='routing-brief'>
                    {airports.join('-')}
                </div>
                <div className='routing-detail'>
                    <div className='routing-detail-title'>
                        <div className='left-col'>
                            <div className='flight-number-font'>{`${flightNumbers.join(', ')} / ${marketingCarriers.join(', ')}`}</div>
                            <div className='class-font'>{`${cabinClasses.join(', ')}(${bookingClasses.join(', ')}) / ${aircrafts.join(', ')}`}</div>
                        </div>
                        <div className="right-col">
                            <FormattedMessage id='amenities' />
                        </div>
                    </div>
                    {detail.Segments.map((segment, index) => {
                        let transferNode = null;
                        if (index > 0) {
                            const prevSegment = detail.Segments[index - 1];
                            const transferDuration = moment(segment.DepTimeRemark, API_TIME_FORMAT).diff(moment(prevSegment.ArrTimeRemark, API_TIME_FORMAT), 'minutes');
                            transferNode = <div className='transfer-info'>
                                <FormattedMessage id='duration_fmt' values={{ hours: parseInt(transferDuration / 60), minutes: transferDuration % 60 }} />
                                <span className="transfer-key"><FormattedMessage id='stopover' /></span>
                                {`${segment.DepAirportName || segment.DepAirport} (${segment.DepAirport} ${segment.DepartureTerminal? segment.DepartureTerminal : ''})`}
                            </div>;
                        }
                        return <Fragment key={index}>
                            {transferNode}
                            <div className='segment-item'>
                                <div className='segment-item-info'>
                                    <div className='date-airport-info'>
                                        <div>{moment(segment.DepTimeRemark, API_TIME_FORMAT).format(UI_DATE_TIME_FORMAT).toUpperCase()}</div>
                                        <div>{`${segment.DepAirportName || segment.DepAirport} (${segment.DepAirport}) ${segment.DepartureTerminal? segment.DepartureTerminal : ''}`}</div>
                                    </div>
                                    <div className='elapsed-time-info'>
                                        <FormattedMessage id='travel_time_fmt' values={{ hours: parseInt(segment.ElapsedTime / 60), minutes: segment.ElapsedTime % 60 }} />
                                    </div>
                                    <div className='date-airport-info'>
                                        <div>{moment(segment.ArrTimeRemark, API_TIME_FORMAT).format(UI_DATE_TIME_FORMAT).toUpperCase()}</div>
                                        <div>{`${segment.ArrAirportName || segment.ArrAirport} (${segment.ArrAirport}) ${segment.ArrivalTerminal? segment.ArrivalTerminal:''}`}</div>
                                    </div>
                                </div>
                                <div className='segment-facility'>
                                    {segment.Facilities && segment.Facilities.length ? <Fragment>
                                        {segment.Facilities.map((facility, fIndex) => {
                                            return <div className='facility-item' key={fIndex}>
                                                <img src={getImageUrl(`${facility.key}.png`)} alt={facility.key} />
                                                <span>{facility.value}</span>
                                            </div>;
                                        })}
                                    </Fragment> : <div>
                                            <FormattedMessage id='amenities_not_found' />
                                        </div>}
                                </div>
                            </div>
                        </Fragment>;
                    })}
                </div>
            </div>
        );
    }
}

FlightDetail.propTypes = {
    detail: PropTypes.object.isRequired,
}

export default FlightDetail;