import { getCacheUserInfo } from '~/common';

export const UPDATE_USER_INFO = 'UPDATE_USER_INFO';

export const updateUserInfo = payload => ({ type: UPDATE_USER_INFO, payload });

const initState = () => ({
    name: '',
    email: '',
    loginId: 0,
    enableLoginByQRCode4Mobile: false,
    viewCompanies: [],
    moduleRights: {
        AllowAccessCTMProfile: false,
        AllowAccessAsiaLightning: false,
        UserApps:[]
    },
    ...getCacheUserInfo(),
});

const user = (state = initState(), action) => {
    switch (action.type) {
        case UPDATE_USER_INFO:
            return {
                ...state,
                ...action.payload
            };
        default: return state;
    }
}

export default user;