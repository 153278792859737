import React, { Component } from 'react';
import * as d3 from 'd3';
import PropTypes from 'prop-types';

/**
 * D3 analog clock for world clocks widget.
 */
class AnalogClock extends Component {

    constructor(props) {
        super(props);
        this.clockRef = React.createRef();
        this.clockGroup = null;
        this.timeData = [
            {
                "unit": "minutes",
                "numeric": this.props.minute || 0
            }, {
                "unit": "hours",
                "numeric": this.props.hour || 10
            }
        ];
        // We define our minute/hour d3 code here so that re-define it every minute on re-render
        this.scaleMins = d3.scaleLinear().domain([0, 59 + 59 / 60]).range([0, 2 * Math.PI]);
        this.scaleHours = d3.scaleLinear().domain([0, 11 + 59 / 60]).range([0, 2 * Math.PI]);
        this.minuteArc = d3.arc()
            .innerRadius(0)
            .outerRadius(35)
            .startAngle(function (d) {
                return this.scaleMins(d.numeric);
            })
            .endAngle(function (d) {
                return this.scaleMins(d.numeric);
            });

        this.hourArc = d3.arc()
            .innerRadius(0)
            .outerRadius(25)
            .startAngle(function (d) {
                return this.scaleHours(d % 12);
            })
            .endAngle(function (d) {
                return this.scaleHours(d % 12);
            });
    }

    componentDidMount() {
        // On mount, we setup our d3 clock
        let width, height, offSetX, offSetY;
        width = 130;
        height = 130;
        offSetX = 65;
        offSetY = 65;
        const svg = d3.select(this.clockRef.current)
            .append("svg:svg")
            .attr("width", width)
            .attr("height", height);

        // Drop shadow
        const dropShadow = {
            'stdDeviation': 2,
            'dx': 1,
            'dy': 3,
            'slope': 0.5,
            'type': 'linear'
        };

        const filter = svg.append('defs')
            .append('filter')
            .attr('id', 'activeDropShadow')
            .attr('filterUnits', 'userSpaceOnUse')
            .attr('height', '300%')
            .attr('y', '-150px')
            .attr('width', '300%')
            .attr('x', '-150px');

        filter.append('feGaussianBlur')
            .attr('in', 'SourceAlpha')
            .attr('stdDeviation', parseInt(dropShadow.stdDeviation, 10));

        filter.append('feOffset')
            .attr('dx', parseInt(dropShadow.dx, 10))
            .attr('dy', parseInt(dropShadow.dy, 10));

        const feComponentTransfer = filter.append('feComponentTransfer');
        feComponentTransfer
            .append('feFuncA')
            .attr('type', dropShadow.type)
            .attr('slope', parseFloat(dropShadow.slope));

        const feMerge = filter.append('feMerge');
        feMerge.append('feMergeNode');
        feMerge.append('feMergeNode').attr('in', 'SourceGraphic');

        // Here we start setting up our clock svg
        this.clockGroup = svg.append("svg:g")
            .attr("transform", "translate(" + offSetX + "," + offSetY + ")");

        // Clock face
        this.clockGroup.append("svg:circle")
            .attr("r", 55).attr("fill", "#6D7B9E")
            .attr("class", "clock outercircle")
            .attr("stroke", "#475174")
            .attr("stroke-width", 12);

        // Circle in the middle of the clock
        this.clockGroup.append("svg:circle")
            .attr("r", 2)
            .attr("fill", "black")
            .attr("class", "clock innercircle");

        // Add ticks at sides of clock
        for (let i = 0; i < 60; i = i + 15) {
            const tickLength = 4;
            svg.append("line")
                .attr("class", "hourtick face")
                .attr("x1", offSetX)
                .attr("y1", 8)
                .attr("x2", offSetY)
                .attr("y2", 8 + tickLength)
                .attr("stroke", "black")
                .attr("stroke-width", 2)
                .attr("transform", "rotate(" + i * 6 + "," + offSetX + "," + offSetY + ")")
        }

        this.renderClockHands();
    }

    // We use shouldComponentUpdate to re-run our renderClockHands function with fresh data
    // and we also prevent React from re-rendering the component since d3 is controlling the rendering
    shouldComponentUpdate(nextProps) {
        this.timeData[0].numeric = nextProps.minute;
        this.timeData[1].numeric = nextProps.hour;
        this.renderClockHands();
        // prevent React re-render
        return false;
    }

    renderClockHands() {
        const minute = this.timeData[0].numeric;
        // Remove all of the current clockhands so we can add new ones
        this.clockGroup.selectAll(".clockhand").remove();
        this.clockGroup.selectAll(".clockhand")
            .data(this.timeData)
            .enter()
            .append("svg:path")
            .attr("d", (d) => {
                if (d.unit === "minutes") return this.minuteArc(d);
                return this.hourArc(d.numeric + (minute / 60));
            })
            .attr("class", "clockhand")
            .attr("stroke", "black")
            .attr("stroke-width", 5)
            .attr("stroke-linejoin", "round")
            .attr("fill", "none")
            .attr('filter', 'url(#activeDropShadow)');
    }

    render() {
        return (
            <div className='analog-clock' ref={this.clockRef} ></div>
        );
    }
}

AnalogClock.propTypes = {
    minute: PropTypes.number,
    hour: PropTypes.number
}

export default AnalogClock;


