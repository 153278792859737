import { request, config, combineUrl } from '~/common';

const tripRequest = (params, callback, action) => {
    return request(combineUrl(config.obtApiUrl, `Trip/V1/json/${action}`), params, callback);
}

export function searchBookingOverview(params, callback) {
    return tripRequest(params, callback, 'SearchBookingOverview');
}

export function searchUpcomingTrip(params, callback) {
    return tripRequest(params, callback, 'SearchUpcomingTrip');
}

export function searchTripOverview(params, callback) {
    return tripRequest(params, callback, 'SearchTripOverview');
}

export function searchTravelerTracker(params, callback) {
    return tripRequest(params, callback, 'SearchTravelerTracker');
}

export function searchTravelerTrackerDetail(params, callback) {
    return tripRequest(params, callback, 'SearchTravelerTrackerDetail');
}

export function searchItineraryDetail(params, callback) {
    return tripRequest(params, callback, 'SearchItineraryDetail');
}