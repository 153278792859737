import React, { Fragment } from 'react';
import * as PropTypes from 'prop-types';
import BaseWidget from '../BaseWidget';
import { Loading, RecordNotFound } from '~/components';
import { FormattedMessage } from 'react-intl';
import { BookingType, WidgetType } from '~/common';
import { Tooltip, Row, Col } from 'antd';

class MyTrips extends React.Component {

    renderTripOverviews = (overviews) => {
        return <div className="new-trip-item">
            <div className='trip-summaries overviews'>
                <div className="content">
                    {overviews.map((overview, index) => {
                        const showPointer = this.props.enableAsiaLightning && overview.MainBkgRef && overview.MainBkgRef.trim() !== '';
                        return <Fragment key={index}>
                            <div className='summary-item airfare'>
                                <div className='flight-segments'>
                                    <div className={`segment-item box${showPointer ? ' pointer' : ''}`} onClick={() => this.props.handleTripClick({ MainBkgRef: overview.MainBkgRef })}>
                                        <div className="item-top item-list">
                                            <div className="trip-name" dangerouslySetInnerHTML={{ __html: overview.TripName }}></div>
                                            <Tooltip title={overview.Travelers}>
                                                <span className="trip">{overview.Travelers}</span>
                                            </Tooltip>
                                        </div>
                                        <div className="item-list">
                                            <Row gutter={8} type="flex" align="middle" justify="space-between">
                                                <Col span={4}>
                                                    <span className={`type-logo plane ${overview.IncludeBookingTypes && overview.IncludeBookingTypes.includes(BookingType.Airfare) && 'span-active'}`}></span>
                                                    <span className={`type-logo hotel ${overview.IncludeBookingTypes && overview.IncludeBookingTypes.includes(BookingType.Hotel) && 'span-active'}`}></span>
                                                </Col>
                                                <Col span={10} className="line-normal">
                                                    <div>
                                                        <span className="sm-txt"><FormattedMessage id='order_no' /></span>
                                                        <span>{overview.MainBkgRef}</span>
                                                    </div>
                                                </Col>
                                                <Col span={10} className="status-box">
                                                    <div>
                                                        <span className="sm-txt"><FormattedMessage id='status' /></span>
                                                        <span className="finalize">{overview.MainRequestStatusRemark}</span>
                                                    </div>
                                                </Col>
                                            </Row>
                                            {overview.Deadline && overview.Deadline.trim() !== '' && <Row gutter={8} type="flex" align="middle" className="deadline">
                                                <Col span={4}></Col>
                                                <Col span={20} className="line-normal">
                                                    <span className="sm-txt"><FormattedMessage id='deadline' /></span>
                                                    <span className='red'>{overview.Deadline}</span>
                                                </Col>
                                            </Row>}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Fragment>
                    })}
                </div>
            </div>
        </div>
    }

    render() {
        const { loading, data, error, widgetType } = this.props;
        if (loading || error) {
            return <Loading error={error} style={{ color: 'white' }} />;
        } else if (!data || !data.length) {
            const notFoundMessageId = widgetType === WidgetType.PendingForApproval ? 'no_booking_for_approval' : widgetType === WidgetType.DeadlineAlert ? 'no_booking_be_alert' : widgetType === WidgetType.UpcomingTrips ? 'no_booking_for_upcoming' : 'record_not_found';
            return <RecordNotFound formatId={notFoundMessageId} />
        } else {
            return this.renderTripOverviews(data);
        }
    }
}

MyTrips.propTypes = {
    //whether show loading animation
    loading: PropTypes.bool,
    //trip data which fetch from api
    data: PropTypes.array,
    //network error or server crash
    error: PropTypes.object,
    //trip item user click callback
    handleTripClick: PropTypes.func.isRequired,
    //widget type
    isWidgets: PropTypes.bool.isRequired,
    widgetType: PropTypes.oneOf([WidgetType.PendingForApproval, WidgetType.DeadlineAlert]).isRequired,
    title: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    viewCompanies: PropTypes.array.isRequired,
    companyChange: PropTypes.func.isRequired,
    enableAsiaLightning: PropTypes.bool,
}

export default BaseWidget(MyTrips);