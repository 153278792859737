import { Languages, config } from '~/common';
import { PREFIX, ACCESS_TOKEN } from '~/common/Auth';
import { ThemeType } from '../constants';
import CryptoJS from 'crypto-js';

let AutoClearCacheKeys = [];
function getCacheKey(key, encrypt = false, autoClear = true) {
    const cacheKey = `${PREFIX}_${key}${encrypt ? '_encToken' : ''}`;
    if (autoClear && !AutoClearCacheKeys.includes(cacheKey)) AutoClearCacheKeys.push(cacheKey);
    return cacheKey;
}

const USER_SETTING_KEY = getCacheKey('userSetting', false, false);
const PROFILE_TOKEN_KEY = getCacheKey('profile_token');
const USER_INFO_KEY = getCacheKey('user_info', true);
const AUTHENTICATED_LOCATION_HASH = getCacheKey('authenticated_location_hash');
const APP_VERSION = getCacheKey('app_version', false, false);
const SESSION_TIMEOUT_MINUTES = getCacheKey('sess_timeout_mins');
const PROFILE_MANAGE_SCRIPT_URL = getCacheKey('pm_url');
const LOGIN_REDIRECT = getCacheKey('login_redirect', false, false);
const OBT_API_ACCESS_TOKEN_KEY = getCacheKey('service_access_token');
const PTA_EMAIL_TOKEN_KEY = getCacheKey('pta_email_token');
const ENCRYPT_KEY = '12345678';

const DesEncrypt = str => {
    try {
        const ciphertext = CryptoJS.DES.encrypt(typeof str === 'string' ? str : JSON.stringify(str), ENCRYPT_KEY).toString();
        return ciphertext;
    } catch (ex) {
        config.isDebug && console.log(ex);
        return str;
    }
}

const DesDecrypt = ciphertext => {
    try {
        var bytes = CryptoJS.DES.decrypt(ciphertext, ENCRYPT_KEY);
        var decryptedData = bytes.toString(CryptoJS.enc.Utf8)
        return decryptedData;
    }
    catch (ex) {
        config.isDebug && console.log(ex);
        return null;
    }
}

export const setCache = (key, value, timeoutMins = null) => {
    if (!key) return;
    const encrypt = key.endsWith('_encToken');
    let str = value == null ? '' : encrypt ? DesEncrypt(value) : typeof value === 'string' ? value : JSON.stringify(value);
    if (str && timeoutMins && !isNaN(Number(timeoutMins))) {
        const expire = new Date().getTime() + Number(timeoutMins) * 60 * 1000;
        str += ';expire=' + expire;
    }
    localStorage.setItem(key, str);
}

export const getCache = (key) => {
    if (!key) return null;
    let str = localStorage.getItem(key);
    if (!str) return '';
    if (str.indexOf(';expire=') > -1) {
        const expire = str.split(';expire=')[1];
        if (expire < new Date().getTime()) {
            return '';
        }
        str = str.split(';expire=')[0];
    }
    const encrypt = key.endsWith('_encToken');
    return encrypt ? DesDecrypt(str) : str;
}

export const getCacheJSON = (key, defaultV = null) => {
    const jsonStr = getCache(key);
    if (jsonStr) {
        try {
            return JSON.parse(jsonStr);
        } catch (e) {
            if (config && config.isDebug) {
                throw new Error(e);
            } else {
                console.error(e);
            }
            return defaultV;
        }
    }
    return defaultV;
}

export const clearCache = () => {
    AutoClearCacheKeys.forEach(key => localStorage.removeItem(key));
}

export const getCurrentLang = () => {
    let lang = Languages.en_us;
    if (config.enableMultiLanguage) {
        lang = getCacheUserSetting().Language;
        if (!lang) {
            const navLang = (navigator.language || navigator.browserLanguage || Languages.en_us).toLowerCase();
            if ([Languages.en_us, Languages.zh_cn, Languages.zh_tw].includes(navLang)) {
                lang = navLang;
            }
        }
    }
    return lang || Languages.en_us;
}
export const getAuth0CurrentLang = () => {
    let lang = Languages.en_us;
    if (config.enableAuth0MultiLanguage) {
        lang = getCurrentLang();
    }
    return lang;
}

export const setProfileToken = profileToken => setCache(PROFILE_TOKEN_KEY, profileToken);
export const getProfileToken = () => getCache(PROFILE_TOKEN_KEY);

export const getCacheUserInfo = () => getCacheJSON(USER_INFO_KEY);
export const setCacheUserInfo = userInfo => setCache(USER_INFO_KEY, userInfo);

export const isLightMode = () => [ThemeType.Breezy].includes(getCacheUserSetting().ThemeType);

export const getToken = () => getCache(ACCESS_TOKEN);

export const setCacheUserSetting = userSetting => setCache(USER_SETTING_KEY, userSetting);
export const getCacheUserSetting = () => getCacheJSON(USER_SETTING_KEY, { ThemeType: ThemeType.Galaxy });

export const getAuthenticatedLocationHash = () => getCache(AUTHENTICATED_LOCATION_HASH);
export const setAuthenticatedLocationHash = hash => setCache(AUTHENTICATED_LOCATION_HASH, hash);

export const getCacheAppVersion = () => getCache(APP_VERSION);
export const setCacheAppVersion = version => setCache(APP_VERSION, version);

export const getSessionTimeoutMinutes = () => getCacheJSON(SESSION_TIMEOUT_MINUTES, 20);
export const setSessionTimeoutMinutes = mins => setCache(SESSION_TIMEOUT_MINUTES, mins);

export const getProfileManageScriptUrl = () => getCache(PROFILE_MANAGE_SCRIPT_URL);
export const setProfileManageScriptUrl = url => setCache(PROFILE_MANAGE_SCRIPT_URL, url);

export const getLoginRedirect = () => getCache(LOGIN_REDIRECT);
export const setLoginRedirect = url => setCache(LOGIN_REDIRECT, url, 5);

export const setOBTApiAccessToken = token => setCache(OBT_API_ACCESS_TOKEN_KEY, token);
export const getOBTApiAccessToken = () => getCache(OBT_API_ACCESS_TOKEN_KEY);

export const setEmailToken = token => setCache(PTA_EMAIL_TOKEN_KEY, token);
export const getEmailToken = () => getCache(PTA_EMAIL_TOKEN_KEY);
