import { auth0Timeout } from '~/redux/application';
import { store } from '~/index.js';
import { config } from '../config';
import { ErrorCode } from '../constants';

export const handleErrors = (response) => {
    // If the status is a 401, our token in no longer valid and the user needs to log back in.
    if (response.status === 401 && store) store.dispatch(auth0Timeout());
    if (!response.ok || response.status !== 200) {
        const error = new Error(response.statusText);
        error.code = response.status;
        throw error;
    }
    return response;
}

export const getError = data => data && (data.status === -1 || data.Status === -1) ? (`${data.msg || data.Msg || 'error occurred'}${config.isDebug && (data.errorCode || data.ErrorCode) ? `(${data.errorCode || data.ErrorCode || ''})` : ''}`) : data && data instanceof Error ? (data.message) : typeof data === 'string' ? data : '';

export const handleProfileTokenExpired = (data) => {
    if (data && [data.errorCode, data.ErrorCode].includes(ErrorCode.TokenExpired) && store) store.dispatch(auth0Timeout());
}