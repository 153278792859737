import React, { Component, Fragment } from 'react';
import { genQRCode4MobileLogin, genAppDownloadUrlQRCode, deactivateQRCodeMobileLogin } from '~/client';
import { getProfileToken } from '~/common'
import { Loading } from '~/components'
import { Row, Col, Modal } from 'antd';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { updateQRCodeMsg, updateQueueNotifyKeys } from '~/redux/qrCode';

class QRCode extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            showDownloadQRCode: false,
            onLoadingUserId: {}
        };
    }
    componentDidMount() {
        this.mounted = true;
        this.handleGenQRCode4MobileLogin();
        this.props.updateQRCodeMsg({ qrCodeComponentDidMount: true })
    }

    componentWillUnmount() {
        this.mounted = false;
        this.props.updateQRCodeMsg({ qrCodeComponentDidMount: false })
    }

    handleGenQRCode4MobileLogin() {
        this.mounted && this.setState({ disabledQrCode: true });
        genQRCode4MobileLogin({ ProfileToken: getProfileToken(), IsOnlyQRCode: false }, (err, data) => {
            this.mounted && this.setState({
                loading: false,
                disabledQrCode: false
            })
            this.props.updateQRCodeMsg({ ...data, error: err, refreshQRCode: false });
            data.RequestNotifyKey && this.props.updateQueueNotifyKey([...this.props.qrCodeMsg.asyncOperationQueueKeys, data.RequestNotifyKey])
        })
    }

    handleGenAppDownloadUrlQRCode() {
        genAppDownloadUrlQRCode({ ProfileToken: getProfileToken() }, (err, data) => {
            this.mounted && this.setState({
                disabledQrCode: false
            })
            this.props.updateQRCodeMsg({ ...data, error: err });
        })
    }

    handleDeactivateQRCodeMobileLogin({ PlatformUserId, PlatformCode }) {
        const onLoadingUserId = { ...this.state.onLoadingUserId };
        onLoadingUserId[PlatformUserId] = true;
        this.setState({ onLoadingUserId });
        deactivateQRCodeMobileLogin({
            ProfileToken: getProfileToken(),
            PlatformUserId,
            PlatformCode
        }, (err, data) => {
            this.props.updateQRCodeMsg({ ...data, error: err });
            onLoadingUserId[PlatformUserId] = false;
            this.setState({ onLoadingUserId });
        })
    }

    showDownloadQRCode(isShow) {
        this.setState({
            disabledQrCode: true,
            showDownloadQRCode: isShow
        })
        isShow ? this.handleGenAppDownloadUrlQRCode() : this.handleGenQRCode4MobileLogin();
    }

    render() {
        let { LoginPlatformInfos, QRCodeImageUrl, error, Status, Msg, refreshQRCode, platformInfosLoading } = this.props.qrCodeMsg;
        if (this.state.loading && Status != 0 && !Msg) return <Loading error={error} style={{ position: 'fixed' }} />;
        return <Modal
            visible={true}
            onCancel={this.props.onCancel}
            footer={null}
            closable={true}
            centered={true}
            destroyOnClose={true}
            width="auto"
            className="qrcode-modal"
        >
            <div className="qrcode">
                {(() => {
                    if (this.state.loading) return <Loading error={error} />;
                    if (Status === 0) {
                        return <div className="qrcode-content">
                            <div className="qr-img-box">
                                <img src={QRCodeImageUrl} alt='' />
                                {refreshQRCode && <span className="qr-refresh" onClick={() => this.handleGenQRCode4MobileLogin()}></span>}
                                {this.state.disabledQrCode && <span className="img-loading"></span>}
                            </div>
                            {!refreshQRCode && <Fragment>
                                {this.state.showDownloadQRCode ? <Fragment>
                                    <div className="mobile-download"><FormattedMessage id="scan_qr_code_to_download_app" /></div>
                                    <div className="cl-b txt-center mobile-download"><span onClick={() => this.showDownloadQRCode(false)}><FormattedMessage id="download_app_already_login_now" /></span></div>
                                </Fragment> : <Fragment>
                                    <div className="mobile-download"><FormattedMessage id="mobile_phone_login" /></div>
                                    <div className="mobile_dv mobile-download"><span onClick={() => this.showDownloadQRCode(true)}><FormattedMessage id="download_app" tagName="label" /></span></div>
                                    <div className="mobile-download"><FormattedMessage id="login_ctm_lightning_mobile_app" /></div>
                                </Fragment>}
                                <div className="sch_login_platform">
                                    <ul >
                                        {platformInfosLoading && <Loading spinStyle={{ "color": "#ccc" }} style={{ "backgroundColor": "none" }} />}
                                        {LoginPlatformInfos && LoginPlatformInfos.map((platform, index) =>
                                            <li key={index}>
                                                <Row type="flex" align="middle" justify="space-between">
                                                    <Col>
                                                        <div>{platform.PlatformName}</div>
                                                        <div>{platform.CreateOnRemark}</div>
                                                    </Col>
                                                    <Col>
                                                        {this.state.onLoadingUserId[platform.PlatformUserId] ? <span className="cl-b loading"></span> :
                                                            <span className="cl-b" onClick={() => this.handleDeactivateQRCodeMobileLogin(platform)}>
                                                                <FormattedMessage id="deactivate" />
                                                            </span>
                                                        }
                                                    </Col>
                                                </Row>
                                            </li>
                                        )}
                                    </ul>
                                </div>
                            </Fragment>}
                        </div>;
                    }
                    return <div className="txt-center">{Msg}</div>;
                })()}
            </div>
        </Modal>
    }
}
export default connect(state => ({
    qrCodeMsg: state.qrCode,
}), dispatch => ({
    updateQRCodeMsg: payload => dispatch(updateQRCodeMsg(payload)),
    updateQueueNotifyKey: payload => dispatch(updateQueueNotifyKeys(payload))
}))(QRCode) 