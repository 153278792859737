import {
    getLoginProfileInvoked,
    getLoginProfileSuccess,
    getLoginProfileFailed,
    GET_LOGIN_PROFILE,
    GET_LOGIN_PROFILE_SUCCESS,
} from '../loginProfile';
import { put, takeLatest, call } from 'redux-saga/effects';
import { getLoginProfile as getLoginProfileAPI } from '~/client';
import {
    setProfileToken,
    getError,
    getProfileToken,
    setCacheUserInfo,
    setWidgetDefaults,
    setCacheUserSetting,
    setSessionTimeoutMinutes as setCacheSessionTimeoutMinutes,
    setProfileManageScriptUrl as setCacheProfileManageScriptUrl,
    setOBTApiAccessToken,
    setEmailToken,
    CTMPortalSystemType,
    config
} from '~/common';
import { updateUserSetting } from '~/redux/userSetting';
import { updateUserInfo } from '~/redux/user';
import {
    setSessionTimeoutMinutes as setStoreSessionTimeoutMinutes,
    setProfileManageScriptUrl as setStoreProfileManageScriptUrl,
} from '~/redux/application';

function* getLoginProfileHandler() {
    try {
        yield put(getLoginProfileInvoked());
        const data = yield call(getLoginProfileAPI, { profileToken: getProfileToken(), systemType: CTMPortalSystemType });
        const errMsg = getError(data);
        if (errMsg) {
            yield put(getLoginProfileFailed(errMsg));
        } else {
            yield put(getLoginProfileSuccess(data));
        }
    } catch (ex) {
        yield put(getLoginProfileFailed(ex.message));
    }
}

function* getLoginProfileSuccessHandler(action) {
    try {
        const data = action.payload;
        setEmailToken(data.EmailToken);
        setProfileToken(data.ProfileToken);
        data.UserSetting.Widgets = setWidgetDefaults(data.UserSetting.Widgets);
        setCacheUserSetting(data.UserSetting);
        let userInfo = {
            name: data.UserName.DisplayName,
            nameZhTw: data.UserName.DisplayNameZhTw,
            nameZhCn: data.UserName.DisplayNameZhCn,
            viewCompanies: data.ViewCompanies || [],
            email: data.Email,
            loginId: data.LoginId,
            moduleRights: data.ModuleRights,
            enableLoginByQRCode4Mobile: data.EnableLoginByQRCode4Mobile,
            travelerId: data.TravelerId,
            shortName: data.ShortName,
            ctmScoutUserInfo: data.CtmScoutUserInfo,
        };
        setCacheUserInfo(userInfo);
        setCacheSessionTimeoutMinutes(data.SessionTimeoutMinutes);
        setCacheProfileManageScriptUrl(data.ProfileManageScriptUrl);
        setOBTApiAccessToken('');
        yield put(updateUserSetting(data.UserSetting));
        yield put(updateUserInfo(userInfo));
        yield put(setStoreSessionTimeoutMinutes(data.SessionTimeoutMinutes));
        yield put(setStoreProfileManageScriptUrl(data.ProfileManageScriptUrl));
    } catch (ex) {
        console.error(ex);
    }
}

export default function* () {
    yield takeLatest(GET_LOGIN_PROFILE, getLoginProfileHandler);
    yield takeLatest(GET_LOGIN_PROFILE_SUCCESS, getLoginProfileSuccessHandler);
}