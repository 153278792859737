import appLocaleData from 'react-intl/locale-data/en';
import messages from './messages.js';
import antd from 'antd/lib/locale-provider/en_US';

export default {
    // 合并所有 messages，加入 antd 组件的 messages
    messages: {
        ...messages,
    },

    antd: antd,

    // locale
    locale: 'en-US',

    // react-intl locale-data
    data: appLocaleData,
};