import { request, combineUrl, config } from '~/common';

const newsRequest = (params, callback, action) => {
    return request(combineUrl(config.obtApiUrl, `News/V1/json/${action}`), params, callback);
}

export function searchNewsContent(params, callback) {
    return newsRequest(params, callback, 'SearchNewsContent');
}

export function readNewsContent(params, callback) {
    return newsRequest(params, callback, 'ReadNewsContent');
}

export function getNewsAttachment(params, callback) {
    return newsRequest(params, callback, 'GetNewsAttachment');
}
