import React, { Fragment } from 'react';
import { Layout } from 'antd';
import * as PropTypes from 'prop-types';
import { Footer } from '~/components';
import { getImageUrl, config } from '~/common';
import { Link } from 'react-router-dom';
import { getContent4CTMPortal } from '~/client';
import { Loading } from '~/components';


class ContentView extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            content: '',
            loading: false,
        };
    }

    componentDidMount() {
        if (this.props.category && this.props.keyword) {
            const rq = {
                Token: config.obtApiToken,
                Category: this.props.category,
                Keyword: this.props.keyword,
            };
            this.setState({ loading: true });
            getContent4CTMPortal(rq, (err, data) => {
                this.setState({ loading: false });
                if (data && data.Content) {
                    this.setState({ content: data.Content });
                }
            });
        }
    }

    render() {
        return (
            <Layout className='smart-layout smart-view-layout' style={{ minHeight: '100vh' }}>
                <Layout.Header className='header ctm-header'>
                    <div className="ctm-header-logo"><Link to='/'><img src={getImageUrl('ctm_portal_logo.svg')} alt="" /></Link></div>
                </Layout.Header>
                {this.state.loading ? <Layout.Content className='smart-view-content'>
                    <Loading></Loading>
                </Layout.Content> : <Layout.Content className='smart-view-content' dangerouslySetInnerHTML={{ __html: this.state.content }}></Layout.Content>}
                <Footer showContactUs={false} />
            </Layout>
        );
    }
}


ContentView.propTypes = {
    category: PropTypes.string.isRequired,
    keyword: PropTypes.string.isRequired,
}

export default ContentView;
