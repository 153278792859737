import React from 'react';
import { AutoComplete } from 'antd';
import { searchCity } from '~/client';
import { debounce, includesSpecialCharacter, getValue4Lang } from '~/common';
import * as PropTypes from 'prop-types';

const Option = AutoComplete.Option;

class CityComplete extends React.Component {
    constructor() {
        super();
        this.state = {
            dataSource: [],
            displayName: '',
            code: '',
            isSchByAirport: false,
        };
        this.handleSearch = debounce(this.handleSearch, 300);
    }

    componentDidMount() {
        this.mounted = true;
        const cityInfo = this.props.defaultValue;
        cityInfo && cityInfo.code && searchCity(cityInfo.code + '|', null, (err, data) => {
            if (data && data.datas && data.datas.length) {
                let item = data.datas[0];
                if (item.type !== (cityInfo.isSchByAirport ? 0 : 1)) {
                    if (item.datas && item.datas.length) {
                        item = item.datas[0];
                    }
                }
                this.mounted && this.setState({ displayName: this.getDisplayName(item), code: item.code, isSchByAirport: item.type === 0 });
            }
        });
        this.props.allowFreeInput && cityInfo && !cityInfo.code && cityInfo.displayName && this.setState({ displayName: cityInfo.displayName, code: '', isSchByAirport: false });
    }

    componentWillUnmount() {
        this.mounted = false;
    }

    componentWillUpdate() {
        this.previousDefaultValue = this.props.defaultValue;
    }

    componentDidUpdate() {
        const prevCityInfo = this.previousDefaultValue;
        const cityInfo = this.props.defaultValue;
        if ((prevCityInfo && cityInfo && prevCityInfo.code === cityInfo.code && prevCityInfo.isSchByAirport === cityInfo.isSchByAirport) || (cityInfo && cityInfo.code === this.state.code && cityInfo.isSchByAirport === this.state.isSchByAirport)) return;
        cityInfo && cityInfo.code && searchCity(cityInfo.code + '|', null, (err, data) => {
            if (data && data.datas && data.datas.length) {
                let item = data.datas[0];
                if (item.type !== (cityInfo.isSchByAirport ? 0 : 1)) {
                    if (item.datas && item.datas.length) {
                        item = item.datas[0];
                    }
                }
                this.mounted && this.setState({ displayName: this.getDisplayName(item), code: item.code, isSchByAirport: item.type === 0 });
            }
        });
        !this.props.allowFreeInput && (!cityInfo || !cityInfo.code) && setTimeout(() => { this.setState({ displayName: '', code: '', isSchByAirport: false }) });
    }

    getDisplayName = item => `${getValue4Lang(item.name, item.name_zh_cn, item.name_zh_tw)}(${item.code})`;

    handleSearch = val => {
        this.searchIndex = (this.searchIndex || 0) + 1;
        const currentSearchIndex = this.searchIndex;
        searchCity(val, null, (err, data) => {
            if (currentSearchIndex != this.searchIndex) return;
            this.dataSourceMatchIndex = currentSearchIndex;
            let dataSource = [];
            if (data && data.datas) {
                data.datas.forEach(item => {
                    dataSource.push({ ...item, query: val });
                    item.datas && item.datas.forEach(subItem => {
                        dataSource.push({ ...subItem, query: val, isSubItem: true });
                    });
                });
            }
            this.mounted && this.setState({ dataSource });
        });
    }

    renderOption = item => {
        const allMessage = this.props.intl.formatMessage({ id: 'all' });
        let query = item.query;
        let infoName = `${item.name} (${item.code})${item.type === 1 ? ` (${allMessage})` : ''}`;
        let displayName = getValue4Lang(item.name, item.name_zh_cn, item.name_zh_tw);
        if (query && query.trim() && !includesSpecialCharacter(query)) {
            infoName = infoName.replace(new RegExp(query, 'ig'), m => {
                return `<span class='matched'>${m}</span>`;
            });
            displayName = displayName.replace(new RegExp(query, 'ig'), m => {
                return `<span class='matched'>${m}</span>`;
            });
        }
        return (
            <Option
                key={`${item.code}-${item.type}`}
                text={this.getDisplayName(item)}
                className={`sg-option ${item.type === 1 ? 'city' : 'airport'} ${item.isSubItem ? 'sub' : ''} ${item.code}`}
            >
                <span dangerouslySetInnerHTML={{ __html: infoName }}></span>
                <span className='sg-option-country'>{getValue4Lang(item.country_name, item.country_name_zh_cn, item.country_name_zh_tw)}</span>
                <span dangerouslySetInnerHTML={{ __html: displayName }}></span>
            </Option>
        );
    }

    render() {
        const { className, style, onSelect, inputId, placeholder, allowFreeInput } = this.props;
        const { displayName, dataSource, code } = this.state;
        return (
            <AutoComplete
                value={displayName}
                className={className}
                style={style}
                dataSource={dataSource.map(this.renderOption)}
                onSelect={(val, opt) => {
                    this.setState({ displayName: opt.props.text, code: val.split('-')[0], isSchByAirport: val.split('-')[1] === '0' });
                    onSelect(val.split('-')[0], val.split('-')[1] === '0', opt.props.text);
                }}
                onFocus={() => {
                    document.getElementById(inputId).select();
                    this.handleSearch(code || displayName);
                }}
                onBlur={() => {
                    const searching = this.dataSourceMatchIndex != this.searchIndex;
                    if (!code && displayName && dataSource && dataSource.length > 0 && !searching) {
                        const firstItem = {
                            code: dataSource[0].code,
                            displayName: this.getDisplayName(dataSource[0]),
                            isSchByAirport: dataSource[0].type === 0,
                        };
                        this.setState({ ...firstItem });
                        onSelect(firstItem.code, firstItem.isSchByAirport, firstItem.displayName);
                    } else if (!code) {
                        this.setState({ code: '', displayName: allowFreeInput ? displayName : '', isSchByAirport: false });
                        onSelect('', false, allowFreeInput ? displayName : '');
                    }
                    this.setState({ dataSource: [] });
                }}
                onSearch={val => {
                    this.setState({ displayName: val, code: '', isSchByAirport: false });
                    this.handleSearch(val);
                }}
                placeholder={placeholder}
                optionLabelProp="text"
                dropdownMatchSelectWidth={false}
                dropdownClassName="ctm-select-dropdown"
            >
                <input type='text' id={inputId} autoComplete='off' />
            </AutoComplete>
        );
    }
}
CityComplete.propTypes = {
    onSelect: PropTypes.func.isRequired,
    inputId: PropTypes.string.isRequired,
    allowFreeInput: PropTypes.bool,
}

export default CityComplete;