import React from 'react';
import { Checkbox, Switch } from 'antd';
import { RiskLevel } from '~/common';

function GeoRiskToggle({ riskLevels, riskOn, setRiskLevels, setRiskOn, intl }) {
    return (
        <div className="georisk-toggle">
            <div>
                <h6>{intl.formatMessage({ id: 'georisk_map' })}</h6>
                <Switch checked={riskOn} on={intl.formatMessage({ id: 'on' })} off={intl.formatMessage({ id: 'off' })} onChange={setRiskOn} checkedChildren={intl.formatMessage({ id: 'on' })} unCheckedChildren={intl.formatMessage({ id: 'off' })} />
            </div>
            {riskOn && <div className='checkbox-group'>
                <h6>{intl.formatMessage({ id: 'risk_level_up' })}</h6>
                {Object.keys(RiskLevel).map((ele, index) =>
                    <Checkbox
                        key={index}
                        checked={ele === 'Any' ? false : riskLevels[RiskLevel[ele]]}
                        className={`georisk-checkbox severe status status-${RiskLevel[ele]}`}
                        onClick={() => setRiskLevels(RiskLevel[ele])}
                        disabled={ele === 'Any' ? true : false}
                    >
                        {ele === 'Any' ? 'UNKNOWN' : ele}
                    </Checkbox>
                )}
            </div>}
        </div>
    )
}

export default GeoRiskToggle;