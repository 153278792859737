//#region action type
export const GET_USER_DATA = 'GET_USER_DATA';
export const GET_USER_DATA_INVOKED = 'GET_USER_DATA_INVOKED';
export const GET_USER_DATA_SUCCESS = 'GET_USER_DATA_SUCCESS';
export const GET_USER_DATA_FAILED = 'GET_USER_DATA_FAILED';
//#endregion

//#region action creator
export const getUserData = () => ({ type: GET_USER_DATA });
export const getUserDataInvoked = () => ({ type: GET_USER_DATA_INVOKED });
export const getUserDataSuccess = payload => ({ type: GET_USER_DATA_SUCCESS, payload });
export const getUserDataFailed = payload => ({ type: GET_USER_DATA_FAILED, payload });
//#endregion

//#region reducer
const initState = {
    loading: false,
    error: null,
    DefaultDepartureCity: '',
    CompanyLogoUrl: '',
    CompanyLogoSize: '',
}

const userData = (state = initState, action) => {
    switch (action.type) {
        case GET_USER_DATA_INVOKED:
            return {
                loading: true,
            };
        case GET_USER_DATA_SUCCESS:
            return {
                ...state,
                loading: false,
                error: null,
                ...action.payload,
            };
        case GET_USER_DATA_FAILED:
            return {
                loading: false,
                error: action.payload,
            };
        default:
            return state
    }
}
//#endregion

export default userData;
