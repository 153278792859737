import React, { Fragment } from 'react';
import { Loading } from '~/components';
import { FormattedMessage } from 'react-intl';
import { getValue4Lang } from '~/common';

function RiskCardCollection({ riskAlerts = [], toggleRiskCollection, riskAlertsTotal, clickCountryName, loading, intl }) {
    const renderCards = () => {
        const countryName = getValue4Lang(clickCountryName.name_en, clickCountryName.name_zh_cn, clickCountryName.name_zh_tw);
        return <div className="flight-card-content scrollbar-none">
            <span className="close" onClick={() => toggleRiskCollection(false)}></span>
            <div className='title'>
                <FormattedMessage id="risk_alert_fmt" values={{ count: riskAlertsTotal }} />
                <div className="country" title={countryName}>{countryName}</div>
            </div>
            <div className="content travel-itineraries show-indicator risk-content">
                <div className="country-list">
                    {riskAlerts.map((news, index) => <div className="risk" key={index}>
                        <div className="risk-header">
                            <span className="date" title={news.StartDateRemark}>{news.StartDateRemark}</span>
                            <span className={`status status-${news.RiskLevel}`}>{news.RiskLevelRemark}</span>
                        </div>
                        <input type="checkbox" id={`risk-checkbox-${index}`} /><label className="content" readless={intl.formatMessage({ id: "read_less" })} readmore={intl.formatMessage({ id: "read_more" })} htmlFor={`risk-checkbox-${index}`}>{news.Body}</label>
                    </div>
                    )}
                </div>
            </div>
        </div>
    }
    return <div className='trip-collection'>
        {loading ? <Loading /> : <Fragment>
            {renderCards(riskAlerts)}
        </Fragment>
        }
    </div>;
}

export default RiskCardCollection;