import React from 'react';
import { injectIntl } from 'react-intl';
import { Modal } from 'antd';
import { isSupportFontFamily } from '~/common';

const getCommonProps = ({ style, maskStyle, className, okButtonProps, cancelButtonProps, footer, ...restProps } = {}, type) => {
    const isSupportSFProText = isSupportFontFamily('SF Pro Text');
    return {
        width: 550,
        style: {
            maxHeight: 520,
            padding: 0,
            ...style,
        },
        maskStyle: {
            backgroundColor: 'rgba(20, 27, 34, 0.9)',
            ...maskStyle,
        },
        className: ['ctm-modal', isSupportSFProText ? 'ctm-font-sf-pro-text' : '', (type == 'info' || (!type && typeof footer != 'undefined')) ? '' : 'ctm-modal-with-btn'].concat((className || '').split(' ').filter(Boolean)).join(' '),
        autoFocusButton: null,
        okButtonProps: {
            className: 'ctm-btn',
            ...okButtonProps,
        },
        cancelButtonProps: {
            className: 'ctm-btn ctm-btn-ghost',
            style: {
                color: 'var(--default-color)',
                backgroundColor: 'transparent',
                boxShadow: 'none',
            },
            ...cancelButtonProps,
        },
        footer,
        ...restProps,
    };
}

//https://company-199859.frontify.com/d/bun754Zanu7g/guidelines#/components/modal
class CTMModal extends React.Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        this.mounted = true;
    }

    componentWillUnmount() {
        this.mounted = false;
    }

    render() {
        return <Modal {...getCommonProps(this.props)}></Modal>;
    }
}

CTMModal.confirm = (props) => {
    return Modal.confirm(getCommonProps(props, 'confirm'));
}

CTMModal.info = (props) => {
    return Modal.info(getCommonProps(props, 'info'));
}

CTMModal.success = (props) => {
    return Modal.success(getCommonProps(props, 'success'));
}

CTMModal.warning = (props) => {
    return Modal.warning(getCommonProps(props, 'warning'));
}

CTMModal.error = (props) => {
    return Modal.error(getCommonProps(props, 'error'));
}

export default injectIntl(CTMModal);
