import React, { Component } from 'react';
import BaseWidget from '../BaseWidget';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import arrayMove from 'array-move';
import { Select } from 'antd'
import timezones from '~/common/data/timezones.js';
import AnalogClock from './AnalogClock';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import { animate, WidgetType } from '~/common';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl } from 'react-intl';
import { updateUserSettingSaga } from '~/redux/userSetting';
const { Option } = Select;


const SortableItem = SortableElement(({ clockItems, clock, itemIndex, mappedTimeZones, handleCancel, handleChange, removeClock, toggleEditMode, marginBottom, lastItem }) => {
    if (clock.inEditMode) {
        return (
            <li className='world-clock world-clock-edit' id={'world-clock' + itemIndex} key={itemIndex} style={lastItem ? { marginBottom: marginBottom } : {}}>
                <Select
                    getPopupContainer={() => document.getElementById('world-clock' + itemIndex)}
                    showSearch
                    style={{ width: '100%' }}
                    onBlur={() => { handleCancel(clock, itemIndex) }}
                    onChange={(val) => handleChange(val, itemIndex)}
                    {...clock.data.value ? { value: clock.data.value } : {}}
                    placeholder={<FormattedMessage id="choose_timezone" />}
                    optionFilterProp="children"
                    dropdownClassName="clock-dropdown"
                    size="large"
                    suffixIcon={React.createElement('span', { 'className': 'down' })}
                    filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                >
                    {mappedTimeZones.filter(item => !clockItems.includes(item.value) || clock.data.value === item.value).map(((ele, index) => <Option className="select-option" key={index} value={ele.value}>{`${ele.text.substring(0, ele.text.indexOf(' '))} ${ele.value}`}</Option>
                    ))}
                </Select>
                <div>
                    {clock.data.value && <button className="delete" style={{ marginTop: '15px' }} onClick={() => removeClock(itemIndex)}><FormattedMessage id="delete" /></button>}
                    <button className="cancel" style={{ marginTop: '15px', float: 'right' }} onClick={() => handleCancel(clock, itemIndex)}><FormattedMessage id="cancel" /></button>
                </div>
            </li>
        );
    }
    const offsetTime = moment.utc().tz(clock.data.value);
    return (
        <li className='world-clock world-clock-display' key={itemIndex} style={lastItem ? { marginBottom: marginBottom } : {}}>
            <AnalogClock hour={offsetTime.hour()} minute={offsetTime.minute()} />
            <div className='clock-details'>
                <h4>{offsetTime.format('hh:mm A')}</h4>
                <p>{clock.data.text}</p>
            </div>
            <div onClick={() => toggleEditMode(itemIndex)}>
                <button className='edit-clock-btn'><span className="down"></span></button>
            </div>
        </li>
    );
});

class MySortableContainer extends Component {
    constructor(props) {
        super(props);
        this.listRef = React.createRef();
        this.handleToggle = this.handleToggle.bind(this);
        this.marginBottom = '0px';
        this.scrollPosition = 0;
        this.scrollOnUpdate = false;
        this.scrollToTop = this.scrollToTop.bind(this);
    }

    componentDidUpdate() {
        this.marginBottom = '0px';
        if (this.scrollOnUpdate) {
            animate.scrollTo(this.listRef.current, this.scrollPosition, 400)
            this.scrollOnUpdate = false;
        }
        this.scrollPosition = 0;
    }

    scrollToTop() {
        animate.scrollToTop(this.listRef.current)
    }

    handleToggle(val, index) {
        this.scrollOnUpdate = true;
        this.scrollPosition = index > 0 ? (index * 155) : 0;
        if (index === this.props.items.length - 1) this.marginBottom = '320px';
        if (index === this.props.items.length - 2) this.marginBottom = '160px';
        this.props.toggleEditMode(val);
    }

    render() {
        const { items, handleChange, handleCancel, mappedTimeZones, removeClock } = this.props;
        let clockItems = items.map(ele => ele.data && ele.data.value);
        return (
            <ul className='clock-list' ref={this.listRef} >
                {items.map((clock, index) => {
                    return (
                        <SortableItem
                            clockItems={clockItems}
                            disabled={clock.inEditMode}
                            toggleEditMode={(val) => this.handleToggle(val, index)}
                            key={`item-${index}`}
                            index={index}
                            itemIndex={index}
                            clock={clock}
                            handleChange={handleChange}
                            handleCancel={handleCancel}
                            mappedTimeZones={mappedTimeZones}
                            removeClock={removeClock}
                            marginBottom={this.marginBottom}
                            lastItem={index === items.length - 1}
                        />
                    )
                })}
            </ul>
        );
    }
}
const SortableList = SortableContainer(MySortableContainer, { withRef: true });

class WorldClocks extends Component {
    constructor(props) {
        super(props);
        this.state = {
            clocks: this.props.clocks && this.props.clocks.length ? this.parseClocks(this.props.clocks) : [],
        };

        this.mappedTimeZones = timezones.map(zone => {
            if (zone.offset <= -10) return { ...zone, text: `(GMT-${Math.abs(zone.offset)}:00) ${zone.text}` };
            if (zone.offset < 0) return { ...zone, text: `(GMT-0${Math.abs(zone.offset)}:00) ${zone.text}` };
            if (zone.offset >= 10) return { ...zone, text: `(GMT+${zone.offset}:00) ${zone.text}` };
            return { ...zone, text: `(GMT+0${zone.offset}:00) ${zone.text}` };
        });

        this.addClock = this.addClock.bind(this);
        this.handleCancel = this.handleCancel.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.onSortEnd = this.onSortEnd.bind(this);
        this.removeClock = this.removeClock.bind(this);
        this.toggleEditMode = this.toggleEditMode.bind(this);
        this.timeout = null;
        this.interval = null;
        this.sortableListRef = React.createRef();
    }

    componentDidMount() {
        const secondsToNextMin = 60 - new Date().getSeconds();
        this.timeout = setTimeout(() => {
            if (this.state.clocks.length) this.forceUpdate();
            this.interval = setInterval(() => {
                if (this.state.clocks.length) this.forceUpdate();
            }, 60000);
        }, secondsToNextMin * 1000);
    }

    componentWillUnmount() {
        clearTimeout(this.timeout);
        clearInterval(this.interval);
    }

    addClock() {
        let clocks = [...this.state.clocks];
        if (clocks.some(ele => !ele.data.value)) return;
        this.sortableListRef.current.refs.wrappedInstance.scrollToTop();
        clocks.forEach((ele, idx) => ele.inEditMode = false);
        const newClock = {
            inEditMode: true,
            data: { value: null }
        };
        clocks.unshift(newClock);
        this.setState({ clocks });
    }

    handleCancel(clock, index) {
        if (clock.data.value) this.toggleEditMode(index);
        else this.removeClock(index);
    }

    handleChange(value, index) {
        const newClocks = [...this.state.clocks];
        const data = timezones.find(el => el.value === value);
        newClocks[index] = { ...newClocks[index], inEditMode: false, data };
        this.updateClocks(newClocks);
    }

    onSortEnd({ oldIndex, newIndex }) {
        const clocks = arrayMove(this.state.clocks, oldIndex, newIndex)
        this.updateClocks(clocks);
    }

    parseClocks(clockData) {
        const clocks = [];
        clockData.forEach(clock => {
            const data = timezones.find(el => el.value === clock);
            clock && clocks.push({ inEditMode: false, data });
        });
        return clocks;
    }

    removeClock(index) {
        const clocks = [...this.state.clocks];
        const value = clocks[index].data.value;
        clocks.splice(index, 1);
        value ? this.updateClocks(clocks) : this.setState({ clocks });
    }

    toggleEditMode(index) {
        const clocks = [...this.state.clocks];
        if (clocks.some(ele => !ele.data.value)) return;
        clocks.forEach((ele, idx) => ele.inEditMode = idx === index ? ele.inEditMode : false);
        clocks[index].inEditMode = !clocks[index].inEditMode;
        this.setState({ clocks });
    }

    updateClocks(clocks) {
        this.setState({ clocks });
        const widgets = [...this.props.widgets];
        const clocksWidget = widgets.find(el => el.WidgetType === WidgetType.WorldClock);
        clocksWidget.Attributes.clocks = clocks.map(clock => clock.data.value);
        this.props.updateWidgetSaga(widgets);
    }

    render() {
        return (
            <div className='world-clocks'>
                <div className='add-new-clock' onClick={this.addClock}>
                    <button>+</button>
                    <FormattedMessage id="add_new_clock" />
                </div>
                <SortableList
                    items={this.state.clocks}
                    mappedTimeZones={this.mappedTimeZones}
                    handleChange={this.handleChange}
                    handleCancel={this.handleCancel}
                    onSortEnd={this.onSortEnd}
                    distance={1}
                    removeClock={this.removeClock}
                    toggleEditMode={this.toggleEditMode}
                    ref={this.sortableListRef}
                />
            </div>
        );
    }
}

WorldClocks.propTypes = {
    clocks: PropTypes.array,
    widgets: PropTypes.array,
}

export default connect(state => ({
    widgets: state.userSetting.Widgets
}), dispatch => ({
    updateWidgetSaga: payload => dispatch(updateUserSettingSaga({ Widgets: payload }))
}))(BaseWidget(injectIntl(WorldClocks)));


