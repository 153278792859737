import $ from 'jquery';
import { START_HUB_CONNECTION, STOP_HUB_CONNECTION } from '../sso';
import { eventChannel, buffers } from 'redux-saga';
import { apply, call, take, put, fork, cancel, cancelled } from 'redux-saga/effects';
import { updateLoginPlatformInfos, updateQRCodeMsg } from '../qrCode';
import { getLoginPlatformInfo, exceptionLog } from '~/client';
import { config, getErrorParams, getCacheUserInfo, SendAsyncOperationDoneMessageType, getProfileToken } from '~/common';
import { store } from '~/index.js';
import { notifyStatus } from '../tripProposal';

window.$ = window.jQuery = require("jquery");
require('signalr')

function subscribeToUpdates(connection) {
    return eventChannel(emit => {
        function broadcastNotifyMessage(rs) {
            rs = JSON.parse(rs);
            if (rs.msgType === SendAsyncOperationDoneMessageType.qrCodeLogin) {
                let { asyncOperationQueueKeys = [], qrCodeComponentDidMount } = store.getState().qrCode;
                asyncOperationQueueKeys.includes(rs.optTransKey) && qrCodeComponentDidMount && emit(rs);
            } else if (rs.msgType === SendAsyncOperationDoneMessageType.tripProposal) {
                emit(rs)
            }
        }
        function unsubscribe() {
            connection.off('broadcastNotifyMessage');
        }
        connection.on('broadcastNotifyMessage', broadcastNotifyMessage);
        return unsubscribe;
    }, buffers.expanding());
}

function* bgSync() {
    let connection = $.hubConnection(config.signalRHubUrl, { useDefaultPath: false });
    let messageHubProxy = connection.createHubProxy('messageHub');
    const socketChannel = yield call(subscribeToUpdates, messageHubProxy);
    try {
        yield call([connection, connection.start], []);
        const userInfo = getCacheUserInfo();
        if (userInfo && userInfo.loginId) {
            yield apply(messageHubProxy, messageHubProxy.invoke, ['createGroup', 'BroadcastNotifyMessage-' + userInfo.loginId]);
            while (true) {
                const notify = yield take(socketChannel);
                console.groupCollapsed('BroadcastNotifyMessage-' + userInfo.loginId);
                console.log(notify);
                console.groupEnd();
                if (notify.msgType === SendAsyncOperationDoneMessageType.qrCodeLogin) {
                    if (notify.extraData.status === 0) {
                        let loginPlatformInfos = store.getState().qrCode.LoginPlatformInfos || [];
                        let updatePlatformUserId = notify.extraData.otherInfo.platformUserId;
                        if (loginPlatformInfos.some(ele => ele.PlatformUserId === updatePlatformUserId)) {
                            loginPlatformInfos.forEach(infos => {
                                if (infos.PlatformUserId === updatePlatformUserId)
                                    infos.CreateOnRemark = notify.extraData.otherInfo.lastLoginTime;
                            });
                        }
                        else {
                            yield put(updateLoginPlatformInfos({ platformInfosLoading: true }));
                            let payload = yield call(getLoginPlatformInfo, { profileToken: getProfileToken() });
                            loginPlatformInfos = payload ? payload.LoginPlatformInfos : loginPlatformInfos;
                        }
                        yield put(updateLoginPlatformInfos({ LoginPlatformInfos: loginPlatformInfos, platformInfosLoading: false }));
                    }
                    else {
                        yield put(updateQRCodeMsg({ refreshQRCode: true }));
                    }
                } else if (notify.msgType === SendAsyncOperationDoneMessageType.tripProposal) {
                    yield put(notifyStatus(notify.extraData));
                }
            }
        }
    } catch (e) {
        let params = getErrorParams(e, 'hubConnection error')
        exceptionLog(params);
        console.log('hubConnection error');
    } finally {
        if (yield cancelled()) {
            socketChannel.close();
            console.log('hubConnection stop');
            yield apply(connection, connection.stop, []);
        }
    };
}

export default function* () {
    while (yield take(START_HUB_CONNECTION)) {
        const bgSyncTask = yield fork(bgSync)
        yield take(STOP_HUB_CONNECTION)
        yield cancel(bgSyncTask)
    }
}