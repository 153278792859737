import React, { Fragment } from 'react';
import { Route, Switch } from 'react-router-dom';
import { PageNotFound, Loading } from '~/components';
import Loadable from 'react-loadable';
import { prepareMeta } from '~/pages/PreTrip/helpers';
import { getEmailToken } from '~/common';
import { prepareMetadata } from '~/pages/TripProposal/helpers';

// Have to create component for each import(...).  Webpack can't resolve import(varName), it needs a static string for it to resolve at compile-time.
const loaderOptions = {
    loading: props => props.pastDelay ? <Loading /> : null,
    delay: 500,
}
const Dashboard = Loadable({ ...loaderOptions, loader: () => import('../Dashboard') });

const FF = Loadable({ ...loaderOptions, loader: () => import('../FareForecaster') });

const TT = Loadable({ ...loaderOptions, loader: () => import('../TravelerTracker') });

const ContactUs = Loadable({ ...loaderOptions, loader: () => import('../ContactUs') });

const PreTrip = Loadable({ ...loaderOptions, loader: () => prepareMeta().then(errMsg => errMsg ? null : import('../PreTrip')) });
const PreTripDetail = Loadable({ ...loaderOptions, loader: () => prepareMeta().then(errMsg => errMsg ? null : import('../PreTrip/Detail')) });
const PreTripSubmit = Loadable({ ...loaderOptions, loader: () => prepareMeta().then(errMsg => errMsg ? null : import('../PreTrip/Submit')) });

const TripProposal = Loadable({ ...loaderOptions, loader: () => prepareMetadata().then(errMsg => errMsg ? null : import('../TripProposal')) });
const TripProposalDetail = Loadable({ ...loaderOptions, loader: () => prepareMetadata().then(errMsg => errMsg ? null : import('../TripProposal/Detail')) });

const Routes = ({ auth, showTravelerTracker, showFareForecaster, showContactUs, showPreTrip, showTripProposal }) =>

    <Switch>
        <Route path='/' exact component={Dashboard} />
        {showFareForecaster && <Route path='/fare-forecaster' exact component={FF} />}
        {showTravelerTracker && <Route path='/traveller-tracker' exact component={TT} />}
        {showContactUs && <Route path='/contact-us' exact component={ContactUs} />}
        {showPreTrip && <Route path='/pre-trip' exact component={PreTrip} />}
        {showPreTrip && <Route path='/pre-trip/new' exact component={PreTripSubmit} />}
        {showPreTrip && <Route path='/pre-trip/detail' exact component={PreTripDetail} />}
        {showTripProposal && <Route path='/trip-proposal' exact component={TripProposal} />}
        {(showTripProposal || getEmailToken()) && <Route path='/trip-proposal/detail' exact render={props => <TripProposalDetail auth={auth} {...props} />} />}
        <Route component={PageNotFound} />
    </Switch>;

export default Routes;
