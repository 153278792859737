import React from 'react';
import * as PropTypes from 'prop-types';
import { searchUpcomingTrip } from '~/client';
import { getProfileToken, WidgetType } from '~/common';
import { Loading, RecordNotFound, BaseWidget, TravelItineraries } from '~/components';
import { Pagination } from 'antd';
import { injectIntl } from 'react-intl';

const PAGESIZE = 5;

function renderUpcomingTrips(props) {
    const { loading, data, error, handleTripClick, enableAsiaLightning, intl } = props;
    let content = null;
    if (loading || error) {
        content = <Loading error={error} style={{ color: 'white' }} />;
    } else if (!data || !data.length) {
        content = <RecordNotFound formatId={'no_booking_for_upcoming'} />
    } else {
        content = <div className='new-trip-item'>
            <TravelItineraries
                intl={intl}
                travelSummaries={data}
                handleTripClick={() => void (0)}
                showActiveIndicator={false}
                detailWrapClassName='upcoming-trip-detail'
                handleViewInLightning={(bkgRef) => handleTripClick({ MainBkgRef: bkgRef })}
                stopDetailClickPropagation={true}
                enableAsiaLightning={enableAsiaLightning}
            />
        </div>;
    }
    return content;
}

class UpcomingTrips extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            total: 0,
            loading: true,
        }
    }
    componentDidMount() {
        this.mounted = true;
        this.fetchUpcomingTrips();
    }

    componentWillUnmount() {
        this.mounted = false;
    }

    fetchUpcomingTrips = ({ pageIndex = 1, pageSize = PAGESIZE, companyCode = this.state.companyCode || "" } = {}) => {
        this.setState({ loading: true, companyCode: companyCode, pageIndex: pageIndex });
        const upcomingParams = {
            profileToken: getProfileToken(),
            paging: {
                pageIndex,
                pageSize
            },
            ...(companyCode && { customerCodes: [companyCode] }) || {}
        }
        let trips = [], total = 0;
        searchUpcomingTrip(upcomingParams, (err, data) => {
            if (data && data.TravelSummaries) {
                data.TravelSummaries.forEach((summary, index) => {
                    summary.index = index;
                    trips.push(summary);
                });
                total = data.Total;
            }
            this.mounted && this.setState({ data: trips, loading: false, error: err, total: total });
        });
    }

    onChange = (pageIndex, pageSize) => {
        this.fetchUpcomingTrips({ pageIndex, pageSize });
    }

    companyChange = (val) => {
        this.fetchUpcomingTrips({ pageIndex: 1, ...val });
        this.setState({ displayName: val.displayName });
    }

    render() {
        const { loading, total, pageIndex } = this.state;
        const UpcomingTripsWidget = BaseWidget(renderUpcomingTrips);

        return <UpcomingTripsWidget
            companyChange={this.companyChange}
            {...this.props}
            {...this.state}
        >
            <div className="paging" >
                <Pagination
                    defaultCurrent={1}
                    current={pageIndex}
                    total={total}
                    onChange={this.onChange}
                    pageSize={PAGESIZE}
                    hideOnSinglePage={true}
                    size="small" />
                {loading && <div className="mask"></div>}
            </div>
            <div className="company-name" title={this.state.displayName}>{this.state.displayName}</div>
        </UpcomingTripsWidget>;
    }
}

UpcomingTrips.propTypes = {
    handleTripClick: PropTypes.func.isRequired,
    isWidgets: PropTypes.bool.isRequired,
    widgetType: PropTypes.oneOf([WidgetType.UpcomingTrips]).isRequired,
    title: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    viewCompanies: PropTypes.array.isRequired,
    enableAsiaLightning: PropTypes.bool,
}

export default injectIntl(UpcomingTrips);