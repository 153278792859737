import React, { Component, Fragment } from 'react';
import { Icon, Tooltip, Modal } from 'antd';
import { getImageUrl, isEmpty, isValidEmail, getError, config, LoginSystemType, getAuth0CurrentLang, parseQueryString } from '~/common'
import { sendForgotPasswordEmail, validPassword, resetPassword, validResetPasswordEmailToken } from '~/client';
import { formatMessage as customFormatMessage } from '~/locales';

const CheckedPwd = {
    // isLowletter: {
    //     rule: (a) => a.match(/([a-z])+/),
    //     msg: "Lower case letters (a-z)"
    // },
    // isUpperletter: {
    //     rule: (a) => a.match(/([A-Z])+/),
    //     msg: "Upper case letters (A-Z)"
    // },
    // isNum: {
    //     rule: (a) => a.match(/([0-9])+/),
    //     msg: "Numbers (i.e. 0-9)"
    // },
    // isSpecial: {
    //     rule: (a) => a.match(/[^a-zA-Z0-9]+/),
    //     msg: "Special characters (e.g. !@#$%^&*)"
    // },
    // isLength: {
    //     rule: (a) => a.length >= 6,
    //     msgKey: "at_least_6_characters_in_length",
    // }
};

class ResetPwd extends Component {
    constructor(props) {
        super(props);
        this.state = {
            email: "",
            pwd: "",
            cfrPwd: "",
            validate: true,
            errMsg: null,
            showSuccess: false,
            resetEmail: "",
        };
        this.token = parseQueryString()['token'];
    }

    componentDidMount() {
        this.mounted = true;
        const token = this.token;
        const email = this.props.location.state;
        if (token && !email) {
            // Modal.error({
            //     content: 'Invalid token',
            //     onOk: close => (close(), this.props.history.replace('/')),
            //     okText: this.formatMessage({ id: 'ok' }),
            // });
            this.handleValidEmailToken();
        }
    }

    handleValidEmailToken = () => {
        const params = {
            Token: config.obtApiToken,
            EmailToken: this.token,
            MsgLanguage: getAuth0CurrentLang(),
        };
        const fm = id => customFormatMessage({ id, lang: getAuth0CurrentLang() });
        this.setState({ loading: true });
        validResetPasswordEmailToken(params, (err, data) => {
            const errMsg = getError(err || data);
            if (errMsg) {
                Modal.error({
                    centered: true,
                    title: fm('system_information'),
                    okText: fm('ok'),
                    content: errMsg,
                    onOk: () => this.props.history.replace('/'),
                });
            } else {
                this.setState({ resetEmail: data.Email, loading: false });
            }
        });
    }

    componentWillUnmount() {
        this.mounted = false;
    }

    formatMessage = ({ id, values } = {}) => customFormatMessage({ id, values, lang: getAuth0CurrentLang() })

    comparePwd = () => this.state.pwd === this.state.cfrPwd;
    resetPwd = () => {
        const validate = isValidEmail(this.state.email);
        this.setState({ validate });
        validate && this.sendResetPwdEmail();
    }
    onFocus = (e) => e.target.parentElement.classList.add('auth0-lock-input-focus');
    onBlur = (e) => e.target.parentElement.classList.remove('auth0-lock-input-focus');
    isPassPwd = (pwd) => Object.keys(CheckedPwd).length === 0 || Object.keys(CheckedPwd).every(k => CheckedPwd[k].rule(pwd));

    sendResetPwdEmail = () => {
        this.setState({ loading: true });
        const ctmPortalEmailSysType = 1;
        const params = {
            Token: config.obtApiToken,
            Email: this.state.email,
            EmailSysType: ctmPortalEmailSysType,
            MsgLanguage: getAuth0CurrentLang(),
        };
        sendForgotPasswordEmail(params, (err, data) => {
            if (!this.mounted) return;
            const errMsg = getError(err || data);
            this.setState({ errMsg, loading: false });
            if (!errMsg) {
                this.props.history.replace('/login', { flashMessage: { type: 'success', text: data.Msg || 'send email success' } });
            }
        });
    }

    changePwd = () => {
        if (isEmpty(this.state.pwd)) {
            this.refs.pwdRef.focus();
            this.setState({ validate: false, difPwd: false });
        }
        else if (!this.isPassPwd(this.state.pwd)) {
            this.refs.pwdRef.focus();
            this.setState({ showValidateForm: true, validate: false });
        }
        else if (!this.comparePwd()) {
            this.refs.cfrPwd.focus();
            this.setState({ difPwd: true });
        }
        else {
            this.setState({ difPwd: false, loading: true });
            this.handleValidPassword();
        }
    }

    handleValidPassword = () => {
        const formatMessage = this.formatMessage;
        const params = {
            Token: config.obtApiToken,
            EmailToken: this.token,
            Password: this.state.pwd,
            MsgLanguage: getAuth0CurrentLang(),
        };
        validPassword(params, (err, data) => {
            if (!this.mounted) return;
            const errMsg = getError(err || data);
            this.setState({ errMsg, loading: !errMsg });
            if (!errMsg) {
                if (data.Policies && data.Policies.length > 0) {
                    const content = <dl className="pm-popup-policy-content">
                        {data.Policies.map((policy, index) => {
                            return <Fragment key={index}>
                                <dt><img src={policy.IconUrl} alt='icon'/></dt>
                                <dd>{policy.Description}</dd>
                            </Fragment>;
                        })}
                    </dl>;
                    if (data.IsBlock) {
                        Modal.error({
                            title: formatMessage({ id: 'password_policy' }),
                            content,
                            onOk: close => (this.setState({ loading: false }), close()),
                            okText: formatMessage({ id: 'ok' }),
                        });
                    } else {
                        Modal.confirm({
                            title: formatMessage({ id: 'password_policy' }),
                            content,
                            cancelText: formatMessage({ id: 'cancel' }),
                            onCancel: close => (this.setState({ loading: false }), close()),
                            okText: formatMessage({ id: 'continue' }),
                            onOk: close => {
                                close();
                                this.handleResetPassword();
                            },
                        });
                    }
                } else {
                    this.handleResetPassword();
                }
            }
        });
    }

    handleResetPassword = () => {
        let params = {
            Token: config.obtApiToken,
            EmailToken: this.token,
            Password: this.state.pwd,
            MsgLanguage: getAuth0CurrentLang(),
        };
        resetPassword(params, (err, data) => {
            if (!this.mounted) return;
            const errMsg = getError(err || data);
            this.setState({ errMsg, loading: false, showSuccess: !errMsg });
            if (!errMsg) {
                if (data.LoginSystemType === LoginSystemType.CTM_Portal) {
                    this.props.auth.autoLogin(this.props.location.state || this.state.resetEmail, this.state.pwd, data.Auth0Connection);
                } else {
                    setTimeout(() => {
                        window.location.href = data.OBTAutoLoginUrl;
                    }, 500);
                }
            }
        });
    }

    renderValidateForm = (a) => <div className="validateForm">
        <ul>
            {
                Object.keys(CheckedPwd).map((k, index) => <li key={index} className={CheckedPwd[k].rule(a) ? "pass" : "fail"}>{this.formatMessage({ id: CheckedPwd[k].msgKey })}</li>)
            }
        </ul>
    </div>

    render() {
        let token = this.token;
        let { validate, difPwd, loading, pwd, showValidateForm, cfrPwd, email, errMsg, showSuccess } = this.state;
        return <div className="auth0-lock-container">
            <div className={`auth0-lock auth0-lock-opened ${loading ? "auth0-lock-mode-loading" : ''}`} id="auth0-lock">
                <div className="auth0-lock-center">
                    <div className="auth0-lock-widget">
                        <div className="auth0-lock-widget-container">
                            <div className="auth0-lock-cred-pane auth0-lock-quiet">
                                <div className="auth0-lock-cred-pane-internal-wrapper">
                                    <div className="auth0-lock-header">
                                        {!token && <span tabIndex="0" className="auth0-lock-back-button" onClick={() => window.history.length > 1 ? window.history.back() : this.props.history.push('/')}>
                                            <Icon type="arrow-left" />
                                        </span>}
                                        <div className="auth0-lock-header-bg auth0-lock-blur-support">
                                            <div className="auth0-lock-header-bg-blur"></div>
                                        </div>
                                        <div className="auth0-lock-header-welcome">
                                            <img alt="" className="auth0-lock-header-logo" src={getImageUrl("ctm-logo.png")} />
                                            <div className="auth0-lock-name" title="Reset your password">{this.formatMessage({ id: token ? "change_password" : "reset_your_password" })}</div>
                                        </div>
                                    </div>
                                    {errMsg && <div className="auth0-global-message auth0-global-message-error"><span className="animated fadeInUp"><span>{errMsg}</span></span></div>}
                                    <div className="auth0-lock-content-wrapper">
                                        <div className="relative">
                                            <div className="auth0-lock-body-content">
                                                <div className="auth0-lock-content">
                                                    <div className="auth0-lock-form">
                                                        <p>{this.formatMessage({ id: token ? "enter_a_new_password" : "please_enter_your_email_address", values: { email: this.props.location.state || this.state.resetEmail || "someone@example.com" } })}</p>
                                                        {token ? <Fragment>
                                                            <Tooltip getPopupContainer={() => document.getElementById("auth0-lock")} placement="topLeft" title={this.renderValidateForm(pwd)} visible={showValidateForm && !isEmpty(pwd) && Object.keys(CheckedPwd).length > 0}>
                                                                <div className="auth0-lock-input-block auth0-lock-input-email">
                                                                    <div className={`auth0-lock-input-wrap auth0-lock-input-wrap-with-icon ${validate ? '' : 'checked'}`}>
                                                                        <span>
                                                                            <Icon type="lock" />
                                                                        </span>
                                                                        <input ref="pwdRef" onFocus={(e) => { this.onFocus(e); this.setState({ showValidateForm: true, difPwd: false }) }} onBlur={(e) => { this.onBlur(e); this.setState({ showValidateForm: false }) }} type="password" onChange={(e) => this.setState({ pwd: e.target.value, validate: true, difPwd: false, errMsg: null, ...!this.isPassPwd(e.target.value) && { cfrPwd: "" } })} name="password" className="auth0-lock-input" placeholder={this.formatMessage({ id: "your_new_password" })} autoComplete="off" autoCapitalize="off" value={pwd} />
                                                                    </div>
                                                                </div>
                                                            </Tooltip>
                                                            <Tooltip placement="right" title="Please ensure the password and the confirmation are the same" visible={validate && difPwd}>
                                                                <div className="auth0-lock-input-block auth0-lock-input-email">
                                                                    <div className={`auth0-lock-input-wrap auth0-lock-input-wrap-with-icon ${validate && difPwd && 'checked'}`}>
                                                                        <span>
                                                                            <Icon type="lock" />
                                                                        </span>
                                                                        <input ref="cfrPwd" disabled={!this.isPassPwd(pwd) || isEmpty(pwd)} onFocus={(e) => this.onFocus(e)} onBlur={(e) => this.onBlur(e)} type="password" onChange={(e) => this.setState({ cfrPwd: e.target.value, difPwd: false })} name="password" className="auth0-lock-input" placeholder={this.formatMessage({ id: "confirm_your_new_password" })} autoComplete="off" autoCapitalize="off" value={cfrPwd} />
                                                                    </div>
                                                                </div>
                                                            </Tooltip>
                                                        </Fragment> : <Tooltip placement="right" title={this.formatMessage({ id: "invalid_email_format" })} visible={!validate}><div className="auth0-lock-input-block auth0-lock-input-email">
                                                            <div className={`auth0-lock-input-wrap auth0-lock-input-wrap-with-icon ${validate ? '' : 'checked'}`}>
                                                                <span>
                                                                    <Icon type="mail" />
                                                                </span>
                                                                <input onFocus={(e) => this.onFocus(e)} onBlur={(e) => this.onBlur(e)} type="email" onChange={(e) => this.setState({ email: e.target.value, validate: isValidEmail(email) || isEmpty(e.target.value), errMsg: null })} name="email" className="auth0-lock-input" placeholder="yours@example.com" autoComplete="off" autoCapitalize="off" value={email} />
                                                            </div>
                                                        </div>
                                                            </Tooltip>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <button className="auth0-lock-submit" style={{ backgroundColor: "green" }} onClick={token ? this.changePwd : this.resetPwd} disabled={loading}>
                                        <div className="auth0-loading-container">
                                            <div className="auth0-loading"></div>
                                        </div>
                                        <span className="auth0-label-submit">
                                            {token ? <img src={getImageUrl("right-circle.svg")} alt="" /> :
                                                <span>
                                                    {this.formatMessage({ id: 'send_email' })}
                                                    <Icon type="right" />
                                                </span>
                                            }
                                        </span>
                                    </button>
                                </div>
                            </div>
                            {
                                showSuccess && <div className="auth0-lock-confirmation">
                                    <div className="auth0-lock-confirmation-content">
                                        <span><svg width="56px" height="56px" viewBox="0 0 52 52" version="1.1" xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" className="checkmark"> <circle cx="26" cy="26" r="25" fill="none" className="checkmark__circle"></circle> <path fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" className="checkmark__check"></path> </svg></span>
                                        <p>{this.formatMessage({ id: 'your_password_has_been_reset_successfully' })}</p>
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div >
    }
}

export default ResetPwd;
