import { getUserDataInvoked, getUserDataSuccess, getUserDataFailed, GET_USER_DATA, GET_USER_DATA_SUCCESS } from '../userData';
import { put, takeLatest, call } from 'redux-saga/effects';
import { getProfileToken, getError } from '~/common';
import { getUserData } from '~/client';
import { getLoginProfile } from '../loginProfile';

function* getUserDataSaga() {
  try {
    yield put(getUserDataInvoked());
    const payload = yield call(getUserData, { profileToken: getProfileToken(), NeedCheckProfileManageUrl: true });
    const errMsg = getError(payload);
    if (errMsg) {
      yield put(getUserDataFailed(errMsg));
    } else {
      yield put(getUserDataSuccess(payload));
    }
  } catch (ex) {
    yield put(getUserDataFailed(ex.message));
  }
}

function* getUserDataSuccessSaga(action) {
  try {
    const data = action.payload;
    if (data.IsProfileManageScriptUrlChange) {
      yield put(getLoginProfile());
    }
  } catch (ex) {
    console.error(ex);
  }
}

export default function* () {
  yield takeLatest(GET_USER_DATA, getUserDataSaga);
  yield takeLatest(GET_USER_DATA_SUCCESS, getUserDataSuccessSaga);
}