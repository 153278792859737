import React, { Component } from 'react';
import PropTypes from 'prop-types';
import * as d3 from 'd3';

/**
 * D3 gauge used for simple data visualization
 */

class MetricGauge extends Component {
  static defaultProps = {
    width: 180,
    height: 180,
    thickness: 6,
    count: 0,
    isSelected: false
  }

  tau = Math.PI * 2;
  arcRef = React.createRef();
  counterRef = React.createRef();
  // Initialize our counter and progress at 0
  progress = { percent: 0 };
  counter = { count: 0 };

  componentDidMount() {
    this.animate();
  }
  componentDidUpdate(prevProps) {
    // On update, we want to re-animate our gauges based on previous values
    this.animate(prevProps.count, prevProps.total);
  }

  animate = (prevCount = 0, prevTotal = 0) => {
    this.counter.count = this.props.count;
    if (this.counterRef && this.counterRef.current) {
      this.counterRef.current.textContent = this.counter.count;
    }

    // TweenMax.to(this.counter, 1, {
    //   lazy: true,
    //   count: `+=${this.props.count - prevCount}`,
    //   roundProps: "count",
    //   onUpdate: () => {
    //     if(this.counterRef && this.counterRef.current)
    //       this.counterRef.current.textContent = this.counter.count;
    //   },
    //   ease: SteppedEase.config(this.props.count)
    // });
    // If we have no count and didn't have a count, or if our count has not changed, we don't need to change our arc
    if ((!this.props.count && !prevCount) || this.props.count === prevCount) return;
    this.progress.percent = this.props.proportion;
    if (this.arcRef && this.arcRef.current) {
      this.arcRef.current.setAttribute("d", this.getArc(this.progress.percent)());
      if (!this.props.count) this.arcRef.current.setAttribute("d", "");
    }

    // TweenMax.to(this.progress, 1.5, {
    //   lazy: true,
    //   percent: this.props.total ? `+=${(this.props.count - prevCount) / this.props.total}` : `-=${prevCount / prevTotal}`,
    //   onUpdate: () => {
    //     if(this.arcRef && this.arcRef.current)
    //       this.arcRef.current.setAttribute("d", this.getArc(this.progress.percent)());
    //   },
    //   onComplete: () => {
    //     // On complete, check if we have no count, and if so, wipe out our arc data
    //     if (!this.props.count) this.arcRef.current.setAttribute("d", "");
    //   },
    //   ease: Power4.easeOut
    // });
  }

  getArc = percent => {
    const {
      width,
      thickness
    } = this.props;
    const radius = (width - thickness) / 2;
    const circleRadius = radius - 7; // 5 units for the box shadow around selected circle
    const donutRadius = circleRadius - 12; // 8 units for padding radius of select circle and donut

    return d3.arc()
      .startAngle(0)
      .cornerRadius(thickness / 2)
      .innerRadius(donutRadius)
      .outerRadius(donutRadius - thickness)
      .endAngle(this.tau * percent);
  }

  render() {
    const {
      width,
      thickness,
      height,
      onSelected,
      text,
      code,
      isSelected,
      hasBg } = this.props;

    const radius = (width - thickness) / 2;
    const circleRadius = radius - 5;

    const track = this.getArc(1);

    return (
      <svg viewBox={`0 0 ${width} ${height}`} onClick={onSelected} className={`${isSelected ? "is-selected " : ""}metric-gauge ${code}`}>
        {hasBg && <circle className="bg" cx="50%" cy="50%" r={circleRadius - 12} />}
        <circle className="select" cx="50%" cy="50%" r={circleRadius} />
        <g className="track-group" transform={`translate(${width / 2},${height / 2})`}>
          <path d={track()} className="track" strokeWidth={thickness} />
          <path ref={this.arcRef} className="arc" strokeWidth={thickness} />
        </g>
        <text ref={this.counterRef} x="50%" y="50%" className="counter" textAnchor="middle">0</text>
        <text x="50%" y="50%" dy="2em" textAnchor="middle" className="label">
          {text}
        </text>
      </svg>
    );
  }
}

MetricGauge.propTypes = {
  /** The amount we are visualizing */
  count: PropTypes.number,
  /** The total amount of what we could be visualizing */
  total: PropTypes.number,
  /** Sets width of svg element */
  width: PropTypes.number,
  /** The width of the colored border of the gauge */
  thickness: PropTypes.number,
  /** Sets height of svg element */
  height: PropTypes.number,
  /** Toggle for whether or not gauge has animated yet */
  isCalculated: PropTypes.bool,
  /** onClick callback */
  onSelected: PropTypes.func,
  /** Text that renders in gauge */
  text: PropTypes.string.isRequired,
  /** Status code used for choosing color */
  code: PropTypes.string,
  /** Toggle for whether element is selected */
  isSelected: PropTypes.bool,
  /** Toggle for whether the gauge renders a background */
  hasBg: PropTypes.bool,

  proportion:PropTypes.number
}

export default MetricGauge;
