import React, { Fragment } from 'react'
import { Companies } from '~/components';
import { WidgetType } from '~/common'

const BaseWidget = (WidgetContent) => {
    class Widget extends React.Component {
        render() {
            const { title, isWidgets, id, loading, viewCompanies, companyChange, widgetType, companyCode, footer } = this.props;
            return (
                <Fragment key={title}>
                    {isWidgets && <div className='trips-container widget' id={id}>
                        <div className='title clearfix'>{title}
                            {viewCompanies && viewCompanies.length > 1 && [WidgetType.DeadlineAlert, WidgetType.PendingForApproval, WidgetType.UpcomingTrips, WidgetType.CustomBooking].includes(widgetType) &&
                                <Companies id={id}
                                    viewCompanies={viewCompanies}
                                    disabled={loading}
                                    companyChange={companyChange}
                                    companyCode={companyCode}
                                    widgetType={widgetType}>
                                </Companies>}
                            {this.props.children}
                        </div>
                        <div className="content">
                            <WidgetContent {...this.props} />
                        </div>
                        {footer}
                    </div>
                    }
                    {!isWidgets && <WidgetContent {...this.props} />}
                </Fragment>
            )
        }
    }
    return Widget
}

export default BaseWidget;
