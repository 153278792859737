import React, { Component } from 'react';
import { Loading } from '~/components';
import { getProfileToken, CTMPortalSystemType } from '~/common';
import { logout } from '~/client';

class Logout extends Component {
    componentDidMount() {
        const profileToken = getProfileToken();
        if (!profileToken) {
            this.props.auth.logout();
        } else {
            logout({ profileToken, systemType: CTMPortalSystemType }, () => this.props.auth.logout());
        }
    }

    render() {
        return <Loading style={{ backgroundColor: 'rgba(0,0,0,.85)' }} />;
    }
}

export default Logout;
