import React from 'react';
import { Alert } from 'antd';
import { injectIntl } from 'react-intl';

const RecordNotFound = (props) => {
    const intl = props.intl;
    function tripNotFound() {
        return <div className="no-found-box" style={props.style}>
            <span className="img-box"></span>
            <div>{Object.keys(intl.messages).includes(props.formatId) ? intl.formatMessage({ id: props.formatId }) : props.formatId}</div>
            {props.children}
        </div>
    }
    return props.formatId ? tripNotFound() : <Alert
        message={props.message || intl.formatMessage({ id: 'booking_not_found' })}
        description={props.description}
        type="error"
        style={{ marginTop: 10, marginBottom: 10 }}
    />;
};

export default injectIntl(RecordNotFound);
