import { ThemeType, getCacheUserSetting, isLightMode, getCurrentLang } from '~/common';

export const UPDATE_USER_SETTING_SAGA = 'UPDATE_USER_SETTING_SAGA';
export const UPDATE_USER_SETTING = 'UPDATE_USER_SETTING';

export const updateUserSettingSaga = payload => ({ type: UPDATE_USER_SETTING_SAGA, payload });
export const updateUserSetting = payload => ({ type: UPDATE_USER_SETTING, payload });

const initState = {
    Language: getCurrentLang(),
    ThemeType: getCacheUserSetting().ThemeType,
    Widgets: [],
    ...getCacheUserSetting(),
}

const userSetting = (state = initState, action) => {
    switch (action.type) {
        case UPDATE_USER_SETTING:
            return {
                ...state,
                ...action.payload,
            };
        default:
            return state;
    }
}

export default userSetting;
