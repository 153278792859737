import React, { Component, Fragment } from 'react';
import { Loading, TravelItineraries } from '~/components';
import { FormattedMessage } from 'react-intl';
import * as PropTypes from 'prop-types';
import { TravelStatus, previousTimeFrame, upcomingTimeFrame, config, getTravelStatus, getCurrentLang, Languages } from '~/common';
import { Icon, Select, Row, Col, Input } from 'antd';

const { Option } = Select;

class FlightCardCollection extends Component {

    constructor(props) {
        super(props);
        this.state = {
            query: '',
            selectedItinerary: null,
        };
        this.itinerariesRef = React.createRef();
    }

    componentDidMount() {
        window.addEventListener('click', this.handleClickEvent);
    }

    componentWillUnmount() {
        window.removeEventListener('click', this.handleClickEvent);
    }

    handleClickEvent = async (e) => {
        const el = e.target;
        if (el && el.dataset && el.dataset.click === "itinerary") {
            let { travelerid, locationkey, orderid } = el.dataset;
            e.preventDefault();
            this.props.toggleTripCollection(false);
            this.setState({ selectedItinerary: { orderid, travelerid, locationkey } });
        }
    }

    handleTripClick = (summary) => {
        const { LocationKey } = summary;
        this.props.highlightMarker((LocationKey || '').replace(/-[AH]$/i, ''));
    }

    filterSummariesByTravelStatus = (summaries) => {
        let summ = (summaries && JSON.parse(JSON.stringify(summaries))) || [];
        if (this.props.selectedStatusCode === TravelStatus.All) {
            return summ;
        }
        return summ.reduce((pre, ele) => {
            if (ele.A) {
                ele.A.Segments = ele.A.Segments ? ele.A.Segments.filter(seg => getTravelStatus(seg.TravelStateType).statusType === getTravelStatus(this.props.selectedStatusCode).statusType
                    || (this.props.selectedStatusCode === TravelStatus.Warning && seg.IsWarning)) : [];
                ele.A.Segments.length && pre.push(ele);
            }
            else if (ele.H) {
                (getTravelStatus(ele.H.TravelStateType).statusType === getTravelStatus(this.props.selectedStatusCode).statusType
                    || (this.props.selectedStatusCode === TravelStatus.Warning && ele.H.IsWarning))
                    && pre.push(ele);
            }
            return pre;
        }, []);
    }

    updateQuery = query => {
        this.setState({ query });
    }

    resetItinerary = () => {
        this.setState({ selectedItinerary: null });
    }

    reset = () => {
        this.setState({ selectedItinerary: null, query: '' });
        this.itinerariesRef.current && this.itinerariesRef.current.reset();
    }

    filterSummaries = (summaries) => {
        summaries = this.filterSummariesByTravelStatus(summaries);
        const selectedItinerary = this.state.selectedItinerary;
        const query = (this.state.query || '').trim().toLowerCase();
        if (query && this.props.selectedStatusCode === TravelStatus.All && !selectedItinerary) {
            //filter by query
            summaries = summaries.filter(f => {
                let found = false;
                if (f.A) {
                    const seg = f.A.Segments[0];
                    found = [f.A.Travelers, seg.DepAirport, seg.ArrAirport, seg.ArrAirportName, seg.DepAirportName, `${seg.MarketingCarrier || ''}${seg.FlightNumber || ''}`].some(val => (val || '').toLowerCase().includes(query));
                }
                else if (f.H) {
                    found = [f.H.Travelers, f.H.CityCode, f.H.CityName, f.H.HotelCode, f.H.HotelName].some(val => (val || '').toLowerCase().includes(query));
                }
                return found;
            });
        }

        if (!!selectedItinerary) {
            //filter by selected itinerary
            const { orderid, travelerid, locationkey } = selectedItinerary;
            summaries = summaries.filter(f => {
                let found = false;
                const item = f.A || f.H;
                if (item) {
                    found = item.OrderId + '' === orderid && item.TravelerId + '' === travelerid && item.LocationKey === locationkey;
                }
                return found;
            });
        }
        return summaries;
    };

    renderCountryRisks() {
        let { CountryRisks = {}, showArrow } = this.props;
        let count = 0;
        let content = Object.keys(CountryRisks).map((countryKey) => {
            count = count + CountryRisks[countryKey].length;
            return <div className="country-list" key={countryKey}>
                {CountryRisks[countryKey].map((news, index) => <div className="risk" key={index}>
                    <div className="risk-header">
                        <span className="country" title={news.CountryName}>{news.CountryName}</span>
                        <span className={`status status-${news.RiskLevel}`}>{news.RiskLevelRemark}</span>
                    </div>
                    <input type="checkbox" id={`risk-checkbox-${countryKey}-${index}`} /><label className="content" readless={this.props.intl.formatMessage({ id: "read_less" })} readmore={this.props.intl.formatMessage({ id: "read_more" })} htmlFor={`risk-checkbox-${countryKey}-${index}`}>{news.Body}</label>
                </div>
                )}
            </div>
        });
        return Object.keys(CountryRisks).length ? <div className="risk-wrapper" onMouseEnter={() => document.getElementsByClassName('mouse-wrapper')[0].style.display = "none"}>
            <div className="mouse-wrapper" style={{ display: showArrow && count > 2 ? "block" : "none" }}>
                <div className="mouse"></div>
                <FormattedMessage id="scroll" />
            </div>
            {content}
        </div > : null
    }

    getTripCountStatistics = summaries => {
        let flightCount = 0, hotelCount = 0;
        (summaries || []).forEach(s => {
            if (s.A) flightCount++;
            else if (s.H) hotelCount++;
        });
        return { hotelCount, flightCount, totalCount: flightCount + hotelCount };
    }

    renderCards = (summaries) => {
        let timeFrames = this.props.timeFrames;
        let data = this.filterSummaries(summaries);
        const tripCountStatistics = this.getTripCountStatistics(data);
        let companyName = null;
        if (this.props.companyCode && this.props.viewCompanies) {
            const company = this.props.viewCompanies.find(c => c.Code === this.props.companyCode);
            if (company) {
                companyName = company.DisplayName;
            }
        }
        const showSelectBox = this.props.selectedStatusCode === TravelStatus.All && !this.state.selectedItinerary;
        return <div className={`flight-card-content scrollbar-none${showSelectBox ? ' with-select-box' : ''}`}>
            <span className="close" onClick={() => this.props.toggleTripCollection(true)}></span>
            {this.props.children}
            {companyName && <span className='sel-company-name'>{companyName}</span>}
            <div className='title'>
                {this.props.selectedStatusCode === TravelStatus.All ? <FormattedMessage
                    id='trip_count_fmt'
                    values={{ count: tripCountStatistics.totalCount }}
                /> : <Fragment>
                        <span className="flight-icon"></span>
                        <span className='count-font'>{tripCountStatistics.flightCount}</span>
                        <span className="hotel-icon"></span>
                        <span className='count-font'>{tripCountStatistics.hotelCount}</span>
                        <span className='status-font'>
                            {!(this.props.selectedStatusCode === TravelStatus.Warning && getCurrentLang() === Languages.zh_tw) && <FormattedMessage id={this.props.selectedStatusCode.replace('-', '_')} />}
                        </span>
                    </Fragment>}
            </div>
            <div className="itinerary-container">
                {showSelectBox && <div className="select-box">
                    {!!(summaries && summaries.length) && <Row>
                        <Col span={24}>
                            <Input name="query" disabled={this.props.updateLoading} prefix={<Icon type="search" />} value={this.state.query} onChange={(ev) => this.updateQuery(ev.target.value)} placeholder={this.props.intl.formatMessage({ id: 'search_trip' })} className='query-input' autoComplete='off'></Input>
                        </Col>
                    </Row>}
                    <Row gutter={8}>
                        <Col span={12}>
                            <FormattedMessage id="previous" tagName="label" />
                            <Select disabled={this.props.updateLoading} dropdownClassName="tripSelect" style={{ width: '100%' }} defaultValue={timeFrames[0]} suffixIcon={<Icon type="caret-down" />} onSelect={(val) => this.props.updateTimeRange([val, timeFrames[1]])}>
                                {config.isDebug && <Option value="noLimit">debug - <FormattedMessage id="no_limit" /></Option>}
                                {previousTimeFrame.map(ele => (<Option className="no-wrap" value={ele.key} key={ele.key}><FormattedMessage id={ele.text} values={{ count: ele.value, type: 0 }} /></Option>))}
                            </Select>
                        </Col>
                        <Col span={12} >
                            <FormattedMessage id="upcoming" tagName="label" />
                            <Select disabled={this.props.updateLoading} dropdownClassName="tripSelect" style={{ width: '100%' }} defaultValue={timeFrames[1]} suffixIcon={<Icon type="caret-down" />} onSelect={(val) => this.props.updateTimeRange([timeFrames[0], val])}>
                                {upcomingTimeFrame.map(ele => (<Option className="no-wrap" value={ele.key} key={ele.key}><FormattedMessage id={ele.text} values={{ count: ele.value, type: 1 }} /></Option>))}
                            </Select>
                        </Col>
                    </Row>
                    {this.props.updateLoading && <Loading spinStyle={{ color: 'rgba(107, 107, 107, 0.87)' }} />}
                </div>}
                {this.props.selectedStatusCode === TravelStatus.Warning && this.renderCountryRisks()}
                {!this.props.updateLoading && !!data.length && <TravelItineraries
                    intl={this.props.intl}
                    ref={this.itinerariesRef}
                    travelSummaries={data}
                    handleTripClick={this.handleTripClick}
                    showActiveIndicator={true}
                    detailContainerId='tracker-itinerary-detail'
                    enableAsiaLightning={this.props.enableAsiaLightning}
                    handleViewInLightning={this.props.handleViewInLightning}
                />}
            </div>
        </div >
    }

    render() {
        const { TravelSummaries, selectedStatusCode, loading, closeTripCollection } = this.props;
        return <div className={`trip-collection ${selectedStatusCode} ${closeTripCollection ? 'hidden' : ''}`}>
            {loading ? <Loading /> : <Fragment>
                {this.renderCards(TravelSummaries)}
            </Fragment>
            }
        </div>;
    }
}
FlightCardCollection.propTypes = {
    CountryRisks: PropTypes.object
}
export default FlightCardCollection;