if (!global._babelPolyfill) {
    require('babel-polyfill');
}
if (!global._reactAppPolyfill) {
    require('react-app-polyfill/ie11');
    global._reactAppPolyfill = true;
}
if (!global._promisePolyfill) {
    require('es6-promise').polyfill();
    global._promisePolyfill = true;
}
if (!window.scrollTo) {
    window.scrollTo = function (x, y) {
        window.pageXOffset = x;
        window.pageYOffset = y;
    };
}
if (!window.scrollBy) {
    window.scrollBy = function (x, y) {
        window.pageXOffset += x;
        window.pageYOffset += y;
    };
}
if (!document.body.scrollTo) {
    Element.prototype.scrollTo = function (x, y) {
        this.scrollLeft = x;
        this.scrollTop = y;
    };
}
if (!document.body.scrollBy) {
    Element.prototype.scrollBy = function (x, y) {
        this.scrollLeft += x;
        this.scrollTop += y;
    };
}