import { handleErrors, config, combineUrl, getCurrentLang, getToken, handleProfileTokenExpired } from '~/common';

const headers = new Headers({
    "Content-Type": "application/json; charset=utf-8",
    //"language": (localStorage.getItem('language') || navigator.language || "en-us").toLowerCase(),
});
const init = { method: "Post", mode: "cors", headers: headers, credentials: 'include' };

function parseJson(text) {
    try {
        return JSON.parse(text);
    } catch (ex) {
        console.error('parse api response json error', ex);
        return text;
    }
}

export default function request(url, data, callback, { needValidAccessToken = true, interceptors } = {}) {
    const token = getToken();
    if (needValidAccessToken && !token) {
        try {
            handleErrors({ status: 401 });
        }
        catch (ex) {
            const error = new Error('No Access Token');
            error.code = 401;
            const rs = { status: -1, Status: -1, msg: error.message, Msg: error.message };
            typeof callback === 'function' && callback(error, rs);
            return Promise.resolve(rs);
        }
    }
    let newInit = Object.assign({}, init, { body: data instanceof FormData ? data : JSON.stringify(data) });
    if (data instanceof FormData) {
        newInit.headers.delete('Content-Type');
    } else {
        newInit.headers.set('Content-Type', 'application/json; charset=utf-8');
    }
    needValidAccessToken && newInit.headers.set('Authorization', `Bearer ${token}`);
    newInit.headers.set('language', getCurrentLang());
    if (!/https?/i.test(url)) {
        url = combineUrl(config.obtApiUrl, url);
    }
    url += `${url.includes('?') ? '&' : '?'}_r=${Math.random()}`;
    let requestInit = new Request(url, newInit);
    if (interceptors && typeof interceptors.request == 'function') {
        interceptors.request(requestInit);
    }
    let promise = fetch(requestInit).then(res => interceptors && typeof interceptors.response == 'function' ? interceptors.response(res) : handleErrors(res)).then(async function (response) {
        const contentType = response.headers.get('Content-Type').split(';')[0];
        if (contentType === 'application/json') {
            return response.json();
        } else if (contentType === 'application/octet-stream') {
            const blob = await response.blob();
            return blob;
        } else {
            let jsonText = await response.text();
            return parseJson(jsonText);
        }
    }).then(function (rs) {
        handleProfileTokenExpired(rs);
        typeof callback === 'function' && callback(null, rs, requestInit);
        return Promise.resolve(rs);
    }).catch(function (ex) {
        typeof callback === 'function' && callback(ex, null, requestInit);
        return Promise.reject(ex);
    });
    return promise;
}

