import React, { Fragment } from 'react';
import { Form, Select, Row, Col, Checkbox, Button, DatePicker } from 'antd';
import moment from 'moment';
import { CityComplete } from '~/components';
import { getCurrentLang, TripType, Languages, getTripType, combineUrl, config, animate, getValue4Lang } from '~/common';
import { FormattedMessage } from 'react-intl';

const { Option } = Select;

class SearchForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = { cities: [] }
    }

    handleAddFlight = () => {
        let param = this.props.searchParam;
        let newTrip = {};
        if (param.multiFlights.length >= 2) {
            if (!param.multiFlights[1].depCity && param.multiFlights[1].dstCity && param.multiFlights[0].depCity === param.multiFlights[1].dstCity) {
                param.multiFlights[1].dstCity = '';
            }
        }
        if (param.multiFlights.slice(-1)[0].depDate) {
            newTrip.depDate = moment(param.multiFlights.slice(-1)[0].depDate).add(2, 'days').format('YYYYMMDD');
        }
        newTrip.depCity = param.multiFlights[0].dstCity;
        newTrip.dstCity = param.multiFlights[0].depCity;
        newTrip.isDepSchByAirport = param.multiFlights[0].isDstSchByAirport;
        newTrip.isDstSchByAirport = param.multiFlights[0].isDepSchByAirport;

        param.multiFlights.push(newTrip);
        this.props.handleSearchParamChange(param);
        this.handleAnimate();
    }

    handleAnimate = () => {
        requestAnimationFrame(() => {
            const flightDom = document.querySelector('.flight-2');
            if (flightDom) {
                animate.slideDown(flightDom, 300);
            } else {
                this.handleAnimate();
            }
        });
    }

    handleDeleteTrip = tripIndex => {
        const flightDom = document.querySelector('.flight-2');
        animate.slideUp(flightDom, 300, () => {
            let param = this.props.searchParam;
            param.multiFlights.splice(tripIndex, 1);
            this.props.handleSearchParamChange(param);
            this.handleAddBtnAnimate();
        });
    }

    handleAddBtnAnimate = () => {
        requestAnimationFrame(() => {
            const dom = document.querySelector('.add-flight-btn');
            if (dom) {
                animate.fadeIn(dom, 300);
            } else {
                this.handleAddBtnAnimate();
            }
        });
    }

    handleCityChange = (index, isDep, cityCode, isSchByAirport) => {
        let param = this.props.searchParam,
            trip = param.multiFlights[index],
            mapping = {
                '0': '1',
                '1': '0'
            };
        if (param.multiFlights.length === 2) {
            if (isDep) {
                param.multiFlights[index].depCity = cityCode;
                param.multiFlights[index].isDepSchByAirport = isSchByAirport;
                param.multiFlights[mapping[index]].dstCity = cityCode;
                param.multiFlights[mapping[index]].isDstSchByAirport = isSchByAirport;
            }
            else {
                param.multiFlights[index].dstCity = cityCode;
                param.multiFlights[index].isDstSchByAirport = isSchByAirport;
                param.multiFlights[mapping[index]].depCity = cityCode;
                param.multiFlights[mapping[index]].isDepSchByAirport = isSchByAirport;
            }

        } else {
            param.multiFlights[index][isDep ? 'depCity' : 'dstCity'] = cityCode;
            param.multiFlights[index][isDep ? 'isDepSchByAirport' : 'isDstSchByAirport'] = isSchByAirport;
        }
        if (cityCode) {
            try {
                if (isDep) {
                    if (!trip.dstCity) {
                        document.getElementById(`arr_city_${index}`).click();
                    }
                } else {
                    if (!trip.depDate) {
                        document.getElementsByClassName('date-txt__wrap')[index].firstElementChild.firstElementChild.click();
                    }
                }
            } catch (ex) {
                console.log(ex);
            }
        }
        this.props.handleSearchParamChange(param);
    }

    handleDateChange = (index, date) => {
        let param = this.props.searchParam;
        date && (param.multiFlights[index].depDate = date.format('YYYYMMDD'));
        for (let i = index + 1; i < param.multiFlights.length; i++) {
            let prevTrip = param.multiFlights[i - 1];
            let trip = param.multiFlights[i];
            if (!trip.depDate || moment(trip.depDate).isBefore(prevTrip.depDate)) {
                trip.depDate = moment(prevTrip.depDate).add(2, 'days').format('YYYYMMDD');
            }
        }
        this.props.handleSearchParamChange(param);
    }

    disabledDate = (index, date) => {
        let today = moment().startOf('days');
        let endDate = moment().add(1, 'years').startOf('days');
        date = date.startOf('days');
        if (date.isBefore(today) || date.isAfter(endDate)) return true;
        let multiFlights = this.props.searchParam.multiFlights;
        if (index > 0 && multiFlights[index - 1].depDate) {
            if (date.isBefore(multiFlights[index - 1].depDate)) return true;
        }
        return false;
    }

    getCityName = (key, withCode) => {
        let name = (key + '').split('-')[0];
        if (key && this.props.citiesInfo && this.props.citiesInfo.size) {
            const cityInfo = this.props.citiesInfo.get(key);
            if (cityInfo) {
                name = getValue4Lang(cityInfo.name, cityInfo.name_zh_cn, cityInfo.name_zh_tw);
                name += withCode ? ` (${cityInfo.code})` : '';
            }
        }
        return name;
    }

    render() {
        const { searchParam, handleSearchParamChange, closeForm, handleToggleForm, handleSubmit, orgSearchParam, intl, isWidgets } = this.props;
        const formItemLayout = {
            labelCol: {
                span: 24,
            },
            wrapperCol: {
                span: 24,
            }
        }
        const formSubItemLayout = {
            wrapperCol: {
                sm: { span: 24 },
                md: { span: 6 },
            }
        }
        let orgTripType = getTripType(orgSearchParam && orgSearchParam.multiFlights);
        const flightImg = <img alt='->' src={combineUrl(config.localhostUrl, 'images/flight-dep.png')} width={15} style={{ margin: '-3px 5px 0', }} />;
        return (
            <Fragment>
                <div>
                    {!isWidgets && <Button onClick={handleToggleForm} className={`toggle-form-btn${closeForm ? ' close' : ''}`}><FormattedMessage id={closeForm ? 'update_search' : 'close_filters'} /></Button>}
                </div>

                <div className='fare-forecaster-panel' style={!closeForm ? { display: 'none' } : {}}>
                    {orgSearchParam ?
                        <Fragment>
                            <span className='panel-trip-type'><FormattedMessage id={orgTripType === TripType.Oneway ? 'one_way' : orgTripType === TripType.Roundtrip ? 'round_trip' : 'multi_city'} /></span>
                            {orgTripType !== TripType.MultiCity && <span className='panel-trip'>{this.getCityName(`${orgSearchParam.multiFlights[0].depCity}-${orgSearchParam.multiFlights[0].isDepSchByAirport ? 0 : 1}`, true)} {flightImg} {this.getCityName(`${orgSearchParam.multiFlights[0].dstCity}-${orgSearchParam.multiFlights[0].isDstSchByAirport ? 0 : 1}`, true)}</span>}
                            {orgTripType === TripType.MultiCity && <span>{orgSearchParam.multiFlights.map((trip, index) => {
                                return <span key={index} className='panel-trip'>{this.getCityName(`${trip.depCity}-${trip.isDepSchByAirport ? 0 : 1}`, true)} {flightImg} {this.getCityName(`${trip.dstCity}-${trip.isDstSchByAirport ? 0 : 1}`, true)}</span>;
                            })}</span>}
                        </Fragment>
                        : <Fragment>
                            <span className='panel-trip-type'><FormattedMessage id='round_trip' /></span>
                            <span className='panel-trip'><FormattedMessage id='enter_an_airport' /> {flightImg} <FormattedMessage id='enter_an_airport' /></span>
                        </Fragment>
                    }
                </div>

                <div className='fare-forecaster-form-wrapper' style={closeForm ? { display: 'none' } : {}}>
                    <Form onSubmit={handleSubmit} className="fare-forecaster-form">
                        <Form.Item {...formItemLayout} label={<FormattedMessage id='fare_forecaster_form_prompt' />} style={{ marginBottom: '1.5em' }}></Form.Item>

                        {searchParam.multiFlights.map((trip, index) => {
                            return (
                                <Form.Item
                                    className={`flight-${index + 1}`}
                                    {...formItemLayout}
                                    style={{ marginBottom: 0 }}
                                    key={index}
                                    label={
                                        <Row>
                                            <Col style={{ float: 'left' }}><FormattedMessage id='flight_index_fmt' values={{ index: index + 1 }} /></Col>
                                            <Col sm={1} md={0}>{index > 0 && <span className='f-delete' style={{ marginLeft: 10 }} onClick={() => this.handleDeleteTrip(index)}></span>}</Col>
                                        </Row>
                                    }
                                >
                                    <Row gutter={8} className='vertical-align-top'>
                                        <Col {...formSubItemLayout.wrapperCol}>
                                            <Form.Item {...formItemLayout}>
                                                <CityComplete
                                                    className='dep-city-txt__wrap input-require'
                                                    placeholder={intl.formatMessage({ id: 'enter_city_airport_name' })}
                                                    defaultValue={{ code: trip.depCity, isSchByAirport: trip.isDepSchByAirport }}
                                                    inputId={`dep_city_${index}`}
                                                    onSelect={(val, isSchByAirport) => this.handleCityChange(index, true, val, isSchByAirport)}
                                                    intl={intl}
                                                />
                                            </Form.Item>
                                        </Col>
                                        <Col {...formSubItemLayout.wrapperCol}>
                                            <Form.Item {...formItemLayout}>
                                                <CityComplete
                                                    className='arr-city-txt__wrap input-require'
                                                    placeholder={intl.formatMessage({ id: 'enter_city_airport_name' })}
                                                    defaultValue={{ code: trip.dstCity, isSchByAirport: trip.isDstSchByAirport }}
                                                    inputId={`arr_city_${index}`}
                                                    onSelect={(val, isSchByAirport) => this.handleCityChange(index, false, val, isSchByAirport)}
                                                    intl={intl}
                                                />
                                            </Form.Item>
                                        </Col>
                                        <Col {...formSubItemLayout.wrapperCol}>
                                            <Form.Item {...formItemLayout}>
                                                <DatePicker
                                                    className='date-txt__wrap input-require'
                                                    value={trip.depDate && moment(trip.depDate)}
                                                    format={getCurrentLang() === Languages.en_us ? 'DD MMM YYYY' : 'YYYY[年]MM[月]DD[日]'}
                                                    onChange={date => this.handleDateChange(index, date)}
                                                    style={{ width: '100%' }}
                                                    placeholder={intl.formatMessage({ id: 'departure_date' })}
                                                    suffixIcon={<Fragment />}
                                                    showToday={false}
                                                    allowClear={false}
                                                    disabledDate={date => this.disabledDate(index, date)}
                                                />
                                            </Form.Item>
                                        </Col>
                                        <Col {...formSubItemLayout.wrapperCol}>
                                            <Form.Item {...formItemLayout}>
                                                <Select value={trip.depTime || ''} onChange={val => handleSearchParamChange({ ...searchParam, multiFlights: searchParam.multiFlights.map((v, i) => i === index ? { ...v, depTime: val } : v) })}>
                                                    <Option value=''><FormattedMessage id='any_time' /></Option>
                                                    <Option value='00:00-06:00'>00: 00 - 06: 00</Option>
                                                    <Option value='06:00-12:00'>06: 00 - 12: 00</Option>
                                                    <Option value='12:00-18:00'>12: 00 - 18: 00</Option>
                                                    <Option value='18:00-23:59'>18: 00 - 24: 00</Option>
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                        <Col xs={0} md={1} className={isWidgets ? 'widget-wrapper-delete' : 'wrapper-delete'}>
                                            {index > 0 && <span className='f-delete' onClick={() => this.handleDeleteTrip(index)}></span>}
                                        </Col>
                                    </Row>
                                </Form.Item>
                            );
                        })}

                        {searchParam.multiFlights.length < 2 && <Form.Item>
                            <div className='add-flight-btn' onClick={this.handleAddFlight}><FormattedMessage id='add_another_flight' /></div>
                        </Form.Item>}

                        {/* <Form.Item {...formItemLayout} label='Flight Pricing Mode'>
                            <Select value={searchParam.searchModes + ''} onChange={val => handleSearchParamChange({ ...searchParam, searchModes: val })}>
                                <Option value='1'>Roundtrip</Option>
                                <Option value='2'>Sector Fare</Option>
                            </Select>
                        </Form.Item> */}

                        <Form.Item {...formItemLayout} label={<FormattedMessage id='cabin_class' />}>
                            <Select value={searchParam.fareClass} onChange={val => handleSearchParamChange({ ...searchParam, fareClass: val })}>
                                <Option value='Y'><FormattedMessage id='economy' /></Option>
                                <Option value='S'><FormattedMessage id='premium_economy' /></Option>
                                <Option value='C'><FormattedMessage id='business' /></Option>
                                <Option value='F'><FormattedMessage id='first_class' /></Option>
                            </Select>
                        </Form.Item>
                        <Form.Item {...formItemLayout} label={<Checkbox checked={parseInt(searchParam.directFlight) ? true : false} onChange={e => handleSearchParamChange({ ...searchParam, directFlight: e.target.checked ? 1 : 0 })}><FormattedMessage id='direct' /></Checkbox>}></Form.Item>
                        <Row>
                            <Col sm={4} md={5} className="submit">
                                <button type="submit" className="ctm-btn ctm-btn-success" style={{ width: '100%', marginTop: 10, marginBottom: 30 }}><FormattedMessage id='search' /></button>
                            </Col>
                        </Row>
                    </Form>
                </div>

            </Fragment>
        );
    }

}

const FareForecasterForm = Form.create()(SearchForm);

export default FareForecasterForm;