import {
    sso2AppInvoked,
    sso2AppSuccess,
    sso2AppFailed,
    SSO_LOGIN_2_LIGHTNING,
    SSO_LOGIN_2_APP
} from '../sso';
import { put, call, takeLatest } from 'redux-saga/effects';
import { sso2App as sso2AppClient } from '~/client';
import { config, combineUrl, getProfileToken, getToken } from '~/common';
import { store } from '~/index.js';
import { auth0Timeout } from '../application';

function* sso2Lightning(action) {
    try {
        yield put(sso2AppInvoked());
        const user = store.getState().user;
        const params = {
            Email: user && user.email,
            AppId: config.ssoAppId,
            Secret: config.ssoSecret,
            ReturnTo: combineUrl(config.localhostUrl, 'callback?sso=1'),
            AccessToken: getToken(),
            profileToken: getProfileToken() || '',
        };
        if (!params.AccessToken) {
            yield put(auth0Timeout());
        } else {
            const directUrl = (action.payload && action.payload.DirectUrl) || '';
            const payload = {
                Status: 0,
                Url: combineUrl(config.obtUrl, `sso/token.aspx?appId=${params.AppId}&returnTo=${encodeURIComponent(params.ReturnTo)}&accessToken=${encodeURIComponent(params.AccessToken)}&directUrl=${encodeURIComponent(directUrl)}&profileToken=${encodeURIComponent(params.profileToken)}`),
            };
            yield put(sso2AppSuccess(payload));
        }
    } catch (ex) {
        yield put(sso2AppFailed(ex.message));
    }
}

function* sso2App(action) {
    try {
        const { SystemId, AppId } = action.payload;
        let params = {
            profileToken: getProfileToken() || '',
            SystemId,
            AppId,
            AccessToken: getToken()
        }
        if (!params.AccessToken) {
            yield put(auth0Timeout());
        } else {
            yield put(sso2AppInvoked());
            const payload = yield call(sso2AppClient, params);
            yield put(sso2AppSuccess(payload));
        }
    } catch (ex) {
        yield put(sso2AppFailed(ex.message));
    }
}

export default function* () {
    yield takeLatest(SSO_LOGIN_2_LIGHTNING, sso2Lightning);
    yield takeLatest(SSO_LOGIN_2_APP, sso2App);
}
