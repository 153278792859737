import React from 'react';
import { Alert } from 'antd';
import { injectIntl } from 'react-intl';

const PageNotFound = (props) => {
    const intl = props.intl;
    let message = intl.formatMessage({ id: 'page_not_found' });
    if (props.location && props.location.pathname && ['fare-forecaster', 'traveller-tracker', 'contact-us', 'pre-trip', 'trip-proposal'].includes(props.location.pathname.toLowerCase().split('/').filter(Boolean)[0])) {
        message = intl.formatMessage({ id: 'page_no_right' });
    }
    return <Alert
        message={message}
        description={intl.formatMessage({ id: 'page_not_found_prompt' })}
        type="error"
        style={{ marginTop: '20px', maxHeight: 100, width: '100%' }}
    />;
};

export default injectIntl(PageNotFound);
