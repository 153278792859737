import moment from 'moment';
import { Form } from 'antd';
import { store } from '~/index.js';
import { getMetadata } from '~/redux/tripProposal';
import { CTMModal } from '~/components';
import { getValue4Lang } from '~/common';

export function convertDate(dateStr, format) {
    if (dateStr) {
        if (!format) {
            if (/^\d{8}$/.test(dateStr)) {
                format = 'YYYYMMDD';
            } else if (/^\d{4}-\d{2}-\d{2}$/.test(dateStr)) {
                format = 'YYYY-MM-DD';
            } else if (/^\d{12}$/.test(dateStr)) {
                format = 'YYYYMMDDHHmm';
            } else if (/^\d{14}$/.test(dateStr)) {
                format = 'YYYYMMDDHHmmss';
            }
        }
        return moment(dateStr, format);
    }
    return null;
}

export const formatDate = date => date ? date.format('YYYYMMDD') : '';

export const getRouting = (airfare) => {
    let routing = '';
    airfare.flights.forEach((flight, fIndex) => {
        const dep = flight.segments[0].depAirport;
        const arr = flight.segments.slice(-1)[0].arrAirport;
        let prevFlight = fIndex > 0 ? airfare.flights[fIndex - 1] : null;
        if (!prevFlight) {
            routing += dep + ' > ' + arr;
        } else {
            const prevArr = prevFlight.segments.slice(-1)[0].arrAirport;
            if (prevArr != dep) {
                routing += ' / ' + dep;
            }
            routing += ' > ' + arr;
        }
    });
    return routing;
}

export function convertUTCDate(date, timezone) {
    return convertDate(date).subtract(timezone, 'hour');
}

export function getDateDiff(date1, date2) {
    const d1 = convertDate(date1).endOf('date');
    const d2 = convertDate(date2).endOf('date');
    if (d1.isSame(d2)) return '';
    return `${d2.isAfter(d1) ? '+' : ''}${d2.diff(d1, 'day')}`;
}

export function initTripUdidFormFields(submitParam) {
    let field = {
        tripUdids: [],
    };
    submitParam.tripUdids && submitParam.tripUdids.forEach((tripUdid, index) => {
        const key = Object.keys(tripUdid)[0];
        field.tripUdids.push({ [key]: { value: tripUdid[key] } });
    });
    return field;
}

export function createTripUdidFormFields(fields) {
    let formFields = {
        tripUdids: [],
    };
    fields.tripUdids && fields.tripUdids.forEach((field, index) => {
        const key = Object.keys(field)[0];
        let formField = Form.createFormField(field[key]);
        eval(`formFields['tripUdids.${index}.${key}']=formField`);
    });
    return formFields;
}

export const prepareMetadata = () => {
    return new Promise(resolve => {
        const state = store.getState();
        if (state.tripProposal.metadata) {
            resolve();
        } else {
            !state.tripProposal.loading && store.dispatch(getMetadata());
            const unsubscribe = store.subscribe(() => {
                const tripProposal = store.getState().tripProposal;
                if (tripProposal.metadata || tripProposal.errMsg) {
                    unsubscribe();
                    tripProposal.errMsg && CTMModal.error({ title: getValue4Lang('Get trip proposal data failed: ', '获取行程计划数据失败：', '獲取行程計劃數據失敗：') + tripProposal.errMsg });
                    resolve(tripProposal.errMsg);
                }
            });
        }
    });
}
