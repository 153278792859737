import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Select, Input, Checkbox, Radio, InputNumber } from 'antd';

const { Option } = Select;

class BaseInput extends Component {
    constructor(props) {
        super(props);
    }

    shouldComponentUpdate(nextProps, nextState) {
        return ['value', 'dataSource', 'id', 'viewOnly'].concat(this.props.scuProps || []).some(k => JSON.stringify(this.props[k]) !== JSON.stringify(nextProps[k]));
    }

    render() {
        const {
            viewOnly,
            dataSource,
            value,
            valueIn,
            valueOut,
            onChange,
            component: Component,
            trigger,
            type,
            onPropsInit,
            scuProps,
            ...restProps
        } = this.props;
        const renderProps = {
            ...restProps,
            disabled: viewOnly,
            autoComplete: "off",
            value: valueIn ? valueIn(value) : value,
            [trigger || 'onChange']: val => {
                if (viewOnly) return;
                let outVal = valueOut ? valueOut(val) : val;
                onChange(outVal);
            },
        };
        if (onPropsInit) {
            onPropsInit(renderProps);
        }
        if (Component) {
            return <Component className="ctm-bi-txt-wrap" {...renderProps} dataSource={dataSource} />;
        }
        if (type === 'checkbox') {
            return <Checkbox checked={!!renderProps.value} {...renderProps}>{this.props.children}</Checkbox>;
        }
        if (type === 'textarea') {
            return <Input.TextArea className="ctm-bi-txt" {...renderProps} />;
        }
        if (type === 'number') {
            return <InputNumber className="ctm-bi-txt" {...renderProps} />;
        }
        if (dataSource && dataSource.length > 0) {
            return (
                <Select
                    className="ctm-bi-txt-wrap"
                    {...renderProps}
                >
                    {dataSource.map((ds, index) => {
                        return <Option key={index} value={ds.code} disabled={ds.disabled}>{ds.name}</Option>;
                    })}
                </Select>
            );
        }
        return (
            <Input className="ctm-bi-txt" {...renderProps} />
        );
    }
}

BaseInput.propTypes = {
    type: PropTypes.oneOf(['text', 'select', 'checkbox', 'textarea', 'number']),
    viewOnly: PropTypes.bool,
    dataSource: PropTypes.array,//for "select" type, [{code,name,disabled}...]
    valueIn: PropTypes.func,
    valueOut: PropTypes.func,
    component: PropTypes.func,
    trigger: PropTypes.string,
    scuProps: PropTypes.array,//shouldComponentUpdate props
}

export default BaseInput;