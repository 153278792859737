module.exports = {
    language: "语言",
    system_information: "系统提示",
    login_lightning_failed_prompt: "单点登录lightning失败, 无效用户",
    loading: "加载中...",
    page_not_found: "404 - 找不到页面!",
    page_not_found_prompt: "非常抱歉，你查询的页面不存在。",
    record_not_found: "找不到记录",
    error_occurred: "发生错误",
    booking_not_found: '未找到预订',
    dashboard: '首页',
    tools: '工具',
    fare_forecaster: '机票报价',
    deadline_alert: '行程预订提醒',
    pending_for_approval: '待审批行程',
    upcoming_trips: '待出發行程',
    deadline: '截止日期',
    logout: '退出',
    change_password: '更改密码',
    fare_forecaster_form_prompt: '请在下方输入您的搜寻条件，以查看最优惠的航班价格。',
    close_filters: '关闭搜寻',
    flight_index_fmt: '航班 {index}',
    enter_city_airport_name: '输入城市 / 机场',
    departure_date: '出发日期',
    any_time: '不限时间',
    add_another_flight: '添加航班',
    cabin_class: '舱位等级',
    first_class: '头等舱',
    business: '商务舱',
    premium_economy: '特选经济舱',
    economy: '经济舱',
    direct: '直飞',
    search: '搜寻',
    no_data: '-',
    one_way: '单程',
    round_trip: '往返',
    multi_city: '多程',
    enter_an_airport: '输入机场',
    just_loading_your_search_results: '正在加载你的搜寻结果',
    departing: '去程',
    returning: '回程',
    departure: '去程',
    return: '回程',
    lowest_fare: '最低票价',
    booking_now: '现在预订',
    same_city_prompt: '出发城市不能和到达城市相同',
    multi_city_not_support_prompt: '多程暂未支持，请试单程或者往返',
    search_timeout_prompt: '搜寻超时，请刷新你的搜寻',
    refresh: '刷新',
    all: '所有',
    stop: '经停',
    duration_fmt: '{hours}小时{minutes}分钟',
    traveler_tracker: '旅客踪迹',
    highest_fare: '最高票价',
    browser_not_support_webgl: 'Mapbox GL需要WebGL支持。 请检查您是否使用支持的浏览器并启用了WebGL。',
    en_route: '旅途中',
    landed: '已抵达',
    at_risk: '有风险',
    type: '类型',
    code: '代码',
    total: '总数',
    airport: '机场',
    hotel: '酒店',
    back_to_home_page: '返回首页',
    oops_something_went_wrong: '糟糕，页面出错了！',
    trip_name: '行程名称',
    update_search: '更新搜寻',
    on_time: '准点',
    delayed: '延误',
    not_departed: '未出发',
    my_apps: '我的应用',
    hello: '你好',
    status: '状态',
    arrival: '抵达',
    check_in: '入住',
    check_out: '退房',
    order_no: '订单编号',
    select_screen_theme: '选择主题',
    select_language: '选择语言',
    breezy: '微风',
    galaxy: '银河',
    widgets: '小工具',
    start_date: '开始日期',
    end_date: '结束日期',
    to: '到',
    world_clock: '世界时间',
    add_new_clock: '添加时钟',
    choose_timezone: '选择时区',
    delete: '刪除',
    cancel: '取消',
    day: '{type,select,0{前}other{后}}{count}天',
    week: '{type,select,0{前}other{后}}{count}周',
    month_to_date: '本月初',
    date_to_month_end: '本月月底',
    search_trip: '输入姓名、航班、酒店名称或地点搜索',
    trip_between: '{endDate,select,noLimit{{startDate,select,noLimit{显示所有行程}other{显示 {previous} 之后的行程}}}other{{startDate,select,noLimit{显示 {upcoming} 之前的行程}other{显示 {previous} 和 {upcoming} 之间的行程}}}}',
    previous: '从',
    upcoming: '至',
    found: '找到',
    no_limit: '没有限制',
    please_select: "请选择",
    filter: "筛选",
    no_booking_for_approval: "没有待审批的行程",
    no_booking_for_upcoming: "没有待出发的行程",
    no_booking_be_alert: "没有行程预订提醒",
    any: "所有",
    click_for_detail: '点击查看详情',
    agency_record_locator: '行程编号',
    travel_time_fmt: '飞行时间: {hours}小时{minutes}分钟',
    amenities: '设施',
    amenities_not_found: '没有找到设施',
    nights_count: '晚数',
    description: '说明',
    location: '所在位置',
    stopover: '经停',
    view_in: '前往',
    trip_count_fmt: '{count,plural,=0{没有行程}other{# 段行程}}',
    fare_not_found: '找不到机票报价',
    online_check_in: '在线值机',
    login_for_mobile: '登录Lightning Mobile',
    mobile_phone_login: '手机登录',
    download_app: '下载应用程序',
    scan_qr_code_to_download_app: '扫描二维码下载客户端',
    download_app_already_login_now: '已下载客戶端，立即登录',
    deactivate: '停用',
    login_ctm_lightning_mobile_app: "请扫描二维码登录CTM Lightning手机客户端",
    risk_news: '新闻资讯',
    risk_level: '风险等级',
    country: "国家 / 地区",
    keyword: "关键字",
    country_code_or_name: "国家/地区 代码或名称",
    read_less: "收起...",
    read_more: "查看更多...",
    scroll: "鼠标滚动",
    no_risk_news: "没有新闻资讯",
    on: "开",
    off: "关",
    georisk_map: "风险地区",
    risk_level_up: '风险等级',
    risk_alert_fmt: '{count,plural,=0{没有风险摘要}other{# 则风险摘要}}',
    new: "未读",
    load_more: "加载更多",
    search_for_a_news: "搜索更新",
    search_for_a_document: "搜索文件",
    search_for_a_link: "搜索网址",
    news: "快讯",
    documents: "文档",
    links: "网址",
    news_feed: "资讯",
    no_news_found: "找不到快讯",
    no_links_found: "找不到网址",
    no_documents_found: "找不到文档",
    cancelled: "航班取消",
    unknown: "未知",
    warning: "警告",
    contact_us: "联系我们",
    contact_person: "联系人",
    phone: "电话",
    email: "邮件",
    reset_your_password: "重置密码",
    please_enter_your_email_address: "请输入您的电子邮件地址，我们将发送电子邮件给您重置密码",
    your_new_password: "设置新密码",
    confirm_your_new_password: "再次确认新密码",
    invalid_email_format: "无效的邮件地址",
    send_email: "发送邮件",
    your_password_has_been_reset_successfully: "您已成功重置密码。",
    enter_a_new_password: "请为{email}重置密码",
    retry: "重试",
    or: "或者",
    timeout_tip: "会话超时，请重新登录",
    you_have_signed_out: "您已成功退出 CTM Portal",
    profile: '资料',
    continue: '继续',
    password_policy: '密码政策',
    at_least_6_characters_in_length: '请输入至少6位字符',
    ok: '确定',
    share: '分享',
    copy_link_success: '复制链接成功',
    the_document_was_not_downloaded: '文档没有下载？',
    trip_request: '行程请求',
    page_no_right: '403 - 你没有权限查看此页面！',
    not_authorized_login_tip: '您无权访问此网址。请在访问此网址前登录CTM Portal。',
    not_authorized_tip: '您无权访问此网址。',
    login_now: '立即登录',
    invalid_parameter: '无效的参数',
    later: '稍后',
    confirm: '确认',
    close: '关闭',
    save: '保存',
    cancel_request_tip: '此行程请求将被取消。请确认是否要继续。',
    submit_to_approver_tip: '您确认将此行程请求提交至审批人吗？',
    submit_to_tc_tip: '您确认将此行程请求提交至旅游顾问跟进吗？',
    cancel_flight_request_tip: '您确定要取消这个航班请求吗？',
    cancel_hotel_request_tip: '您确定要取消这个酒店请求吗？',
    cancel_other_service_request_tip: '您确定要取消这个服务请求吗？',
    update_success: '更新成功',
    make_booking_tip: '您确定为此行程请求创建订单吗？',
    approve_tip: '您确定要批准此行程请求吗？',
    optional_approve_reason: '选填批准原因',
    please_enter: '请输入',
    approve_request: '审批请求',
    approve: '批准',
    please_enter_reject_reason: '请输入拒绝原因',
    approval_declined: '审批不通过',
    decline: '拒绝',
    resend_email: '重发电子邮件',
    resend_email_success: '重新发送邮件成功',
    resend_email_tip: '您确定重新发送审批邮件吗？',
    select_approver: '选择审批人',
    select_approver_tip: '请先选择审批人并创建审批流程。',
    add_flight_request: '添加航班请求',
    add_hotel_request: '添加酒店请求',
    add_other_service_request: '添加其他服务请求',
    edit_flight_request: '编辑航班请求',
    edit_hotel_request: '编辑酒店请求',
    edit_other_service_request: '编辑其他服务请求',
    trip_request_detail: '行程请求详情',
    operation_history: '操作历史',
    operated_by: '操作人',
    operated_on: '操作时间',
    back: '返回',
    new_trip_request: '新建行程请求',
    approver_not_found: '找不到审批人',
    please_select_approver: '请选择审批人',
    change_level_approver_fmt: '更改级别{step}审批人',
    edit_note_to_approver: '编辑审批人须知',
    approve_form_not_found_tip: '找不到审批表单，请联系您的旅游顾问',
    approval_workflow: '审批流程',
    no_need_approval: '不需要审批',
    please_select_level_approver_fmt: '请选择级别{step}审批人',
    note_to_approver: '审批人须知',
    attachment: '附件',
    level_fmt: '级别{step}',
    change_approver: '更改审批人',
    remark: '备注',
    remark_colon: '备注：',
    please_enter_trip_name: '请输入行程名称',
    special_request: '特别要求',
    trip_overview: '行程概览',
    traveller_udid_not_found: '找不到旅客UDID信息',
    enable: '启用',
    please_enter_this_field: '请输入此项',
    update_trip_information: '更改行程信息',
    created: '创建',
    total_budget: '总计',
    request_reference: '请求编号',
    booking_num: '订单号',
    traveller: '旅客',
    contact_phone_num: '联系电话号码',
    trip_information: '行程信息',
    details: '详情',
    flight: '航班',
    price_currency_tip_fmt: '如无额外声明，所有票价均以{currency}显示',
    segment_departure: '出发',
    cabin: '舱位等级',
    time_prefer: '时间偏好',
    airlines: '航空公司',
    seat_preference: '座位偏好',
    meal_preference: '餐食偏好',
    flight_budget: '航班预算',
    taxes_tip_with_parenthesis: '（包括税费和服务费）',
    edit: '编辑',
    clear: '清除',
    room_name: '房间名称',
    loyalty_program: '会员计划',
    hotel_budget: '酒店预算',
    add_flight: '添加航班',
    add_hotel: '添加酒店',
    add_other_service: '添加其他服务',
    cancel_request: '取消请求',
    submit_to_approver: '提交至审批人',
    confirm_request: '确认请求',
    submit_to_consultant: '提交至旅游顾问',
    make_booking: '创建订单',
    create_new: '新建',
    search_result_colon: '搜寻结果：',
    trip_request_search: '搜寻行程请求',
    advanced_trip_request_search: '高级搜寻行程请求',
    company: '公司',
    traveller_names: '旅客姓名',
    traveller_email: '旅客电邮',
    destination: '目的地',
    hotel_name: '酒店名称',
    request_date_from: '请求日期从',
    request_date_to: '请求日期至',
    departure_date_from: '出发日期从',
    departure_date_to: '出发日期至',
    return_date_from: '返回日期从',
    return_date_to: '返回日期至',
    general_search: '一般搜寻',
    advanced_search: '高级搜寻',
    start: '开始',
    end: '结束',
    requested: '请求',
    please_select_fmt: '请选择{0}',
    please_enter_fmt: '请输入{0}',
    please_enter_correct_fmt: '请输入正确的{0}',
    departure_city: '出发城市',
    arrival_city: '抵达城市',
    fare_class: '票价类别',
    departure_time: '出发时间',
    city: '城市',
    check_in_date: '入住日期',
    check_out_date: '退房日期',
    please_fill_in_your_other_requirements: '请填写你的其他需求',
    add_another_hotel: '添加酒店',
    all_status: '所有状态',
    comment: '评论',
    write_a_message_here: '在此处填写留言...',
    invalid_message: '无效的留言',
    message_size_max_limit_colon: '留言大小最大限制：',
    pending: '待审批',
    approved: '已批准',
    rejected: '已拒绝',
    already_exist_fmt: '{0}已经存在',
    invalid_attachment_type: '无效的附件类型',
    support_attachment_type_colon: '支持的附件类型：',
    invalid_attachment: '无效的附件',
    attachment_size_max_limit_colon: '附件大小最大限制：',
    click_drag_upload: '点击或拖动文件到这里',
    single_bulk_upload: '支持单个或批量上传',
    download: '下载',
    invalid_traveller: '无效旅客',
    confirm_request_tip: '您确定此行程请求吗？',
    no_trip_request: '没有行程请求',
    message_dialog: '留言',
    related_login_not_found: '找不到关联用户',
    special_request_remark: '特别要求（备注）',
    itinerary: '行程',
    please_enter_different_city: '请输入不同城市',
    flow_type: '流程类型',
    approval_history: '审批历史',
    original_total_colon: '原总计：',
    original_budget_colon: '原预算: ',
    show_all: '显示全部',
    show_less: '显示更少',
    other_service: '其他服务',
    budget: '预算',
    category: '类别',
    add_another_service: '添加其他服务',
    online_trip_proposal: '在线行程计划',
    no_online_trip_proposal: '没有在线行程计划',
    reference_number: '请求编号',
    online_trip_proposal_search: '搜寻在线行程计划',
    advanced_online_trip_proposal_search: '高级搜寻在线行程计划',
    online_trip_proposal_detail: '在线行程计划详情',
    proposal_ref: '请求编号',
    overviews: '概述',
    estimate_total: '估计总额',
    total_amount: '总额',
    per_adult: '每成人',
    detail: '详情',
    client_information: '客人信息',
    name: '姓名',
    itinerary_detail_of_fmt: '{0}的行程详情',
    ticket_fare_per_person: '每人的票价',
    show_detail: '显示详情',
    depart: '出发',
    travel_time: '飞行时间',
    fare: '票价',
    tax: '税',
    surcharge: '附加费',
    service_fee: '服务费',
    pnr_fee: 'PNR费',
    credit_card_payment: '信用卡支付',
    discount: '折扣',
    selected_fmt: '已选{0}',
    option: '选项',
    carbon_emission: '碳排放',
    not_included: '不包含',
    higher: '更高',
    emissions: '排放',
    this_flight: '本航班',
    typical_for_this_route: '这条路线的典型情况',
    lower: '更低',
    emissions_tip: '排放量是以您选择的座位等级中的一名乘客计算的。',
    carry_on_bag: '手提行李',
    checked_bag: '托运行李',
    carry_on_bag_fmt: '第{0}段手提行李',
    checked_bag_fmt: '第{0}段托运行李',
    confirm_trip_detail: '确认行程详情',
    traveller_information: '旅客信息',
    itinerary_information: '行程信息',
    not_lowest_price_tip: '该航班不是最低价格。',
    current_price: '当前价格',
    lowest_price: '最低价格',
    lowest_fare_tax: '最低价税',
    lowest_fare_carrier: '最低价航空公司',
    optional: '可选',
    proposal_created_tip: '你的预订已成功创建。',
    proposal_submit_tip: '请提交本预订以继续您的预订程序。',
    trip_plan_fmt: '行程计划{0}',
    option_fmt: '选项{0}',
    agency_contact_person: '代理联系人',
    client_company_name: '客人公司名称',
    operated_by_fmt: '承运：{0}',
    no_check_in_baggage: '没有托运行李',
    please_confirm_trip_plan_option_first: '请先确认行程计划选项',
    fare_rules: '票价规则',
    itineraries: '行程',
    corporate_deal: '公司协议价',
    sme_deal: 'SME协议价',
    rules: '规则',
    selected: '已选',
    select: '选择',
    processing_your_booking_now: '正在处理你的预订...',
    traveller_not_found: '找不到旅客',
    finalize: '确认',
    select_approver_success_tip: '选择审批人成功，现在确认你的订单吗？',
    related_booking_info_not_found: '找不到关联订单信息',
    submit: '提交',
    ancillary: '附加费',
    flight_ordinal_fmt: '第{ordinal}躺航班',
    transfer: '中转',
    transfer_in: '中转',
    otp_stop: '经停',
    otp_stopover: '经停',
    transaction_fee: '附加交易手续费（CTM收取）',
    admin_service_fee: '附加服务手续费（CTM收取）',
    preview_email_dialog_history: '预览邮件对话历史',
    email_dialog_history: '邮件对话历史',
    date: '日期',
    subject: '主题',
    email_dialog_history_not_found: '找不到邮件对话历史',
    email__from: '发件人',
    email__to: '收件人',
    email__cc: '抄送',
    email__bcc: '密送',
    all_rules: '所有规则',
    all_detail: '所有详情',
    transit_time: '中转时间',
    risk_hub: '风险等级查询',
    dark: '深色',
    light: '浅色',
}