import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import {Languages, getCurrentLang} from '~/common';
import moment from 'moment';

const API_DATE_FORMAT = 'YYYYMMDD';
const UI_DATE_FORMAT = getCurrentLang() === Languages.en_us ? 'DD MMM YYYY (ddd)':'YYYY年MM月DD日 (ddd)';

class HotelDetail extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    componentDidMount() {

    }

    render() {
        const { detail } = this.props;
        if (!detail) return null;
        return (
            <div className='detail-hotel-item'>
                <div className="hotel-name-info">
                    {detail.HotelName}
                </div>
                <div className="hotel-detail-info">
                    <div className="date-row-info">
                        <div className="date-col">
                            <div className="key-font">
                                <FormattedMessage id='check_in' />
                            </div>
                            <div className="value-font">{moment(detail.CheckinDateRemark, API_DATE_FORMAT).format(UI_DATE_FORMAT).toUpperCase()}</div>
                        </div>
                        <div className="date-col">
                            <div className="key-font">
                                <FormattedMessage id='check_out' />
                            </div>
                            <div className="value-font">{moment(detail.CheckoutDateRemark, API_DATE_FORMAT).format(UI_DATE_FORMAT).toUpperCase()}</div>
                        </div>
                        <div className="date-col">
                            <div className="key-font">
                                <FormattedMessage id='nights_count' />
                            </div>
                            <div className="value-font">{detail.Nights || '-'}</div>
                        </div>
                    </div>
                    {detail.RoomName &&
                        <div className="room-info">
                            <div className="key-font">
                                <FormattedMessage id='description' />
                            </div>
                            <div className="value-font">{detail.RoomName || '-'}</div>
                        </div>}
                    {detail.Address &&
                        <div className="addr-info">
                            <div className="key-font">
                                <FormattedMessage id='location' />
                            </div>
                            <div className="value-font">{detail.Address || '-'}</div>
                        </div>}
                </div>
            </div>
        );
    }

}

HotelDetail.propTypes = {
    detail: PropTypes.object.isRequired,
}

export default HotelDetail;