import React from 'react';
import PropTypes from 'prop-types';
import { getProfileToken, config, getCurrentLang, getToken } from '~/common';
import { connect } from 'react-redux';
import { store } from '~/index.js';
import { getLoginProfile } from '~/redux/loginProfile';

window['__onMyProfileChange'] = function () {
    store && store.dispatch(getLoginProfile());
}

class ProfileManage extends React.Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        this.mounted = true;
        this.loadProfileManage();
    }

    componentWillUnmount() {
        this.mounted = false;
        const $pm = window['$pm'];
        typeof $pm === 'function' && $pm('unmount');
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.profileManageScriptUrl !== this.props.profileManageScriptUrl) {
            const $pm = window['$pm'];
            typeof $pm === 'function' && $pm('unmount', true);
            this.loadProfileManage();
        }
    }

    loadProfileManage = () => {
        const { profileManageScriptUrl } = this.props;
        if (!profileManageScriptUrl) {
            console.error('empty profile manage script URL');
            return;
        }
        let tag = Array.from(document.getElementsByTagName('script')).find(t => t.src && t.src === profileManageScriptUrl && !!t.dataset.config);
        if (!tag) {
            let tag = document.createElement('script');
            tag.src = profileManageScriptUrl;
            let pmConfig = {
                name: '$pm',
                targetElementId: this.props.id,
                apiUrl: config.obtApiUrl,
                apiToken: getProfileToken(),
                accessToken: getToken(),
                language: getCurrentLang(),
                onMyProfileChange: '__onMyProfileChange',
            };
            tag.setAttribute('data-config', JSON.stringify(pmConfig).replace(/"/g, "'"));
            tag.onload = () => {
                if (!this.mounted) {
                    window['$pm']('unmount');
                }
            };
            document.body.appendChild(tag);
        } else {
            window['$pm']('init');
        }
    }

    shouldComponentUpdate(nextProps, nextState) {
        return nextProps.profileManageScriptUrl !== this.props.profileManageScriptUrl;
    }

    render() {
        const { id } = this.props;
        return (
            <div className='trips-container widget' id={id}></div>
        );
    }
}

ProfileManage.propTypes = {
    id: PropTypes.string.isRequired,
};

export default connect(state => ({
    profileManageScriptUrl: state.application.profileManageScriptUrl,
}))(ProfileManage);
