import { request, config, combineUrl } from '~/common';

const membershipRequest = (params, callback, action) => {
    return request(combineUrl(config.obtApiUrl, `Membership/V1/json/${action}`), params, callback);
}

export function saveUserSetting(params, callback) {
    return membershipRequest(params, callback, 'SaveUserSetting');
}

