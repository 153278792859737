import React, { Fragment } from 'react';
import { Breadcrumb, message, Modal } from 'antd';
import { FareForecasterForm, FareForecasterResult } from '~/components';
import { searchCity, searchFlightCalendar, searchFlightCalendarDetail } from '~/client';
import { TripType, getProfileToken, getError, getTripType, animate, ThemeType, ErrorCode } from '~/common';
import { connect } from 'react-redux';
import { sso2Lightning } from '~/redux/sso';
import { toggleAsideMenu } from '~/redux/application';
import { FormattedMessage, injectIntl } from 'react-intl';
import moment from 'moment';
import BaseWidget from '../../components/BaseWidget';
import { withRouter } from 'react-router-dom';

const SEARCH_DATE_FORMAT = 'YYYYMMDD';

class FareForecaster extends React.Component {
    constructor() {
        super();
        this.state = {
            isLoading: false,
            searchParam: {
                multiFlights: [
                    { depCity: '', isDepSchByAirport: false, isDstSchByAirport: false },
                    { dstCity: '', isDepSchByAirport: false, isDstSchByAirport: false }
                ],
                searchModes: 1,
                fareClass: 'Y',
                directFlight: 0,
            },
            orgSearchParam: null,
            searchParam4Result: null,
            citiesInfo: new Map(),
        };
    }

    componentDidMount() {
        this.mounted = true;
        if (this.props.location && this.props.location.state) {
            this.handleSearchParamChange(this.props.location.state);
            this.handleSubmit('', this.props.location.state);
        }
        else {
            this.props.defaultDepartureCity && this.setDefaultDepartureCity();
        }
    }

    componentWillUnmount() {
        this.mounted = false;
        this.searchTimeoutId && clearTimeout(this.searchTimeoutId);
    }

    componentDidUpdate(prevProps, prevState) {
        const defaultDepartureCity = this.props.defaultDepartureCity;
        if (!this.isSearchParamChange && defaultDepartureCity && defaultDepartureCity !== prevProps.defaultDepartureCity) {
            this.setDefaultDepartureCity();
        }
    }

    setDefaultDepartureCity = () => {
        const defaultDepartureCity = this.props.defaultDepartureCity;
        let params = this.state.searchParam;
        params.multiFlights[0].depCity = defaultDepartureCity;
        params.multiFlights[1] && (params.multiFlights[1].dstCity = defaultDepartureCity);
        this.handleSearchParamChange(params);
    }

    initCitiesInfo = searchParam => {
        let keys = [];
        searchParam.multiFlights.forEach((trip) => {
            if (trip.depCity) keys.push(`${trip.depCity}-${trip.isDepSchByAirport ? 0 : 1}`);
            if (trip.dstCity) keys.push(`${trip.dstCity}-${trip.isDstSchByAirport ? 0 : 1}`);
        });
        keys = [...new Set(keys)].filter(k => !this.state.citiesInfo || !this.state.citiesInfo.has(k));
        if (keys && keys.length) {
            return searchCity(keys.map(k => k.split('-')[0]).join('|'), null, (err, data) => {
                if (data && data.datas) {
                    let citiesInfo = this.state.citiesInfo;
                    data.datas.forEach(item => {
                        citiesInfo.set(`${item.code}-${item.type}`, item);
                        item.datas && item.datas.forEach(subItem => {
                            citiesInfo.set(`${subItem.code}-${subItem.type}`, subItem);
                        });
                    });
                    this.mounted && this.setState({ citiesInfo });
                }
            });
        }
        return Promise.resolve();
    }

    handleSearchParamChange = param => {
        this.isSearchParamChange = true;
        this.mounted && this.setState({ searchParam: param });
    }

    handleAnimate = () => {
        requestAnimationFrame(() => {
            if (document.getElementsByClassName('fare-forecaster-result-wrapper').length) {
                animate.slideDown(document.getElementsByClassName('fare-forecaster-result-wrapper')[0], null, () => {
                    this.props.toggleAsideMenu(true);
                });
            } else {
                this.handleAnimate();
            }
        });
    }

    handleSubmit = (e, searchParam) => {
        e && e.preventDefault();
        if (!searchParam) searchParam = this.state.searchParam;
        let errMsg = '';

        for (let trip of searchParam.multiFlights) {
            if (!trip.depCity || !trip.dstCity || !trip.depDate) {
                errMsg = 'notWarning';
                for (let inputParent of [...document.getElementsByClassName('input-require')]) {
                    let inputRequire = inputParent.getElementsByTagName('input')[0];
                    if (!inputRequire.value) {
                        inputRequire.click();
                        break
                    }
                }
                break;
            }
            if (trip.depCity === trip.dstCity) {
                errMsg = this.props.intl.formatMessage({ id: 'same_city_prompt' });
                break;
            }
        }
        if (!errMsg) {
            const tripType = getTripType(searchParam.multiFlights);
            if (tripType === TripType.MultiCity) {
                errMsg = this.props.intl.formatMessage({ id: 'multi_city_not_support_prompt' });
            }
        }
        if (errMsg) {
            errMsg !== 'notWarning' && message.warn(errMsg);
        } else {
            if (this.props.isWidgets) {
                this.props.history.push('/fare-forecaster', searchParam);
                return;
            };
            window.scrollTo(0, 80);
            this.mounted && this.setState({ closeForm: true, searchParam, orgSearchParam: JSON.parse(JSON.stringify(searchParam)), isLoading: true, result: null, detail: null, error: null, detailError: null });
            this.handleAnimate();
            this.initCitiesInfo(searchParam).then(_ => {
                this.searchFareForecaster(JSON.parse(JSON.stringify(searchParam)));
            });
        }
    }

    searchFareForecaster(searchParam) {
        let processSearchParam = {
            directFlight: searchParam.directFlight,
            fareClass: searchParam.fareClass,
            tripType: getTripType(searchParam.multiFlights),
        };
        if (searchParam.multiFlights.length === 1) {
            processSearchParam = {
                ...processSearchParam,
                ...searchParam.multiFlights[0],
            };
        }
        else {
            processSearchParam = {
                ...processSearchParam,
                multiFlights: searchParam.multiFlights,
                depCity: searchParam.multiFlights[0].depCity,
                dstCity: searchParam.multiFlights[0].dstCity,
                depDate: searchParam.multiFlights[0].depDate,
                depTime: searchParam.multiFlights[0].depTime,
                isDepSchByAirport: searchParam.multiFlights[0].isDepSchByAirport,
                isDstSchByAirport: searchParam.multiFlights[0].isDstSchByAirport,
            }
        }
        let params = {
            profileToken: getProfileToken(),
            searchParam: {
                tokenSeq: "0",
                GdsType: "0",
                segType: 0,
                segSequence: 1,
                fareClass: "",
                searchType: 0,
                directFlight: 0,
                adultQty: 1,
                childQty: 0,
                infantQty: 0,
                refundableOnly: false,
                ...processSearchParam,
            },
            isFirstSearch: true,
            isNewSearch: true,
        };

        searchFlightCalendar(params, (err, data) => {
            let errMsg = null;
            if (data) {
                if (!data.FlightCalendars || !data.FlightCalendars.length || !data.DepTitles || !data.DepTitles.length) {
                    errMsg = this.props.intl.formatMessage({ id: 'fare_not_found' });
                }
                else if (!data.DepTitles.includes(searchParam.multiFlights[0].depDate)) {
                    errMsg = this.props.intl.formatMessage({ id: 'fare_not_found' });
                }
            }
            const state = {
                isLoading: false,
                result: data,
                error: err || errMsg || getError(data),
                detail: data && data.FlightCalendarDetail,
            };
            this.mounted && this.setState(state);
            if (!state.error) {
                this.resetSearchTimeout(JSON.parse(JSON.stringify(searchParam)));
            }
        });
    }

    resetSearchTimeout = searchParam => {
        this.searchTimeoutId && clearTimeout(this.searchTimeoutId);
        this.searchTimeoutId = setTimeout(() => {
            this.mounted && Modal.warning({
                centered: true,
                title: this.props.intl.formatMessage({ id: 'system_information' }),
                content: this.props.intl.formatMessage({ id: 'search_timeout_prompt' }),
                okText: this.props.intl.formatMessage({ id: 'refresh' }),
                onOk: () => this.handleSubmit(null, searchParam),
            });
        }, this.props.searchTimeoutMinutes * 60 * 1000);
    }

    handleSearchDetail = calendarId => {
        this.loadingTimeoutId && clearTimeout(this.loadingTimeoutId);
        this.loadingTimeoutId = setTimeout(() => {
            this.mounted && this.setState({ isDetailLoading: true });
        }, 500);
        const params = {
            CalendarId: calendarId,
            SearchRefKey: this.state.result.SearchRefKey,
            ProfileToken: getProfileToken(),
        }
        searchFlightCalendarDetail(params, (err, data) => {
            this.loadingTimeoutId && clearTimeout(this.loadingTimeoutId);
            if (data && (data.errorCode === ErrorCode.Timeout || data.ErrorCode === ErrorCode.Timeout)) {
                this.mounted && this.setState({ isDetailLoading: false });
                this.searchTimeoutId && clearTimeout(this.searchTimeoutId);
                Modal.warning({
                    title: this.props.intl.formatMessage({ id: 'system_information' }),
                    content: this.props.intl.formatMessage({ id: 'search_timeout_prompt' }),
                    okText: this.props.intl.formatMessage({ id: 'refresh' }),
                    onOk: () => this.handleSubmit(null, this.state.orgSearchParam),
                });
                return;
            }
            this.resetSearchTimeout(this.state.orgSearchParam);
            this.mounted && this.setState({ detail: data && data.FlightCalendarDetail, isDetailLoading: false, detailError: err || getError(data) });
        });
    }

    handlePrevOrNextDay = (isDep, isPrev) => {
        let searchParam = this.state.orgSearchParam;
        if (isDep) {
            searchParam.multiFlights[0].depDate = moment(searchParam.multiFlights[0].depDate, SEARCH_DATE_FORMAT).add(isPrev ? -1 : 1, 'days').format(SEARCH_DATE_FORMAT);
            if (searchParam.multiFlights[1]) {
                const _depDate = moment(searchParam.multiFlights[0].depDate);
                const _retDepDate = moment(searchParam.multiFlights[1].depDate);
                if (_retDepDate.isBefore(_depDate)) {
                    searchParam.multiFlights[1].depDate = searchParam.multiFlights[0].depDate;
                }
            }
        } else {
            searchParam.multiFlights[1].depDate = moment(searchParam.multiFlights[1].depDate, SEARCH_DATE_FORMAT).add(isPrev ? -1 : 1, 'days').format(SEARCH_DATE_FORMAT);
            const _depDate = moment(searchParam.multiFlights[0].depDate);
            const _retDepDate = moment(searchParam.multiFlights[1].depDate);
            if (_retDepDate.isBefore(_depDate)) {
                searchParam.multiFlights[0].depDate = searchParam.multiFlights[1].depDate;
            }
        }
        this.handleSubmit(null, searchParam);
    }

    render() {
        const { searchParam, closeForm, citiesInfo, orgSearchParam, isLoading, result, detail, error, isDetailLoading, detailError } = this.state;
        return (
            <Fragment>
                {!this.props.isWidgets && <Breadcrumb className='breadcrumb'>
                    <Breadcrumb.Item className='breadcrumb-item'><FormattedMessage id='fare_forecaster' /></Breadcrumb.Item>
                </Breadcrumb>}
                <div className='fare-forecaster'>
                    <FareForecasterForm
                        searchParam={searchParam}
                        handleSearchParamChange={this.handleSearchParamChange}
                        handleSubmit={this.handleSubmit}
                        closeForm={closeForm}
                        handleToggleForm={isLoading ? null : () => this.setState(prevState => ({ closeForm: !prevState.closeForm }))}
                        citiesInfo={citiesInfo}
                        orgSearchParam={orgSearchParam}
                        intl={this.props.intl}
                        isWidgets={this.props.isWidgets}
                    />

                    <FareForecasterResult
                        searchParam={orgSearchParam}
                        isLoading={isLoading}
                        result={result}
                        detail={detail}
                        isDetailLoading={isDetailLoading}
                        error={error}
                        detailError={detailError}
                        handleSearchDetail={this.handleSearchDetail}
                        handleGoToLightning={this.props.sso2Lightning}
                        intl={this.props.intl}
                        isLightMode={this.props.isLightMode}
                        handlePrevOrNextDay={this.handlePrevOrNextDay}
                        handleNotFoundRetry={() => this.handleSubmit(null, orgSearchParam)}
                        handleOpenForm={() => { this.setState({ closeForm: false }); window.scrollTo(0, 80); }}
                    />
                </div>
            </Fragment>
        );
    };
}

export default connect(state => ({
    isLightMode: state.userSetting.ThemeType === ThemeType.Breezy,
    defaultDepartureCity: state.userData.DefaultDepartureCity,
    searchTimeoutMinutes: state.application.sessionTimeoutMinutes,
}), dispatch => ({
    sso2Lightning: payload => dispatch(sso2Lightning(payload)),
    toggleAsideMenu: payload => dispatch(toggleAsideMenu(payload)),
}))(injectIntl(withRouter(BaseWidget(FareForecaster))));