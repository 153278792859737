import React, { Fragment } from 'react';
import { Tooltip, Row, Col, Button, Alert } from 'antd';
import moment from 'moment';
import { Loading, RecordNotFound } from '~/components';
import { config, combineUrl, getCurrencySymbol, getCurrentLang, Languages, getImageUrl } from '~/common';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';

const lang = getCurrentLang();
const API_TIME_FORMAT = 'YYYYMMDDHHmm';
const UI_DATE_FORMAT_4_TITLE = lang === Languages.en_us ? 'DD MMM[<br>]ddd' : 'MM月DD日[<br>]dddd';
const UI_DATE_FORMAT_4_CONTENT = lang === Languages.en_us ? 'DD MMM, ddd' : 'MM月DD日, dddd';
const UI_DATE_FORMAT_4_DETAIL = lang === Languages.en_us ? 'DD MMM YYYY' : 'MM月DD日';
const UI_TIME_FORMAT_4_DETAIL = lang === Languages.en_us ? 'h:mm A' : 'HH : mm';
const SEARCH_DATE_FORMAT = "YYYYMMDD";

function ResultFlightCalendars(props) {
    const {
        error,
        activeX,
        activeY,
        clickedX,
        clickedY,
        handleItemMouseEnter,
        handleItemMouseOut,
        handleItemClick,
        searchParam,
        handlePrevOrNextDay,
        handleNotFoundRetry,
        handleOpenForm,
    } = props;
    if (error) {
        return (
            <div className='flight-calendars-wrapper'>
                {
                    error === props.intl.formatMessage({ id: 'fare_not_found' }) ?
                        <RecordNotFound formatId='fare_not_found' style={{ height: '560px' }} >
                            <div>
                                <span className='link' onClick={handleNotFoundRetry}><FormattedMessage id='retry' /></span>
                                <span style={{ margin: '0 5px' }}><FormattedMessage id='or'></FormattedMessage></span>
                                <span className='link' onClick={handleOpenForm}><FormattedMessage id='update_search' /></span>
                            </div>
                        </RecordNotFound>
                        : <Alert type='error' className='error' message={error} />
                }
            </div>
        );
    }
    if (!props.result) {
        return (
            <div className='flight-calendars-wrapper'>
                <div className='first-loading-wrapper'>
                    <img src={getImageUrl('plane_loading.gif')} alt='' style={{ width: "220px" }} />
                    <div style={{ whiteSpace: 'nowrap', textAlign: "center" }}>
                        <FormattedHTMLMessage id='just_loading_your_search_results' />
                    </div>
                </div>
            </div>
        );
    }
    const { FlightCalendars: calendars, DepTitles: depDateArr, RetTitles: retDateArr } = props.result || {};

    return (
        <div className={`flight-calendars-wrapper ${retDateArr && retDateArr.length ? 'round-trip' : 'one-way'}`}>
            <div className='flight-calendars-mobile'>
                <div className='title'>
                    <FormattedMessage id='departing' />
                    <i className={`prev-day${moment().format(SEARCH_DATE_FORMAT) === searchParam.multiFlights[0].depDate ? ' disabled' : ''}`} onClick={() => handlePrevOrNextDay(true, true)}></i>
                    <i className='next-day' onClick={() => handlePrevOrNextDay(true, false)}></i>
                </div>
                {retDateArr && retDateArr.length ?
                    <table width='100%' border='0' cellPadding='5px' cellSpacing='0'>
                        <tbody>
                            <tr>
                                {depDateArr.map((date, index) => {
                                    const existLowest = calendars[index] && calendars[index].some(v => v && v.IsLowest);
                                    const existHighest = calendars[index] && calendars[index].some(v => v && v.IsHighest);
                                    return (
                                        <td
                                            key={index}
                                            dangerouslySetInnerHTML={{ __html: moment(date).format(UI_DATE_FORMAT_4_TITLE) }}
                                            className={`dep-col${index === clickedY ? ' active' : ''}${existLowest ? ' lowest' : ''}${existHighest ? ' highest' : ''}`}
                                            onClick={() => handleItemClick(clickedX, index, calendars[index] && calendars[index][clickedX] && calendars[index] && calendars[index][clickedX].CalendarId)}
                                        ></td>
                                    );
                                })}
                            </tr>
                        </tbody>
                    </table> :
                    <Row>
                        {depDateArr.map((depDate, depIndex) => {
                            const item = calendars[0][depIndex];
                            const valid = item && depDate === item.DepDate && item.TotalFare;
                            const active = valid && clickedY === depIndex;
                            return (
                                <Col
                                    className={`ret-row${!valid ? ' disabled' : item.IsLowest ? ' lowest' : item.IsHighest ? ' highest' : ''}${active ? ' active' : ''}`}
                                    key={depIndex}
                                    onClick={valid ? () => handleItemClick(-1, depIndex, item.CalendarId) : null}
                                >
                                    <Row>
                                        <Col span={12} className='date'>{moment(depDate).format(UI_DATE_FORMAT_4_CONTENT)}</Col>
                                        <Col span={12} className='price'>{valid ? item.TotalFare : <FormattedMessage id='no_data' />}</Col>
                                    </Row>
                                </Col>
                            );
                        })}
                    </Row>
                }

                {retDateArr && !!retDateArr.length &&
                    <Fragment>
                        <div style={{ marginTop: 20 }} className='title'>
                            <FormattedMessage id='returning' />
                            <i className={`prev-day${moment().format(SEARCH_DATE_FORMAT) === searchParam.multiFlights[1].depDate ? ' disabled' : ''}`} onClick={() => handlePrevOrNextDay(false, true)}></i>
                            <i className='next-day' onClick={() => handlePrevOrNextDay(false, false)}></i>
                        </div>
                        {clickedY > -1 &&
                            <Row>
                                {retDateArr.map((retDate, retIndex) => {
                                    const item = calendars[clickedY][retIndex];
                                    const valid = item && depDateArr[clickedY] === item.DepDate && retDate === item.RetDate && item.TotalFare;
                                    const active = valid && clickedX === retIndex;
                                    return (
                                        <Col
                                            className={`ret-row${!valid ? ' disabled' : item.IsLowest ? ' lowest' : item.IsHighest ? ' highest' : ''}${active ? ' active' : ''}`}
                                            key={retIndex}
                                            onClick={valid ? () => handleItemClick(retIndex, clickedY, item.CalendarId) : null}
                                        >
                                            <Row>
                                                <Col span={12} className='date'>{moment(retDate).format(UI_DATE_FORMAT_4_CONTENT)}</Col>
                                                <Col span={12} className='price'>{valid ? item.TotalFare : <FormattedMessage id='no_data' />}</Col>
                                            </Row>
                                        </Col>
                                    );
                                })}
                            </Row>
                        }
                    </Fragment>
                }
                <div className='lowest-fare-icon-wrapper'>
                    <span className='lowest-fare-icon'></span><FormattedMessage id='lowest_fare' />
                    <span className='highest-fare-icon'></span><FormattedMessage id='highest_fare' />
                </div>
            </div>
            <div className='flight-calendars-desktop'>
                {retDateArr && retDateArr.length ?
                    <div className="inner">
                        <div className='legend-departing'>
                            <FormattedMessage id='departing' />
                            <i className={`prev-day${moment().format(SEARCH_DATE_FORMAT) === searchParam.multiFlights[0].depDate ? ' disabled' : ''}`} onClick={() => handlePrevOrNextDay(true, true)}></i>
                            <i className='next-day' onClick={() => handlePrevOrNextDay(true, false)}></i>
                        </div>
                        <div className='right-content'>
                            <Row type='flex' justify='center' className='legend-returning'>
                                <Col span={21} offset={3} className='inner'>
                                    <FormattedMessage id='returning' />
                                    <i className={`prev-day${moment().format(SEARCH_DATE_FORMAT) === searchParam.multiFlights[1].depDate ? ' disabled' : ''}`} onClick={() => handlePrevOrNextDay(false, true)}></i>
                                    <i className='next-day' onClick={() => handlePrevOrNextDay(false, false)}></i>
                                </Col>
                            </Row>
                            <Row type='flex' justify='center'>
                                <Col span={3} className='row-head col-head'></Col>
                                {retDateArr.map((retDate, i) => <Col span={3} key={i} className={`col-head${activeX === i ? ' focus' : ''}${[0, 6].some(weekday => weekday === new Date(moment(retDate)).getDay()) ? ' weekend' : ''}`} dangerouslySetInnerHTML={{ __html: moment(retDate).format(UI_DATE_FORMAT_4_TITLE) }}></Col>)}
                            </Row>
                            {depDateArr.map((depDate, depIndex) => {
                                return (
                                    <Row key={depIndex} type='flex' justify='center'>
                                        <Col span={3} className={`row-head${activeY === depIndex ? ' focus' : ''}${[0, 6].some(weekday => weekday === new Date(moment(depDate)).getDay()) ? ' weekend' : ''}`} dangerouslySetInnerHTML={{ __html: moment(depDate).format(UI_DATE_FORMAT_4_TITLE) }}></Col>
                                        {retDateArr.map((retDate, retIndex) => {
                                            const item = calendars[depIndex][retIndex];
                                            const valid = item && depDate === item.DepDate && retDate === item.RetDate && item.TotalFare;
                                            const active = valid && clickedX === retIndex && clickedY === depIndex;
                                            const focus = (activeX === retIndex && depIndex <= activeY) || (activeY === depIndex && retIndex <= activeX);
                                            return (
                                                <Col
                                                    span={3}
                                                    className={`cell${!valid ? ' disabled' : item.IsLowest ? ' lowest' : item.IsHighest ? ' highest' : ''}${active ? ' active' : focus ? ' focus' : ''}`}
                                                    key={retIndex}
                                                    onMouseEnter={valid ? () => handleItemMouseEnter(retIndex, depIndex) : null}
                                                    onMouseOut={valid ? () => handleItemMouseOut() : null}
                                                    onClick={valid ? () => handleItemClick(retIndex, depIndex, item.CalendarId) : null}
                                                >
                                                    {valid ? item.TotalFare : <FormattedMessage id='no_data' />}
                                                </Col>
                                            );
                                        })}
                                    </Row>
                                );
                            })}
                        </div>
                    </div>
                    :
                    <div style={{ padding: '50px 0' }}>
                        <div className='one-way-title'>
                            <FormattedMessage id='departing' />
                            <i className={`prev-day${moment().format(SEARCH_DATE_FORMAT) === searchParam.multiFlights[0].depDate ? ' disabled' : ''}`} onClick={() => handlePrevOrNextDay(true, true)}></i>
                            <i className='next-day' onClick={() => handlePrevOrNextDay(true, false)}></i>
                        </div>
                        <Row type='flex' justify='center'>
                            {depDateArr.map((depDate, i) => <Col span={3} key={i} className={`col-head${activeY === i ? ' focus' : ''}${[0, 6].some(weekday => weekday === new Date(moment(depDate)).getDay()) ? ' weekend' : ''}`} dangerouslySetInnerHTML={{ __html: moment(depDate).format(UI_DATE_FORMAT_4_TITLE) }}></Col>)}
                        </Row>
                        <Row type='flex' justify='center'>
                            {depDateArr.map((depDate, depIndex) => {
                                const item = calendars[0][depIndex];
                                const valid = item && depDate === item.DepDate && item.TotalFare;
                                const active = valid && clickedY === depIndex;
                                return (
                                    <Col
                                        span={3}
                                        className={`cell${!valid ? ' disabled' : item.IsLowest ? ' lowest' : item.IsHighest ? ' highest' : ''}${active ? ' active' : ''}`}
                                        key={depIndex}
                                        onClick={valid ? () => handleItemClick(-1, depIndex, item.CalendarId) : null}
                                    >
                                        {valid ? item.TotalFare : <FormattedMessage id='no_data' />}
                                    </Col>
                                );
                            })}
                        </Row>
                    </div>
                }
                <div className='lowest-fare-icon-wrapper'>
                    <span className='lowest-fare-icon'></span><FormattedMessage id='lowest_fare' />
                    <span className='highest-fare-icon'></span><FormattedMessage id='highest_fare' />
                </div>
            </div>
        </div>
    );
}

function ResultCalendarDetail(props) {
    const { handleGoToLightning, error, isLoading, isLowest, isHighest, isLightMode } = props;
    const logoImg = isLightMode ? 'ctm_portal_navy.svg' : 'ctm_portal_white.svg';
    if (error) return <div className='calendar-detail-wrapper'><Alert type='error' className='error' message={error} /></div>;
    if (!props.detail) return <div className='calendar-detail-wrapper empty'><div className='first-loading-wrapper'><img src={getImageUrl(logoImg)} height={35} alt='' /></div></div>;
    const { DepartFlight: depFlight, ReturnFlight: retFlight, FareDetail: fareDetail, Url: ssoDirectUrl } = props.detail || {};
    return (
        <div className='calendar-detail-wrapper'>
            {isLoading && <Loading />}
            {[depFlight, retFlight].map((flight, index) => {
                if (!flight) return null;
                const isDepFlight = index === 0;
                let totalDuration = 0;
                flight.Segments.forEach((segment, segIndex) => {
                    if (segIndex > 0) totalDuration += moment(segment.DepTime, API_TIME_FORMAT).diff(moment(flight.Segments[segIndex - 1].ArrTime, API_TIME_FORMAT), 'minutes')
                    totalDuration += segment.ElapsedTime;
                });
                return (
                    <div key={index} className='detail-flight-wrapper'>
                        <div className='detail-flight-title'><FormattedMessage id={isDepFlight ? 'departure' : 'return'} /></div>
                        <div className='detail-flight-content'>
                            <div className='clearfix'>
                                {flight.Segments.map((seg, i) => {
                                    return (
                                        <span key={i}>
                                            {(i === 0 || seg.MarketingCarrier !== flight.Segments[i - 1].MarketingCarrier) &&
                                                <Fragment>
                                                    {i > 0 && <br />}
                                                    <img className='airline-logo' alt='' src={combineUrl(config.obtUrl, `App/images/airline/${seg.MarketingCarrier}.png`)} width={25} />
                                                    <span style={{ marginRight: 5 }}>{seg.MarketingCarrierName}</span>
                                                </Fragment>
                                            }
                                            <span>{`${i > 0 && seg.MarketingCarrier === flight.Segments[i - 1].MarketingCarrier ? ' / ' : ''}${seg.MarketingCarrier}${seg.FlightNumber}`}</span>
                                        </span>
                                    );
                                })}
                                <span style={{ float: 'right', marginLeft: 10 }}>{flight.Segments[0].FareClassName}</span>
                            </div>
                            <div>
                                <Row>
                                    <Col span={8} style={{ textAlign: 'left' }}>
                                        <div className='flight-airport-wrapper'>
                                            <Tooltip title={flight.Segments[0].DepAirportName}>
                                                <b>{flight.Segments[0].DepAirport}</b>
                                            </Tooltip>
                                        </div>
                                        <div>
                                            {moment(flight.Segments[0].DepTime, API_TIME_FORMAT).format(UI_TIME_FORMAT_4_DETAIL)}
                                        </div>
                                        <div>
                                            {moment(flight.Segments[0].DepTime, API_TIME_FORMAT).format(UI_DATE_FORMAT_4_DETAIL)}
                                        </div>
                                    </Col>
                                    <Col span={8} style={{ textAlign: 'center' }}>
                                        <div className='flight-icon-wrapper'>
                                            <img alt='->' src={combineUrl(config.localhostUrl, 'images/flight-dep.png')} width={15} />
                                        </div>
                                        <div>{flight.Segments.length === 1 ?
                                            <FormattedMessage id='direct' /> :
                                            <span><FormattedMessage id='stop' /> <Tooltip title={flight.Segments[0].ArrAirportName}>{flight.Segments[0].ArrAirport}</Tooltip></span>}
                                        </div>
                                        <div>
                                            <FormattedMessage id='duration_fmt' values={{ hours: parseInt(totalDuration / 60), minutes: totalDuration % 60 }} />
                                        </div>
                                    </Col>
                                    <Col span={8} style={{ textAlign: 'right' }}>
                                        <div className='flight-airport-wrapper'>
                                            <Tooltip title={flight.Segments[flight.Segments.length - 1].ArrAirportName}>
                                                <b>{flight.Segments[flight.Segments.length - 1].ArrAirport}</b>
                                            </Tooltip>
                                        </div>
                                        <div>
                                            {moment(flight.Segments[flight.Segments.length - 1].ArrTime, API_TIME_FORMAT).format(UI_TIME_FORMAT_4_DETAIL)}
                                        </div>
                                        <div>
                                            {moment(flight.Segments[flight.Segments.length - 1].ArrTime, API_TIME_FORMAT).format(UI_DATE_FORMAT_4_DETAIL)}
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </div>
                    </div>
                );
            })}
            {fareDetail &&
                <div className={`detail-fare-wrapper${isLowest ? ' lowest' : isHighest ? ' highest' : ''}`}>
                    <b>{getCurrencySymbol(fareDetail.Currency)}{fareDetail.TotalFare}</b>
                    <span>{fareDetail.Currency}</span>
                </div>
            }
            {ssoDirectUrl &&
                <div>
                    <Button className='detail-bkg-btn' type='primary' onClick={() => handleGoToLightning({ DirectUrl: ssoDirectUrl })}><FormattedMessage id='booking_now' />
                        <img src={getImageUrl('open_white.png')} alt='Open' height={15} style={{ marginLeft: 5, transform: 'translateY(-2px)' }} />
                    </Button>
                </div>
            }
        </div>
    );
}

class FareForecasterResult extends React.Component {
    constructor() {
        super();
        this.state = {
            activeX: -1,
            activeY: -1,
            clickedX: -1,
            clickedY: -1,
        };
    }

    componentWillUpdate() {
        this.SEARCH_PARAM_JSON = JSON.stringify(this.props.searchParam);
        this.RESULT_JSON = JSON.stringify(this.props.result);
    }

    componentDidUpdate() {
        const { error, result, searchParam } = this.props;
        const isNewSearch = this.SEARCH_PARAM_JSON !== JSON.stringify(searchParam) || this.RESULT_JSON !== JSON.stringify(result);
        if (!error && result && isNewSearch) {
            let activeX = -1, activeY = -1;
            const { FlightCalendars: calendars, DepTitles: depDateArr, RetTitles: retDateArr } = result;
            if (retDateArr && retDateArr.length) {//round trip
                let tempX = retDateArr.indexOf(searchParam.multiFlights[1].depDate);
                let tempY = depDateArr.indexOf(searchParam.multiFlights[0].depDate);
                const current = calendars[tempY][tempX];
                const valid = current && current.TotalFare && depDateArr[tempY] === current.DepDate && retDateArr[tempX] === current.RetDate;
                if (valid) {
                    activeX = tempX;
                    activeY = tempY;
                }
            } else {//one way
                let tempY = depDateArr.indexOf(searchParam.multiFlights[0].depDate);
                const current = calendars[0][tempY];
                const valid = current && current.TotalFare && depDateArr[tempY] === current.DepDate;
                if (valid) {
                    activeY = tempY;
                }
            }
            setTimeout(() => {
                this.setState({ clickedX: activeX, clickedY: activeY });
                this.handleItemChanged(activeX, activeY);
            });
        }
    }

    handleItemMouseEnter = (x, y) => {
        this.mouseEnterTimeoutId && clearTimeout(this.mouseEnterTimeoutId);
        this.mouseEnterTimeoutId = setTimeout(() => {
            this.setState({ activeX: x, activeY: y });
        }, 200);
    }

    handleItemMouseOut = () => {
        this.mouseOutTimeoutId && clearTimeout(this.mouseOutTimeoutId);
        this.mouseEnterTimeoutId && clearTimeout(this.mouseEnterTimeoutId);
        this.mouseOutTimeoutId = setTimeout(() => {
            this.setState({ activeX: -1, activeY: -1 });
        }, 200);
    }

    handleItemClick = (clickedX, clickedY, calendarId) => {
        this.setState({ clickedX, clickedY });
        this.handleItemChanged(clickedX, clickedY);
        this.props.handleSearchDetail(calendarId);
    }

    handleItemChanged = (clickedX, clickedY) => {
        if (clickedX === -1 && clickedY === -1) return;
        const result = this.props.result;
        const isRoundTrip = result.RetTitles && result.RetTitles.length;
        const calendarItem = isRoundTrip ? result.FlightCalendars[clickedY][clickedX] : result.FlightCalendars[0][clickedY];
        const isLowest = calendarItem && calendarItem.IsLowest;
        const isHighest = calendarItem && calendarItem.IsHighest;
        this.setState({ isLowest, isHighest });
    }

    render() {
        const { isLoading, searchParam, result, error, handleGoToLightning, detail, isDetailLoading, detailError, isLightMode, handlePrevOrNextDay } = this.props;
        if (!isLoading && !searchParam && !error && !result) return null;
        return (
            <div className='fare-forecaster-result-wrapper'>
                {isLoading && result && <Loading />}
                <Row gutter={16}>
                    <Col xl={18} lg={24}>
                        <ResultFlightCalendars
                            {...this.props}
                            {...this.state}
                            handleItemMouseEnter={this.handleItemMouseEnter}
                            handleItemMouseOut={this.handleItemMouseOut}
                            handleItemClick={this.handleItemClick}
                            searchParam={searchParam}
                            handlePrevOrNextDay={handlePrevOrNextDay}
                        />
                    </Col>
                    <Col xl={6} lg={24}>
                        <ResultCalendarDetail
                            detail={detail}
                            isLoading={isDetailLoading}
                            error={detailError}
                            handleGoToLightning={handleGoToLightning}
                            isLowest={this.state.isLowest}
                            isHighest={this.state.isHighest}
                            isLightMode={isLightMode}
                        />
                    </Col>
                </Row>
            </div>
        );
    }
}

export default FareForecasterResult;