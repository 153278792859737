import React from 'react';
import * as PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import moment from 'moment';
import { getTravelStatus, isEmpty ,Languages, getCurrentLang} from '~/common';
import { Tooltip, Popover, Spin, Icon } from 'antd';

const API_DATE_FORMAT = 'YYYYMMDD';
const UI_DATE_FORMAT = getCurrentLang() === Languages.en_us ? 'DD MMM YYYY (ddd)':'YYYY年MM月DD日 (ddd)'; 

class HotelSummary extends React.Component {

    renderPopup(depAirportName, arrAirportName, passengers) {
        return (
            <div className='todays-flights-popup' onClick={(e) => e.stopPropagation()}>
                {depAirportName && arrAirportName && <h4>{depAirportName} To {arrAirportName}</h4>}
                <ul>
                    {passengers.map(p => <li key={p.Id}>{p.Name} {p.Email ? <a href={`mailto:${p.Email}`}><i className='email-icon'>&nbsp;</i></a> : null}</li>)}
                </ul>
            </div>
        );
    }

    render() {
        const { summary, isLoading, isActive, showActiveIndicator } = this.props;
        const loadingIcon = <Icon type='loading' />;
        const travelStatus = getTravelStatus(summary.TravelStateType);
        return <div className={`hotel-summary-info${isActive ? ' active' : ''}${isActive && showActiveIndicator ? ' with-indicator' : ''}`}>
            <div
                className={`hotel-summary-item`}
                onClick={() => {
                    if (document.getElementById('popover')) return;
                    return this.props.handleTripClick(summary);
                }}
            >
                <div className="item-row-top">
                    <div className="title-name">
                        <Tooltip title={summary.Travelers} className='title-name-inner'>{summary.Travelers}</Tooltip>
                    </div>
                    <div className="title-email">
                        {summary.TravelerDetails && summary.TravelerDetails.filter(item => !isEmpty(item.Email)) && <div className="email" onClick={(e) => e.stopPropagation()}>
                            <Popover placement="left" id='popover' destroyTooltipOnHide={true} content={this.renderPopup(null, null, summary.TravelerDetails)} trigger="hover">
                                <i className="email-icon">&nbsp;</i>{summary.TravelerDetails.length}
                            </Popover>
                        </div>}
                    </div>
                </div>
                <div className='item-row-name'>
                    <span className='addr-icon'></span>
                    <span>{`${summary.HotelName} - ${summary.CityName}`}</span>
                </div>
                <div className='item-row-content'>
                    <div className='checkin-info'>
                        <div className='key-font'>
                            <FormattedMessage id='check_in' />
                        </div>
                        <div className='date-font'>
                            {moment(summary.CheckinDateRemark, API_DATE_FORMAT).format(UI_DATE_FORMAT).toUpperCase()}
                        </div>

                    </div>
                    <div className='bed-icon'></div>
                    <div className='checkout-info'>
                        <div className='key-font'>
                            <FormattedMessage id='check_out' />
                        </div>
                        <div className='date-font'>
                            {moment(summary.CheckoutDateRemark, API_DATE_FORMAT).format(UI_DATE_FORMAT).toUpperCase()}
                        </div>
                    </div>

                </div>
                <div className="item-row-bottom">
                    <div>
                        <FormattedMessage id='status' />
                        <span className='travel-status'><FormattedMessage id={travelStatus.statusCode.replace('-', '_')} /></span>
                    </div>
                    <div className='detail-link' onClick={(e) => this.props.handleDetailClick(e, summary.OrderId)}>
                        <FormattedMessage id='click_for_detail' />
                        {!isLoading && <span className='indicator'></span>}
                        {isLoading && <Spin size='small' className='loading-icon' indicator={loadingIcon} />}
                    </div>
                </div>
            </div>

        </div>;
    }
}

HotelSummary.propTypes = {
    summary: PropTypes.object.isRequired,
    handleTripClick: PropTypes.func.isRequired,
    handleDetailClick: PropTypes.func.isRequired,
    isLoading: PropTypes.bool,
    isActive: PropTypes.bool,
    showActiveIndicator: PropTypes.bool,
}

export default HotelSummary;